import { useEffect, useState, useRef } from "react";
import "./PresetsPlanCrud.scss";
import ModalContainer from "./ModalContainer";
import useNotification from "../../hooks/notification.hook";
import { Transition } from "react-transition-group";
import useCommonHelper from "../../hooks/helper.hook";
import Table from "../utils/table/Table";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import ActionBtnItem from "../utils/ActionBtnItem";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import ConfirmModal from "./ConfirmPopup";
import StatusContent from "../plan-download/StatusContent";
import BoxPlanOrganizationTemplate from "./BoxPlanOrganizationTemplate";
import * as Accordion from "@radix-ui/react-accordion";
import {
  createTemplatePreset,
  deleteTemplatePreset,
  editTemplatePreset,
  getTemplatePresets,
} from "../../services/presets.service";
import { getPresetSelection } from "../../helper/formatter.helper";
import EmptyBox from "../emptystate/EmptyBox";

export default function PresetsPlanCrud({
  open,
  setOpen,
  planTemplate,
  onListUpdate = () => {},
  statuses = [],
  template,
}) {
  const { openNotification, helperErrorNotification } = useNotification();

  const { animationClass } = useCommonHelper();

  const [loading, setLoading] = useState(false);

  const [wizardStep, setWizardStep] = useState(1);

  const [wizardVisible, setWizardVisible] = useState(1);

  const [presetsList, setPresetsList] = useState(
    planTemplate.print_templates ?? [],
  );

  const [selectedStatus, setSelectedStatus] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const firstStepRef = useRef(null);

  const secondStepRef = useRef(null);

  const [selectedChapters, setSelectedChapters] = useState({
    chapters: [],
    contents: [],
    goals: [],
    reqs: [],
  });

  const [includeDeadlines, setIncludeDeadlines] = useState(false);

  const { t } = useTranslation();

  // Set message
  yup.setLocale({
    // use constant translation keys for messages without values
    mixed: {
      required: ({ path }) =>
        t("common:errors.required", { field: t(`common:fields.${path}`) }),
    },
  });

  // Schema Yup
  const schema = yup.object().shape({
    name: yup.string().required(),
  });

  const {
    register,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const formHandler = (data) => {
    if (!loading) {
      setLoading(true);

      const templateChapters = selectedChapters.chapters;

      const templateChapterContents = selectedChapters.contents.map(
        (content) => content.id,
      );

      const templateGoals = selectedChapters.goals.map((goal) => goal.id);

      const templateRequirements = selectedChapters.reqs.map((r) => r.id);

      const body = {
        name: data.name,
        options: {
          organizationRequirementsStatuses: selectedStatus,
          templateChapters,
          templateChapterContents,
          templateGoals,
          templateRequirements,
          deadlines: includeDeadlines,
        },
      };

      if (selectedItem?.id) {
        editTemplatePreset(
          { id: selectedItem?.id, ...body },
          () => {
            getListStatus();
            setWizardStep(0);
            setLoading(false);
            openNotification({
              text: "Preset modificato con successo!",
              status: "success",
            });
          },
          (err) => {
            setLoading(false);
            helperErrorNotification(err);
          },
        );
      } else {
        createTemplatePreset(
          { templateId: planTemplate.id, ...body },
          (response) => {
            getListStatus();
            setWizardStep(0);
            setLoading(false);
            openNotification({
              text: "Preset creato con successo!",
              status: "success",
            });
          },
          (err) => {
            setLoading(false);
            helperErrorNotification(err);
          },
        );
      }
    }
  };

  const selectItem = (item) => {
    setValue("name", item?.name ?? "");
    setIncludeDeadlines(!!item?.options?.deadlines);
    setSelectedStatus(item?.options?.organizationRequirementsStatuses ?? []);
    let data = {
      chapters: [],
      contents: [],
      goals: [],
      reqs: [],
    };

    if (item) {
      data = getPresetSelection(
        template,
        item?.options?.templateRequirements,
        item?.options?.templateChapterContents,
      );
    }
    setSelectedChapters(data);
    setSelectedItem(item);
    setWizardStep(0);
  };

  const getListStatus = () => {
    getTemplatePresets({ id: planTemplate.id }, (response) => {
      setPresetsList(response.data);
      onListUpdate(response.data);
    });
  };

  const deleteStatusHandler = () => {
    if (!loading) {
      deleteTemplatePreset(
        selectedItem.id,
        () => {
          setSelectedItem(null);
          setIsDeleteOpen(false);
          openNotification({
            text: "Stato eliminato con successo!",
            status: "success",
          });
          getListStatus();
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          helperErrorNotification(err);
        },
      );
    }
  };

  useEffect(() => {
    if (open) {
      setWizardStep(1);
      setWizardVisible(1);
      setSelectedStatus([]);
      setSelectedItem(null);
      setIncludeDeadlines(false);
      setSelectedChapters({
        chapters: [],
        contents: [],
        goals: [],
        reqs: [],
      });
      setValue("name", "");
    }
  }, [open]);

  useEffect(() => {
    clearErrors();
  }, [selectedItem]);

  const toggleStatus = (status) =>
    selectedStatus.find((s) => s === status)
      ? setSelectedStatus((list) => list.filter((s) => s != status))
      : setSelectedStatus((l) => [...l, status]);

  const toggleAllStatus = () =>
    selectedStatus.length === statuses.length
      ? setSelectedStatus([])
      : setSelectedStatus(statuses.map((s) => s.id));

  /**
   * Errors Form
   */
  useEffect(() => {
    if (Object.keys(errors).length) {
      const fields = Object.values(errors).map((er) => er.message);
      openNotification({ fields, status: "error" });
    }
  }, [errors]);

  return (
    <ModalContainer isOpen={open} setIsOpen={setOpen} disableClose={loading}>
      <div className="main_modal_content presets_plan_crud_popup">
        <div className="main_close" onClick={() => !loading && setOpen(false)}>
          <span className="icon_10 default close icon_mask !bg-white"></span>
        </div>

        {wizardVisible === 1 && (
          <div
            className={
              "transition-opacity " + (wizardStep === 0 ? "opacity-0" : "")
            }
          >
            <div className="main_header_modal flex_header">
              {/* Left content */}
              <div className="left_content">
                <div className="main_desc">
                  <span className="title">
                    Gestione configurazioni di stampa
                  </span>
                </div>
              </div>

              <div className="main_plan">
                <div className="main_icon">
                  <span
                    className={
                      "icon_24 default plan icon_mask !bg-primary-100 " +
                      (!planTemplate.diff_download?.length ? "piano" : "branch")
                    }
                  ></span>
                </div>
                <div className="main_info">
                  <div className="name">{planTemplate.name}</div>
                  <div className="description">{planTemplate.description}</div>
                </div>
              </div>
            </div>
          </div>
        )}

        {wizardVisible === 2 && (
          <div
            className={
              "transition-opacity " + (wizardStep === 0 ? "opacity-0" : "")
            }
          >
            <div className="main_header_modal flex_header">
              <div className="left_content">
                <div
                  className="main_back_button"
                  onClick={() => setWizardStep(0)}
                >
                  <span className="icon_16 default arrow left icon_mask !bg-interactive-100"></span>
                </div>

                <div className="main_desc">
                  <span className="title">
                    {selectedItem?.id ? "Modifica preset" : "Nuovo preset"}
                  </span>
                  <div className="desc">{planTemplate.name}</div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={"main_body_modal"}>
          {/* List Reqs */}

          <Transition
            nodeRef={firstStepRef}
            in={wizardStep === 1}
            mountOnEnter
            onExited={() => {
              setWizardStep(2);
              setWizardVisible(2);
            }}
            unmountOnExit
            timeout={{ enter: 250, exit: 250 }}
          >
            {(state) => (
              <div
                ref={firstStepRef}
                className={
                  "main_list_items " +
                  animationClass(state, "wizard_step_in", "wizard_step_out")
                }
              >
                <div className="main_title">
                  <div className="left_content">
                    Configurazioni: {presetsList.length}
                  </div>
                  <div
                    className="right_content"
                    onClick={() => selectItem(null)}
                  >
                    <div className="icon_16 default plus icon_mask !bg-interactive-100"></div>
                    Nuovo
                  </div>
                </div>

                {presetsList.length > 0 ? (
                  <OverlayScrollbarsComponent
                    defer
                    options={{ scrollbars: { autoHide: "scroll" } }}
                    className="main_table_status"
                  >
                    {/* Table organizations */}
                    <Table className="layout_popup big_row">
                      {/* Table Heade */}
                      <Table.Head className="sticky_head">
                        <Table.Column className="!pl-4 !pb-3">
                          Nome
                        </Table.Column>
                        <Table.Column></Table.Column>
                      </Table.Head>

                      {/* Table Body */}
                      <Table.Body>
                        {presetsList.map((status, index) => (
                          <Table.Row key={status.id}>
                            <Table.Cell>{status.name}</Table.Cell>

                            <Table.Cell width={50}>
                              <div className="flex gap-3">
                                {/* Edit */}
                                <ActionBtnItem
                                  tooltip="Modifica"
                                  onClick={() => selectItem(status)}
                                  icon="edit"
                                />

                                {/* Delete*/}
                                <ActionBtnItem
                                  tooltip={"Elimina"}
                                  onClick={() => {
                                    setIsDeleteOpen(true);
                                    setSelectedItem(status);
                                  }}
                                  icon="delete"
                                />
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </OverlayScrollbarsComponent>
                ) : (
                  <EmptyBox
                    title={"Nessuna configurazione trovata"}
                    icon={"icon_75 default archive_plan"}
                  />
                )}
              </div>
            )}
          </Transition>

          <Transition
            nodeRef={secondStepRef}
            in={wizardStep === 2}
            mountOnEnter
            onExited={() => {
              setWizardStep(1);
              setWizardVisible(1);
              setSelectedItem(null);
            }}
            unmountOnExit
            timeout={{ enter: 250, exit: 250 }}
          >
            {(state) => (
              <div
                ref={secondStepRef}
                className={animationClass(
                  state,
                  "wizard_step_in",
                  "wizard_step_out",
                )}
              >
                <form
                  id="crud-preset-plan-form"
                  className="global_form_landing"
                  onSubmit={handleSubmit(formHandler)}
                  noValidate
                >
                  {/* Name */}
                  <div className="base ">
                    <div className="wrap_input_landing">
                      <label htmlFor="crud_preset_name" className="label_input">
                        Nome
                      </label>
                      <input
                        className={"landing big" + (errors.name ? " err" : "")}
                        id="crud_preset_name"
                        type="text"
                        {...register("name")}
                      />
                    </div>
                  </div>
                </form>

                <Accordion.Root
                  className=""
                  type="single"
                  defaultValue="item-1"
                  collapsible
                >
                  <Accordion.Item
                    value="item-1"
                    className="shadow-[0_1px_0] shadow-dark-10"
                  >
                    <Accordion.Trigger asChild={true}>
                      <div className="px-7 pb-4 pt-5 flex justify-between w-full items-center cursor-pointer group">
                        <div className="text-16 font-medium">
                          Seleziona gli stati da includere
                        </div>
                        <span className="icon_16 chevron default icon_mask !bg-dark-70 group-data-[state=open]:rotate-180"></span>
                      </div>
                    </Accordion.Trigger>
                    <Accordion.Content className="data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden">
                      <StatusContent
                        selectedStatues={selectedStatus}
                        listStatuses={statuses}
                        onToggleStatus={toggleStatus}
                        onToggleAllStatus={toggleAllStatus}
                        className="pb-3"
                      />
                    </Accordion.Content>
                  </Accordion.Item>

                  <Accordion.Item value="item-2">
                    <Accordion.Trigger asChild={true}>
                      <div className="px-7 pb-4 pt-5 flex justify-between w-full items-center cursor-pointer group">
                        <div className="text-16 font-medium">
                          Seleziona il contenuto da includere
                        </div>
                        <span className="icon_16 chevron default icon_mask !bg-dark-70 group-data-[state=open]:rotate-180"></span>
                      </div>
                    </Accordion.Trigger>
                    <Accordion.Content className="data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden">
                      <div className="pb-3">
                        <BoxPlanOrganizationTemplate
                          template={template}
                          setSelectedItems={setSelectedChapters}
                          selectedItems={selectedChapters}
                          showDeadlines={true}
                          isDeadlineSelected={includeDeadlines}
                          includeDeadlines={setIncludeDeadlines}
                          className="!h-full max-h-[380px]"
                        />
                      </div>
                    </Accordion.Content>
                  </Accordion.Item>
                </Accordion.Root>
              </div>
            )}
          </Transition>
        </div>

        <div
          className={
            "main_footer_modal" + (wizardVisible === 1 ? " empty" : "")
          }
        >
          <div className="right_content">
            {wizardVisible === 2 && (
              <div
                className={
                  "transition-opacity " + (wizardStep === 0 ? "opacity-0" : "")
                }
              >
                <button
                  form={"crud-preset-plan-form"}
                  className={
                    "button fill success" + (loading ? " loading" : "")
                  }
                >
                  <div className="load_content">Salva</div>
                  <div className="main_loader_icon fade_in"></div>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Confirm content */}
      <ConfirmModal
        open={isDeleteOpen}
        setOpen={setIsDeleteOpen}
        title={"Elimina preset"}
        alertMessage="Attenzione! Questa operazione è irreversibile! <b>Sei sicuro di procedere?</b>"
        ctaLabel="Elimina"
        onConfirm={deleteStatusHandler}
      />
    </ModalContainer>
  );
}
