import Header from "../elements/Header";
import Footer from "../elements/Footer";
import c from "./LayoutNav.module.scss"
import { Tooltip } from "react-tooltip";

function LayoutNav({children, withSubheader}) {
    return (
        <>
            <div className={c.main_layout}>
                <Header withSubheader={withSubheader} />

                <div className={c.main_page}>{children}</div>
                <Footer />
            </div>
            <div id="modal-container-el"></div>
            <Tooltip id="tooltip_small_top" classNameArrow="tooltipster_arrow" className="tooltipster small" />
        </>
    );
}

export default LayoutNav;