import { apiClient } from "./config"





/**
 * Upload image editor
 * @param {image} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const uploadImageEditor = async (params, successCallback = false, errorCallback = false) => {
    try{
        const response = await apiClient.post(`/editorjs/upload-image`, params, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}