import c from "./TableContainer.module.scss"
import Pagination from "../utils/Pagination";
import Table from "../utils/table/Table";
import CrudPlanModal from "../popups/CrudPlanPopup";
import ConfirmModal from "../popups/ConfirmPopup";
import {useContext, useState} from "react";
import {Link} from "react-router-dom";
import {deleteTemplatePlan} from "../../services/plans-template.service";
import DuplicatePlanModal from "../plans/DuplicatePlan";
import useNotification from "../../hooks/notification.hook";
import LoadingBox from '../loading/LoadingBox';
import EmptyBox from "../emptystate/EmptyBox";
import GlobalContext from "../../context/global.context";

function TableTemplatePlans({
    isLoading,
    plans,
    updatePage,
    pageData,
    onInsert,
    onEdit,
    onDelete,
    onClone,
    onSearch,
    isInit
}) {

    const [crudOpen, setCrudOpen] = useState(false);

    const [duplicateOpen, setDuplicateOpen] = useState(false);

    const [deleteOpen, setDeleteOpen] = useState(false);

    const [deleteItem, setDeleteItem] = useState(null);

    const [duplicateItem, setDuplicateItem] = useState(null);

    const [editPlan, setEditPlan] = useState(null);

    const {openNotification, helperErrorNotification} = useNotification()

    const { token } = useContext(GlobalContext)


    /**
     * Edit request
     * @param {*} plan
     */
    const openEdit = (plan) => {
        setEditPlan(plan)
        setCrudOpen(true)
    }


    /**
     * Create request
     */
    const createOpen = () => {
        setEditPlan(null)
        setCrudOpen(true)
    }


    /**
     * Request Delete
     * @param {*} item
     */
    const deleteRequest = (item) => {
        setDeleteItem(item)
        setDeleteOpen(true)
    }


    /**
     * Request duplicate
     * @param {*} item
     */
    const duplicateRequest = (item) => {
        setDuplicateItem(item)
        setDuplicateOpen(true)
    }


    /**
     * insert callback
     * @param {I} res
     */
    const onInsertHandler = (res) => {
        setCrudOpen(false)
        onInsert(res)
    }


    /**
     * edit callback
     * @param {*} res
     */
    const onEditHandler = (res) => {
        setCrudOpen(false)
        onEdit(res)
    }


    /**
     * Delete handler
     */
    const onDeleteHandler = () => {
        setDeleteOpen(false)
        deleteTemplatePlan(deleteItem.id, () => {
            openNotification({text: "Piano eliminato con successo!", status: "success"})
            onDelete()
        }, (err) => {
            helperErrorNotification(err)
        })
    }


    /**
     * Clone Handler
     */
    function onCloneHandler() {
        setDuplicateOpen(false)
        onClone()
    }


    /**
     * Download plan difference
     * @param url
     */
    function downloadDifference(url, filename){

        // Bearer
        const authHeader = `Bearer ${token}`

        // Options
        const options = {
            headers: {
                Authorization: authHeader
            }
        };

        // Fetch
        fetch(url, options)
            .then(res => {
                return res.blob()
            })
            .then( blob => {
                const fileURL = window.URL.createObjectURL(blob);
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.download = filename;
                fileLink.click();
            });
    }


    return (
        <>
            <div className={c.main_intro}>
                <div className={c.left_content}>
                    <div className={c.title}>Piani</div>
                </div>
                <div className={c.right_content}>
                    <div className="wrap_input_landing icon">
                        <input type="text" onChange={(e) => onSearch(e.target.value)} className="landing"
                               placeholder="Ricerca..."/>
                        <span className="icon_16 default search"></span>
                    </div>
                    <button className="button fill primary small" onClick={createOpen}>
                        <div className="load_content">Nuovo</div>
                    </button>
                </div>
            </div>

            <div className="relative">
                {plans.length > 0 ?
                    <div className="fade_in">

                        <Table>
                            <Table.Head>
                                <Table.Column>Anno</Table.Column>
                                <Table.Column>Nome</Table.Column>
                                <Table.Column>Contenuto</Table.Column>
                                <Table.Column>Enti abilitati</Table.Column>
                                <Table.Column>Attivo</Table.Column>
                                <Table.Column>In lavorazione</Table.Column>
                                <Table.Column></Table.Column>
                            </Table.Head>
                            <Table.Body>
                                {plans.map((el, index) => (
                                    <Table.Row key={index} className="action_hover_list">
                                        <Table.Cell width={80}>{el.year}</Table.Cell>
                                        <Table.Cell width={500}>
                                            <div className="max-w-[400px] flex align-middle">
                                                <div className="mr-[13px]">
                                                    <div className={"h-[40px] w-[40px] bg-primary-10 flex items-center justify-center rounded-[4px]"}>
                                                        {(!el.diff_download?.length)
                                                            ?
                                                            <span className="icon_24 default icon_mask !bg-primary-100 piano"></span>
                                                            :
                                                            <span className="icon_24 default icon_mask !bg-primary-100 branch"></span>
                                                        }
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="title">{el.name}</div>
                                                    <div className="subtitle line-clamp-1">{el.description}</div>
                                                </div>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell width={250}>
                                            <div className="spacing_items">
                                                <div className="main_icon_text">
                                                    <span className="icon_16 default folder"></span>
                                                    {el.template_chapters_count}
                                                </div>
                                                <div className="main_icon_text">
                                                    <span className="icon_16 default text_box"></span>
                                                    {el.template_chapter_contents_count}
                                                </div>
                                                <div className="main_icon_text">
                                                    <span className="icon_16 default layers"></span>
                                                    {el.template_goals_count}
                                                </div>
                                                <div className="main_icon_text">
                                                    <span className="icon_16 default layer"></span>
                                                    {el.template_requirements_count}
                                                </div>
                                            </div>
                                        </Table.Cell>

                                        <Table.Cell width={100}>
                                            <div className="main_icon_text">
                                                <span className="icon_16 default entity"></span>
                                                {el.active_organization_plans_count}
                                            </div>
                                        </Table.Cell>

                                        <Table.Cell width={100}>
                                            <div className="main_icon_text">
                                                <span className={"icon_16 default icon_mask " + (el.active ? "check !bg-green-100" : "cross !bg-red-100")}></span>
                                                {el.active ? "Si" : "No"}
                                            </div>
                                        </Table.Cell>

                                        <Table.Cell width={100}>
                                            <div className="main_icon_text">
                                                <span className={"icon_16 default icon_mask " + (el.working ? "check !bg-green-100" : "cross !bg-red-100")}></span>
                                                {el.working ? "Si" : "No"}
                                            </div>
                                        </Table.Cell>

                                        <Table.Cell width={40}>
                                            <div className="spacing_items">

                                                <div className="action_list">
                                                    {(!!el.diff_download?.length) &&
                                                        <button onClick={() => downloadDifference(el.diff_download, el.diff_filename)} className="action" data-tooltip-id="tooltip_small_top"
                                                           data-tooltip-offset={10} data-tooltip-content="Scarica differenze">
                                                            <span
                                                                className="icon_16 default download_diff icon_mask !bg-interactive-100"></span>
                                                        </button>
                                                    }
                                                    <button className="action" onClick={() => openEdit(el)}
                                                            data-tooltip-id="tooltip_small_top" data-tooltip-offset={10}
                                                            data-tooltip-content="Modifica">
                                                        <span
                                                            className="icon_16 default edit icon_mask !bg-interactive-100"></span>
                                                    </button>
                                                    <button className="action" onClick={() => deleteRequest(el)}
                                                            data-tooltip-id="tooltip_small_top" data-tooltip-offset={10}
                                                            data-tooltip-content="Elimina">
                                                        <span
                                                            className="icon_16 default delete icon_mask !bg-interactive-100"></span>
                                                    </button>
                                                    <button className="action" onClick={() => duplicateRequest(el)}
                                                            data-tooltip-id="tooltip_small_top" data-tooltip-offset={10}
                                                            data-tooltip-content="Duplica">
                                                        <span
                                                            className="icon_16 default duplicate icon_mask !bg-interactive-100"></span>
                                                    </button>
                                                </div>

                                                <Link to={`/admin/plans/template/configuration/${el.id}`}
                                                      className="button fill secondary x_small">
                                                    <span
                                                        className="icon_16 default wrench icon_mask !bg-primary-100 -ml-2"></span>
                                                    Configura
                                                </Link>

                                                <Link to={`/admin/plans/template/detail/${el.id}`}
                                                      className="button fill tertiary x_small">Dettaglio</Link>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>

                        <Pagination
                            last={pageData.last}
                            current={pageData.current}
                            onChange={updatePage}
                            total={pageData.total}
                        />
                    </div>
                    :
                    isInit &&
                    <EmptyBox
                        icon="icon_75 default archive_plan"
                        title="Nessun risultato trovato"
                        description="Qui visualizzerai la lista di tutti i piani creati"
                    />
                }
                {isLoading.length > 0 && <LoadingBox blocked={!isInit}/>}
            </div>


            <CrudPlanModal
                open={crudOpen}
                setOpen={setCrudOpen}
                planModel={editPlan}
                onInsert={onInsertHandler}
                onEdit={onEditHandler}
            />


            <DuplicatePlanModal
                open={duplicateOpen}
                setOpen={setDuplicateOpen}
                planModel={duplicateItem}
                onSuccess={onCloneHandler}
            />

            <ConfirmModal
                open={deleteOpen}
                setOpen={setDeleteOpen}
                title="Conferma eliminazione"
                icon="icon_16 default piano"
                subtitle={deleteItem?.name && deleteItem.name}
                description={deleteItem?.description && deleteItem.description}
                counterCaphters={deleteItem?.chapters_count && deleteItem.chapters_count}
                counterContents={deleteItem?.chapter_contents_count && deleteItem.chapter_contents_count}
                counterGoals={deleteItem?.goals_count && deleteItem.goals_count}
                counterActions={deleteItem?.requirements_count && deleteItem.requirements_count}
                alertMessage="Attenzione! Questa operazione è irreversibile! <b>Sei sicuro di procedere?</b>"
                ctaLabel="Elimina"
                onConfirm={onDeleteHandler}
            />
        </>
    );
}

export default TableTemplatePlans;
