import { forwardRef } from "react";
import { useEffect, useState, useRef } from "react";

const TextareaInput = forwardRef(({ model, onChange = () => {}, className = '' , ...props}, ref) => {

    const [value, setValue] = useState(model)

    const localRef = useRef()

    useEffect(() => {

        if(localRef.current){
            const textare = localRef.current.querySelector("textarea")
            resizeTextarea(textare)
        }

    }, [value])





    /**
     * Resize textare
     * @param {*} element
     */
    function resizeTextarea(element){

        element.style.height = "0px";

        const scrollHeight = element.scrollHeight;

        element.style.height = scrollHeight + "px";
    }





    /**
     * On Change handler
     * @param {*} e
     */
    function onChangeHandler(e){
        setValue(e.target.value)
        onChange(e)
    }



    return (
        <div ref={localRef}>
            <textarea ref={ref} defaultValue={value} onChange={onChangeHandler} {...props} className={"landing " + className}>
            </textarea>
        </div>
    );
})

export default TextareaInput;
