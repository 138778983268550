import { useRef, useEffect, useState } from "react";
import useNotification from "../../hooks/notification.hook";
import ModalContainer from "./ModalContainer";
import { createDownloadAgenda } from "../../helper/download-agenda.helper";


export default function AnnotationDownloadModal({open, setOpen, annotation, idAgenda, idOrganization }) {

	const { openNotification, helperErrorNotification } = useNotification()

	const [loading, setLoading] = useState(false);

    const downloadButton = useRef(null)


    /**
     * Create Download
     */
    const createDownload = () => {
        if(!loading){

            setLoading(true)

            const body = {
                idCorp: idOrganization,
                idAgenda: idAgenda,
                options: {
                    annotations: [annotation.id],
                }
            }

            createDownloadAgenda(body, (res) => {
                downloadButton.current.href = res.data.url
                downloadButton.current.click()
                downloadButton.current.href = ""
                setLoading(false)
                setOpen(false)
                openNotification({text: "Documento generato con successo!", status: "success"})

            }, (error) => {
                helperErrorNotification(error)
                setLoading(false)
            })
        }
    }


	return (
        <ModalContainer isOpen={open} setIsOpen={setOpen}>
            <div className={"main_modal_content"} >

                <div className="main_close" onClick={() => setOpen(false)}>
                    <span className="icon_10 default close icon_mask !bg-white"></span>
                </div>

                <div className="main_header_modal left_text">
                    <span className="title">Scarica annotazione</span>
                </div>


                {/* Modal Form */}
                <div className={"main_body_modal"}>

                    {annotation &&
                        <div className="main_sub_intro pb-5">
                            <div className="wrap_sub_intro">
                                <div className="main_icon">
                                    <span className="icon_16 default note"></span>
                                </div>
                                <div className="subintro">Annotazione</div>
                            </div>

                            <div className="main_description">{annotation.name}</div>
                        </div>
                    }

                    <div className="main_message_box box_info">
                        <div className="main_icon">
                            <span className="icon_16 default info"></span>
                        </div>
                        <div className="message">Genera il documento includendo solamente l'annotazione selezionata.</div>
                    </div>
                </div>

                <div className="main_footer_modal">
                    <div className="right_content">

                        <a href="" download target="_blank" ref={downloadButton}></a>

                        {/* Main Cta */}
                        <button onClick={createDownload} className={'button fill success' + (loading ? " loading" : "")}>
                            <div className="load_content">Scarica</div>
                            <div className="main_loader_icon fade_in"></div>
                        </button>
                    </div>

                </div>
            </div>
        </ModalContainer>
	);
}
