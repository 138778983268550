function useCalendly() {


    /**
     * Open Calendly
     */
	const openCalendly = () => {
        window.Calendly.initPopupWidget({
            url: "https://calendly.com/andreamarella/appuntamento-video-da-1-ora?primary_color=00b3ba",
        })
    }


	return { openCalendly };

}

export default useCalendly;
