import PlanTemplate from "./PlanTemplate";
import {useEffect} from "react";
import {usePlanTemplateStore} from "../../store/plan-template.store";

function PlanTemplateContainer(props) {

    const resetPlanData = usePlanTemplateStore(state => state.reset)

    useEffect(() => {
        return () => {
            resetPlanData()
        }
    }, []);

    return (
        <PlanTemplate {...props} />
    );
}

export default PlanTemplateContainer;