import { forwardRef } from "react";
import "./CardCourseContent.scss"
import ActionBtnItem from "../utils/ActionBtnItem";
import {usePlanTemplateStore} from "../../store/plan-template.store";


const CardCourseContent = forwardRef(({className = '', item, isDragging, position, onClick, isActived, isContents, dragHandleProps, isEditable, isDraggable = true, onEdit = () => {}, onDelete = () => {}, ...props}, ref) => {

    const {
        updateState,
        duplicateContent,
        deleteContent
    } = usePlanTemplateStore()


    /**
     * Edit item
     */
    const editItem = () => {
        updateState({ contentModel: {model: item}, isCrudContentOpen: true})
    }



    /**
     * Delete item
     */
    const deleteItem = () => {
        updateState({
            deleteConf: {
                title: "Elimina corso",
                icon: "icon_16 default text_box",
                subtitle: item.data.name,
                confirmHandler: deleteContent,
            },
            deleteItem: item,
            isDeleteModalOpen: true
        })
    }



    /**
     * Duplicate Item
     */
    const duplicateItem = () => {
        duplicateContent(item, position+1)
    }
    

    return (
        <div ref={ref} {...props} className={"main_comp_card_course_content " + (isDragging ? " is_dragging " : '') + (isActived ? " active " : '') + (!isDraggable ? " not_draggable " : '' ) + (className)}>
            <div className="main_intro">
                <div className="wrap_intro">

                    <div className="main_icon type">
                        <span className={"icon_16 default course"}></span>
                    </div>

                    {isDraggable &&
                        <div className="main_icon draggable" {...dragHandleProps}>
                            <span className="icon_10 default draggable"></span>
                        </div>
                    }

                    <span className="title">Corso</span>
                </div>

                {/* Actions */}
                {isEditable &&
                    <div className="main_actions">

                        {/* Edit */}
                        <ActionBtnItem tooltip="Modifica" onClick={editItem} icon="edit" />

                        {/* Clone */}
                        {isDraggable &&
                            <ActionBtnItem tooltip="Duplica" onClick={duplicateItem} icon="duplicate" />
                        }

                        {/* Delete */}
                        <ActionBtnItem onClick={deleteItem} tooltip="Elimina" icon="delete" />

                    </div>
                }
            </div>

            <div className="main_desc">
                <div className="desc">{item.data.course.name}</div>

                <div className="main_image">
                    {item?.data?.course?.preview_full_path ?
                        <img src={item.data.course.preview_full_path} className="image"/>
                        :
                        <span className="icon_16 default piano icon_mask !bg-dark-60"></span>
                    }
                </div>
            </div>
        </div>
    );
})

export default CardCourseContent;