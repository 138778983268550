import SubHeader from "../../components/elements/SubHeader";
import LayoutSidebar from "../../components/layout/LayoutSidebar";
import Sidebar from "../../components/agendas/Sidebar";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { deleteAnnotation, getAnnotation, getAnnotations, updateAnnotation } from "../../services/agendas.service";
import SubNav from "../../components/plan-template/SubNav";
import EditorContent from "../../components/plan-template/EditorContent";
import useDateFormatter from "../../hooks/date-formatter.hook";
import debounce from "lodash.debounce";
import CrudAnnotationModal from "../../components/popups/CrudAnnotationPopup";
import useNotification from "../../hooks/notification.hook";
import ConfirmModal from "../../components/popups/ConfirmPopup";
import ActionBtnItem from "../../components/utils/ActionBtnItem";
import EmptyTemplate from "../../components/emptystate/EmptyTemplate";
import useLoadingHelper from '../../hooks/loading.hook';
import AgendaDownloadModal from "../../components/popups/AgendaDownloadPopup";
import AnnotationDownloadModal from "../../components/popups/AnnotationDownloadPopup";

function AgendaDetail({ role }) {

    const agendaData = useLoaderData()

    const { addQueue, removeQueue, queueLoading } = useLoadingHelper()

    const { formatDate } = useDateFormatter()

    const [ annotations, setAnnotations ] = useState([])

    const [ openDownloadAgenda, setOpenDownloadAgenda ] = useState(false)

    const [ openDownloadAnnotation, setOpenDownloadAnnotation ] = useState(false)

    const [ selectedDownload, setSelectedDownload ] = useState(null)

    const [ isInit, setIsInit ] = useState(false)
    
    const [ forceUpdate, setForceUpdate ] = useState(1)

    const [ searchParams, setSearchParams ] = useSearchParams();

    const [ queryParams, setQueryParams ] = useState({
        filter: {
            name: "",
            date: "",
        }
    })

    const [ selectedItem, setSelectedItem ] = useState(null)

    const [crudOpen, setCrudOpen] = useState(false)

    const [confirmOpen, setConfirmOpen] = useState(false)

    const [crudAnnotation, setCrudAnnotation] = useState(null)

    const { openNotification, helperErrorNotification } = useNotification()


    useEffect(() => {

        getListAnnotations()

    }, [queryParams, forceUpdate])



    useEffect(() => {

        if(searchParams.get("annotation"))
            selectAnnotionHandler({ id: searchParams.get("annotation")})

    }, [])



    /**
     * Get list annotations
     */
    const getListAnnotations = () => {

        const body = {
            ...queryParams,
            id: agendaData.id,
            idCorp: agendaData.organization_id,
        }

        addQueue()

        getAnnotations(body, (res) => {
            setAnnotations(res.data)
            setIsInit(true)
            removeQueue()
        }, () => removeQueue())
    }



    /**
     * get selected annotation detail 
     * @param {*} item 
     */
    const selectAnnotionHandler = (item) => {

        const body = {
            id: item.id,
            idCorp: agendaData.organization_plan.organization_id
        }

        getAnnotation({ params: body}, (res) => {
            setSelectedItem(res.data)
        })

    }



    const onDateChange = (e) => setQueryParams(q => ({...q, filter: { ...q.filter, date: e.target.value,}}))



    /**
     * On Search
     * @param {*} e 
     */
    const onSearch = (e) => {
        const searchModel = e.target.value

        if(searchModel.trim().length > 0 || queryParams.filter.name != null){
            setQueryParams(q => ({...q, filter: { ...q.filter, name: searchModel.trim().length > 0 ? searchModel : '',}}))
        }
    }



    /**
     * Debounce search
     */
    const debounceSearch = debounce(onSearch, 500)




    /**
     * Download annotation
     * @param {*} annotation 
     */
    const downloadAnnotation = (annotation) => {
        setSelectedDownload(annotation) 
        setOpenDownloadAnnotation(true)
    }



    /**
     * Template
     * @returns 
     */
    const templateNav = () => (

        <div className="main_comp_actions_group">
            {/* Actions */}
            <div className="main_list_counter">

                {selectedItem?.user_can_update &&
                    <ActionBtnItem icon="edit" onClick={() => editAnnotationRequest(selectedItem)} tooltip="Modifica" />
                }

                {selectedItem?.user_can_update &&
                    <ActionBtnItem icon="delete" onClick={() => deleteAnnotationRequest(selectedItem)} tooltip="Elimina" />
                }

                <ActionBtnItem icon="download" tooltip="Scarica" onClick={() => downloadAnnotation(selectedItem)} />
            </div>
        </div>
    )



    /**
     * new item request
     * @param {*} item 
     */
    const createAnnotationRequest = () => {
        setCrudAnnotation(null)
        setCrudOpen(true)
    }



    /**
     * edit item request
     * @param {*} item 
     */
    const editAnnotationRequest = (item) => {
        setCrudAnnotation(item)
        setCrudOpen(true)
    }

    /**
     * edit item request
     * @param {*} item 
     */
    const deleteAnnotationRequest = (item) => {
        setCrudAnnotation(item)
        setConfirmOpen(true)
    }


    /**
     * List update handler
     */
    const onListUpdate = () => {
        setForceUpdate(i => i+1)
        setCrudOpen(false)
        setConfirmOpen(false)
    }


    /**
     * List update handler
     */
    const deleteAnnotationHandler = () => {

        deleteAnnotation({ id: crudAnnotation.id, idCorp: agendaData.organization_plan.organization_id }, () => {
            
            if(crudAnnotation.id == selectedItem.id)
                setSelectedItem(null)

            openNotification({text: "Annotazione eliminata con successo!", status: "success"})
            onListUpdate()
        }, (err) => {
            helperErrorNotification(err)
        })
    }




    /**
     * Edit Annotation Content
     * @param {*} editor 
     * @returns 
     */
    const editAnnotationContent = async (savedData) => {

        await updateAnnotation({
            id: selectedItem.id, 
            idCorp: agendaData.organization_plan.organization_id, 
            name: selectedItem.name,
            date: selectedItem.date,
            editorjs: savedData,
        })
    }



    const templateHeader = () => (
        <div className="flex gap-3 items-center">

            <ActionBtnItem icon="download" onClick={() => setOpenDownloadAgenda(true)} big />
        </div>
    )


    const backLink = {
        admin: "/admin/agendas",
        reseller: "/reseller/agendas",
        organization: "/organization/agendas",
    }


    return (
        <LayoutSidebar>

            <SubHeader 
                title={agendaData.name} 
                titleSmall 
                description={agendaData.organization_plan.organization.name} 
                back 
                backHref={backLink[role] ?? ""}
                TemplateRightContent={templateHeader}
            />

            <Sidebar
                onSearch={debounceSearch}
                onDateFilter={onDateChange}
                onSelect={selectAnnotionHandler}
                annotations={annotations}
                selectedAnnotation={selectedItem}
                onCreate={createAnnotationRequest}
                onEditAnnotation={editAnnotationRequest}
                onDeleteAnnotation={deleteAnnotationRequest}
                onDownloadAnnotation={downloadAnnotation}
                filters={queryParams}
                isLoading={queueLoading.length > 0}
                role={role}
            />


            <div className="main_page_sidebar">
            {selectedItem ?
                    <>
                        <SubNav
                            category={formatDate(selectedItem.date, "DD MMM YYYY")} 
                            title={selectedItem.name}
                            TemplateRightContent={templateNav}
                        />

                        <div className="wrap_page_sidebar">
                            <EditorContent readOnly={!selectedItem.user_can_update} key={selectedItem.id} onChange={editAnnotationContent} initVal={selectedItem.editorjs} withCode />
                        </div>
                    </>
                :
                isInit &&
                    <EmptyTemplate 
                        title={annotations.length > 0 ? "Nessun appuntamento selezionato" : "Nessun appuntamento trovato"}
                        description={annotations.length > 0 ? "Seleziona un appuntamento per vedere il suo contenuto" : "Crea un nuovo appuntamento."}
                        image="/images/empty/empty-agenda.svg" 
                    />
            }
            </div>


            <CrudAnnotationModal
                open={crudOpen} 
                agenda={agendaData}
                annotationModel={crudAnnotation}
                setOpen={setCrudOpen}
                onInsert={onListUpdate}
                onEdit={onListUpdate}
            />


            <ConfirmModal
                open={confirmOpen}
                setOpen={setConfirmOpen}
                title="Conferma eliminazione"
                icon="icon_16 default note"
                subtitle="Annotazione"
                description={crudAnnotation?.name ? crudAnnotation?.name : ''}
                alertMessage="Attenzione! Questa operazione è irreversibile! <b>Sei sicuro di procedere?</b>"
                ctaLabel="Elimina"
                onConfirm={deleteAnnotationHandler}
            />

            <AgendaDownloadModal
                open={openDownloadAgenda}
                setOpen={setOpenDownloadAgenda}
                annotations={annotations}
                idAgenda={agendaData.id}
                idOrganization={agendaData?.organization_plan.organization_id}
            />


            <AnnotationDownloadModal
                open={openDownloadAnnotation}
                setOpen={setOpenDownloadAnnotation}
                annotation={selectedDownload}
                idAgenda={agendaData.id}
                idOrganization={agendaData?.organization_plan.organization_id}
            />

        </LayoutSidebar>
    );
}

export default AgendaDetail;