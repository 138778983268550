import { createContext } from "react";
import Head from "./Head";
import Body from "./Body";
import Cell from './Cell';
import Column from './Column';
import Row from './Row';

export const TableContext = createContext()

function Table({children, className = '', ...props}) {

    return (
        <TableContext.Provider value={''}>
            <table {...props} className={"main_comp_table " + className}>
                {children}
            </table>
        </TableContext.Provider>
    );
}

Table.Head = Head
Table.Body = Body
Table.Cell = Cell
Table.Column = Column
Table.Row = Row

export default Table;