import { useState } from "react";

function useLoadingHelper() {

	const [queueLoading, setQueueLoading] = useState([]);

	const [initStatus, setInitStatus] = useState(false);


    const addQueue = () => setQueueLoading(q => [...q, 1])

    const removeQueue = () => setQueueLoading(q => {q.pop(); return q })



	return { addQueue, queueLoading, removeQueue, initStatus, setInitStatus };

}

export default useLoadingHelper;
