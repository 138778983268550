import { Transition } from "react-transition-group";
import { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import useCommonHelper from "../../hooks/helper.hook";

function ModalContainer({children, isOpen, setIsOpen, disableClose}) {
    const [containerOpen, setContainerOpen] = useState(false)
    
    const [nestedOpen, setNestedOpen] = useState(false)

    const [ready, setReady] = useState(false)

    const containerEl = useRef(null)

    const nestedEl = useRef(null)

    const {animationClass} = useCommonHelper()

    useEffect(() => {
        if(isOpen){

            document.body.style.overflow = "hidden"

            containerOpen ? setNestedOpen(true) : setContainerOpen(true)

        }else{

            document.body.style.overflow = ""

            nestedOpen ? setNestedOpen(false) : setContainerOpen(false)
        }

    },[isOpen])


    useEffect(() => {
        setReady(true)
    },[])

    if(!ready) return null 

    return (
        createPortal(
            <>
                <Transition
                    nodeRef={containerEl} 
                    in={isOpen} 
                    mountOnEnter
                    unmountOnExit
                    onEntered={() => setNestedOpen(true)}
                    timeout={{enter: 200, exit: 200}}
                >
                    { state => (

                        <div ref={containerEl} onClick={() => !disableClose && setNestedOpen(false)} className={"main_modal_container " + animationClass(state,"modal_in","modal_out")}>
                            <div className="wrap_modal_container">

                                {/* Nested Modal */}
                                <Transition
                                    nodeRef={nestedEl} 
                                    in={nestedOpen}
                                    mountOnEnter
                                    unmountOnExit
                                    onExited={() => setIsOpen(false)}
                                    timeout={{enter: 200, exit: 200}}
                                >
                                    {stateNested => (
                                        <div className={"container_modal_prewrap " + (animationClass(stateNested, "modal_content_in", "modal_content_out"))} ref={nestedEl} onClick={(e) => e.stopPropagation()}>
                                            {children}
                                        </div>
                                    )}
                                </Transition>
                            </div>

                        </div>
                    )}
                </Transition>
            </>,
            document.querySelector("#modal-container-el")
        )
        
    );
}

export default ModalContainer;