import c from "./ItemAction.module.scss"

function ItemAction({ title, icon = "", description, actionTemplate = () => {} }) {
    return (
        <div className={c.main_item}>
            <div className={c.left_content}>
                <div className={c.main_title}>
                    <div className={c.main_icon}>
                        <span className={`icon_16 default ${icon}`}></span>
                    </div>
                    <div className={c.title}>{title}</div>
                </div>
                <div className={c.description}>{description}</div>
            </div>
            <div className={c.right_content}>
                {actionTemplate()}
            </div>
        </div>
    );
}

export default ItemAction;