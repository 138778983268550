import { forwardRef } from "react";
import { useRef } from "react";

const NumberInput = forwardRef(({ withArrows = true, type, max = undefined, min = undefined, className = '', onUpdate, icon, ...props}, ref) => {

    const inputContainer = useRef(null)

    function updateValue(increase = true){

        const inputEl = inputContainer.current.querySelector("input")

        if(increase){
            if(max == undefined || max >= (+inputEl.value + 1))
                inputEl.value = +inputEl.value + 1
        }else{
            if(min == undefined || min <= (+inputEl.value - 1))
                inputEl.value = +inputEl.value - 1
        }

        inputEl.focus()
        inputEl.blur()
        if(onUpdate)
            onUpdate(inputEl.value)
    }


    function checkMaxMin(event){

        const inputEl = inputContainer.current.querySelector("input")

        const value = event.target.value
        if(max != undefined && value > max){
            if(inputContainer?.current) inputEl.value = max
        }
        if(min != undefined && value < min ){
            if(inputContainer?.current) inputEl.value = min
        }
        if(onUpdate)
            onUpdate(inputEl.value)
    }


    return (
        <div ref={inputContainer} className={"wrap_input_landing number" + (icon ? " icon_currency" : '') + (!withArrows ? " no_arrows" : '')}>
            {withArrows && <span onClick={() => updateValue(true)} className="icon_10 default chevron top icon_mask !bg-interactive-100 arrow_up"></span>}
            <input ref={ref} onInput={checkMaxMin} type="number" className={"landing " + className} {...props} />
            {withArrows && <span onClick={() => updateValue(false)} className="icon_10 default chevron icon_mask !bg-interactive-100 arrow_down"></span>}
            {icon &&
                <div className="main_icon">
                    <div className={"icon_16 default " + icon}></div>
                </div>
            }
        </div>
    );
})

export default NumberInput;