import { apiClient } from "./config"






/**
 * Get template plan statuses
 * @param {title,types,page,per_page,} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getTemplateStatuses = async (params, successCallback = false, errorCallback = false) => {

    const { id, ...body } = params

    try{
        const response = await apiClient.get(`/admin/templates/plans/${id}/requirement-statuses`, {params: body})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}









/**
 * Create new template status
 * @param {name, description, icon, done, default} body
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const createTemplateStatus = async (body, successCallback = false, errorCallback = false) => {

    const { idPlan, ...data } = body

    try{
        const response = await apiClient.post(`/admin/templates/plans/${idPlan}/requirement-statuses`, data)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}








/**
 * Edit template status
 * @param {id, name, description, icon, done, default} body
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const editTemplateStatus = async (body, successCallback = false, errorCallback = false) => {
    try{
        const response = await apiClient.patch(`/admin/templates/requirement-statuses/${body.id}`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}








/**
 * Delete template status
 * @param {id} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const deleteTemplateStatus = async (id, successCallback = false, errorCallback = false) => {
    try{
        const response = await apiClient.delete(`/admin/templates/requirement-statuses/${id}`)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}



/**
 * Get template plan statuses
 * @param {} params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const getWorkingTemplatePlans = async (params, successCallback = false, errorCallback = false) => {

    try{
        const response = await apiClient.get(`/working-template-plans`, {params: params})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback)
            errorCallback(error.response)
        return error
    }
}

