import c from "./TableContainer.module.scss"
import Pagination from "../utils/Pagination";
import FilterDropdown from '../utils/FilterDropdown';
import Table from "../utils/table/Table";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getPlans } from '../../services/plans.service';
import useLoadingHelper from "../../hooks/loading.hook";
import LoadingBox from "../loading/LoadingBox";
import EmptyBox from "../emptystate/EmptyBox";

function TablePlans({ organization, role }) {

    const [ plans, setPlans ] = useState([])

    const { queueLoading, addQueue, removeQueue, initStatus, setInitStatus } = useLoadingHelper()

    const [ queryParams, setQueryParams ] = useState({
        page: 1,
        filter: {
            "organizationWorkingPlan.organizationRequirements.statuses": []
        }
    })

    const [ pageData, setPageData ] = useState({
        last: 0,
        current: 0,
        total: 0
    })



    /**
     * Fetch data
     */
    useEffect(() => {

        getListOrganizations()

    }, [queryParams])






    /**
     * Get List organizations
     */
    const getListOrganizations = () => {

        const body = {
            id: organization.id,
            include: [
                "organizationChaptersCount",
                "organizationChapterContentsCount",
                "organizationGoalsCount",
                "organizationRequirementsCount",
                "templatePlan"
            ],
            ...queryParams,
            filter: {
                active: true,
                "templatePlan.working": false,
                "templatePlan.active": true,
                ...queryParams.filter
            },
        }

        addQueue()
        
        // Fetch Api
        getPlans(body, (res) => {

            setPlans(res.data)

            removeQueue()

            setInitStatus(true)

            setPageData({
                last: res.meta.last_page,
                current: res.meta.current_page,
                total: res.meta.total
            })
        }, () => removeQueue())
    }




    /**
     * Update page
     * @param {*} newPage 
     * @returns 
     */
    const updatePage = (newPage) => setQueryParams(q => ({...q, page: newPage}))




    /**
     * link Detail
     * @param {*} item 
     * @returns 
     */
    const linkDetail = (item) => {
        if(role === "admin")
            return `/admin/entities/entity/${organization.id}/plan/${item.id}`

        if(role === "reseller")
            return `/reseller/${organization.id}/plan/${item.id}`

        return `/organization/plan/${item.id}`
    }


    return (
        <div className="relative">
            {plans.length > 0 ?
                <div className="fade_in">
                    <Table>
                        <Table.Head>
                            <Table.Column>Anno</Table.Column>
                            <Table.Column>Nome</Table.Column>
                            <Table.Column>Contenuto</Table.Column>
                            <Table.Column></Table.Column>
                        </Table.Head>
                        <Table.Body>
                            {plans.map((el, index) => (
                                <Table.Row key={index} className="action_hover_list">
                                    <Table.Cell width={10}>{el.template_plan.year}</Table.Cell>
                                    <Table.Cell width={300}>
                                        <div className="max-w-[400px]">
                                            <div className="title">{el.template_plan.name}</div>
                                            <div className="subtitle line-clamp-1">{el.template_plan.description}</div>
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell width={10}>
                                        <div className="spacing_items">
                                            <div className="main_icon_text">
                                                <span className="icon_16 default folder"></span>
                                                {el.organization_chapters_count}
                                            </div>
                                            <div className="main_icon_text">
                                                <span className="icon_16 default text_box"></span>
                                                {el.organization_chapter_contents_count}
                                            </div>
                                            <div className="main_icon_text">
                                                <span className="icon_16 default layers"></span>
                                                {el.organization_goals_count}
                                            </div>
                                            <div className="main_icon_text">
                                                <span className="icon_16 default layer"></span>
                                                {el.organization_requirements_count}
                                            </div>
                                        </div>
                                    </Table.Cell>

                                    <Table.Cell width={1}>
                                        <div className="spacing_items">
                                            <Link to={linkDetail(el)} className="button fill tertiary x_small">Dettaglio</Link>
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>

                    <Pagination
                        last={pageData.last}
                        current={pageData.current}
                        onChange={updatePage}
                        total={pageData.total}
                    /> 
                </div>
                :
                initStatus &&
                    <EmptyBox
                        icon="icon_75 default archive_plan"
                        title="Nessun risultato trovato"
                        description="Qui visualizzerai la lista di tutti i piani creati"
                    />
            }
            {queueLoading.length > 0 && <LoadingBox blocked={!initStatus} />}
        </div>
    );
}

export default TablePlans;
