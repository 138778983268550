import { useEffect, useState, useRef } from "react";
import "./AgendaDownloadPopup.scss"
import ModalContainer from "./ModalContainer";
import useNotification from "../../hooks/notification.hook";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import EmptyBox from "../emptystate/EmptyBox";
import LoadingBox from "../loading/LoadingBox";
import { createDownloadAgenda } from "../../helper/download-agenda.helper";



export default function AgendaDownloadModal({open, setOpen, annotations = [], isLoadingAnnotations = false, idOrganization, idAgenda}) {

    const { openNotification, helperErrorNotification } = useNotification()

	const [loading, setLoading] = useState(false);
    
	const [selectedAnnotations, setSelectedAnnotations] = useState([]);

    const downloadButton = useRef(null)

    const timerDownload = useRef(null)


    useEffect(() => {
        if(open){
            setSelectedAnnotations(annotations.map(a => a.id))
        }
    },[open, isLoadingAnnotations])



    /**
     * Create Download
     */
    const createDownload = () => {
        if(!loading){

            setLoading(true)

            const body = {
                idCorp: idOrganization,
                idAgenda: idAgenda,
                options: {
                    annotations: selectedAnnotations,
                }
            }

            createDownloadAgenda(body, (res) => {
                downloadButton.current.href = res.data.url
                downloadButton.current.click()
                downloadButton.current.href = ""
                setLoading(false)
                setOpen(false)
                openNotification({text: "Documento generato con successo!", status: "success"})

            }, (error) => {
                helperErrorNotification(error)
                setLoading(false)
            })
        }
    }



    
    /**
     * On check 
     * @param {*} e 
     * @param {*} annotation 
     */
    const onCheck = (e, annotation) => {
        if(e.target.checked){
            setSelectedAnnotations(annotations => [...new Set([...annotations, annotation])])
        }else{
            setSelectedAnnotations(annotations => annotations.filter(a => a != annotation))
        }
    }


    /**
     * On select all
     * @param {*} e 
     */
    const onSelectAll = (e) => {
        if(e.target.checked){
            setSelectedAnnotations(annotations.map(a => a.id))
        }else{
            setSelectedAnnotations([])
        }
    }

	return (
        <ModalContainer isOpen={open} setIsOpen={setOpen} disableClose={loading}>
            <div className="main_modal_content agenda_download_popup" >

                <div className="main_close" onClick={() => !loading && setOpen(false)}>
                    <span className="icon_10 default close icon_mask !bg-white"></span>
                </div>

                <div className="main_header_modal flex_header">

                    {/* Left content */}
                    <div className="left_content">
                        <div className="main_desc">
                            <span className="title">Scarica agenda</span>
                            <div className="desc">Seleziona le annotazioni che vuoi includere nel documento</div>
                        </div>
                    </div>
                </div>



                <div className={"main_body_modal"}>

                    {(annotations.length > 0 && !isLoadingAnnotations ) &&
                        <OverlayScrollbarsComponent defer options={{ scrollbars: { autoHide: "scroll" }}} className="main_list_annotations fade_in">
                            <div className="main_list_items">

                                {/* Select all */}
                                <div className="item_list">
                                    <div className={"check_landing small" + (selectedAnnotations.length > 0 ? ' partial' : '')}>
                                        <input type="checkbox" checked={selectedAnnotations.length === annotations.length} onChange={(e) => onSelectAll(e)} id={"select-annotation-all"} />
                                        <label htmlFor={"select-annotation-all"}>
                                            <span className="name">Seleziona tutto</span>
                                        </label>
                                    </div>
                                </div>

                                {/* Annotations */}
                                {annotations.map((annotation, i) => (
                                    <div className="item_list" key={i}>
                                        <div className={"check_landing small"}>
                                            <input type="checkbox" checked={selectedAnnotations.includes(annotation.id)} onChange={(e) => onCheck(e, annotation.id)} id={"select-annotation-" + annotation.id} />
                                            <label htmlFor={"select-annotation-" + annotation.id} className="icon">
                                                <div className="main_icon">
                                                    <span className="icon_16 default note"></span> 
                                                </div>
                                                <span className="name">{annotation.name}</span>
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </OverlayScrollbarsComponent>
                    }

                    {(annotations.length == 0 && !isLoadingAnnotations) && 

                        <EmptyBox
                            icon="icon_75 default archive_note"
                            title="Nessun annotazione"
                            description="Non ci sono annotazioni per questa agenda"
                        />
                    }

                    {isLoadingAnnotations && <LoadingBox blocked={true} classNames="!min-h-[380px]" />}

                </div>

                <div className="main_footer_modal">

                    <div className="right_content">
                        <a href="" download target="_blank" ref={downloadButton}></a>
                        
                        <button onClick={createDownload} className={'button fill success' + (loading ? " loading" : "")}>
                            <div className="load_content">Scarica</div>
                            <div className="main_loader_icon fade_in"></div>
                        </button>
                    </div>
                </div>
            </div>
        </ModalContainer>
	);
}
