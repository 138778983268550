import React from 'react';
import ReactDOM from 'react-dom/client';
import './style.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_it from "./translations/it/common.json";
import { NotificationContextProvider } from './context/notification.context';
import Notification from './components/Notification';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
	apiKey: process.env.REACT_APP_BUGSNAG ? process.env.REACT_APP_BUGSNAG : '0',
	plugins: [new BugsnagPluginReact()],
	appVersion: process.env.REACT_APP_VERSION 
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)



i18next.init({
	interpolation: { escapeValue: false }, // React already does escaping
	lng: "it",
	resources: {
		it: {
			common: common_it,
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<ErrorBoundary>
		<I18nextProvider i18n={i18next}>
			<NotificationContextProvider>
				<App />
				<Notification />
			</NotificationContextProvider>
		</I18nextProvider>
	</ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
