import { apiClient, apiClientPlatform } from "./config"





/**
 * Get list organizations
 * @param {
 *      template_plan_id, filter[name], 
 *      filter[organization_type_id],
 *      filter[reseller_id][],
 *      per_page,
 *      filter[organizationWorkingPlan.organizationRequirements.statuses][],
 *      include[organizationType, reseller, organizationPlans, organizationWorkingPlan]
 * } params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getOrganizations = async (params, successCallback = false, errorCallback = false) => {
    try{
        const response = await apiClient.get(`/organizations`, {params: params})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}












/**
 * Create plan organization relation
 * @param {id, template_plan_id, active} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const createOrganizationPlanRelation = async (params, successCallback = false, errorCallback = false) => {

    const {id, ...body} = params

    try{
        const response = await apiClient.post(`/admin/organizations/${id}/plans`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}







/**
 * Update plan organization relation
 * @param {id, idPlan, active} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const updateOrganizationPlanRelation = async (params, successCallback = false, errorCallback = false) => {

    const {id, idPlan, ...body} = params

    try{
        const response = await apiClient.patch(`/admin/organizations/${id}/plans/${idPlan}`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}








/**
 * Get list copiers
 * @param {id, idPlan, active} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getListCopiers = async (params, successCallback = false, errorCallback = false) => {

    const {id, idPlan, ...body} = params

    try{
        const response = await apiClient.get(`/admin/organizations/${id}/plans/${idPlan}/copiers`, {params: body})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}





/**
 * Get organization plan
 * @param {
 *  append[] organization_requirements_completion_percentage,
 *  append[] organization_requirements_statuses,
 *  append[] template_goals_statuses,
 *  append[] template_requirements_statuses,
 * 
 *  include[] organization,
 *  include[] templatePlan,
 *  include[] organizationChapters,
 *  include[] organizationChaptersCount,
 *  include[] organizationChapters.templateChapter,
 *  include[] organizationChapters.organizationChapterContents,
 *  include[] organizationChapterContentsCount,
 *  include[] organizationChapterContentsUncompletedCount,
 *  include[] organizationChapters.organizationChapterContents.templateChapterContent,
 *  include[] organizationChapters.organizationGoals,
 *  include[] organizationGoalsCount,
 *  include[] organizationChapters.organizationGoals.templateGoal,
 *  include[] organizationChapters.organizationGoals.organizationRequirements,
 *  include[] organizationRequirementsCount,
 *  include[] organizationRequirementsUncompletedCount,
 *  include[] organizationChapters.organizationGoals.organizationRequirements.templateRequirement,
 * } params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getOrganizationPlan = async (params, successCallback = false, errorCallback = false) => {

    const {id, idPlan, ...body} = params

    try{
        const response = await apiClient.get(`/organizations/${id}/plans/${idPlan}`, {params: body})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}


/**
 * Search in Organization Plan
 * @param { q }
 * @param successCallback
 * @param errorCallback
 * @returns {Promise<*>}
 */
export const searchInOrganizationPlan = async ({ params, options }, successCallback = false, errorCallback = false) => {

    const {id, idPlan, ...body} = params

    try{
        const response = await apiClient.get(`/organizations/${id}/plans/${idPlan}/search`, {params: body, ...options})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback)
            errorCallback(error.response)
        return error
    }
}









/**
 * Create copy template
 * @param {id, idPlan, name} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const createCopyTemplate = async (params, successCallback = false, errorCallback = false) => {

    const {id, idPlan, ...body} = params

    try{
        const response = await apiClient.post(`/admin/organizations/${id}/plans/${idPlan}/copiers`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}









/**
 * get copier temlpate
 * @param {id, idPlan, name} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getCopier = async (params, successCallback = false, errorCallback = false) => {

    const {id, idCorp, ...body} = params

    try{
        const response = await apiClient.get(`/admin/organizations/${idCorp}/copiers/${id}`, {params: body})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}






/**
 * get copier temlpate
 * @param {id, idPlan, name} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const createCopier = async (params, successCallback = false, errorCallback = false) => {

    const {id, idPlan, ...body} = params

    try{
        const response = await apiClient.post(`/admin/organizations/${id}/plans/${idPlan}/copiers`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}





/**
 * update copier temlpate
 * @param {id, idPlan, name} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const updateCopier = async (params, successCallback = false, errorCallback = false) => {

    const {id, idCorp, ...body} = params

    try{
        const response = await apiClient.patch(`/admin/organizations/${idCorp}/copiers/${id}`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}







/**
 * get copier temlpate
 * @param {idCopier, idCorp, organization_chapter_content_id} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const createCopierContent = async (params, successCallback = false, errorCallback = false) => {

    const {idCopier, idCorp, ...body} = params

    try{
        const response = await apiClient.post(`/admin/organizations/${idCorp}/copiers/${idCopier}/chapter-contents`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}







/**
 * get copier temlpate
 * @param {idCopier, idCorp, organization_chapter_content_id} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const createCopierRequirement = async (params, successCallback = false, errorCallback = false) => {

    const {idCopier, idCorp, ...body} = params

    try{
        const response = await apiClient.post(`/admin/organizations/${idCorp}/copiers/${idCopier}/requirements`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}










/**
 * delete requirement copier 
 * @param {idCopier, id} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const deleteCopierRequirement = async (params, successCallback = false, errorCallback = false) => {

    const {id, idCorp, ...body} = params

    try{
        const response = await apiClient.delete(`/admin/organizations/${idCorp}/requirements/${id}`)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}






/**
 * delete content copier 
 * @param {idCopier, id} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const deleteCopierContent = async (params, successCallback = false, errorCallback = false) => {

    const {id, idCorp, ...body} = params

    try{
        const response = await apiClient.delete(`/admin/organizations/${idCorp}/chapter-contents/${id}`)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}









/**
 * get copier temlpate
 * @param {idCopier, idCorp, organization_chapter_content_id} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const deleteCopier = async (params, successCallback = false, errorCallback = false) => {

    const {id, idCorp, ...body} = params

    try{
        const response = await apiClient.delete(`/admin/organizations/${idCorp}/copiers/${id}`)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}













/**
 * get copier temlpate
 * @param {idPlan, idCorp, copier_id, organization_plan_ids} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const copyOrganization = async (params, successCallback = false, errorCallback = false) => {

    const {idPlan, idCorp, ...body} = params

    try{
        const response = await apiClient({
            method: "copy",
            url: `/admin/organizations/${idCorp}/plans/${idPlan}`,
            params : { ...body }
        })
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}












/**
 * get wotking plan
 * @param {id} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getWorkingPlan = async (params, successCallback = false, errorCallback = false) => {

    const {id, ...body} = params

    try{
        const response = await apiClient.get(`/organizations/${id}/working-plan`, {params: body})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}





/**
 * get wotking plan
 * @param {id} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getOrganizationTypes = async (params, successCallback = false, errorCallback = false) => {

    const {id, ...body} = params

    try{
        const response = await apiClientPlatform.get(`/organization-types`, {params: body})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}





/**
 * create export annual report
 * @param params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const exportAnnualReportCreate = async (params, successCallback = false, errorCallback = false) => {

    const { idOrganization, idPlan, ...body} = params

    try{
        const response = await apiClient.post(`/admin/organizations/${idOrganization}/plan/${idPlan}/annual-report-exports`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback)
            errorCallback(error.response)
        return error
    }
}




/**
 * get export annual report
 * @param params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const exportAnnualReportShow = async (params, successCallback = false, errorCallback = false) => {

    const { idOrganization, idPlan, id, ...body} = params

    try{
        const response = await apiClient.get(`/admin/organizations/${idOrganization}/plan/${idPlan}/annual-report-exports/${id}`, { params: body })
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback)
            errorCallback(error.response)
        return error
    }
}