import { useEffect, useState, useRef, useContext } from "react";
import "./DownloadMultiplePopup.scss";
import ModalContainer from "./ModalContainer";
import useNotification from "../../hooks/notification.hook";
import StatusIcon from "../utils/StatusIcon";
import { exportCreate, exportShow } from "../../services/plans.service";
import { Transition } from "react-transition-group";
import useCommonHelper from "../../hooks/helper.hook";
import PickerPlanTemplate from "./PickerPlanTemplate";
import OrganizationsPickerTable from "../utils/OrganizationsPickerTable";
import OrganizationsPickerContext from "../../context/organizations-picker.context";
import BoxPlanOrganizationTemplate from "./BoxPlanOrganizationTemplate";
import StatusContent from "../plan-download/StatusContent";
import { getPresetSelection } from "../../helper/formatter.helper";

export default function DownloadMultipleModal({
  open,
  setOpen,
  template,
  isAdmin,
  loadingData,
  statuses,
  presets = [],
}) {
  const { openNotification, helperErrorNotification } = useNotification();

  const {
    setFilteredList,
    organizations,
    setFilterType,
    selectedEntity,
    setSelectedEntity,
  } = useContext(OrganizationsPickerContext);

  const { animationClass } = useCommonHelper();

  const [loadingButton, setLoading] = useState([]);

  const loading = useRef([]);

  const [selectedFormat, setSelectedFormat] = useState("pdf");

  const [selectedChapters, setSelectedChapters] = useState({
    chapters: [],
    contents: [],
    goals: [],
    reqs: [],
  });

  const [includeDeadlines, setIncludeDeadlines] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState([]);

  const [selectedPreset, setSelectedPreset] = useState("");

  const [wizardStep, setWizardStep] = useState(1);

  const [wizardVisible, setWizardVisible] = useState(1);

  const downloadButton = useRef(null);

  const timerDownload = useRef(null);

  const firstStepRef = useRef(null);

  const secondStepRef = useRef(null);

  const thirdStepRef = useRef(null);

  useEffect(() => {
    if (open) {
      setSelectedChapters({
        chapters: [],
        contents: [],
        goals: [],
        reqs: [],
      });
      setSelectedStatus([]);
      setSelectedFormat("pdf");
      setIncludeDeadlines(false);
      setSelectedEntity([]);
      setFilteredList(organizations);
      setFilterType([]);
      setSelectedPreset("");
      setWizardStep(1);
      setWizardVisible(1);
    }
  }, [open]);

  const toggleStatus = (status) =>
    selectedStatus.find((s) => s === status)
      ? setSelectedStatus((list) => list.filter((s) => s != status))
      : setSelectedStatus((l) => [...l, status]);

  const toggleAllStatus = () =>
    selectedStatus.length === statuses.length
      ? setSelectedStatus([])
      : setSelectedStatus(statuses.map((s) => s.id));

  /**
   * Create Download
   */
  const createDownload = () => {
    if (loading.current.length == 0 && selectedEntity.length > 0) {
      setLoading([...Array(selectedEntity.length)]);
      loading.current = [...Array(selectedEntity.length)];

      const templateChapters = selectedChapters.chapters;

      const templateChapterContents = selectedChapters.contents.map(
        (content) => content.id,
      );

      const templateGoals = selectedChapters.goals.map((goal) => goal.id);

      const templateRequirements = selectedChapters.reqs.map((r) => r.id);

      selectedEntity.forEach((org) => {
        const body = {
          idCorp: org.id,
          idPlan: org.organization_plans[0].id,
          options: {
            organizationRequirementsStatuses: selectedStatus,
            templateChapters,
            templateChapterContents,
            templateGoals,
            templateRequirements,
            deadlines: selectedFormat !== "csv" && includeDeadlines,
          },
          format: selectedFormat,
        };

        exportCreate(
          body,
          (res) => {
            getDownload(res.data.id, org.id, org.organization_plans[0].id);
          },
          (error) => {
            helperErrorNotification(error);
            loading.current.pop();
            setLoading((l) => {
              l.pop();
              return l;
            });
          },
        );
      });
    }
  };

  const CsvTemplate = template.map((chapt) => ({
    ...chapt,
    contents: chapt.contents.filter((c) => c.content_type === "chapter_goal"),
  }));

  /**
   * On change format
   * @param {*} format
   */
  const onChangeFormat = (format) => {
    if (format === "csv")
      setSelectedChapters((cpts) => ({
        ...cpts,
        contents: [],
      }));

    setSelectedFormat(format);
  };

  /**
   * Get Download
   * @param {*} id
   */
  const getDownload = (id, idOrganization, idPlan) => {
    exportShow(
      { idCorp: idOrganization, idPlan: idPlan, id },
      (res) => {
        if (res.data.status === "pending") {
          timerDownload.current = setTimeout(() => {
            getDownload(id, idOrganization, idPlan);
          }, 2000);
        } else {
          const link = document.body.appendChild(document.createElement("a"));
          link.href = res.data.url;
          link.target = "_blank";
          link.download = " ";
          link.click();
          link.href = "";
          document.body.removeChild(link);
          setLoading((l) => {
            l.pop();
            return l;
          });
          loading.current.pop();
          if (loading.current.length === 0) {
            setOpen(false);
          }
          openNotification({
            text: "Documenti generati con successo!",
            status: "success",
          });
        }
      },
      (error) => {
        helperErrorNotification(error);
        loading.current.pop();
        setLoading((l) => {
          l.pop();
          return l;
        });
      },
    );
  };

  useEffect(() => {
    if (selectedPreset) {
      const preset = presets.find((p) => p.id == selectedPreset);
      setSelectedStatus(
        preset?.options?.organizationRequirementsStatuses ?? [],
      );

      let data = {
        chapters: [],
        contents: [],
        goals: [],
        reqs: [],
      };

      if (preset) {
        data = getPresetSelection(
          template,
          preset?.options?.templateRequirements,
          preset?.options?.templateChapterContents,
        );
      }
      setSelectedChapters(data);
      setIncludeDeadlines(!!preset?.options?.deadlines);
    }
  }, [selectedPreset]);

  return (
    <ModalContainer
      isOpen={open}
      setIsOpen={setOpen}
      disableClose={loading.current.length > 0}
    >
      <div
        className={
          "main_modal_content plan_download_popup" +
          (wizardVisible == 1 ? " corps_wizard" : "")
        }
      >
        <div
          className="main_close"
          onClick={() => loading.current.length == 0 && setOpen(false)}
        >
          <span className="icon_10 default close icon_mask !bg-white"></span>
        </div>

        {wizardVisible === 1 && (
          <div
            className={
              "transition-opacity " + (wizardStep === 0 ? "opacity-0" : "")
            }
          >
            <div className="main_header_modal flex_header">
              {/* Left content */}
              <div className="left_content">
                <div className="main_desc">
                  <span className="title">Genera documento massivo</span>
                  <div className="desc">Seleziona gli enti di destinazione</div>
                </div>
              </div>
            </div>
          </div>
        )}

        {wizardVisible === 2 && (
          <div
            className={
              "transition-opacity " + (wizardStep === 0 ? "opacity-0" : "")
            }
          >
            <div className="main_header_modal flex_header">
              {/* Left content */}
              <div className="left_content">
                <div
                  className="main_back_button"
                  onClick={() => setWizardStep(-1)}
                >
                  <span className="icon_16 default arrow left icon_mask !bg-interactive-100"></span>
                </div>

                <div className="main_desc">
                  <span className="title">Genera documento massivo</span>
                  <div className="desc">
                    Seleziona gli stati delle linee d’azione da includere
                  </div>
                </div>
              </div>

              {/* Right Content */}
              <div className="right_content self-end">
                <div className="main_select_landing">
                  <div className="relative">
                    <select
                      className={"landing"}
                      value={selectedPreset}
                      onChange={(e) => setSelectedPreset(e.target.value)}
                    >
                      <option disabled value="">
                        Seleziona configurazione
                      </option>
                      {presets.map((preset, i) => (
                        <option key={i} value={preset.id}>
                          {preset.name}
                        </option>
                      ))}
                    </select>
                    <div className="wrap_arrow">
                      <span className="icon_16 default chevron icon_mask !bg-interactive-100"></span>
                    </div>
                  </div>
                </div>
                {/* <div className="toggle_landing">
                  <input
                    onChange={toggleAllStatus}
                    checked={selectedStatus.length === statuses.length}
                    type="checkbox"
                    id="select_all_option_generation"
                    className="landing"
                  />
                  <label htmlFor={"select_all_option_generation"}>
                    Seleziona tutti
                  </label>
                </div>*/}
              </div>
            </div>
          </div>
        )}

        {wizardVisible === 3 && (
          <div
            className={
              "transition-opacity " + (wizardStep === 0 ? "opacity-0" : "")
            }
          >
            <div className="main_header_modal flex_header">
              <div className="left_content">
                <div
                  className="main_back_button"
                  onClick={() => setWizardStep(-1)}
                >
                  <span className="icon_16 default arrow left icon_mask !bg-interactive-100"></span>
                </div>

                <div className="main_desc">
                  <span className="title">Genera documento massivo</span>
                  <div className="desc">
                    Seleziona il contenuto ed il formato dei file
                  </div>
                </div>
              </div>

              <div className="right_content">
                <div className={"main_select_landing"}>
                  <select
                    defaultValue={selectedFormat}
                    onChange={(e) => onChangeFormat(e.target.value)}
                    className={"landing big"}
                  >
                    <option value="pdf">Formato PDF</option>
                    <option value="docx">Formato DOCX</option>
                    {isAdmin && <option value="csv">Formato CSV</option>}
                  </select>
                  <div className="wrap_arrow">
                    <span className={"icon_16 default chevron"}></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={"main_body_modal"}>
          {/* List Corps */}
          <Transition
            nodeRef={firstStepRef}
            in={wizardStep === 1}
            mountOnEnter
            onExited={() => {
              setWizardStep(2);
              setWizardVisible(2);
            }}
            unmountOnExit
            timeout={{ enter: 250, exit: 250 }}
          >
            {(state) => (
              <div
                ref={firstStepRef}
                className={
                  "" +
                  animationClass(state, "change_step_in", "change_step_out")
                }
              >
                <OrganizationsPickerTable loadingData={loadingData} />
              </div>
            )}
          </Transition>

          {/* List Reqs */}
          <Transition
            nodeRef={secondStepRef}
            in={wizardStep === 2}
            mountOnEnter
            onExited={() => {
              setWizardStep(wizardStep < 0 ? 1 : 3);
              setWizardVisible(wizardStep < 0 ? 1 : 3);
            }}
            unmountOnExit
            timeout={{ enter: 250, exit: 250 }}
          >
            {(state) => (
              <div
                ref={secondStepRef}
                className={animationClass(
                  state,
                  "change_step_in",
                  "change_step_out",
                )}
              >
                <StatusContent
                  selectedStatues={selectedStatus}
                  listStatuses={statuses}
                  onToggleStatus={toggleStatus}
                  onToggleAllStatus={toggleAllStatus}
                />
              </div>
            )}
          </Transition>

          {/* Template list */}
          <Transition
            nodeRef={thirdStepRef}
            in={wizardStep === 3}
            mountOnEnter
            onExited={() => {
              setWizardStep(2);
              setWizardVisible(2);
            }}
            unmountOnExit
            timeout={{ enter: 250, exit: 250 }}
          >
            {(state) => (
              <div
                ref={thirdStepRef}
                className={animationClass(
                  state,
                  "change_step_in",
                  "change_step_out",
                )}
              >
                <div className="py-2">
                  {/*<PickerPlanTemplate
                                        template={selectedFormat === "csv" ? CsvTemplate : template}
                                        setSelectedItems={setSelectedChapters}
                                        selectedItems={selectedChapters}
                                    />*/}
                  <BoxPlanOrganizationTemplate
                    template={selectedFormat === "csv" ? CsvTemplate : template}
                    setSelectedItems={setSelectedChapters}
                    selectedItems={selectedChapters}
                    showDeadlines={selectedFormat !== "csv"}
                    isDeadlineSelected={includeDeadlines}
                    includeDeadlines={setIncludeDeadlines}
                  />
                </div>
              </div>
            )}
          </Transition>
        </div>

        <div className="main_footer_modal">
          <div className="right_content">
            <a href="" download target="_blank" ref={downloadButton}></a>

            {/* Main Cta */}
            {(wizardVisible === 1 || wizardVisible === 2) && (
              <div
                className={
                  "transition-opacity " + (wizardStep === 0 ? "opacity-0" : "")
                }
              >
                <button
                  onClick={() => setWizardStep(0)}
                  className={
                    "button fill success" +
                    (selectedEntity.length === 0 ? " disabled" : "")
                  }
                >
                  Procedi
                </button>
              </div>
            )}

            {wizardVisible === 3 && (
              <div
                className={
                  "transition-opacity " + (wizardStep === 0 ? "opacity-0" : "")
                }
              >
                <button
                  onClick={createDownload}
                  className={
                    "button fill success" +
                    (loadingButton.length > 0 ? " loading" : "")
                  }
                >
                  <div className="load_content">Genera Documento</div>
                  <div className="main_loader_icon fade_in"></div>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}
