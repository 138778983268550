import { useRef, useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import useNotification from "../../hooks/notification.hook";
import ModalContainer from "./ModalContainer";
import { useTranslation } from 'react-i18next';
import { getOrganizationPlans } from "../../services/admin.service";
import { createAgenda, updateAgenda } from "../../services/agendas.service";


export default function CrudAgendaModal({open, setOpen, templatePlans = [], agendaModel, onEdit = function(){}, onInsert = function(){}}) {

    const { t } = useTranslation()

	// Set message
	yup.setLocale({
		// use constant translation keys for messages without values
		mixed : {
			required: ({path}) => (t("common:errors.required",{field : t(`common:fields.${path}`)})),
		},
        number: {
            moreThan : ({path, more}) => (t("common:errors.more_than",{field : t(`common:fields.${path}`), value: more})),
            lessThan : ({path, less}) => (t("common:errors.less_than",{field : t(`common:fields.${path}`), value: less})),
        }
	});

	// Schema Yup
	const schema = yup.object().shape({
		plan: yup.string().when({
			is: () => !agendaModel,
			then: (schema) => schema.required()
		}),
		entity: yup.string().when({
			is: () => !agendaModel,
			then: (schema) => schema.required()
		}),
		title: yup.string().required(),
	});

	const { register, setValue, clearErrors, getValues, handleSubmit, formState:{ errors } } = useForm({resolver: yupResolver(schema)});

	const { openNotification, helperErrorNotification } = useNotification()

	const [loading, setLoading] = useState(false);

	const [loadingCorps, setLoadingCorps] = useState(false);

	const [init, setInit] = useState(false);

	const [entities, setEntities] = useState([]);



    const onSelectPlan = (planId) => {

        if(!loadingCorps){

            setLoadingCorps(true)

            setValue("entity", "")

            const body = {
                filter: { template_plan_id: planId },
                include: ["organization" , "agenda"]
            }

            getOrganizationPlans(body, (res) => {
                setEntities(res.data.filter(el => el.active && el.agenda === null))
                setInit(true)
                setLoadingCorps(false)
            }, (err) => {
                helperErrorNotification(err)
                setLoadingCorps(false)
            })
        }

    }


	/**
	 * Form Handler
	 * @param {form modal} data 
	 */
	async function formHandler(data) {
        
        if(!loading){

            setLoading(true)
            
            if(!agendaModel?.id){

                const findPlan = entities.find(el => el.id == data.entity)

                const body = {
                    idPlan: findPlan.id,
                    idCorp: findPlan.organization_id,
                    name: data.title
                }

                // Create plan
                createAgenda(body, (res) => {
                    setLoading(false)
                    openNotification({text: "Diario creato con successo!", status: "success"})
                    onInsert(res)
                }, (error) => {
                    helperErrorNotification(error)
                    setLoading(false)
                })
            }else{

                const body = {
                    id: agendaModel.id,
                    idCorp: agendaModel.organization_plan.organization_id,
                    name: data.title
                }

                // Edit plan
                updateAgenda(body, (res) => {
                    setLoading(false)
                    openNotification({text: "Diario modificato con successo!", status: "success"})
                    onEdit(res);
                }, (error) => {
                    helperErrorNotification(error)
                    setLoading(false)
                })
            }
        }
	}



	/**
	 * Errors Form
	 */
	useEffect(() => {
		if (Object.keys(errors).length) {
            const fields = Object.values(errors).map(er => er.message)
			openNotification({ fields, status: "error"})
		}
	}, [errors]);





	/**
	 * Init value
	 */
	useEffect(() => {
		if (open) {
            clearErrors()
			setValue("plan", '')
			setValue("entity", '')
			setValue("title", agendaModel?.name ? agendaModel.name : "")
            setEntities([])
            setInit(false)
		}
	}, [open]);


	return (
        <ModalContainer isOpen={open} setIsOpen={setOpen}>
            <div className={"main_modal_content"} >

                <div className="main_close" onClick={() => setOpen(false)}>
                    <span className="icon_10 default close icon_mask !bg-white"></span>
                </div>

                <div className="main_header_modal left_text">
                    <span className="title">{agendaModel?.id ? "Modifica diario" : "Nuovo diario"}</span>
                </div>


                {/* Modal Form */}
                <div className={"main_body_modal"}>

                    {!agendaModel &&
                        <div className="mb-5">
                            <div className="main_message_box box_info">
                                <div className="main_icon">
                                    <span className="icon_16 default info"></span>
                                </div>
                                <div className="message">Selezionare un piano per abilitare la scelta dell'ente</div>
                            </div>
                        </div>
                    }



                    <form id='crud-edit-psw-form' className='global_form_landing' onSubmit={handleSubmit(formHandler)} noValidate>

                        {/* Plan */}
                        {!agendaModel &&
                            <div className="base">
                                <div className="main_select_landing">
                                    <label htmlFor='crud-agenda-plan' className='label_input'>Piano</label>
                                    <div className="relative">
                                        <select disabled={loadingCorps} className={"landing big" + (errors.plan ? ' err' : '')} id='crud-agenda-plan' {...register("plan",  { onChange: (e) => onSelectPlan(e.target.value)})}>
                                            <option disabled value="">Seleziona un piano</option>
                                            {templatePlans.map((plan, i) => (
                                                <option key={i} value={plan.id}>{plan.name}</option>
                                            ))}
                                        </select>
                                        <div className="wrap_arrow">
                                            <span className="icon_16 default chevron icon_mask !bg-interactive-100"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Entity */}
                        {!agendaModel && 
                            <div className="base">
                                <div className={"main_select_landing" + (loadingCorps || !init ? " dis" : "")}>
                                    <label htmlFor='crud-agenda-entity' className='label_input'>Ente</label>
                                    <div className="relative">
                                        <select disabled={loadingCorps || !init} className={"landing big" + (errors.entity ? ' err' : '')} id='crud-agenda-entity' {...register("entity")}>
                                            <option disabled value="">Seleziona un ente</option>
                                            {entities.map((plan, i) => (
                                                <option key={i} value={plan.id}>{plan.organization.name}</option>
                                            ))}
                                        </select>
                                        <div className="wrap_arrow">
                                            <span className={"icon_16 default " + (loadingCorps ? "loader" : "chevron icon_mask !bg-interactive-100")}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Name extended */}
                        <div className="base">
                            <div className="wrap_input_landing">
                                <label htmlFor='crud-agenda-title' className='label_input'>Titolo</label>
                                <input className={"landing big" + (errors.title ? ' err' : '')} id='crud-agenda-title' type='text' {...register("title")}/>
                            </div>
                        </div>

                    </form>
                </div>

                <div className="main_footer_modal">

                    <div className="right_content">

                        {/* Main Cta */}
                        <button form="crud-edit-psw-form" className='button fill success'>Conferma</button>
                    </div>
                </div>
            </div>
        </ModalContainer>
	);
}
