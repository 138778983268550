import { apiClientPlatform } from "./config";


/**
 * Get authentication token API
 * @param {id} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const userLogin = async (params, successCallback = false, errorCallback = false) => {

    const { id } = params 

    try{
        const response = await apiClientPlatform.get(`/users/${id}`)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }

}



/**
 * Get organization API
 * @param {id} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getOrganization = async (params, successCallback = false, errorCallback = false) => {

    const { id , ...body } = params

    try{
        const response = await apiClientPlatform.get(`/organizations/${id}`, {params: body})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }

}