import c from "./FilterDropdown.module.scss";
import { useState, useRef, useEffect } from "react";
import { Transition } from "react-transition-group";
import useCommonHelper from "../../hooks/helper.hook";
import useOnClickOutside from "../../hooks/click-outside.hook";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

function FilterDropdownGeneric({
  toggleLabel,
  icon,
  onApply,
  counter,
  isLoading,
  children,
  onClose = () => {},
  onOpen = () => {},
  containerClass = "",
}) {
  const popoverContainer = useRef(null);

  const popoverContent = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const { animationClass } = useCommonHelper();

  useOnClickOutside(popoverContainer, () => {
    setIsOpen(false);
    onClose();
  });

  return (
    <div
      ref={popoverContainer}
      className={`${c.main_dropdown_filter} ${isLoading ? c.loading : ""}`}
    >
      <div
        className={`${c.main_toggle_filter} ${isOpen ? c.open : ""}`}
        onClick={() => {
          if (!isLoading) {
            setIsOpen(!isOpen);
            onOpen();
          }
        }}
      >
        <span className={c.label}>{toggleLabel}</span>

        {icon && !counter && (
          <div className="flex">
            <span className={`${icon} icon_mask !bg-dark-50`}></span>
          </div>
        )}
        {counter && (
          <div className={c.main_counter}>
            <span className={c.counter}>{counter}</span>
          </div>
        )}
        {isLoading && (
          <div className="flex">
            <span className="icon_16 default loader icon_mask !bg-dark-70"></span>
          </div>
        )}
      </div>

      <Transition
        nodeRef={popoverContent}
        in={isOpen}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 200, exit: 200 }}
      >
        {(state) => (
          <div
            ref={popoverContent}
            className={`${c.main_popover_filter} ${animationClass(state, "popover_in", "popover_out")}`}
          >
            <OverlayScrollbarsComponent
              defer
              options={{ scrollbars: { autoHide: "scroll" } }}
              className={`${c.popover_content} ${containerClass}`}
            >
              <div className={c.main_list_items}>{children}</div>
            </OverlayScrollbarsComponent>

            <div className={c.popover_footer}>
              <div
                className="button fill small primary w-full"
                onClick={() => {
                  onApply();
                  setIsOpen(false);
                }}
              >
                Filtra
              </div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
}

export default FilterDropdownGeneric;
