import { Fragment } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import c from "./BoxPlanOrganizationTemplate.module.scss";

export default function BoxPlanOrganizationTemplate({
  template,
  showAllContents = true,
  selectedItems,
  setSelectedItems,
  showDeadlines = false,
  isDeadlineSelected,
  includeDeadlines,
  className = "",
}) {
  const checkArrayContent = (arr, target) =>
    target.every((v) => arr.includes(v));

  const includeChapter = (arr, target) =>
    target.reduce((s, c) => s || arr.includes(c), false);

  /**
   * All selected req
   * @returns Array of ids
   */
  const selectedReqs = () => selectedItems.reqs;

  /**
   * All selected contents
   * @returns Array of ids
   */
  const selectedContents = () => selectedItems.contents;

  /**
   * Check if is all selected
   * @returns
   */
  const isAllSelected = () => {
    const allContents = template.reduce(
      (a, c) => [
        ...a,
        ...(c?.contents.filter(
          (c) =>
            c.content_type === "chapter_content" &&
            (c.editable || showAllContents),
        )?.length > 0
          ? c?.contents
              .filter(
                (c) =>
                  c.content_type === "chapter_content" &&
                  (c.editable || showAllContents),
              )
              .map((content) => content.id)
          : []),
      ],
      [],
    );

    const allReqs = template
      .reduce(
        (acc, c) => [
          ...acc,
          ...(c?.contents.filter((c) => c.content_type === "chapter_goal")
            ? [
                ...c?.contents
                  .filter((c) => c.content_type === "chapter_goal")
                  .map((g) => g.requirements.map((req) => req.id)),
              ]
            : []),
        ],
        [],
      )
      .reduce((a, b) => [...a, ...b], []);

    return (
      allReqs.length === selectedReqs().length &&
      allContents.length === selectedContents().length
    );
  };

  /**
   * On change requirement handler
   * @param {*} e
   * @param {*} action
   */
  function onCheckRequirement(e, actionId, goalId, chapterTemplateId) {
    if (e.target.checked) {
      setSelectedItems((s) => {
        s.chapters = [...new Set([...s.chapters, chapterTemplateId])];
        s.goals = !s.goals.find((g) => g.id === goalId)
          ? [...s.goals, { id: goalId, chapter_id: chapterTemplateId }]
          : s.goals;
        s.reqs = [
          ...s.reqs,
          { id: actionId, goal_id: goalId, chapter_id: chapterTemplateId },
        ];
        return { ...s };
      });
    } else {
      setSelectedItems((s) => {
        s.reqs = s.reqs.filter((r) => r.id !== actionId);
        s.goals = s.goals.filter((g) => s.reqs.find((r) => r.goal_id === g.id));
        s.chapters = s.chapters.filter(
          (c) =>
            s.contents.find((e) => e.chapter_id === c) ||
            s.goals.find((e) => e.chapter_id === c),
        );
        return { ...s };
      });
    }
  }

  /**
   * On change content handler
   * @param {*} e
   * @param {*} content
   */
  function onCheckContent(e, content, chapterTemplateId) {
    if (e.target.checked) {
      setSelectedItems((s) => {
        s.chapters = [...new Set([...s.chapters, chapterTemplateId])];
        s.contents = [
          ...s.contents,
          { id: content.id, chapter_id: chapterTemplateId },
        ];
        return { ...s };
      });
    } else {
      setSelectedItems((s) => {
        s.contents = s.contents.filter((c) => c.id !== content.id);
        s.chapters = s.chapters.filter(
          (c) =>
            s.contents.find((e) => e.chapter_id === c) ||
            s.goals.find((e) => e.chapter_id === c),
        );
        return { ...s };
      });
    }
  }

  /**
   * On change goal handler
   * @param {*} e
   * @param {*} goal
   * @param {*} status
   */
  function onCheckGoal(e, goal, chapterTemplateId, status) {
    const listActions = goal.requirements.map((i) => ({
      id: i.id,
      goal_id: goal.id,
      chapter_id: chapterTemplateId,
    }));

    if (!status) {
      setSelectedItems((s) => {
        s.chapters = [...new Set([...s.chapters, chapterTemplateId])];
        s.goals = !s.goals.find((g) => g.id === goal.id)
          ? [...s.goals, { id: goal.id, chapter_id: chapterTemplateId }]
          : s.goals;
        const reqs = listActions.filter(
          (item) => !s.reqs.find((r) => r.id === item.id),
        );
        s.reqs = [...s.reqs, ...reqs];
        return { ...s };
      });
    } else {
      setSelectedItems((s) => {
        s.reqs = s.reqs.filter((r) => r.goal_id !== goal.id);
        s.goals = s.goals.filter((g) => g.id !== goal.id);
        s.chapters = s.chapters.filter(
          (c) =>
            s.contents.find((e) => e.chapter_id === c) ||
            s.goals.find((e) => e.chapter_id === c),
        );
        return { ...s };
      });
    }
  }

  const onCheckItem = (e, content, chapterTemplateId) => {
    if (content.content_type === "chapter_content") {
      onCheckContent(e, content, chapterTemplateId);
    } else {
      onCheckGoal(e, content, chapterTemplateId, checkGoalContent(content));
    }
  };

  /**
   * Check goal is active
   * @param {} goal
   * @returns
   */
  function checkGoalContent(goal, partial = false) {
    const listActions = goal.requirements.map((e) => e.id);
    const selectedList = selectedItems.reqs
      .filter((r) => r.goal_id === goal.id)
      .map((r) => r.id);

    if (partial) return includeChapter(selectedList, listActions);

    return checkArrayContent(selectedList, listActions);
  }

  /**
   * Check Chapter is active
   * @param {} chapter
   * @returns
   */
  function checkChapterContent(chapter, partial) {
    const chapterGoals = chapter.contents.filter(
      (c) => c.content_type === "chapter_goal",
    );

    const listContents = chapter.contents
      .filter(
        (c) =>
          c.content_type === "chapter_content" &&
          (c.editable || showAllContents),
      )
      .map((content) => content.id);

    const listActions = chapterGoals.reduce(
      (acc, item) => [...acc, ...item.requirements.map((r) => r.id)],
      [],
    );

    const selectedListReqs = selectedItems.reqs
      .filter((r) => r.chapter_id === chapter.id)
      .map((r) => r.id);

    const selectedListContents = selectedItems.contents
      .filter((c) => c.chapter_id === chapter.id)
      .map((r) => r.id);

    if (partial) {
      return (
        includeChapter(selectedListReqs, listActions) ||
        includeChapter(selectedListContents, listContents)
      );
    }

    return (
      checkArrayContent(selectedListReqs, listActions) &&
      checkArrayContent(selectedListContents, listContents)
    );
  }

  /**
   * on Check chapter
   * @param {*} e
   * @param {*} chapter
   */
  function onCheckChapter(e, chapter) {
    const chapterGoals = chapter.contents.filter(
      (c) => c.content_type === "chapter_goal",
    );

    const listGoals = chapterGoals.map((goal) => ({
      id: goal.id,
      chapter_id: chapter.id,
    }));

    const listContents = chapter.contents
      .filter(
        (c) =>
          c.content_type === "chapter_content" &&
          (c.editable || showAllContents),
      )
      .map((content) => ({ id: content.id, chapter_id: chapter.id }));

    const listActions = chapterGoals.reduce(
      (acc, item) => [
        ...acc,
        ...item.requirements.map((r) => ({
          id: r.id,
          goal_id: item.id,
          chapter_id: chapter.id,
        })),
      ],
      [],
    );

    if (e.target.checked) {
      setSelectedItems((s) => {
        s.chapters = [...new Set([...s.chapters, chapter.id])];
        const contents = listContents.filter(
          (item) => !s.contents.find((c) => c.id === item.id),
        );
        s.contents = [...s.contents, ...contents];
        const goals = listGoals.filter(
          (item) => !s.goals.find((g) => g.id === item.id),
        );
        s.goals = [...s.goals, ...goals];
        const reqs = listActions.filter(
          (item) => !s.reqs.find((r) => r.id === item.id),
        );
        s.reqs = [...s.reqs, ...reqs];
        return { ...s };
      });
    } else {
      setSelectedItems((s) => {
        s.reqs = s.reqs.filter((r) => r.chapter_id !== chapter.id);
        s.goals = s.goals.filter((g) => g.chapter_id !== chapter.id);
        s.contents = s.contents.filter((c) => c.chapter_id !== chapter.id);
        s.chapters = s.chapters.filter((c) => c !== chapter.id);
        return { ...s };
      });
    }
  }

  /**
   * Select all handler
   * @param {*} e
   */
  const onSelectAll = (e) => {
    if (e.target.checked) {
      const chapters = template.map((c) => c.id);

      const contents = template.reduce(
        (a, c) => [
          ...a,
          ...(c?.contents.filter(
            (c) =>
              c.content_type === "chapter_content" &&
              (c.editable || showAllContents),
          )?.length > 0
            ? c?.contents
                .filter(
                  (c) =>
                    c.content_type === "chapter_content" &&
                    (c.editable || showAllContents),
                )
                .map((content) => ({
                  id: content.id,
                  chapter_id: c.id,
                }))
            : []),
        ],
        [],
      );

      const goals = template.reduce(
        (a, c) => [
          ...a,
          ...(c?.contents.filter((c) => c.content_type === "chapter_goal")
            ?.length > 0
            ? c?.contents
                .filter((c) => c.content_type === "chapter_goal")
                .map((content) => ({
                  id: content.id,
                  chapter_id: c.id,
                }))
            : []),
        ],
        [],
      );

      const reqs = template
        .reduce(
          (acc, chapter) => [
            ...acc,
            ...(chapter?.contents.filter(
              (c) => c.content_type === "chapter_goal",
            )
              ? [
                  ...chapter?.contents
                    .filter((c) => c.content_type === "chapter_goal")
                    .map((g) =>
                      g.requirements.map((req) => ({
                        id: req.id,
                        goal_id: g.id,
                        chapter_id: chapter.id,
                      })),
                    ),
                ]
              : []),
          ],
          [],
        )
        .reduce((a, b) => [...a, ...b], []);

      setSelectedItems({
        chapters: chapters,
        contents: contents,
        goals: goals,
        reqs: reqs,
      });
    } else {
      setSelectedItems({
        chapters: [],
        contents: [],
        goals: [],
        reqs: [],
      });
    }
  };

  const showContent = (content) => {
    if (content.content_type === "chapter_content") {
      return content.editable || showAllContents;
    }

    return true;
  };

  return (
    <OverlayScrollbarsComponent
      defer
      options={{ scrollbars: { autoHide: "scroll" } }}
      className={`${c.main_list_capthers} ${className}`}
    >
      {showDeadlines && (
        <div className={c.main_list_items} data-stiky={true}>
          <div className={c.item_list}>
            <div
              className={
                "check_landing small" +
                (selectedItems.length > 0 ? " partial" : "")
              }
            >
              <input
                type="checkbox"
                checked={isDeadlineSelected}
                onChange={(e) => includeDeadlines(e.target.checked)}
                id="include-plan-deadline"
              />
              <label htmlFor={"include-plan-deadline"}>
                <span className={c.name}>Includi scadenzario</span>
              </label>
            </div>
          </div>
        </div>
      )}

      {/* Chapters */}
      {template && (
        <div className={c.main_list_items}>
          {/* Select all option */}
          <div className={c.item_list}>
            <div
              className={
                "check_landing small" +
                (selectedItems.length > 0 ? " partial" : "")
              }
            >
              <input
                type="checkbox"
                checked={isAllSelected()}
                onChange={(e) => onSelectAll(e)}
                id="select-all-content"
              />
              <label htmlFor={"select-all-content"}>
                <span className={c.name}>Seleziona tutto</span>
              </label>
            </div>
          </div>

          {/* List Chapters */}
          {template.map(
            (chapter, i) =>
              chapter.contents.length > 0 && (
                <Fragment key={i}>
                  {/* List Chapters */}
                  <div className={c.item_list}>
                    <div
                      className={
                        "check_landing small" +
                        (checkChapterContent(chapter, true) ? " partial" : "")
                      }
                    >
                      <input
                        type="checkbox"
                        checked={checkChapterContent(chapter)}
                        onChange={(e) => onCheckChapter(e, chapter)}
                        id={"chapter-" + chapter.id}
                      />
                      <label htmlFor={"chapter-" + chapter.id} className="icon">
                        <div className={c.main_icon}>
                          <span className="icon_16 default folder"></span>
                        </div>
                        <span className={c.name}>{chapter.name}</span>
                      </label>
                    </div>
                  </div>

                  {/* List Contents */}
                  <div className={c.main_list_items}>
                    {chapter.contents.map(
                      (content, i) =>
                        showContent(content) && (
                          <Fragment key={i}>
                            <div className={`${c.item_list} ${c.goal}`} key={i}>
                              <div
                                className={
                                  "check_landing small" +
                                  (content.content_type === "chapter_goal" &&
                                  checkGoalContent(content, true)
                                    ? " partial"
                                    : "")
                                }
                              >
                                <input
                                  type="checkbox"
                                  checked={
                                    content.content_type === "chapter_content"
                                      ? !!selectedContents().find(
                                          (c) => c.id === content.id,
                                        )
                                      : checkGoalContent(content)
                                  }
                                  onChange={(e) =>
                                    onCheckItem(e, content, chapter.id)
                                  }
                                  id={`content-${content.id}-${i}`}
                                />
                                <label
                                  htmlFor={`content-${content.id}-${i}`}
                                  className="icon"
                                >
                                  <div className={c.main_icon}>
                                    <span
                                      className={
                                        "icon_16 default " +
                                        (content.content_type === "chapter_goal"
                                          ? "layers"
                                          : "text_box")
                                      }
                                    ></span>
                                  </div>
                                  <span className={c.name}>
                                    {content?.name}
                                  </span>
                                </label>
                              </div>
                            </div>

                            {/* Requirements list */}
                            {content.content_type === "chapter_goal" && (
                              <div className={c.main_list_items}>
                                {content.requirements.map((req, i) => (
                                  <div
                                    key={i}
                                    className={`${c.item_list} ${c.goal}`}
                                  >
                                    <div className="check_landing small">
                                      <input
                                        type="checkbox"
                                        checked={
                                          !!selectedReqs().find(
                                            (r) => r.id === req.id,
                                          )
                                        }
                                        onChange={(e) =>
                                          onCheckRequirement(
                                            e,
                                            req.id,
                                            content.id,
                                            chapter.id,
                                          )
                                        }
                                        id={"action-" + req.id}
                                      />
                                      <label
                                        htmlFor={"action-" + req.id}
                                        className="icon"
                                      >
                                        <div className={c.main_icon}>
                                          <span className="icon_16 default layer"></span>
                                        </div>
                                        <span className={c.name}>
                                          {req.name}
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </Fragment>
                        ),
                    )}
                  </div>
                </Fragment>
              ),
          )}
        </div>
      )}
    </OverlayScrollbarsComponent>
  );
}
