import {useEffect, useState} from "react";
import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from "react-hook-form";
import useNotification from "../../../hooks/notification.hook";
import {useNavigate} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import ModalContainer from '../ModalContainer';
import "./EndSessionPopup.scss"
import usePlanCompile from '../../../hooks/plan-compile.hook';
import {createAgenda, createAnnotation, getAnnotation, updateAnnotation} from "../../../services/agendas.service";
import {usePlanCompileStore} from "../../../store/plan-compile.store";


export default function EndSessionPopup({open, setOpen, agenda, onCancel = function () {}, annotations, loadingAnnotations, role}) {

    const {t} = useTranslation()

    const [
        planDetail,
        updateState
    ] = usePlanCompileStore(state => [
        state.planDetail,
        state.updateState,
    ])

    const {getStoredChangesBlocks, saveActivities} = usePlanCompile()

    // Set message
    yup.setLocale({
        // use constant translation keys for messages without values
        mixed: {
            required: ({path}) => (t("common:errors.required", {field: t(`common:fields.${path}`)})),
        },
        number: {
            moreThan: ({path, more}) => (t("common:errors.more_than", {
                field: t(`common:fields.${path}`),
                value: more
            })),
            lessThan: ({path, less}) => (t("common:errors.less_than", {
                field: t(`common:fields.${path}`),
                value: less
            })),
        }
    });

    // Schema Yup
    const schema = yup.object().shape({
        name_agenda: yup.string().when({
            is: () => agenda === null,
            then: (schema) => schema.required()
        }),
        annotation_id: yup.string(),
        name_annotation: yup.string().when('annotation_id', {
            is: (val) => val === '',
            then: (schema) => schema.required()
        }),
        date_creation: yup.string().required(),
    });


    const {
        register,
        setValue,
        clearErrors,
        handleSubmit,
        watch,
        formState: {errors}
    } = useForm({resolver: yupResolver(schema)});

    const {openNotification, helperErrorNotification} = useNotification()

    const [loading, setLoading] = useState(false);

    const watchAnnotation = watch("annotation_id")

    const navigate = useNavigate()

    /**
     * Form Handler
     * @param {form modal} data
     */
    async function formHandler(data) {

        if (!loading) {

            setLoading(true)


            let idAgenda = agenda ? agenda.id : null

            if (!agenda) {

                // Create agenda API
                const res = await createAgenda({
                    idCorp: planDetail.organization_id,
                    idPlan: planDetail.id,
                    name: data.name_agenda
                })

                idAgenda = res.data.id
            }


            /* updateAnnotation */
            if (data.annotation_id === "") {

                const blocks = await getStoredChangesBlocks(data.name_annotation)

                // Create annotation API
                createAnnotation({
                    idCorp: planDetail.organization_id,
                    idAgenda,
                    name: data.name_annotation,
                    date: data.date_creation,
                    editorjs: {blocks: blocks}
                }, (res) => {

                    successCallback()

                }, (err) => {
                    setLoading(false)
                    helperErrorNotification(err)
                })


            } else {

                // Get annotation Api
                getAnnotation({params: {id: data.annotation_id, idCorp: planDetail.organization_id}}, async (res) => {
                    const currentEditor = res.data.editorjs
                    const blocks = await getStoredChangesBlocks(res.data.name)

                    currentEditor.blocks.push({
                        type: "delimiter",
                        data: {}
                    }, ...blocks)

                    // Update annotation Api
                    updateAnnotation({
                        idCorp: planDetail.organization_id,
                        id: res.data.id,
                        editorjs: currentEditor,
                        name: res.data.name,
                        date: res.data.date
                    }, () => {

                        successCallback()

                    }, (err) => {

                        setLoading(false)

                        helperErrorNotification(err)
                    })
                }, (err) => {

                    setLoading(false)

                    helperErrorNotification(err)
                })
            }
        }
    }


    /**
     * Success callback
     */
    const successCallback = () => {

        setLoading(false)

        saveActivities(planDetail)

        updateState({ changesList: [], isPlanApproved: false })

        setOpen(false)

        openNotification({text: "Annotazione creata con successo!", status: "success"})

        setTimeout(() => {
            if(role === "admin"){
                navigate(`/admin/entities/entity/${planDetail.organization_id}`)
            }
            if(role === "reseller"){
                navigate(`/reseller/${planDetail.organization_id}`)
            }
            if(role === "organization"){
                navigate(`/organization`)
            }
        }, 0)

    }


    /**
     * Errors Form
     */
    useEffect(() => {
        if (Object.keys(errors).length) {
            const fields = Object.values(errors).map(er => er.message)
            openNotification({fields, status: "error"})
        }
    }, [errors]);


    /**
     * Init value
     */
    useEffect(() => {
        if (open) {
            clearErrors()
            setValue("name_agenda", '')
            setValue("name_annotation", '')
            setValue("date_creation", '')
            setValue("annotation_id", '')
        }
    }, [open]);


    return (
        <ModalContainer isOpen={open} setIsOpen={setOpen}>
            <div className={"main_modal_content session_popup"}>

                <div className="main_close" onClick={() => setOpen(false)}>
                    <span className="icon_10 default close icon_mask !bg-white"></span>
                </div>

                <div className="main_header_modal left_text">
                    <span className="title">Termina sessione</span>
                </div>


                {/* Modal Form */}
                <div className={"main_body_modal"}>

                    <div className="main_message_box box_info">
                        <div className="main_icon">
                            <span className="icon_16 default info"></span>
                        </div>
                        <div className="message">Salva le annotazioni della sessione nel diario.</div>
                    </div>

                    {agenda &&
                        <div className="main_sub_intro pb-4">
                            <div className="wrap_sub_intro">
                                <div className="main_icon">
                                    <span className="icon_16 default book"></span>
                                </div>
                                <div className="subintro">Diario</div>
                            </div>

                            <div className="main_description">{agenda.name}</div>
                        </div>
                    }


                    <form id='crud-edit-psw-form' className='global_form_landing' onSubmit={handleSubmit(formHandler)}
                          noValidate>

                        {/* Name Agenda */}
                        {agenda === null &&
                            <div className="base">
                                <div className="wrap_input_landing">
                                    <label htmlFor='session-end-name-diary' className='label_input'>Nome diario</label>
                                    <input className={"landing big" + (errors.name_agenda ? ' err' : '')}
                                           id='session-end-name-diary' type='text' {...register("name_agenda")}/>
                                </div>
                            </div>
                        }

                        {/* Select annotation */}
                        <div className="base">
                            <div className={"main_select_landing" + (loadingAnnotations ? " dis" : "")}>
                                <label htmlFor='crud-agenda-entity' className='label_input'>Annotazione</label>
                                <div className="relative">
                                    <select disabled={loadingAnnotations}
                                            className={"landing big" + (errors.entity ? ' err' : '')}
                                            id='crud-agenda-entity' {...register("annotation_id")}>
                                        <option value="">+ Nuova annotazione</option>
                                        {annotations.map((annotation, i) => (
                                            <option key={i} value={annotation.id}>{annotation.name}</option>
                                        ))}
                                    </select>
                                    <div className="wrap_arrow">
                                        <span
                                            className={"icon_16 default " + (loadingAnnotations ? "loader" : "chevron icon_mask !bg-interactive-100")}></span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* Name annotation */}
                        {watchAnnotation === "" &&
                            <div className="base fade_in">
                                <div className="wrap_input_landing">
                                    <label htmlFor='session-end-name-annotation' className='label_input'>Nome
                                        annotazione</label>
                                    <input className={"landing big" + (errors.name_annotation ? ' err' : '')}
                                           id='session-end-name-annotation'
                                           type='text' {...register("name_annotation")}/>
                                </div>
                            </div>
                        }


                        {/* Date */}
                        <div className="base">
                            <div className="wrap_input_landing date">
                                <label htmlFor='session-end-date' className='label_input'>Data inizio</label>
                                <div className="relative">
                                    <input {...register("date_creation")}
                                           className={"landing big" + (errors.date_creation ? ' err' : '')}
                                           id='session-end-date' type='date'/>
                                    <span className="icon_24 default calendar icon_mask !bg-interactive-100"></span>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

                <div className="main_footer_modal">

                    {/* Cancel */}
                    <button onClick={onCancel} className='button line'>Non salvare</button>

                    {/* Main Cta */}
                    <button form="crud-edit-psw-form" className='button fill success'>Salva</button>
                </div>
            </div>
        </ModalContainer>
    );
}
