import c from "./EmptyTemplate.module.scss"

function EmptyTemplate({ image, title, description, actionTemplate}) {

    return (
        <div className={`${c.main_empty} fade_in`}>
            <div className={c.wrap_empty}>

                <div className={c.main_image}>
                    <img src={image} className={c.image} />
                </div>

                <div className={c.main_desc}>
                    <div className={c.title}>{title}</div>
                    <div className={c.desc}>{description}</div>
                </div>

                {actionTemplate &&
                    <div className={c.main_action}>
                        {actionTemplate()}
                    </div>
                }

            </div>
        </div>
    );
}

export default EmptyTemplate;