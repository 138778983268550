import LayoutNav from "../../components/layout/LayoutNav";
import SubHeader from "../../components/elements/SubHeader";
import SectionBox from "../../components/section-box/SectionBox";
import ListContent from "../../components/plan-detail/list-content/ListContent";
import ListApporvals from "../../components/plan-detail/box-approvals/ListApporvals";
import ListActivities from "../../components/plan-detail/box-activities/ListActivities";
import BarChartDetail from "../../components/plan-detail/chart-bar/BarChartDetail";
import BoxNotes from "../../components/plan-detail/box-notes/BoxNotes";
import { useLoaderData } from "react-router-dom";
import EmptyBox from "../../components/emptystate/EmptyBox";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAnnotations } from "../../services/agendas.service";
import ListActions from "../../components/plan-detail/action-item/ListActions";
import CrudApprovalPopup from "../../components/popups/plan-compile/CrudApprovalPopup";
import { getActivitiesList } from "../../services/activities.service";
import { getPlanApprovals } from "../../services/plans.service";
import useCalendly from "../../hooks/calenldy.hook";
import LoadingBox from "../../components/loading/LoadingBox";
import ActionBtnItem from "../../components/utils/ActionBtnItem";
import PlanDownloadModal from "../../components/popups/PlanDownloadPopup";
import { formatOrganizationPlanDownloadObject } from "../../helper/formatter.helper";
import { ARRAY_STATUS_ICON } from "../../helper/common.helper";
import AnnualReportPopup from "../../components/popups/AnnualReportPopup";

function PlanDetail({ role = "admin" }) {
  const { planData } = useLoaderData();

  const [annotations, setAnnotations] = useState([]);

  const [openPrinterModal, setOpenPrinterModal] = useState(false);

  const [isAnnualReportOpen, setIsAnnualReportOpen] = useState(false);

  const [activities, setActivities] = useState([]);

  const [isInit, setIsInit] = useState({
    approvals: false,
    annotations: false,
    activities: false,
  });

  const [isLoading, setIsLoading] = useState({
    approvals: false,
    annotations: false,
    activities: false,
  });

  const [approvals, setApprovals] = useState([]);

  const [approvalOpen, setApprovalOpen] = useState(false);
  const [approvalSelected, setApprovalSelected] = useState(null);

  const { openCalendly } = useCalendly();

  useEffect(() => {
    if (role === "admin") {
      if (planData?.agenda) getListAnnotation();
      else {
        setIsInit((i) => ({ ...i, annotations: true }));
      }
    }

    getListActivities();
  }, []);

  useEffect(() => {
    getListApprovals();
  }, []);

  /**
   * Get Annotations list
   */
  const getListAnnotation = () => {
    setIsLoading((l) => ({ ...l, annotations: true }));

    getAnnotations(
      { id: planData.agenda.id, idCorp: planData.organization_id },
      (res) => {
        setIsInit((i) => ({ ...i, annotations: true }));

        setIsLoading((l) => ({ ...l, annotations: false }));

        setAnnotations(res.data);
      },
      () => setIsLoading((l) => ({ ...l, annotations: false })),
    );
  };

  /**
   * Get Approvals list
   */
  const getListApprovals = () => {
    setIsLoading((l) => ({ ...l, approvals: true }));

    getPlanApprovals(
      { idCorp: planData.organization_id, idPlan: planData.id },
      (res) => {
        setIsLoading((l) => ({ ...l, approvals: false }));

        setIsInit((i) => ({ ...i, approvals: true }));

        setApprovals(res.data);
      },
      () => setIsLoading((l) => ({ ...l, approvals: false })),
    );
  };

  /**
   * Get Annotations list
   */
  const getListActivities = () => {
    setIsLoading((l) => ({ ...l, activities: true }));

    getActivitiesList(
      { idPlan: planData.id, idCorp: planData.organization_id },
      (res) => {
        setIsLoading((l) => ({ ...l, activities: false }));

        setIsInit((i) => ({ ...i, activities: true }));

        setActivities(res.data);
      },
      () => setIsLoading((l) => ({ ...l, activities: false })),
    );
  };

  const countEditDocs = planData.organization_chapters.reduce(
    (a, c) =>
      a +
      c.organization_chapter_contents.filter(
        (c) => c.template_chapter_content.editable,
      ).length,
    0,
  );

  const counterAttachmentsRequirements = planData.organization_chapters.reduce(
    (a, c) =>
      a +
      (c?.organization_goals.reduce(
        (a, g) => a + (g?.organization_requirements_attachments_count ?? 0),
        0,
      ) ?? 0),
    0,
  );

  const counterAttachmentsPlan = planData?.attachments?.length ?? 0;

  /**
   * Template subheader
   * @returns
   */
  const templateSubheader = () => {
    if (role === "admin")
      return (
        <div className="flex gap-5 items-center">
          <div className="flex gap-3 items-center">
            <ActionBtnItem icon="support" onClick={openCalendly} big />

            <ActionBtnItem
              icon="printer"
              onClick={() => setOpenPrinterModal(true)}
              big
            />
          </div>

          <button
            className="button line small primary"
            onClick={() => setIsAnnualReportOpen(true)}
          >
            Genera relazione annuale
          </button>

          <Link
            to={`/admin/entities/entity/${planData.organization_id}/plan/${planData.id}/compile`}
            className="button fill small interactive"
          >
            <span className="icon_24 default in_entity icon_mask !bg-white -my-1"></span>
            Accedi come Ente
          </Link>
        </div>
      );

    if (role === "reseller")
      return (
        <Link
          to={`/reseller/${planData.organization_id}/plan/${planData.id}/compile`}
          className="button fill small secondary"
        >
          <span className="icon_24 default wrench icon_mask -ml-2 !bg-primary-100"></span>
          Compila piano
        </Link>
      );

    if (role === "organization")
      return (
        <Link
          to={`/organization/plan/${planData.id}/compile`}
          className="button fill small secondary"
        >
          <span className="icon_24 default wrench icon_mask -ml-2 !bg-primary-100"></span>
          Compila piano
        </Link>
      );
  };

  /**
   *
   * @param item
   */
  const openApprovalPopup = (approval = null) => {
    setApprovalSelected(approval);
    setApprovalOpen(true);
  };

  const downloadTemplate = formatOrganizationPlanDownloadObject(
    planData.organization_contents,
  );

  return (
    <LayoutNav withSubheader>
      <SubHeader
        title={planData?.template_plan?.name}
        sublogo={
          planData.organization.logo_url ? planData.organization.logo_url : null
        }
        subtitle={role !== "organization" && planData.organization.name}
        back
        TemplateRightContent={templateSubheader}
      />

      <div className="main_page_box">
        <div className="grid_page_right_side">
          <div className="left_content">
            {role !== "admin" && (
              <SectionBox title="Cosa vuoi fare?" layout="unboxed">
                <ListActions>
                  {/* Action Approve */}
                  <ListActions.Item
                    title="Adotta il piano"
                    icon="piano"
                    description="Non serve che le linee d'azione siano tutte completate ma è fondamentale che tutte siano state prese in carico."
                    actionTemplate={() => (
                      <button
                        className="button ligth_green small fill"
                        onClick={() => openApprovalPopup()}
                      >
                        <span className="icon_24 default approved icon_mask !bg-success-100"></span>
                        Adotta il piano
                      </button>
                    )}
                  />

                  {/* Action Booking */}
                  {role !== "reseller" && (
                    <ListActions.Item
                      title="Verifica il piano con l’esperto"
                      icon="support"
                      description="Prenota il tuo appuntamento per discutere le linee d'azione del tuo piano."
                      actionTemplate={() => (
                        <div
                          onClick={openCalendly}
                          className="link-interactive" /* onClick={() => setApprovalOpen(true)} */
                        >
                          <span className="icon_24 default link_out icon_mask !bg-interactive-100"></span>
                          Prenota appuntamento
                        </div>
                      )}
                    />
                  )}

                  {/* Action Booking */}
                  <ListActions.Item
                    title="Genera il documento per la stampa"
                    icon="printer"
                    description="Non serve che le linee d'azione siano tutte completate ma è fondamentale che tutte siano state prese in carico."
                    actionTemplate={() => (
                      <div
                        className="link-interactive"
                        onClick={() => setOpenPrinterModal(true)}
                      >
                        <span className="icon_24 default doc icon_mask !bg-interactive-100"></span>
                        Genera documento
                      </div>
                    )}
                  />
                </ListActions>
              </SectionBox>
            )}

            <SectionBox title="Contenuto del piano" layout="unboxed">
              <ListContent className="xl:!grid xl:grid-cols-2">
                <ListContent.Box
                  counter={planData.organization_chapter_contents_count}
                  icon="doc"
                  isToComplete={true}
                  title="documenti testuali"
                  lineDescriptions={[
                    {
                      desc: "modificabili",
                      counter: countEditDocs,
                    },
                    {
                      desc: "da visualizzare",
                      counter:
                        planData.organization_chapter_contents_count -
                        countEditDocs,
                    },
                  ]}
                />

                <ListContent.Box
                  counter={planData.organization_goals_count}
                  icon="layers"
                  isToComplete={true}
                  title="obiettivi"
                  lineDescriptions={[
                    {
                      desc: "nuovi",
                      counter: planData.template_goals_statuses.find(
                        (el) => el.status === "new",
                      ).count,
                      icon: "icon_16 default megaphone icon_mask !bg-secondary-100",
                    },
                    {
                      desc: "aggiornati",
                      counter: planData.template_goals_statuses.find(
                        (el) => el.status === "modified",
                      ).count,
                      icon: "icon_16 default update icon_mask !bg-secondary-100",
                    },
                  ]}
                />

                <ListContent.Box
                  counter={planData.organization_requirements_count}
                  icon="layer"
                  isToComplete={true}
                  title="linee d'azione"
                  lineDescriptions={[
                    {
                      desc: "nuove",
                      counter: planData.template_requirements_statuses.find(
                        (el) => el.status === "new",
                      ).count,
                      icon: "icon_16 default megaphone icon_mask !bg-secondary-100",
                    },
                    {
                      desc: "aggiornate",
                      counter: planData.template_requirements_statuses.find(
                        (el) => el.status === "modified",
                      ).count,
                      icon: "icon_16 default update icon_mask !bg-secondary-100",
                    },
                  ]}
                />

                <ListContent.Box
                  counter={
                    counterAttachmentsPlan + counterAttachmentsRequirements
                  }
                  icon="paper_clip"
                  isToComplete={true}
                  title="Allegati"
                  lineDescriptions={[
                    {
                      desc: "del piano",
                      counter: counterAttachmentsPlan,
                    },
                    {
                      desc: "delle linee d'azione",
                      counter: counterAttachmentsRequirements,
                    },
                  ]}
                />
              </ListContent>
            </SectionBox>

            <SectionBox title="Andamento">
              <BarChartDetail
                completePercentage={planData.completion_percentage}
                data={planData.organization_requirements_statuses.map(
                  (status) => ({
                    value: status.count,
                    color:
                      ARRAY_STATUS_ICON.find((s) => s.icon === status.icon)
                        ?.color ?? "",
                    label: status.name.toLowerCase(),
                  }),
                )}
              />
            </SectionBox>

            {role === "admin" && (
              <SectionBox title="Annotazioni">
                <div className="relative">
                  {annotations.length > 0 ? (
                    <BoxNotes
                      notes={annotations.slice(0, 5)}
                      organizationId={planData.organization_id}
                    />
                  ) : (
                    isInit.annotations && (
                      <EmptyBox
                        icon="icon_75 default archive_note"
                        title="Nessun annotazione"
                        description="Non ci sono annotazioni per questo piano"
                      />
                    )
                  )}

                  {isLoading.annotations && (
                    <LoadingBox blocked={!isInit.annotations} />
                  )}
                </div>
              </SectionBox>
            )}
          </div>

          <div className="right_content">
            <SectionBox
              title="Storico adozioni"
              layout={approvals.length > 0 ? "unboxed" : ""}
            >
              <div className="relative">
                {approvals.length > 0 ? (
                  <div className="fade_in">
                    <ListApporvals
                      list={approvals.slice(0, 5)}
                      planData={planData}
                      onEdit={openApprovalPopup}
                    />
                  </div>
                ) : (
                  isInit.approvals && (
                    <EmptyBox
                      icon="icon_75 default approvals"
                      title="Nessun adozione"
                      description="Non ci sono adozioni per questo piano"
                    />
                  )
                )}

                {isLoading.approvals && (
                  <LoadingBox blocked={!isInit.approvals} />
                )}
              </div>
            </SectionBox>

            <SectionBox title="Attività">
              <div className="relative">
                {activities.length > 0 ? (
                  <div className="fade_in">
                    <ListActivities list={activities.slice(0, 8)} />
                  </div>
                ) : (
                  isInit.activities && (
                    <EmptyBox
                      icon="icon_75 default no_activities"
                      title="Nessun attività"
                      description="Non si sono verificate attivà per questo piano"
                    />
                  )
                )}
                {isLoading.activities && (
                  <LoadingBox blocked={!isInit.activities} />
                )}
              </div>
            </SectionBox>
          </div>
        </div>
      </div>

      <CrudApprovalPopup
        open={approvalOpen}
        plan={planData}
        approval={approvalSelected}
        setOpen={setApprovalOpen}
        onSuccess={getListApprovals}
      />

      <PlanDownloadModal
        open={openPrinterModal}
        setOpen={setOpenPrinterModal}
        template={downloadTemplate}
        idOrganization={planData.organization_id}
        idPlan={planData.id}
        statuses={planData.organization_requirements_statuses}
        isAdmin={role === "admin"}
        presets={planData.template_plan.print_templates}
      />

      {role === "admin" && (
        <AnnualReportPopup
          open={isAnnualReportOpen}
          setOpen={setIsAnnualReportOpen}
          organizationPlan={planData}
          statuses={planData.organization_requirements_statuses}
        />
      )}
    </LayoutNav>
  );
}

export default PlanDetail;
