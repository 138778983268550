import c from "./ListApprovals.module.scss"
import useDateFormatter from '../../../hooks/date-formatter.hook';
import {useState} from "react";
import ActionBtnItem from "../../utils/ActionBtnItem";
import CrudApprovalPopup from "../../popups/plan-compile/CrudApprovalPopup";


function ListApporvals({list = [1, 2], planData, onEdit}) {
    const {formatDate} = useDateFormatter()


    return (
        <>

            <div className={c.main_box}>
                {list.length > 0 &&
                    list.map((item, i) => (
                        <div key={i} className={c.main_item}>
                            <div className={`flex items-center justify-between`}>
                                <div className={c.main_date}>{formatDate(item.date, "DD MMM YYYY")}</div>
                                <div className={`flex`}>
                                    <ActionBtnItem onClick={ () => onEdit(item)} icon="edit" tooltip="Modifica"  />
                                </div>
                            </div>
                            <div className={c.main_message}>
                                <div className={c.main_icon}>
                                    <span className="icon_16 quotes default"></span>
                                </div>
                                {item.note}
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    );
}

export default ListApporvals;
