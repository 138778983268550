import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import c from "./GoalsContent.module.scss"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useState, useRef, useEffect } from "react";
import useCommonHelper from "../../hooks/helper.hook";
import ActionLineForm from "./ActionLineForm";
import AddButton from "./AddButton";
import ActionLineCompile from "./ActionLineCompile";
import usePlanTemplate from '../../hooks/plan-template.hook';
import EmptyTemplate from "../emptystate/EmptyTemplate";
import { Tooltip } from "react-tooltip";
import {usePlanTemplateStore} from "../../store/plan-template.store";



function GoalsContent({ isEditable }) {
    
    const { preventDragXAxis, reorderItems } = useCommonHelper()

    const templateReqModel = {
        name: "",
        name_status: "default",
        description: "",
        description_status: "default",
        advice: "",
        advice_status: "default",
        date_from: "",
        date_from_status: "default",
        date_to: "",
        date_to_status: "default",
        status: "default",
        course_id: "",
        youtube_urls: [],
    }

    const [
        chapterList,
        selectedContent,
        searchResults,
        searchModel,
        idNewCount,
        updateState
    ] = usePlanTemplateStore((state) => [
        state.chapterList,
        state.selectedContent,
        state.searchResults,
        state.searchModel,
        state.idNewCount,
        state.updateState
    ])
    
    const [selectedItem, setSelectedItem] = useState()

    const enableScroll = useRef(true)

    const actionItemsRefs = useRef([])

    const listContainerRef = useRef()




    /**
     * Drag end
     * @param {*} result 
     * @returns 
     */
    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination)
            return;

        const reorderedItems = reorderItems(selectedContent.data.template_requirements, result.source.index, result.destination.index);

        updateRequirementList([...reorderedItems])
    }


    /**
     * Update requirement List
     * @param newList
     */
    const updateRequirementList = (newList) => {

        /*const cpt = chapterList.find(c => c.content_id === selectedContent.data.template_chapter_id)

        const goal = cpt.data.contents.find(c => c.content_id === selectedContent.content_id && c.content_type === selectedContent.content_type)*/

        selectedContent.data.template_requirements = newList

        selectedContent.orderReqs = true

        updateState(({ chapterList: [...chapterList] }))
    }




    /**
     * Open Content
     * @param {*} item 
     */
    const openActionLine = (item) => {
        setSelectedItem(item)
        scrollToItem(item.id)
    }




    /**
     * Scroll to Item
     */
    function scrollToItem(id){

        const hList = listContainerRef.current.offsetTop;

        const scrollTo = document.querySelector(`#action-${id}`).offsetTop

        enableScroll.current = false

        window.scroll({ top: scrollTo - hList, left: 0 })

        setTimeout(() => enableScroll.current = true, 0)
    }



    
    /**
     * On scroll handler
     */
    function onScrollItem (){
        if(enableScroll.current && listContainerRef.current){
            const hList = listContainerRef.current.offsetTop;

            const el = actionItemsRefs.current.reduce((a,el) => {

                if(el){

                    const elTop = Math.abs(el.getBoundingClientRect().top - hList)
    
                    return [...a, { id: el.dataset.id, value: elTop }]
                }
                
                return [...a]

            }, [])
            
            const elId = el.reduce((a,b) => a.value < b.value ? a : b).id;

            setSelectedItem(selectedContent.data.template_requirements.find(el => el.id == elId))
        }
    }



    /**
     * Add Action
     * @param {*} destination 
     */
    function addAction(destination){

        const newList = [...filteredReqs]
        
        const newItem = {
            ...templateReqModel,
            name: `Linea di azione ${filteredReqs.length + 1}`,
            localStatus: "new",
            id: `new-${idNewCount}`,
            template_goal_id: selectedContent.content_id
        }

        updateState({ idNewCount: idNewCount+1 })

        newList.push(newItem)

        const orderedList = reorderItems(newList, newList.length-1, destination)
        
        updateRequirementList([...orderedList, ...selectedContent.data.template_requirements.filter(chapter => chapter.localStatus === "delete")])
    }

    

    /**
     * Update scroll item by position
     */
    useEffect(() => {
        window.addEventListener("scroll", onScrollItem)

        return () => {
            window.removeEventListener("scroll", onScrollItem)
        };
    }, [selectedContent.template_requirements])


    const showRequirement = (item) => {
        return searchResults.template_requirements.includes(item.id)
            || !searchModel
            || (selectedContent.data.template_requirements.every(r => !searchResults.template_requirements.includes(r.id))
                && (searchResults.template_goals.includes(selectedContent.content_id) ||
                    searchResults.template_chapters.includes(selectedContent.data.template_chapter_id)
                )
            )
    }

    const filteredReqs = selectedContent.data.template_requirements.filter(r => showRequirement(r))


    return (
        <>
            <div className={c.main_goals_container}>

                {filteredReqs.length > 0 &&
                    <>
                        <div className={c.main_side_list}>

                            {/* Drag Contaienr */}
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId='droppable_goals'>
                                    {(provided, snapshot) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef} className={c.main_list_actions}>
                                            <OverlayScrollbarsComponent defer options={{ scrollbars: { autoHide: "scroll" }}} className={c.wrap_list_actions}>

                                                {filteredReqs.map((item, index) => (
                                                        <Draggable isDragDisabled={!!searchModel.length} key={item.id} draggableId={String(item.id)} index={index}>
                                                            {(provided, snapshot) => (

                                                                // Item action
                                                                <div ref={provided.innerRef} {...provided.draggableProps} style={preventDragXAxis(provided,snapshot)} className={`${c.item_action} ${selectedItem?.id == item.id ? c.active : ''} ${!!searchModel.length ? c.not_draggable : ''}  ${item.errorValidationStatus ? c.error : ''}`} onClick={() => openActionLine(item)} data-tooltip-id="description_plan_small_right" data-tooltip-offset={10}  data-tooltip-content={snapshot.isDragging ? '' : item.description}>
                                                                    <div className={c.left_content}>
                                                                        <div className={`${c.main_icon} ${c.type}`}>
                                                                            <span className={"icon_16 default " + (item.errorValidationStatus ? 'layer_fill icon_mask !bg-red-100' : 'layer')}></span>
                                                                        </div>

                                                                        {!searchModel.length &&
                                                                            <div className={`${c.main_icon} ${c.draggable}`} {...provided.dragHandleProps}>
                                                                                <span className="icon_10 draggable default"></span>
                                                                            </div>
                                                                        }

                                                                        {item?.status !== "default" &&
                                                                            <div className={`${c.main_icon} ${c.type}`}>
                                                                                <span className={"icon_16 default icon_mask " + (item?.status === "modified" ? "update " : "megaphone ") + (item.errorValidationStatus ? '!bg-red-100' : '!bg-interactive-100')}></span>
                                                                            </div>
                                                                        }


                                                                        <span key={item.name} className={c.name}>{item.name}</span>
                                                                    </div>
                                                                </div>

                                                            )}
                                                        </Draggable>
                                                ))}

                                                {provided.placeholder}
                                            </OverlayScrollbarsComponent>
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>

                        {/* List Form */}
                        <div className={c.main_list_form} ref={listContainerRef}>
                            
                            {!searchModel.length && <AddButton onlyIcon onClick={() => addAction(0)} />}

                            {filteredReqs.map((item, index) => (
                                <div ref={(ref) => actionItemsRefs.current[index] = ref} data-id={item.id} key={item.id} id={"action-" + item.id} className={c.item_form}>

                                    {/* Main Content */}
                                    <div className={c.main_form}>
                                        <ActionLineForm key={item.id} goalItem={selectedContent} item={item} position={index} canDuplicate={!searchModel.length} />
                                    </div>
                                    {!searchModel.length && <AddButton onlyIcon onClick={() => addAction(index + 1)}/>}
                                </div>
                            ))}

                        </div>
                    </>
                }
            </div>

            {filteredReqs.length == 0 &&
                <EmptyTemplate 
                    image="/images/empty/empty-requirements.svg" 
                    actionTemplate={() => !searchModel.length && <button className="button fill primary small" onClick={() => addAction(0)}>+ Nuova linea d'azione</button>}
                    title="Non ci sono linee d'azione"
                    description={!searchModel.length ? "Crea una linea di azione" : ''}
                />
            }

            <Tooltip id="description_plan_small_right" place="right" classNameArrow="tooltipster_arrow right" className="tooltipster small" closeOnScroll={true} positionStrategy="fixed" />
        </>
    );
}

export default GoalsContent;