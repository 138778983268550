import { useState, createContext, useRef, useEffect } from "react";

const PlanCopyConfContext = createContext({
    crudConfOpen: false,
    setCrudConfOpen: function(){},

    crudModel: false,
    setCrudModel: function(){},
    
    selectedConf: false,
    setSelectedConf: function(){},

    selectedConfId: null,
    setSelectedConfId: function(){},

    copyPopup: false,
    setCopyPopup: function(){},

    planChapters: "",
    setPlanChapters: function(){},

    planData: "",
    setPlanData: function(){},

    listConfs: "",
    setListConfs: function(){},

    deleteModalOpen: false,
    setDeleteModalOpen: function(){},

    deleteConf: "",
    setDeleteConf: function(){},

    deleteItem: "",
    setDeleteItem: function(){},

    updateListConf: "",
    setUpdateListConf: function(){},

    loadingCopyPlan: "",
    setLoadingCopyPlan: function(){},

    listOrganizations: [],
    setListOrganizations: function(){},
});

export function PlanCopyConfContextProvider(props) {


	// Global 
	const [crudConfOpen, setCrudConfOpen ] = useState(false)

	const [crudModel, setCrudModel ] = useState(null)

    const [copyPopup, setCopyPopup] = useState(false);

    const [selectedConf, setSelectedConf] = useState(null)

    const [selectedConfId, setSelectedConfId] = useState(null)

    const [planChapters, setPlanChapters] = useState(null);

    const [listConfs, setListConfs] = useState([]);

    const [listOrganizations, setListOrganizations] = useState([]);

    const [planData, setPlanData] = useState({});

    
    // Delete 

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [deleteConf, setDeleteConf] = useState({});

    const [deleteItem, setDeleteItem] = useState(null);

    
    
    const [updateListConf, setUpdateListConf] = useState(0);

    const [loadingCopyPlan, setLoadingCopyPlan] = useState({
        showDetail: false,
        editConf: false
    });



	const context = {
        crudConfOpen,
        setCrudConfOpen,

        crudModel,
        setCrudModel,
        
        selectedConf,
        setSelectedConf,

        selectedConfId,
        setSelectedConfId,

        copyPopup,
        setCopyPopup,

        planChapters,
        setPlanChapters,

        planData,
        setPlanData,
        
        
        listConfs,
        setListConfs,

        deleteModalOpen,
        setDeleteModalOpen,

        deleteConf,
        setDeleteConf,

        deleteItem,
        setDeleteItem,

        updateListConf,
        setUpdateListConf,

        listOrganizations,
        setListOrganizations,

        loadingCopyPlan,
        setLoadingCopyPlan,
	};



	return (
		<PlanCopyConfContext.Provider value={context}>
			{props.children}
		</PlanCopyConfContext.Provider>
	);
}

export default PlanCopyConfContext;
