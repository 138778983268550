import PlanCompile from "./PlanCompile";
import {useEffect} from "react";
import {usePlanCompileStore} from "../../store/plan-compile.store";

function PlanCompileContainer(props) {

    const resetData = usePlanCompileStore(state => state.reset)

    useEffect(() => {
        return () => {
            resetData()
        }
    }, []);


    return (
        <PlanCompile {...props} />
    );
}

export default PlanCompileContainer;