import React, { useState } from "react";
import FilterDropdownGeneric from "../utils/FilterDropdownGeneric";

const initialDateFilter = {
  fromStart: "",
  fromEnd: "",
  toStart: "",
  toEnd: "",
};

const FilterDateRange = ({ onApply, counter, label, currentValue }) => {
  const [date, setDate] = useState(initialDateFilter);

  const resetDateHandler = () => {
    setDate(currentValue);
  };

  return (
    <FilterDropdownGeneric
      containerClass={"!min-h-0 !max-h-[400px]"}
      onApply={() => onApply(date)}
      onOpen={resetDateHandler}
      counter={counter > 0 ? counter : undefined}
      toggleLabel={label}
    >
      <div className="divide-y divide-dark-10 grid gap-5">
        <div className="">
          <div className="uppercase font-semibold text-dark-70 text-12">
            Data inizio
          </div>
          <div className="flex gap-3 pt-2">
            <div className="base flex-auto w-full">
              <div className="wrap_input_landing date">
                <label
                  htmlFor="filter-sidebar-template-from"
                  className="text-12 font-semibold text-dark-50 mb-2 inline-block"
                >
                  Dal
                </label>
                <div className="relative">
                  <input
                    onChange={(e) =>
                      setDate((d) => ({
                        ...d,
                        fromStart: e.target.value,
                      }))
                    }
                    value={date.fromStart}
                    max={date.fromEnd ?? undefined}
                    className={"landing"}
                    id="filter-sidebar-template-from"
                    type="date"
                  />
                  <span className="icon_24 default calendar icon_mask !bg-interactive-100"></span>
                </div>
              </div>
            </div>
            <div className="flex self-end mb-3 flex-none">
              <div className="icon_16 default chevron right opacity-50"></div>
            </div>
            <div className="base flex-auto w-full">
              <div className="wrap_input_landing date">
                <label
                  htmlFor="filter-sidebar-template-to"
                  className="text-12 font-semibold text-dark-50 mb-2 inline-block"
                >
                  Al
                </label>
                <div className="relative">
                  <input
                    onChange={(e) =>
                      setDate((d) => ({
                        ...d,
                        fromEnd: e.target.value,
                      }))
                    }
                    value={date.fromEnd}
                    min={date.fromStart}
                    className={"landing"}
                    id="filter-sidebar-template-to"
                    type="date"
                  />
                  <span className="icon_24 default calendar icon_mask !bg-interactive-100"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-4">
          <div className="uppercase font-semibold text-dark-70 text-12">
            Data Fine
          </div>
          <div className="flex gap-3 pt-2">
            <div className="base w-full">
              <div className="wrap_input_landing date">
                <label
                  htmlFor="filter-sidebar-template-from"
                  className="text-12 font-semibold text-dark-50 mb-2 inline-block"
                >
                  Dal
                </label>
                <div className="relative">
                  <input
                    onChange={(e) =>
                      setDate((d) => ({
                        ...d,
                        toStart: e.target.value,
                      }))
                    }
                    value={date.toStart}
                    max={date.toEnd ?? undefined}
                    className={"landing"}
                    id="filter-sidebar-template-from"
                    type="date"
                  />
                  <span className="icon_24 default calendar icon_mask !bg-interactive-100"></span>
                </div>
              </div>
            </div>
            <div className="icon_16 default chevron flex-none right self-end mb-3 opacity-50"></div>
            <div className="base w-full">
              <div className="wrap_input_landing date">
                <label
                  htmlFor="filter-sidebar-template-to"
                  className="text-12 font-semibold text-dark-50 mb-2 inline-block"
                >
                  Al
                </label>
                <div className="relative">
                  <input
                    onChange={(e) =>
                      setDate((d) => ({
                        ...d,
                        toEnd: e.target.value,
                      }))
                    }
                    value={date.toEnd}
                    min={date.toStart}
                    className={"landing"}
                    id="filter-sidebar-template-to"
                    type="date"
                  />
                  <span className="icon_24 default calendar icon_mask !bg-interactive-100"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FilterDropdownGeneric>
  );
};

export default FilterDateRange;
