import { apiClient } from "./config";

/**
 * Get template presets
 * @param {title,types,page,per_page,} params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const getTemplatePresets = async (
  params,
  successCallback = false,
  errorCallback = false,
) => {
  const { id } = params;

  try {
    const response = await apiClient.get(
      `/admin/templates/plans/${id}/print-presets`,
      {
        params: params,
      },
    );
    if (successCallback) successCallback(response.data);
    return response.data;
  } catch (error) {
    if (errorCallback) errorCallback(error.response);
    return error;
  }
};

/**
 * Create new template plans
 * @param {name, description, year, active} params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const createTemplatePreset = async (
  body,
  successCallback = false,
  errorCallback = false,
) => {
  const { templateId } = body;

  try {
    const response = await apiClient.post(
      `/admin/templates/plans/${templateId}/print-presets`,
      body,
    );
    if (successCallback) successCallback(response.data);
    return response.data;
  } catch (error) {
    if (errorCallback) errorCallback(error.response);
    return error;
  }
};

/**
 * Edit template preset
 * @param {} params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const editTemplatePreset = async (
  body,
  successCallback = false,
  errorCallback = false,
) => {
  try {
    const response = await apiClient.patch(
      `/admin/templates/print-presets/${body.id}`,
      body,
    );
    if (successCallback) successCallback(response.data);
    return response.data;
  } catch (error) {
    if (errorCallback) errorCallback(error.response);
    return error;
  }
};

/**
 * Delete template plans
 * @param {id} params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const deleteTemplatePreset = async (
  id,
  successCallback = false,
  errorCallback = false,
) => {
  try {
    const response = await apiClient.delete(
      `/admin/templates/print-presets/${id}`,
    );
    if (successCallback) successCallback(response.data);
    return response.data;
  } catch (error) {
    if (errorCallback) errorCallback(error.response);
    return error;
  }
};
