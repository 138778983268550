import { useEffect, useState, useRef, useContext } from "react";
import "./EmailMassivePopup.scss"
import ModalContainer from '../ModalContainer';
import useNotification from "../../../hooks/notification.hook";
import OrganizationsPickerTable from '../../utils/OrganizationsPickerTable';
import OrganizationsPickerContext from "../../../context/organizations-picker.context";
import { Transition } from "react-transition-group";
import useCommonHelper from "../../../hooks/helper.hook";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import TextareaInput from "../../input/TextareaInput";
import { sharePlanEmailMassive } from "../../../services/admin.service";


export default function EmailMassiveModal({open, setOpen, loadingData}) {

    const { t } = useTranslation()

    const schema = yup.object().shape({
		mail_subject: yup.string().required(),
		mail_body: yup.string().required(),
	});

    const { register, setValue, clearErrors, handleSubmit, formState:{ errors } } = useForm({resolver: yupResolver(schema)});

	const [loading, setLoading] = useState(false);

    const { setFilteredList, organizations, setFilterType, selectedEntity, setSelectedEntity } = useContext(OrganizationsPickerContext)

    const { openNotification, helperErrorNotification } = useNotification()

    const { animationClass } = useCommonHelper()

    const [wizardStep, setWizardStep] = useState(1);

	const [wizardVisible, setWizardVisible] = useState(1);

    const firstStepRef = useRef(null)

    const secondStepRef = useRef(null)

    useEffect(() => {
        if(open){
            setSelectedEntity([])
            setFilteredList(organizations)
            setFilterType([])
            setValue("mail_subject", "")
            setValue("mail_body", "Gentile cliente, \n\n\n Con l'occasione si porgono i più cordiali saluti.")
            setWizardStep(1)
            setWizardVisible(1)
        }

    }, [open])



	/**
	 * Form Handler
	 * @param {form modal} data
	 */
	async function sendEmailHandler(data) {

        if(selectedEntity.length > 0){

            if(!loading){
                setLoading(true)

                const body = {
                    ...data,
                    organization_plan_ids: selectedEntity.map(el => el.organization_plans[0].id),
                }

                // copy conf
                sharePlanEmailMassive(body, () => {

                    openNotification({text: "Operazione completata con successo!", status: "success"})

                    setLoading(false)

                    setOpen(false)

                }, (err) => {

                    helperErrorNotification(err)

                    setLoading(false)
                })

            }

        }
	}


	return (
        <ModalContainer isOpen={open} setIsOpen={setOpen} disableClose={loading}>
            <div className={"main_modal_content email_massive_plan_popup " + ((wizardVisible == 2) ? "form_wizard" : '')} >

                <div className="main_close" onClick={() => !loading && setOpen(false)}>
                    <span className="icon_10 default close icon_mask !bg-white"></span>
                </div>

                <div className="main_header_modal divide_header flex_header">
                    <div className="left_content">

                        {wizardStep === 2 &&
                            <div className={"transition-opacity " + (wizardStep === 0 ? "opacity-0" : '')}>
                                <div className="main_back_button" onClick={() => setWizardStep(0)}>
                                    <span className="icon_16 default arrow left icon_mask !bg-interactive-100"></span>
                                </div>
                            </div>
                        }

                        <div className="main_desc">
                            <span className="title">Invio massivo piani</span>
                            {wizardStep === 1 &&
                                <div className={"transition-opacity " + (wizardStep === 0 ? "opacity-0" : '')}>
                                    <div className="desc">Seleziona gli enti di destinazione</div>
                                </div>
                            }
                            {wizardStep === 2 &&
                                <div className={"transition-opacity " + (wizardStep === 0 ? "opacity-0" : '')}>
                                    <div className="desc">Compila contenuto email</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className={"main_body_modal"}>

                    <Transition
                        nodeRef={firstStepRef}
                        in={wizardStep === 1}
                        mountOnEnter
                        onExited={() => { setWizardStep(2); setWizardVisible(2) }}
                        unmountOnExit
                        timeout={{enter: 250, exit: 250}}
                    >
                        {state => (

                            <div ref={firstStepRef} className={"main_list_status_reqs " + animationClass(state, "change_step_in", "change_step_out")}>

                                <OrganizationsPickerTable
                                    loadingData={loadingData}
                                />
                            </div>
                        )}
                    </Transition>

                    <Transition
                        nodeRef={secondStepRef}
                        in={wizardStep === 2}
                        mountOnEnter
                        onExited={() => { setWizardStep(1); setWizardVisible(1) }}
                        unmountOnExit
                        timeout={{enter: 250, exit: 250}}
                    >
                        {state => (
                            <div className={" " + animationClass(state, "change_step_in", "change_step_out")} ref={secondStepRef}>
                                <form id="massive-email-form-popup" className="main_form_email" onSubmit={handleSubmit(sendEmailHandler)} noValidate>
                                    <div className="global_form_landing">

                                        {/* Name extended */}
                                        <div className="base">
                                            <div className="wrap_input_landing">
                                                <label htmlFor='email-massive-obj' className='label_input'>Oggetto</label>
                                                <input className={"landing big" + (errors.mail_subject ? ' err' : '')} id='email-massive-obj' type='text' {...register("mail_subject")}/>
                                            </div>
                                        </div>

                                        {/* Name extended */}
                                        <div className="base">
                                            <div className="wrap_input_landing">
                                                <label htmlFor='email-massive-body' className='label_input'>Messaggio</label>
                                                <TextareaInput className={"big" + (errors.mail_body ? ' err' : '')} {...register("mail_body")} id={`email-massive-body`} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                    </Transition>


                </div>

                <div className="main_footer_modal divide_footer">

                    <div className="right_content">

                        {/* Main Cta */}
                        {wizardVisible === 1 &&
                            <div className={"transition-opacity " + (wizardStep === 0 ? "opacity-0" : '')}>
                                <button onClick={() => setWizardStep(0)} className={'button fill success ' + (selectedEntity.length === 0 ? 'disabled' : '')}>Procedi</button>
                            </div>
                        }


                        {/* Main Cta */}
                        {wizardVisible === 2 &&
                            <div className={"transition-opacity " + (wizardStep === 0 ? "opacity-0" : '')}>
                                <button form="massive-email-form-popup" className={'button fill success' + (loading ? " loading" : "")}>
                                    <div className="load_content">Invia email</div>
                                    <div className="main_loader_icon fade_in"></div>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </ModalContainer>
	);
}
