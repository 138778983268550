import { useState, createContext } from "react";

const CollapseContainerContext = createContext({
	globalStatus: "",
    setGlobalStatus: function(){},
	counterUpdate: "",
    setCounterUpdate: function(){},
    forceOpen: function(){},
    forceClose: function(){},
});

export function CollapseContainerContextProvider(props) {


	// Global attributes
	const [globalStatus, setGlobalStatus] = useState(null);

	const [counterUpdate, setCounterUpdate] = useState(1);

	function forceOpen(){
		setGlobalStatus(true)
		setCounterUpdate(i => i+1)
	}


	function forceClose(){
		setGlobalStatus(false)
		setCounterUpdate(i => i+1)
	}


	const context = {
		globalStatus,
        setGlobalStatus,
		counterUpdate,
        setCounterUpdate,
		forceOpen,
    	forceClose,
	};



	return (
		<CollapseContainerContext.Provider value={context}>
			{props.children}
		</CollapseContainerContext.Provider>
	);
}

export default CollapseContainerContext;
