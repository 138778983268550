import c from "./StatusIcon.module.scss"


function StatusIcon({ icon, label = "", tooltip = false }) {
    return (
        <div className={`${c.main_icon} ${c.icon_status}`} {...tooltip && {"data-tooltip-id" : "tooltip_small_top", "data-tooltip-offset": 10, "data-tooltip-content" : label}}>
            <span className={`icon_20 status ${icon} ${c.icon}` }></span>
        </div>
    );
}

export default StatusIcon;
