import { apiClientPlatform } from "./config"





/**
 * Get list organizations
 * @param {} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getResellers = async (params, successCallback = false, errorCallback = false) => {
    try{
        const response = await apiClientPlatform.get(`/resellers`, {params: params})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}
