import c from "./EmptyBox.module.scss"

function EmptyBox({ title = "", description = "", small, icon}) {
    return (
        <div className={`${c.main_empty} ${small ? c.small : ''}`}>
            {icon &&
                <div className={c.main_icon}>
                    <span className={icon}></span>
                </div>
            }
            <div className={c.title}>{title}</div>
            <div className={c.description}>{description}</div>
        </div>
    );
}

export default EmptyBox;