import useDropdown from '../../../hooks/dropdown-item.hook';


function Toggle({ children }) {

    const { isOpen, handleToggle } = useDropdown()

    return (
        <div onClick={handleToggle}>
            {typeof children == "function" ? children({isOpen}) : children}
        </div>
    );
}

export default Toggle;