import CollapseItem from "../utils/collapse-item/CollapseItem";
import c from "./ListContent.module.scss"
import { useState, useEffect } from 'react';
import usePlanCopyConf from '../../hooks/plan-copy-conf.hook';
import EmptyBox from '../emptystate/EmptyBox';

function ListContent({ chapters }) {

    const { selectedConf } = usePlanCopyConf()

    const [filteredList, setFilteredList] = useState([])

    /**
     * Filter list
     * @returns 
     */
    function filterList(){
        if(selectedConf){
            const newList = JSON.parse(JSON.stringify(chapters))

            return newList.map(c => {
                c.data.organization_contents = c.data.organization_contents.map(content => {
                    if(content.content_type === "organization_chapter_content")
                        return content

                    if(content.content_type === "organization_course")
                        return content

                    content.data.organization_requirements = content.data.organization_requirements.filter(req =>
                        selectedConf.copier_requirements.map(el => el.organization_requirement_id).includes(req.id)
                    )

                    return content
                }).filter(content => {
                    if(content.content_type === "organization_chapter_content")
                        return selectedConf.copier_chapter_contents.map(el => el.organization_chapter_content_id).includes(content.content_id)
                    else if(content.content_type === "organization_course"){
                        return false
                    }else{
                        return !!content.data.organization_requirements.length
                    }
                })

                return {...c}
            }).filter(c => c.data.organization_contents.length > 0)
        }

        return [...chapters]
    }



    

    useEffect(() => {
        setFilteredList(filterList())
    }, [selectedConf])



    return (
        <div className={c.main_list_content}>

            {/* List Chapters */}
            {filteredList?.length > 0 ?
                filteredList.map((chapter, i) => (
                    <CollapseItem key={i}>
                        <CollapseItem.Toggle>
                            {({ isOpen }) => (
                                <div className={`${c.main_item} ${c.item_collapse}`}>
                                    <div className={c.main_icon}>
                                        <span className={"icon_16 default chevron" + (isOpen ? "" : ' right')}></span>
                                    </div>
                                    <div className={c.main_icon}>
                                        <span className={"icon_16 default folder"}></span>
                                    </div>
                                    <div className={c.name}>{chapter.data.template_chapter.name}</div>
                                </div>
                            )}
                        </CollapseItem.Toggle>

                        

                        <CollapseItem.Content>

                            {/* List Contents */}
                            <div className={c.main_list_goals}>
                                {chapter.data.organization_contents.filter(content => content.content_type !== "organization_course")
                                    .map((content,i) => (
                                    content.content_type === "organization_chapter_content" ?
                                        (content?.data?.template_chapter_content?.editable &&
                                            <div key={i} className={c.main_item}>
                                                <div className={c.main_icon}>
                                                    <span className={"icon_16 default text_box"}></span>
                                                </div>
                                                <div className={c.name}>{content?.data?.template_chapter_content?.name}</div>
                                            </div>)
                                        :
                                        <CollapseItem key={i}>
                                            <CollapseItem.Toggle>
                                                {({ isOpen }) => (
                                                    <div className={`${c.main_item} ${c.item_collapse}`}>
                                                        <div className={c.main_icon}>
                                                            <span className={"icon_16 default chevron" + (isOpen ? "" : ' right')}></span>
                                                        </div>
                                                        <div className={c.main_icon}>
                                                            <span className={"icon_16 default layers"}></span>
                                                        </div>
                                                        <div className={c.name}>{content.data.template_goal.name}</div>
                                                    </div>
                                                )}
                                            </CollapseItem.Toggle>

                                            {/*List Requirements*/}
                                            <CollapseItem.Content>
                                                <div className={c.main_list_actions}>
                                                    {content.data.organization_requirements.map((req,i) => (
                                                        <div key={i} className={c.main_item}>
                                                            <div className={c.main_icon}>
                                                                <span className={"icon_16 default layer"}></span>
                                                            </div>
                                                            <div className={c.name}>{req.template_requirement.name}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </CollapseItem.Content>
                                        </CollapseItem>
                                ))}
                            </div>
                        </CollapseItem.Content>
                    </CollapseItem>
                ))
                :
                <EmptyBox
                    icon="icon_75 default archive_plan"
                    title="Nessun contenunto trovato"
                    description="Non ci sono contenuti in questo piano"
                />
            }

        </div>
    );
}

export default ListContent;