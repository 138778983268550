import useUserSession from "../../hooks/user-session.hook";
import c from "./UserPopover.module.scss"
import DropDown from "./dropdown-item/DropDown";

function UserPopover({ user }) {

    const { logout } = useUserSession()


    return (
        <DropDown>

            <DropDown.Toggle>
                <div className={c.main_user}>
                    <div className={c.initals}>{user.first_name[0]}{user.last_name[0]}</div>
                    <div className={c.role}>{user.role === "admin" ? "Amministatore" : user.role === "reseller" ? "Rivenditore" : "Operatore"}</div>
                    <span className="icon_10 default chevron"></span>
                </div>
            </DropDown.Toggle>

            {/* Content DropDown */}
            <DropDown.Content right>
                {({closeDropDown}) => 
                    <div className={c.main_content}>
                        <div className={c.main_list}>
                            <div onClick={logout} className={c.main_item}>
                                <div className={c.main_icon}>
                                    <span className="icon_16 default back_platform"></span>
                                </div>
                                Torna alla piattaforma
                            </div>
                        </div>
                    </div>
                }
            </DropDown.Content>

        </DropDown>
    );
}

export default UserPopover;