import { useContext } from "react";
import { CollapseContext } from "../components/utils/collapse-item/CollapseItem";

function useCollapse() {

    const { isOpen, handleToggle, isClosed, setIsClosed } = useContext(CollapseContext)


    return { isOpen, handleToggle, isClosed, setIsClosed}
}

export default useCollapse;