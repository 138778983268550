// tools.js
// import Embed from '@editorjs/embed'
// import Table from '@editorjs/table'
import Paragraph from '@editorjs/paragraph'
// import List from '@editorjs/list'
// import Warning from '@editorjs/warning'
// import Code from '@editorjs/code'
// import LinkTool from '@editorjs/link'
// import Image from '@editorjs/image'
// import Raw from '@editorjs/raw'
import Header from '@editorjs/header'
// import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
// import CheckList from '@editorjs/checklist'
// import Delimiter from '@editorjs/delimiter'
// import InlineCode from '@editorjs/inline-code'
// import SimpleImage from '@editorjs/simple-image'
import NestedList from '@editorjs/nested-list'
import Table from '@editorjs/table'
import Underline from '@editorjs/underline';
import ImageTool from '@editorjs/image'
import { uploadImageEditor } from '../../services/media.service'
import JustifyTune from "./JustifyTune"

const inlineToolbarList = true

export const EDITOR_JS_TOOLS = {
  // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
  paragraph: {
    class: Paragraph,
    inlineToolbar: inlineToolbarList,
    config: {
      preserveBlank: true,
    },
    tunes: ["justifyText"],
  },
  underline: Underline,
//   embed: Embed,
//   table: Table,
  list: {
    class: NestedList,
    inlineToolbar: inlineToolbarList,
  },
//   warning: Warning,
//   code: Code,
//   linkTool: LinkTool,
//   image: Image,
//   raw: Raw,
  header: {
    class: Header,
    inlineToolbar: ["italic", "marker", "link", "underline"],
  },
//   quote: Quote,
  marker: Marker,
  table: {
    class: Table,
    inlineToolbar: inlineToolbarList,
  },
  justifyText: {
    class: JustifyTune,
    config:{
      default: "right",
      blocks: {
        header: 'center',
        list: 'right'
      }
    },
  },
  image: {
    class: ImageTool,
    config: {
      buttonContent: "Seleziona un immagine",

      /**
       * Custom uploader
       */
      uploader: {
        /**
         * Upload file to the server and return an uploaded image data
         * @param {File} file - file selected from the device or pasted by drag-n-drop
         * @return {Promise.<{success, file: {url}}>}
         */
        uploadByFile(file){
          const formData = new FormData()

          formData.append("image", file)

          return uploadImageEditor(formData, (res) => {
            return res
          })
        },
      }
    },
  }
//   checklist: CheckList,
//   delimiter: Delimiter,
//   inlineCode: InlineCode,
//   simpleImage: SimpleImage,
}