function Box({ title, counter, icon, isToComplete, lineDescriptions = []}) {
    return (
        <div className="main_comp_box_content_plan__detail">
            
            <div className="main_counter">
                {icon && <span className={"icon_16 default " + icon}></span>}
                <span className="counter">{counter}</span>
            </div>

            <div className="main_title">{title}</div>

            {lineDescriptions.length > 0 && 
                <div className="main_list_desc">
                    {lineDescriptions.map((item,i) => (
                        <div key={i} className="item_desc">
                            {item.icon && <span className={item.icon}></span>}
                            {item.counter != undefined && <span className="counter">{item.counter}</span>}
                            {item.desc}
                        </div>
                    ))}
                </div>
            }

        </div>
    );
}
export default Box;