import { useState, useEffect, createContext } from "react";

const SidebarContext = createContext({
	navActive: "",
    setNavActive: function(){},
});

export function SidebarContextProvider(props) {


	// Global attributes
	const [navActive, setNavActive] = useState(null);



	const context = {
		navActive,
        setNavActive,
	};

	return (
		<SidebarContext.Provider value={context}>
			{props.children}
		</SidebarContext.Provider>
	);
}

export default SidebarContext;
