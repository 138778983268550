import { apiClient } from "./config"





/**
 * Get list organizations plans
 * @param {filter[template_plan_id], include[organization]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getOrganizationPlans = async (params, successCallback = false, errorCallback = false) => {
    try{
        const response = await apiClient.get(`/admin/organization-plans`, {params: params})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}




/**
 * Send email massive plan
 * @param {mail_subject, mail_body, organization_plan_ids[]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const sharePlanEmailMassive = async (params, successCallback = false, errorCallback = false) => {
    try{
        const response = await apiClient.post(`/admin/share-organization-plan-via-email`, params)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}