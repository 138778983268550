import "./EditorContent.scss"
import {createReactEditorJS} from 'react-editor-js'
import {EDITOR_JS_TOOLS} from "../tools/EditorConfig";
import {useCallback, useEffect, useRef, useState} from "react";
import debounce from "lodash.debounce";
import CodeTool from "@editorjs/code"
import Delimiter from "@editorjs/delimiter"
import useCommonHelper from '../../hooks/helper.hook';

const ContainerEditorJS = createReactEditorJS()

function EditorContent({
   onChange = () => {},
   initVal,
   readOnly = false,
   timeDebounce = 0,
   headerLevels,
   withCode,
   layout,
   minHeight = 300
}) {

    const editorContainer = useRef()

    const {resizeTeaxtarea} = useCommonHelper()

    const tools = {
        ...EDITOR_JS_TOOLS,
        header: {
            ...EDITOR_JS_TOOLS.header,
            ...headerLevels && {config: {levels: headerLevels, defaultLevel: headerLevels[0]}}
        },
        ...withCode && {
            code: CodeTool,
            delimiter: Delimiter
        }
    }


    useEffect(() => {

        return () => {
            document.querySelectorAll("textarea.ce-code__textarea").forEach(textarea => textarea.removeEventListener("keyup", updateSizeTextarea))
        }
    }, [])


    const [isInit, setIsInit] = useState(false);

    /**
     * On init
     * @param {*} instance
     * @returns
     */
    const onInit = (instance) => {
        editorContainer.current = instance
    }


    /**
     * Change handler
     */
    const changeHandler = async () => {

        const savedData = await editorContainer.current.save()

        onChange(savedData)
    }


    /**
     * Debounce Search
     */
    /* const debouncedChange = useCallback(debounce(changeHandler, timeDebounce), []) */
    const debouncedChange = debounce(changeHandler, timeDebounce)


    const isReady = (e) => {
        setIsInit(true)
        updateTextarea()
        document.querySelectorAll("textarea.ce-code__textarea").forEach(textarea => textarea.addEventListener("keyup", updateSizeTextarea))

        document.querySelectorAll("[data-container-editor] a").forEach(element => {
            element.addEventListener("click", linkForceBlank)
        })
    }


    const updateTextarea = () => document.querySelectorAll("textarea.ce-code__textarea").forEach((el) => resizeTeaxtarea(el))

    const updateSizeTextarea = (e) => {
        resizeTeaxtarea(e.target);
    }

    const linkForceBlank = (e) => {
        if(readOnly){
            e.preventDefault()
            const href = e.target.href
            if(href)
                window.open(href, '_blank', "noopener,noreferrer");
        }
    }

    return (
        <div className={`main_editor_content ${isInit ? "" : " pointer-events-none"} ${layout}`} data-container-editor={"editor-js"}>
            <div className={"wrap_editor_content"}>
                <div className={"main_editor"}>
                    <ContainerEditorJS
                        readOnly={readOnly}
                        tools={tools}
                        onReady={isReady}
                        onInitialize={onInit}
                        onChange={debouncedChange}
                        defaultValue={initVal}
                        minHeight={minHeight}
                    />
                </div>
            </div>
        </div>
    );
}

export default EditorContent;
