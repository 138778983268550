import c from "./PopoverActionStatus.module.scss"
import { useEffect, useState } from "react";
import DropDown from "../utils/dropdown-item/DropDown";
import StatusIcon from '../utils/StatusIcon';

function PopoverActionStatus({ layout, initStatus, onChange = () => {}, arrayStatus = [], objectLabels }) {

    const [ status, setStatus ] = useState(initStatus)

    const [isOpen, setIsOpen] = useState(false)


    useEffect(() => {
        setStatus(initStatus)
    }, [initStatus])



    const changeStatus = (value, close) => {
        onChange(value)
        close()
    }

    const findSelected = arrayStatus.find(s => s.value === status)

    return (
        <div className={`${c.main_status_dropdown} ${c[layout]} ${isOpen ? c.open : ""}`}>
            <DropDown onToggle={setIsOpen}>

                {/* Dropdown Toggle */}
                <DropDown.Toggle>
                    <div className={`${c.main_toggle} ${findSelected?.color ? c.bg_toggle : ''}`} style={{ ...findSelected?.color && {background: `${findSelected?.color}33`} }}>

                        <div className={c.main_name}>
                            <div className={c.main_icon}>
                                <span className={`icon_16 status ${findSelected?.icon}`}></span>
                            </div>
                            {findSelected?.title}
                        </div>

                        <div className={c.main_icon}>
                            <span className="icon_10 default chevron"></span>
                        </div>
                    </div>
                </DropDown.Toggle>

                {/* DropDown Content */}
                <DropDown.Content right={true}>
                    {({closeDropDown}) => 
                        <div className={c.main_content}>
                            <div className={c.main_list_status}>

                                {arrayStatus.map((el, i) => (

                                    <label key={i} className={c.item_status}>
                                        <div className="radio_landing">
                                            <input onChange={() => changeStatus(el.value, closeDropDown)} defaultChecked={el.value === status} name="status" type="radio" id={`status-${i}`} />
                                            <label className={c.label} htmlFor={`status-${i}`}></label>
                                        </div>
                                        <div className={c.main_desc}>
                                            <div className={c.title}>{el.title}</div>
                                            <div className={c.desc}>{el.desc}</div>
                                        </div>

                                        <span className="flex flex-none">
                                            <span className={"icon_20 status " + el.icon}></span>
                                        </span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    }
                </DropDown.Content>
            </DropDown>
        </div>
    );
}

export default PopoverActionStatus;