import c from "./Sidebar.module.scss"
import ItemCustomization from "./ItemCustomization";
import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useState } from "react";
import usePlanCopyConf from "../../hooks/plan-copy-conf.hook";
import { getCopier } from "../../services/organizations.service";

function Sidebar({ configurations, disableCreate }) {

    const { setCrudConfOpen, selectedConfId, updateSelectedConf, setDeleteModalOpen, setDeleteConf, setDeleteItem, deleteConfigurationHandler, setCrudModel, planData } = usePlanCopyConf()


    const [loadingEdit, setLoadingEdit] = useState(false)


    /**
     * Delete item request
     * @param {*} item 
     */
    const deleteItem = (item) => {

        setDeleteItem(item)

        setDeleteConf({
            title: "Elimina configurazione",
            icon: "icon_16 default folder",
            subtitle: item.name,
            confirmHandler: deleteConfigurationHandler,
        })

        setDeleteModalOpen(true)
    }


    /**
     * Edit item request
     * @param {*} item 
     */
    const editItem = async (item) => {
        
        if(!loadingEdit){
            
            setLoadingEdit(true)

            const copier = await getCopier({id: item.id, idCorp: planData.organization_id})
            
            setLoadingEdit(false)
    
            setCrudModel(copier.data)
    
            setCrudConfOpen(true)
        }
    }



    /**
     * new item request
     * @param {*} item 
     */
    const newItem = () => {

        setCrudModel(null)

        setCrudConfOpen(true)
    }



    return (
        <div className={c.main_sidebar}>
            <div className={c.wrap_sidebar}>

                {/* Header Sidebar */}
                <div className={c.main_header_sidebar}>
                    <div className={c.main_title}>
                        <div className={c.title}>Seleziona il contenuto da copiare</div>
                    </div>

                    <div className={c.main_item}>
                        <ItemCustomization onChange={() => updateSelectedConf(null)} checked={selectedConfId == null} id="copy_all_plan" name="conf_choice" label="Tutto il contenuto" />
                    </div>
                </div>



                <div className={c.main_content_sidebar}>
                    <div className={c.main_heading}>
                        <div className={c.main_title}>
                            <div className={c.title}>Configurazioni di copia</div>
                        </div>

                        <div className={c.main_action}>
                            <button onClick={() => !disableCreate && newItem()} className={"link-interactive flex items-center gap-2" + (disableCreate ? " link_disabled" : "")}
                                {...disableCreate && { "data-tooltip-id" : "tooltip_small_top", "data-tooltip-offset": 10, "data-tooltip-content": "Nessun contenuto da copiare"}}
                                >
                                <span className="icon_16 plus default icon_mask !bg-interactive-100 -mt-1"></span>
                                Crea
                            </button>
                        </div>
                    </div>


                    <OverlayScrollbarsComponent className={c.main_list_item}>
                        <div className={c.wrap_list_item}>
                            {configurations.map((conf,i) => (
                                <ItemCustomization 
                                    key={i} 
                                    onChange={() => updateSelectedConf(conf.id)} 
                                    checked={selectedConfId == conf.id} 
                                    id={"copy_item_" + conf.id} 
                                    name="conf_choice" 
                                    label={conf.name} 
                                    onEdit={() => editItem(conf)}
                                    onDelete={() => deleteItem(conf)}
                                />
                            ))}
                        </div>
                    </OverlayScrollbarsComponent>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;