import c from "./SectionBox.module.scss"


function SectionBox({ title, children, layout }) {

    return (
        <div className={`${c.main_section_box} ${layout ? c[layout] : ''}`}>
            {title && 
                <div className={c.main_intro}>
                    <h2 className={c.intro}>{title}</h2>
                </div>
            }
            <div className={c.main_box}>
                {children}
            </div>
        </div>
    );
}

export default SectionBox;