import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import useNotification from "../../../hooks/notification.hook";
import ModalContainer from "../ModalContainer";
import { useTranslation } from 'react-i18next';
import usePlanTemplate from '../../../hooks/plan-template.hook';
import TextareaInput from "../../input/TextareaInput";
import {usePlanTemplateStore} from "../../../store/plan-template.store";
import {reorderItems} from "../../../helper/common.helper";


export default function CrudContentModal({open, setOpen, contentModel, onEdit = function(){}, onInsert = function(){}}) {

    const { t } = useTranslation()

	// Set message
	yup.setLocale({
		// use constant translation keys for messages without values
		mixed : {
			required: ({path}) => (t("common:errors.required",{field : t(`common:fields.${path}`)})),
		}
	});

	// Schema Yup
	const schema = yup.object().shape({
		type: yup.string().required(),
		name: yup.string().when("type", {
            is: (value) => value === "template_chapter_content",
            then: (schema) => schema.required()
        }),
        code: yup.string().when("type", {
            is: (value) => value === "template_goal",
            then: (schema) => schema.required()
        }),
        description: yup.string().when("type", {
            is: (value) => value === "template_goal",
            then: (schema) => schema.required()
        }),
        course: yup.string().when("type", {
            is: (value) => value === "template_course",
            then: (schema) => schema.required()
        }),
	});

	const { register, setValue, clearErrors, watch, handleSubmit, formState:{ errors } } = useForm({resolver: yupResolver(schema)});

	const { openNotification } = useNotification()

    const {
        chapterList,
        selectedContent,
        idNewCount,
        updateState,
        courses
    } = usePlanTemplateStore()


	/**
	 * Form Handler
	 * @param {form modal} data 
	 */
	async function formHandler(data) {

        if(contentModel?.model?.content_id){

            editContent(data, contentModel.model)

            onEdit(data)
            
        }else{

            addContent(data, contentModel.chapter, contentModel.position)
            
            onInsert(data)
        }
	}


    /**
     * Edit Content
     * @param {*} param0
     * @param {*} destination
     */
    const editContent = (data, content) => {

         const body = {
            ...content,
            ...!content?.localStatus && { localStatus: "edit"},
            data: {
                ...content.data,
                ...content.content_type === "template_chapter_content" && {
                    name: data.name,
                    editable: data.editable,
                },
                ...content.content_type === "template_goal" && {
                    name: data.code,
                    description: data.description,
                },
                ...content.content_type === "template_course" && {
                    course: courses.find(course => course.id == data.course),
                },
            },
        }

        const cpt = chapterList.find(c => c.content_id === content.data.template_chapter_id)

        const index = cpt.data.contents.findIndex(c => c.index === content.index)

        cpt.data.contents[index] = body

        if(selectedContent?.content_id === body.content_id && selectedContent?.content_type === body.content_type){
            updateState({ selectedContent: cpt.data.contents[index] })
        }

        updateState({ chapterList: [...chapterList] })
    }



    /**
     * Add Content
     * @param {*} param0
     * @param {*} destination
     */
    const addContent = (data, chapter , destination) => {

        const newItem = {
            content_id: `new-content-${idNewCount}`,
            data: {
                id: `new-content-${idNewCount}`,
                template_chapter_id: chapter.content_id,
                ...data.type === "template_chapter_content" && {
                    name: data.name,
                    editable: data.editable,
                    editorjs: {blocks: []},
                },
                ...data.type === "template_goal" && {
                    name: data.code,
                    description: data.description,
                    status: "default",
                    template_requirements: [],
                },
                ...data.type === "template_course" && {
                    course: courses.find(course => course.id == data.course),
                },
            },
            content_type: data.type,
            localStatus: "new",
        }

        chapter.orderContents = true

        chapter.data.contents.push(newItem)

        const orderedList = reorderItems(chapter.data.contents, chapter.data.contents.length - 1, destination)

        chapter.data.contents = orderedList

        updateState({ chapterList: [...chapterList], idNewCount: idNewCount+1 })
    }




    /**
	 * Errors Form
	 */
	useEffect(() => {
		if (Object.keys(errors).length) {
            const fields = Object.values(errors).map(er => er.message)
			openNotification({ fields, status: "error"})
		}
	}, [errors]);





	/**
	 * Init value
	 */
	useEffect(() => {
		if (open) {
            clearErrors()
			setValue("name", contentModel?.model?.data?.name ?? '')
			setValue("editable", contentModel?.model?.data?.editable ?? false)
			setValue("type", contentModel?.model?.content_type ?? "template_chapter_content")
            setValue("code", contentModel?.model?.data?.name ?? '')
			setValue("description", contentModel?.model?.data?.description ?? "")
			setValue("course", contentModel?.model?.data?.course?.id ?? "")
		}
	}, [open]);


    const title = {
        template_chapter_content: "Modifica documento",
        template_goal: "Modifica obiettivo",
        template_course: "Modifica corso"
    }

	return (
        <ModalContainer isOpen={open} setIsOpen={setOpen}>
            <div className={"main_modal_content"} >

                <div className="main_close" onClick={() => setOpen(false)}>
                    <span className="icon_10 default close icon_mask !bg-white"></span>
                </div>

                <div className="main_header_modal left_text">
                    <span className="title">{contentModel?.model?.content_type ? title[contentModel.model.content_type] : "Nuovo contenuto"}</span>
                </div>


                {/* Modal Form */}
                <div className={"main_body_modal"}>
                    <form id='crud-chapter-form' className='global_form_landing' onSubmit={handleSubmit(formHandler)} noValidate>

                        {!contentModel?.model?.content_id &&

                            <div className="base">
                                <div className="grid">
                                    <div className={"py-[6px] px-3 rounded-t-[4px] border border-dark-5" + (watch("type") === "template_chapter_content" ? " border-interactive-100 z-[2]" : '')}>
                                        <div className="radio_landing small">
                                            <input type="radio" id={"type_content"} value="template_chapter_content" {...register("type")}/>
                                            <label htmlFor={`type_content`}>Nuovo documento</label>
                                        </div>
                                    </div>
                                    <div className={"py-[6px] px-3 -mt-[1px] border border-dark-5" + (watch("type") === "template_goal" ? " border-interactive-100 z-[1]" : '')}>
                                        <div className="radio_landing small">
                                            <input type="radio" id={"type_goal"} value="template_goal" {...register("type")}/>
                                            <label htmlFor={`type_goal`}>Nuovo obiettivo</label>
                                        </div>
                                    </div>
                                    <div className={"py-[6px] px-3 rounded-b-[4px] -mt-[1px] border border-dark-5" + (watch("type") === "template_course" ? " border-interactive-100" : '')}>
                                        <div className="radio_landing small">
                                            <input type="radio" id={"type_course"} value="template_course" {...register("type")}/>
                                            <label htmlFor={`type_course`}>Nuovo corso</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {watch("type") === "template_chapter_content" &&
                            <>
                                <div className="base ">

                                    {/* Name */}
                                    <div className="wrap_input_landing">
                                        <label htmlFor='crud_plan_name' className='label_input'>Nome</label>
                                        <input className={"landing big" + (errors.name ? ' err' : '')} id='crud_plan_name' type='text' {...register("name")}/>
                                    </div>

                                </div>


                                <div className="base ">

                                    {/* Editable */}
                                    <div className="toggle_landing">
                                        <input type="checkbox" id="copy_all_entity" className="landing" {...register("editable")}  />
                                        <label htmlFor="copy_all_entity">Modificabile dagli enti </label>
                                    </div>
                                </div>
                            </>
                        }

                        {watch("type") === "template_goal" &&
                            <>
                                <div className="base ">

                                    {/* Name */}
                                    <div className="wrap_input_landing">
                                        <label htmlFor='crud_goal_code' className='label_input'>Codice</label>
                                        <input className={"landing big" + (errors.code ? ' err' : '')} id='crud_goal_code' type='text' {...register("code")}/>
                                    </div>

                                </div>


                                <div className="base ">

                                    {/* Editable */}
                                    <label htmlFor='crud_goal_description' className='label_input'>Descrizione</label>
                                    <TextareaInput id="crud_goal_description" className={errors.description ? ' err' : ''} {...register("description")} />
                                </div>
                            </>
                        }

                        {watch("type") === "template_course" &&
                            <>
                                <div className="base ">

                                    {/* Name */}
                                    <div className={"main_select_landing"}>
                                        <label htmlFor='crud-agenda-entity' className='label_input'>Corso</label>
                                        <div className="relative">
                                            <select className={"landing big" + (errors.course ? ' err' : '')}
                                                    id='crud-agenda-entity' {...register("course")}>
                                                <option disabled value="">Seleziona un corso</option>
                                                {courses.map((course, i) => (
                                                    <option key={i} value={course.id}>{course?.name}</option>
                                                ))}
                                            </select>
                                            <div className="wrap_arrow">
                                                <span className={"icon_16 default chevron icon_mask !bg-interactive-100"}></span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>
                        }


                    </form>
                </div>

                <div className="main_footer_modal">

                    <div className="right_content">

                        {/* Main Cta */}
                        <button form="crud-chapter-form" className='button fill success'>Conferma</button>
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
}
