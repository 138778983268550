import { forwardRef } from "react";
import "../plan-template/CardCourseContent.scss"


const CardCourseContent = forwardRef(({className = '', item, isDragging, position, onClick, isActived, isContents, dragHandleProps, isEditable, isDraggable = true, onEdit = () => {}, onDelete = () => {}, ...props}, ref) => {

    return (
        <a target={"_blank"} href={item.data.template_course.course.td_url} ref={ref} {...props} className={"main_comp_card_course_content link_variant not_draggable block" + (isActived ? " active " : '') + (className)}>
            <div className="main_intro">
                <div className="wrap_intro">

                    <div className="main_icon type">
                        <span className={"icon_16 default course"}></span>
                    </div>

                    <span className="title">Corso</span>
                </div>
            </div>

            <div className="main_desc">
                <div className="desc">{item.data.template_course.course.name}</div>

                <div className="flex-none flex items-center gap-[10px]">
                    <div className="main_image">
                        {item?.data?.template_course?.course?.preview_full_path ?
                            <img src={item.data.template_course.course.preview_full_path} className="image" />
                            :
                            <span className="icon_16 default piano icon_mask !bg-dark-60"></span>
                        }
                    </div>

                    <span className="icon_16 default chevron right icon_mask !bg-dark-70"></span>
                </div>
            </div>
        </a>
);
})

export default CardCourseContent;