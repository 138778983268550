import { useEffect, useState, Fragment, useRef } from "react";
import "./CopyContentPopup.scss"
import ModalContainer from '../ModalContainer';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import Table from "../../utils/table/Table";
import { copyOrganization } from "../../../services/organizations.service";
import useNotification from "../../../hooks/notification.hook";
import EmptyBox from '../../emptystate/EmptyBox';
import FilterDropdown from "../../utils/FilterDropdown";
import LoadingBox from "../../loading/LoadingBox";
import {getResellers} from "../../../services/resellers.service";


export default function CopyContentModal({open, setOpen, loadingData, organizations, types = [], selectedConfId, plan}) {

	const [loading, setLoading] = useState(false);

	const [filteredList, setFilteredList] = useState([]);

    const [resellersLoading, setResellersLoading] = useState(false)

    const [resellers, setResellers] = useState([])

	const [filterType, setFilterType] = useState([]);

	const [filterResellers, setFilterResellers] = useState([]);

    const searchInput = useRef() 

	const [selectedEntity, setSelectedEntity] = useState([]);

    const { openNotification, helperErrorNotification } = useNotification()

    useEffect(() => {
        if(open){
            setFilteredList(organizations)
        }

    }, [open, organizations])


    /**
     * Get List organizations
     */
    const getListResellers = async () => {

        // Fetch Api
        setResellersLoading(true)

        const getAllResellers = async (page = 1, loadMore = false) => {

            // Fetch Api
            await getResellers({page}, async (res) => {

                loadMore ? setResellers(resellers => [...resellers, ...res.data]) : setResellers(res.data);

                if (res.meta.current_page < res.meta.last_page) {
                    await getAllResellers(res.meta.current_page + 1, true)
                } else {
                    setResellersLoading(false)
                }
            }, () => {

                setResellersLoading(false)
            })
        }

        await getAllResellers();
    }

    

	/**
	 * Form Handler
	 * @param {form modal} data 
	 */
	async function confirmCopy() {

        if(selectedEntity.length > 0){
            
            if(!loading){
                setLoading(true)

                const body = {
                    idCorp: plan.organization_id, 
                    idPlan: plan.id,
                    organization_plan_ids: selectedEntity.map(el => el.organization_plans[0].id),
                    ...selectedConfId && {copier_id: selectedConfId}
                }

                // copy conf
                copyOrganization(body, () => {

                    openNotification({text: "Operazione completata con successo!", status: "success"})

                    setLoading(false)

                    setOpen(false)

                }, (err) => {

                    helperErrorNotification(err)

                    setLoading(false)
                })

            }

        }
	}

    useEffect(() => {
        if(open){
            setSelectedEntity([])
            getListResellers()
        }
    },[open])


    /**
     * on Check entity
     * @param {*} e 
     * @param {*} entity 
     */
    function onCheck(e,entity){
        
        if(e.target.checked){
            if(!selectedEntity.find(el => el.id == entity.id))
                setSelectedEntity(items => [...items, entity])
        }else{
            removeSelected(entity)
        }
    }


    /**
     * Remove selected entity
     * @param {*} entity
     */
    function removeSelected(entity){
        setSelectedEntity(items => items.filter(item => item.id != entity.id))
    }



    /**
     * Selected all entity
     * @param {*} entity
     */
    function toggleSelectionAll(e){
        if(e.target.checked){
            const newItems = filteredList.filter(i => !selectedEntity.find(s => s.id == i.id))
            setSelectedEntity(items => [...items, ...newItems])
        }else{
            const newItems = selectedEntity.filter(i => !filteredList.find(s => s.id == i.id))
            setSelectedEntity([...newItems])
        }
    }


    const onSearch = (e) => {
        const searchModel = e.target.value.trim().toLowerCase()

        if(searchModel.length > 0){
            setFilteredList(organizations
                .filter(org => org.name.toLowerCase().includes(searchModel) || org?.reseller?.name.toLowerCase().includes(searchModel))
                .filter(org => filterType.includes(org.organization_type.name) || filterType.length == 0)
                .filter(org => filterResellers.includes(org.reseller_id) || filterResellers.length == 0)
            )
        }else{
            setFilteredList(organizations
                .filter(org => filterType.includes(org.organization_type.name) || filterType.length == 0)
                .filter(org => filterResellers.includes(org.reseller_id) || filterResellers.length == 0)
            )
        }
    }


    /**
	 * Debounce Search
	 */
    const isSelectAll = () => {
        return filteredList.reduce((a,item) => a && !!selectedEntity.find(el => el.id == item.id) , true)
    }



    const filterByType = (items) => {

        const search = searchInput.current.value.trim().toLowerCase()

        setFilterType(items)

        setFilteredList(organizations
            .filter(org => org.name.toLowerCase().includes(search) || org?.reseller?.name.toLowerCase().includes(search))
            .filter(org => filterResellers.includes(org.reseller_id) || filterResellers.length == 0)
            .filter(org => items.includes(org.organization_type.name) || items.length == 0)
        )
    }


    const filterByResellers = (items) => {

        const search = searchInput.current.value.trim().toLowerCase()

        setFilterResellers(items)

        setFilteredList(organizations
            .filter(org => org.name.toLowerCase().includes(search) || org?.reseller?.name.toLowerCase().includes(search))
            .filter(org => filterType.includes(org.organization_type.name) || filterType.length == 0)
            .filter(org => items.includes(org.reseller_id) || items.length == 0)
        )
    }


	return (
        <ModalContainer isOpen={open} setIsOpen={setOpen}>
            <div className="main_modal_content copy_plan_popup" >

                <div className="main_close" onClick={() => setOpen(false)}>
                    <span className="icon_10 default close icon_mask !bg-white"></span>
                </div>

                <div className="main_header_modal">
                    <span className="title">Enti di destinazioni copia contenuto</span>
                </div>

                <div className={"main_body_modal"}>

                    <div className="main_list_entities">

                            <div className="main_heading_list">
                                {/* <div className="main_intro">
                                    <h2 className="title">Tutti gli enti</h2>

                                    
                                        <div className="toggle_landing">
                                            <input onChange={(e) => toggleSelectionAll(e)} checked={isSelectAll() && filteredList.length > 0} type="checkbox" id={"select_all_entity"} className="landing"  />
                                            <label htmlFor={"select_all_entity"}>Seleziona tutti</label>
                                        </div>

                                </div> */}

                               <div className="main_filters">
                                    
                                    <div className="flex gap-[10px]">
                                        <FilterDropdown
                                            options={types.map(el => ({ label: el.name, value:el.name }))}
                                            activeSelected={filterType}
                                            onApply={(items) => filterByType(items)}
                                            toggleLabel="Tipologia"
                                            isLoading={loadingData?.types}
                                            searchFilter
                                        />

                                        <FilterDropdown
                                            options={resellers.map(el => ({label: el.name, value: el.id}))}
                                            activeSelected={filterResellers}
                                            onApply={(items) => filterByResellers(items)}
                                            toggleLabel="Rivenditori"
                                            searchFilter
                                            isLoading={resellersLoading}
                                        />
                                    </div>

                                    <div className="wrap_input_landing icon">
                                        <input type="text" onChange={onSearch} ref={searchInput} className="landing max-w-[200px]" placeholder="Ricerca..." />
                                        <span className="icon_16 default search"></span>
                                    </div>
                                </div>
                            </div>

                            {loadingData?.organizations ?
                            <div>
                                <LoadingBox blocked="true"></LoadingBox>
                            </div>
                            :
                            (filteredList.length > 0 ?
                                <OverlayScrollbarsComponent defer options={{ scrollbars: { autoHide: "scroll" }}} className="main_table_entity">
                                    <Table className="layout_popup">
                                        <Table.Head className="sticky_head">
                                            <Table.Column className="!pl-4 !pb-3">
                                                <div className={"check_landing small"}>
                                                    <input type="checkbox" checked={isSelectAll()} onChange={(e) => toggleSelectionAll(e)} id={"select_all_entity"} />
                                                    <label htmlFor={"select_all_entity"} className="only_toggle"></label>
                                                </div>
                                            </Table.Column>
                                            <Table.Column className="!pb-3">Nome</Table.Column>
                                            <Table.Column className="!pb-3">Rivenditore</Table.Column>
                                            <Table.Column></Table.Column>
                                        </Table.Head>
                                            <Table.Body>
                                                {filteredList.map((organization, index) => (
                                                    <Table.Row key={index}>

                                                        <Table.Cell width={20}>
                                                            <div className={"check_landing small"}>
                                                                <input type="checkbox" checked={!!selectedEntity.find(item => item.id == organization.id)} onChange={(e) => onCheck(e, organization)} id={"entity-" + organization.id} />
                                                                <label htmlFor={"entity-" + organization.id} className="only_toggle"></label>
                                                            </div>
                                                        </Table.Cell>

                                                        <Table.Cell width={220}>
                                                            <div className="name_logo">
                                                                {organization.logo_url ? 
                                                                    <img src={organization.logo_url} className="logo" />
                                                                    :
                                                                    <div className="main_icon">
                                                                        <span className="icon_16 default entity"></span>
                                                                    </div>
                                                                }
                                                                {organization.name}
                                                            </div>
                                                        </Table.Cell>

                                                        <Table.Cell width={250}>{organization?.reseller?.name ? organization.reseller.name : '–'}</Table.Cell>
                                                        
                                                        <Table.Cell ></Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                    </Table>
                                </OverlayScrollbarsComponent>
                                :
                                <div className="main_empty_container">

                                    <EmptyBox 
                                        icon="icon_75 default archive_entity"
                                        title="Nessun ente disponibile"
                                        description="Non ci sono enti abilitati alla copia del contenuto"
                                    />
                                </div>)
                            }
                    </div>
                    <div className="main_list_selected">
                                                
                        {selectedEntity.length > 0 ?
                        <>
                            <div className="main_counter">
                                <h2 className="title">Enti selezionati: <b>{selectedEntity.length}</b></h2>
                            </div>

                            <div className="list_container">

                                    <OverlayScrollbarsComponent defer options={{ scrollbars: { autoHide: "scroll" }}} className="main_list_items">
                                        <div className="wrap_list_items">
                                            {selectedEntity.map((entity, i) => (
                                                <div key={i} className="item_entity">
                                                    <div className="main_name">
                                                        {entity.logo_url ? 
                                                            <img src={entity.logo_url} className="logo" />
                                                            :
                                                            <div className="main_icon">
                                                                <span className="icon_16 default entity"></span>
                                                            </div>
                                                        }
                                                        <div className="name">{entity.name}</div>
                                                    </div>
                                                    <div className="main_delete" onClick={() => removeSelected(entity)}>
                                                        <span className="icon_16 delete default icon_mask !bg-interactive-100"></span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </OverlayScrollbarsComponent>
                            </div>
                        </>
                        :
                            <div className="main_empty_container">

                                <EmptyBox 
                                    icon="icon_75 default list_boxs"
                                    title="Seleziona uno o più enti"
                                    description="Qui vedrai gli enti selezionati in cui copiare il contenuto del piano"
                                />
                            </div>
                        }
                    </div>
                </div>

                <div className="main_footer_modal">

                    <div className="right_content">

                        {/* Main Cta */}
                        <button onClick={confirmCopy} className='button fill success'>Conferma</button>
                    </div>
                </div>
            </div>
        </ModalContainer>
	);
}
