import React, { forwardRef } from "react";
import "./StatusContent.scss";
import StatusIcon from "../utils/StatusIcon";

const StatusContent = forwardRef(
  (
    {
      className = "",
      listStatuses,
      onToggleStatus,
      onToggleAllStatus,
      selectedStatues,
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={"main_list_status_download_component " + className}
      >
        <label htmlFor={"select-all-status"} className="item_status">
          <div className="left_content">
            <div className="check_landing only_toggle">
              <input
                onChange={onToggleAllStatus}
                checked={selectedStatues?.length === listStatuses.length}
                type="checkbox"
                id={"select-all-status"}
              />
              <label htmlFor={"select-all-status"} className="icon"></label>
            </div>

            <div className="main_desc">
              <div className="title">Seleziona tutto</div>
              <div className="desc">
                Seleziona tutti gli stati delle linee d'azione
              </div>
            </div>
          </div>
        </label>
        {listStatuses.map((status, i) => (
          <label htmlFor={"status-" + i} className="item_status" key={i}>
            <div className="left_content">
              <div className="check_landing only_toggle">
                <input
                  onChange={() => onToggleStatus(status.id)}
                  checked={selectedStatues?.includes(status.id)}
                  type="checkbox"
                  id={"status-" + i}
                />
                <label htmlFor={"status-" + i} className="icon"></label>
              </div>

              <div className="main_desc">
                <div className="title">{status.name}</div>
                <div className="desc">{status.description}</div>
              </div>
            </div>

            <div className="right_content">
              <StatusIcon icon={status.icon} />
            </div>
          </label>
        ))}
      </div>
    );
  },
);

export default StatusContent;
