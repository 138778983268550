import {useRef, useEffect} from "react";
import {useTranslation} from 'react-i18next';
import {useNavigate} from "react-router-dom";
import {createActivity} from "../services/activities.service";
import {usePlanCompileStore} from "../store/plan-compile.store";
import {formatDate} from "../helper/date-formatter.helper";

function usePlanCompile() {

    const [
        planApproved,
        changesList,
        planDetail,
        updateState
    ] = usePlanCompileStore(state => [
        state.isPlanApproved,
        state.changesList,
        state.planDetail,
        state.updateState
    ])

    const changesTrack = useRef(changesList)
    const statusesTemplate = useRef(planDetail?.organization_requirements_statuses ?? [])

    useEffect(() => {
        changesTrack.current = changesList;
        statusesTemplate.current = planDetail.organization_requirements_statuses ?? []
    });

    const {t} = useTranslation()

    const navigate = useNavigate()


    /**
     * Format value read
     * @param {*} value
     * @param {*} key
     * @returns
     */
    const formatValueRead = (value, key) => {
        if (key === "requirement_status_id"){
            const findStatus = statusesTemplate.current.find(s => s.id === value)
            return findStatus.name
        }

        if (key === "date_to" || key === "date_from")
            return value ? formatDate(value, "DD/MM/YYYY") : ''

        if (key === "estimated_budget" || key === "actual_budget")
            return value + "€"

        if (key === "progress_percentage")
            return value + "%"

        return value
    }


    /**
     * get stored changes blocks editor
     */
    async function getStoredChangesBlocks(title) {

        const helperLabel = {
            date_to: true,
            date_from: true,
            estimated_budget: true,
            actual_budget: true,
            description: true,
            budget_allocation: true,
            stakeholders: true,
            requirement_status_id: true,
            progress_percentage: true
        }

        const blocks = [{
            type: "header",
            data: {text: title, level: 1}
        }, {
            type: "paragraph",
            data: {text: "Di seguito il riassunto degli argomenti trattati, le azioni compiute e da compiere."}
        }/*, {
            type: "paragraph",
            data: {text: ""}
        }*/]

        if (planApproved) {

            // Insert Chapter Info
            blocks.push({
                type: "header",
                data: {text: `Piano adottato con data ${formatDate(planApproved.date, "DD MMMM YYYY")}`, level: 2}
            }, {
                type: "paragraph",
                data: {text: `Estremi di adozione: ${planApproved.note}`}
            })
        }


        // Compile blocks editor
        for (const changeChapter of changesList) {

            // Insert Chapter Info
            blocks.push({
                type: "header",
                data: {text: `<u class=\"cdx-underline\"><i>Capitolo: ${changeChapter.name}</i></u>`, level: 2}
            })

            if (changeChapter?.contents?.length > 0) {

                // Insert Goal info
                blocks.push({
                    type: "header",
                    data: {text: `<u class=\"cdx-underline\"><i>Documenti modificati:</i></u>`, level: 3}
                })

                const listContents = []

                for (const changeContents of changeChapter.contents) {

                    listContents.push({
                        content: `${changeContents.name}`,
                        items: [
                            ...(changeContents.admin_note ? [{
                                style: "unordered",
                                content: `Note: ${changeContents.admin_note}`,
                                items: []
                            }] : [])
                        ]
                    })

                }

                // Insert contents list
                blocks.push({
                    type: "list",
                    data: {style: "unordered", items: [...listContents]},
                })
            }

            if (changeChapter?.goals) {

                for (const changeGoal of changeChapter.goals) {

                    // Insert Goal info
                    blocks.push({
                        type: "header",
                        data: {text: `<u class=\"cdx-underline\"><i>Obiettivo: ${changeGoal.name}</i></u>`, level: 3}
                    })

                    for (const changeReq of changeGoal.reqs) {

                        // Insert Req name
                        blocks.push({
                            type: "header",
                            data: {
                                text: `Linea di azione: ${changeReq.name} - ${changeReq.descriptionTemplate ?? ''}`,
                                level: 4
                            }
                        })


                        for (const [fields, value] of Object.entries(changeReq)) {
                            if (helperLabel[fields]) {

                                blocks.push({
                                    type: "paragraph",
                                    data: {text: `<b>${t(`common:compile_labels.${fields}`)}:</b> ${formatValueRead(value, fields)}`}
                                })
                            }
                        }


                        /* Note */
                        if (changeReq.admin_note) {

                            const rex = new RegExp('<code>((.|\n)*?)</code>', 'g');

                            let string = changeReq.admin_note

                            const matchArray = changeReq.admin_note.match(rex);

                            if (matchArray)
                                matchArray.forEach(match => {
                                    string = string.replace(match, "###SPLIT_HERE###")
                                });


                            const splitted = string.split("###SPLIT_HERE###")

                            splitted.forEach((split, i) => {
                                let desc = ""
                                if (i == 0) desc = `<mark class=\"cdx-marker\"><b>${t("common:compile_labels.admin_note")}:</b></mark> `

                                blocks.push({
                                    type: "paragraph",
                                    data: {text: `${desc}${split}`}
                                })

                                if (matchArray && matchArray[i]) {
                                    blocks.push({
                                        type: "code",
                                        data: {code: `${matchArray[i].replace("<code>", "").replace("</code>", "")}`}
                                    })
                                }
                            });
                        }

                    }

                    // Insert Spacing
                    /*blocks.push({
                        type: "paragraph",
                        data: {text: ""}
                    })*/
                }

                /*blocks.push({
                    type: "paragraph",
                    data: {text: ""}
                })*/
            }
        }

        return blocks
    }


    /**
     * Redirect Plan
     */
    const redirectPlan = () => {

        saveActivities()

        updateState({ changesList: []})

        setTimeout(() => {
            navigate(`/admin/entities/entity/${planDetail.organization_id}`, {replace: true})
        }, 0)
    }


    /**
     * Save changes activities
     */
    const saveActivities = (plan) => {

        const helperLabel = {
            date_to: true,
            date_from: true,
            estimated_budget: true,
            actual_budget: true,
            description: true,
            budget_allocation: true,
            stakeholders: true,
            requirement_status_id: true,
            progress_percentage: true,
        }

        if (changesTrack.current.length > 0) {

            changesTrack.current.forEach(chapt => {
                if (chapt.goals)
                    chapt.goals.forEach(goal => {
                        goal.reqs.forEach(req => {

                            Object.entries(req).forEach(([key, value]) => {

                                if (helperLabel[key]) {

                                    let description = ""

                                    if (value || key === "progress_percentage")
                                        description = `ha modificato ${t(`common:activities_labels.${key}`)} per la linea d'azione <b>${req.name}</b>: <span class='inline-block'>&quot;${formatValueRead(value, key)}&quot;</span>`
                                    else
                                        description = `ha rimosso ${t(`common:activities_labels.${key}`)} per la linea d'azione <b>${req.name}</b>`

                                    const body = {
                                        idCorp: planDetail?.organization_id ? planDetail.organization_id : plan.organization_id,
                                        idPlan: planDetail?.id ? planDetail.id : plan.id,
                                        description
                                    }

                                    createActivity(body)
                                }
                            })
                        })
                    })

                if (chapt?.contents)
                    chapt.contents.forEach(content => {

                        let description = `ha modificato il documento <b>${content.name}</b>`

                        const body = {
                            idCorp: planDetail?.organization_id ? planDetail.organization_id : plan.organization_id,
                            idPlan: planDetail?.id ? planDetail.id : plan.id,
                            description
                        }

                        createActivity(body)
                    })
            });
        }
    }


    return {
        getStoredChangesBlocks,

        saveActivities,

        redirectPlan
    }
}

export default usePlanCompile;
