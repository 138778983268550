import c from "./Sidebar.module.scss";
import { Fragment, useContext, useRef, useState } from "react";
import { SidebarContextProvider } from "../../context/sidebar.context";
import AddButton from "./AddButton";
import ChapterItem from "./ChapterItem";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "overlayscrollbars/overlayscrollbars.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import CollapseContainerContext from "../../context/collapse-container.context";
import useCommonHelper from "../../hooks/helper.hook";
import { usePlanTemplateStore } from "../../store/plan-template.store";
import debounce from "lodash.debounce";
import { searchInTemplatePlan } from "../../services/plans-template.service";
import EmptyBox from "../emptystate/EmptyBox";

function Sidebar({ isAdmin, hasChangeDetected }) {
  const { preventDragXAxis, reorderItems } = useCommonHelper();

  const [
    chapterList,
    planDetail,
    searchModel,
    searchLoading,
    searchResults,
    updateState,
  ] = usePlanTemplateStore((state) => [
    state.chapterList,
    state.planDetail,
    state.searchModel,
    state.searchLoading,
    state.searchResults,
    state.updateState,
  ]);

  const { forceOpen, forceClose } = useContext(CollapseContainerContext);

  const abortController = useRef(new AbortController());

  /**
   * Drag end
   * @param {*} result
   * @returns
   */
  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) return;

    const reorderedItems = reorderItems(
      chapterList,
      result.source.index,
      result.destination.index,
    );

    updateState({ chapterList: reorderedItems, isChaptersOrdered: true });
  }

  /**
   * Add chapter
   * @param {*} index
   */
  function addChapter(index) {
    updateState({ isCrudChapterOpen: true, chapterModel: { position: index } });
  }

  /**
   * Search chapter
   * @param {*} e
   */
  function searchChapter(e) {
    const search = e.target.value;

    if (search.trim().length > 2) {
      updateState({ searchLoading: true });

      abortController.current.abort();
      abortController.current = new AbortController();

      const body = {
        id: planDetail.id,
        q: search.trim().toLowerCase(),
      };

      searchInTemplatePlan(
        { params: body, options: { signal: abortController.current.signal } },
        (res) => {
          updateState({
            searchLoading: false,
            searchModel: search.trim().toLowerCase(),
            searchResults: res,
          });
        },
        (err) => {
          updateState({ searchLoading: false });
        },
      );
    } else {
      updateState({ searchModel: "" });
    }
  }

  const debounceSearch = debounce(searchChapter, 500);

  const filteredChapters = chapterList.filter(
    (chapter) =>
      searchResults.template_chapters.includes(chapter.content_id) ||
      !searchModel,
  );

  return (
    <SidebarContextProvider>
      <div className={c.main_sidebar}>
        <div className={c.wrap_sidebar}>
          {/* Header Sidebar */}
          <div className={c.main_header_sidebar}>
            <div className={c.wrap_header_sidebar}>
              <div className={c.main_buttons}>
                {/* Collapse Buttons */}
                <div
                  className={c.main_button}
                  onClick={forceOpen}
                  data-tooltip-id="tooltip_small_top"
                  data-tooltip-position-strategy="fixed"
                  data-tooltip-offset={10}
                  data-tooltip-content="Espandi tutti"
                >
                  <span className="icon_16 default expand icon_mask !bg-interactive-100"></span>
                </div>
                <div
                  className={c.main_button}
                  onClick={forceClose}
                  data-tooltip-id="tooltip_small_top"
                  data-tooltip-position-strategy="fixed"
                  data-tooltip-offset={10}
                  data-tooltip-content="Riduci tutti"
                >
                  <span className="icon_16 default collapsed icon_mask !bg-interactive-100"></span>
                </div>
              </div>

              {/* Search */}
              <div className={c.main_search}>
                <div className="wrap_input_landing icon icon_right">
                  <input
                    onChange={debounceSearch}
                    type="text"
                    className="landing"
                    placeholder="Ricerca..."
                  />
                  <span className="icon_16 default search"></span>
                  {hasChangeDetected && (
                    <span
                      data-tooltip-id="tooltip_small_top"
                      data-tooltip-offset={10}
                      data-tooltip-position-strategy="fixed"
                      data-tooltip-content="Sono state rilevate delle modifiche non salvate, di conseguenza i risultati della ricerca potrebbero non essere consistenti."
                      className="icon_16 default info icon_right !opacity-100 cursor-pointer !pointer-events-auto"
                    ></span>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Content Sidebar */}
          <div className={c.main_content_sidebar}>
            {filteredChapters.length > 0 ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={c.main_list_sidebar}
                    >
                      <OverlayScrollbarsComponent
                        defer
                        options={{ scrollbars: { autoHide: "scroll" } }}
                        className={c.wrap_list_sidebar}
                      >
                        {isAdmin && !searchModel.length && (
                          <AddButton
                            isDragging={snapshot.isDraggingOver}
                            inLine
                            chapter
                            onClick={() => addChapter(0)}
                          />
                        )}

                        {/* Drag Item */}
                        {filteredChapters.map((chapter, index) => (
                          <Fragment key={chapter.content_id}>
                            <Draggable
                              isDragDisabled={!isAdmin || !!searchModel.length}
                              key={chapter.content_id}
                              draggableId={String(chapter.content_id)}
                              index={index}
                            >
                              {(provided, snap) => (
                                <div
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                  style={preventDragXAxis(provided, snap)}
                                >
                                  <ChapterItem
                                    isEditable={isAdmin}
                                    isDraggable={!searchModel.length}
                                    position={index}
                                    chapter={chapter}
                                    /*disabled={!isSearched(chapter)}*/
                                    isDragging={snap.isDragging}
                                    dragHandleProps={{
                                      ...provided.dragHandleProps,
                                    }}
                                  />
                                  {isAdmin && !searchModel.length && (
                                    <AddButton
                                      isDragging={snapshot.isDraggingOver}
                                      inLine
                                      chapter
                                      onClick={() => addChapter(index + 1)}
                                    />
                                  )}
                                </div>
                              )}
                            </Draggable>
                          </Fragment>
                        ))}

                        {provided.placeholder}
                      </OverlayScrollbarsComponent>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : !!searchModel.length ? (
              <div className="py-6">
                <EmptyBox
                  title={"Nessun risultato trovato"}
                  icon={"icon_75 default archive_plan"}
                />
              </div>
            ) : (
              <div className={c.main_list_sidebar}>
                <div className={c.wrap_list_sidebar}>
                  <AddButton
                    onClick={() => addChapter(0)}
                    label="Aggiungi una nuova sezione"
                  />
                </div>
              </div>
            )}

            {searchLoading && (
              <div className={c.main_loading}>
                <div className="main_comp_loader_box_icon !bg-dark-70"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </SidebarContextProvider>
  );
}

export default Sidebar;
