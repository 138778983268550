import React from 'react';

const StatusPlanPreview = ({ onManage = () => {}, statusList = [] }) => {
    return (
        <div className="p-5 rounded-[4px] bg-primary-5">
            <div className="flex justify-between gap-5">
                <div className="text-12 font-bold uppercase text-dark-100/50">
                    Stati delle linee d'azione
                </div>

                <div className="text-12 link-interactive" onClick={onManage}>
                    Gestione stati
                </div>

            </div>

            <div className="flex flex-wrap gap-4 pt-3">

                {statusList.map((status, index) =>
                    <div key={index} className="flex gap-2 py-1 px-3 bg-white rounded-full text-12">
                        <span className={"icon_16 status " + status.icon}></span>
                        {status.name}
                    </div>
                )}
            </div>
        </div>
    );
};

export default StatusPlanPreview;