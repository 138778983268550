import c from "./AddButton.module.scss"


function AddButton({ inLine, small, isDragging, chapter, onlyIcon, label, onClick = () => {}}) {

    return (
        <div onClick={onClick} className={`${c.main_add_item} ${small ? c.small : ''} ${inLine ? c.in_line : ''} ${isDragging ? c.disable_hover : ''} ${chapter ? c.chapter : ''} ${onlyIcon ? c.only_icon : ''}`}>
            <div className={c.wrap_add_item}>
                {label ?
                    <div className={c.label}>+ {label}</div>
                    :
                    <div className={c.main_icon}>
                        <span className={"icon_16 plus default icon_mask" + (chapter ? " !bg-primary-70" : " !bg-interactive-100")}></span>
                    </div>
                }
            </div>
        </div>
    );
}

export default AddButton;