import { useRef } from "react";
import { Transition } from "react-transition-group";
import useCommonHelper from "../../../hooks/helper.hook";
import useDropdown from "../../../hooks/dropdown-item.hook";

function Content({ children, right }) {

    const { isOpen, handleToggle } = useDropdown()

    const renderChild = typeof children == "function" ? children({isOpen, closeDropDown: handleToggle }) : children

    const { animationClass } = useCommonHelper()

    const popoverContent = useRef(null)

    return (
        <Transition
            nodeRef={popoverContent} 
            in={isOpen}
            mountOnEnter
            unmountOnExit
            timeout={{enter: 200, exit: 200}}
        >
            {state => (
                <div ref={popoverContent} className={"main_comp_dropdow_content " + (right ? "right_ancor " : "") + animationClass(state, "popover_in", "popover_out")}>
                    {renderChild}
                </div>
            )}
        </Transition>
    );
}

export default Content;