import "./BoxContentPlan.scss";
import Box from "./Box";

function ListContent({ children, className = "" }) {
  return (
    <div className={"main_comp_box_content_plan " + className}>{children}</div>
  );
}

ListContent.Box = Box;

export default ListContent;
