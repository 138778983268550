import React, { forwardRef } from "react";

const FileInput = forwardRef(
  ({ error, label = "Carica file", isLoading, accept, id, ...props }, ref) => {
    return (
      <div className="">
        <input
          ref={ref}
          className={"hidden"}
          id={id}
          type="file"
          accept={accept}
          {...props}
        />
        <label
          htmlFor={id}
          className={
            "p-[10px_16px] flex justify-center items-center border border-dashed text-center text-12 font-bold rounded-[4px] cursor-pointer hover:bg-interactive-5 transition-colors" +
            (error
              ? " border-red-100 text-red-100"
              : " border-interactive-100 text-interactive-100")
          }
        >
          {isLoading ? (
            <span className="icon_16 default loader icon_mask !bg-interactive-100 flex"></span>
          ) : (
            <span className="leading-[16px]">{label}</span>
          )}
        </label>
      </div>
    );
  },
);

export default FileInput;
