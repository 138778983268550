import c from "./CounterStatus.module.scss"

function CounterStatus({value, icon, label, ...props}) {
    return (
        <div className={c.counter_status} {...props}>
            <div className={c.main_value}>
                {icon && <span className={icon}></span>}
                {value}
            </div>
            <div className={c.main_label}>
                <span className={c.label}>{label}</span>
            </div>
        </div>
    );
}

export default CounterStatus;