import { useState, useRef, createContext } from "react";

const NotificationContext = createContext({
	notificationActived: "",
	setNotificationActived: function () {},
	text: "",
	setText: function () {},
	fields: "",
	setFields: function () {},
	status: "",
	setStatus: function () {},
	close: "",
	setClose: function () {},
	timeRef: ""
});

export function NotificationContextProvider(props) {

	// Notification attributes
	const [notificationActived, setNotificationActived] = useState(false);
	const [status, setStatus] = useState("");
	const [text, setText] = useState("");
	const [fields, setFields] = useState([]);
	const [close, setClose] = useState(true);
	const timeRef = useRef(null);

	const context = {
		notificationActived,
		setNotificationActived,
		text,
		setText,
		fields,
		setFields,
		status,
		setStatus,
		close,
		setClose,
		timeRef
	};

	return (
		<NotificationContext.Provider value={context}>
			{props.children}
		</NotificationContext.Provider>
	);
}

export default NotificationContext;
