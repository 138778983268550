import { Fragment } from "react";
import useCollapse from "../../../hooks/collapse-item.hook";

function Toggle({ children }) {

    const { isOpen, handleToggle, isClosed } = useCollapse()

    return (
        <div onClick={handleToggle}>
            {typeof children == "function" ? children({isOpen, isClosed}) : children}
        </div>
    );
}

export default Toggle;