import { useLoaderData } from "react-router-dom";
import LayoutNav from "../../components/layout/LayoutNav";
import SectionBox from "../../components/section-box/SectionBox";
import TableAgendas from '../../components/tables/TableAgendas';
import ItemSummary from "../../components/plans/ItemSummary";

function ListAgendas({ role }) {

    const lastAnnotation = useLoaderData()

    const lastAnnotationLink = {
        admin: `/admin/agendas/${lastAnnotation?.agenda?.organization_plan?.organization_id}/${lastAnnotation?.agenda_id}?annotation=${lastAnnotation?.id}`,
        reseller: `/reseller/agendas/${lastAnnotation?.agenda?.organization_plan?.organization_id}/${lastAnnotation?.agenda_id}?annotation=${lastAnnotation?.id}`,
        organization: `/organization/agendas/${lastAnnotation?.agenda?.organization_plan?.organization_id}/${lastAnnotation?.agenda_id}?annotation=${lastAnnotation?.id}`
    }


    return (
        <LayoutNav>
            
            <div className="main_page_box grid_layout">

                {lastAnnotation &&
                    <SectionBox title="Ultima annotazione">

                        <ItemSummary
                            icon="note"
                            title={lastAnnotation.name}
                            description={`${lastAnnotation.agenda.name} - di ${lastAnnotation.agenda.organization_plan.organization.name}`}
                            ctaConf={{
                                label: "Visualizza",

                                href: lastAnnotationLink[role] ?? ""
                            }}
                        />
                    </SectionBox>
                }
                
                <SectionBox title="Tutte le agende">
                    <TableAgendas role={role} />
                </SectionBox>
            </div>

        </LayoutNav>
    );
}

export default ListAgendas;