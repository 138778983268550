import c from "./LoaderPage.module.scss"

function LoaderPage({ section, title }) {
    return (
        <div className={`${c.loader_container} ${section ? c.section_loader : ''}`}>
            <div className={c.main_loader}>
                <img className={c.loader} src="/images/loader.svg" alt="" />
            </div>
            <div className={c.title}>{title}</div>
        </div>
    );
}

export default LoaderPage;