import c from "./TableContainer.module.scss"
import Pagination from "../utils/Pagination";
import FilterDropdown from '../utils/FilterDropdown';
import Table from "../utils/table/Table";
import { Link } from "react-router-dom";
import { getOrganizations, createOrganizationPlanRelation, updateOrganizationPlanRelation } from '../../services/organizations.service';
import { useEffect, useState } from "react";
import debounce from "lodash.debounce";
import LoadingBox from "../loading/LoadingBox";
import useLoadingHelper from "../../hooks/loading.hook";
import EmptyBox from "../emptystate/EmptyBox";



function TableConfEntities({ plan, onToggleChange }) {

    const { queueLoading, addQueue, removeQueue, initStatus, setInitStatus } = useLoadingHelper()

    const [ organizations, setOrganitazions ] = useState([])

    const [ queryParams, setQueryParams ] = useState({
        page: 1,
        filter: {
            name : ""
        }
    })

    const [ pageData, setPageData ] = useState({
        last: 0,
        current: 0,
        total: 0
    })



    /**
     * Fetch data
     */
    useEffect(() => {

        getListOrganizations()

    }, [queryParams])

    /**
     * Get List organizations
     */
    const getListOrganizations = () => {

        const body = {
            ...plan && { template_plan_id: plan.id },
            include: ["organizationType", "reseller", "organizationPlans"],
            ...queryParams
        }

        addQueue()
        
        // Fetch Api
        getOrganizations(body, (res) => {

            setOrganitazions(res.data)

            removeQueue()

            setInitStatus(true)

            setPageData({
                last: res.meta.last_page,
                current: res.meta.current_page,
                total: res.meta.total
            })

        }, () => {
            removeQueue()
        })
    }



    /**
     * Update page
     * @param {*} newPage 
     * @returns 
     */
    const updatePage = (newPage) => setQueryParams(q => ({...q, page: newPage}))



    /**
	 * Debounce Search
	 */
	const debouncedSearch = debounce(onSearch, 300)


    /**
     * Search event function 
     */
    function onSearch(e) {

        const searchModel = e.target.value

        if(searchModel.trim().length > 0 || queryParams.filter.name != null){
            setQueryParams(q => ({...q, page:1, filter: { name: searchModel.trim().length > 0 ? searchModel : ''}}))
        }
    }



    /**
     * Active status
     * @param {*} e 
     * @param {*} organization 
     */
    function activeOrganization(e, organization, index){
        
        const active = Number(e.target.checked)

        if(organization.organization_plans.length > 0){
            updateOrganizationPlanRelation({id: organization.id, idPlan: organization.organization_plans[0].id, active}, () => {
                onToggleChange()
            })
        }else{
            if(active){
                createOrganizationPlanRelation({id: organization.id, template_plan_id: plan.id, active}, (res) => {

                    setOrganitazions(orgs => {

                        orgs[index].organization_plans.push(res.data)

                        return [...orgs]
                    })

                    onToggleChange()
                })
            }
        }

    }




    return (
        <>
            <div className={c.main_intro}>
                <div className={c.left_content}>
                    <div className={c.main_fitlers}>
                        {/* <FilterDropdown
                            options={[
                                { label: "Completate", value: "completed", icon: "icon_16 default check icon_mask !bg-green-100"},
                                { label: "Da gestire", value: "uncompleted", icon: "icon_16 default danger_corp"},
                                { label: "In corso", value: "processing", icon: "icon_16 default lightning icon_mask !bg-yellow-100"},
                                { label: "Non di competenza", value: "unrelated", icon: "icon_16 default ban icon_mask !bg-fuxia-100"},
                                { label: "Pianificate", value: "planned", icon: "icon_16 default stopwatch icon_mask !bg-blue-100"},
                                { label: "Bloccate", value: "stalled", icon: "icon_16 default lock icon_mask !bg-dark-50"},
                            ]}
                            onApply={() => {}}
                            toggleLabel="Stato linee d'azione"
                        />  */}
                    </div>
                </div>
                <div className={c.right_content}>
                    <div className="wrap_input_landing icon">
                        <input type="text" onChange={debouncedSearch} className="landing" placeholder="Ricerca..." />
                        <span className="icon_16 default search"></span>
                    </div>
                </div>
            </div>
            
            <div className="relative">

                {organizations.length > 0 ?
                    <div className="fade_in">
                        <Table>

                            {/* Table Head */}
                            <Table.Head>
                                <Table.Column>Nome</Table.Column>
                                <Table.Column>Tipologia</Table.Column>
                                <Table.Column>Rivenditore</Table.Column>
                                <Table.Column>Abilita</Table.Column>
                                <Table.Column></Table.Column>
                            </Table.Head>

                            {/* Table Body */}
                            <Table.Body>
                                {organizations.map((el, index) => (
                                    <Table.Row key={index}>

                                        <Table.Cell>
                                            <div className="name_logo">
                                                {el.logo_url ? 
                                                    <img src={el.logo_url} className="logo" />
                                                    :
                                                    <div className="main_icon">
                                                        <span className="icon_16 default entity"></span>
                                                    </div>
                                                }
                                                {el.name}
                                            </div>
                                        </Table.Cell>

                                        <Table.Cell>{el.organization_type.name}</Table.Cell>

                                        <Table.Cell>{el?.reseller?.name ? el.reseller.name : "–"}</Table.Cell>
                                        
                                        <Table.Cell width={100}>
                                            <div className="toggle_landing" key={el.id}>
                                                <input onChange={(e) => activeOrganization(e,el,index)} defaultChecked={el.organization_plans.length > 0 && el.organization_plans[0].active} type="checkbox" id={"entity-" + index} className="landing"  />
                                                <label htmlFor={"entity-" + index} className="only_toggle"></label>
                                            </div>
                                        </Table.Cell>
                                        
                                        <Table.Cell width={170} key={el.organization_plans}>
                                            {el.organization_plans.length > 0 ?
                                                <Link to={`/admin/plans/template/copy/${el.id}/${el.organization_plans[0].id}`} className="button fill secondary x_small">
                                                    <span className="icon_16 to_folder default icon_mask !bg-primary-100"></span>
                                                    Copia contenuto
                                                </Link>
                                                :
                                                <button className="button fill secondary x_small disabled">
                                                    <span className="icon_16 to_folder default icon_mask !bg-primary-100"></span>
                                                    Copia contenuto
                                                </button>
                                            }
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>

                        {/* Paginator */}
                            <Pagination
                            last={pageData.last}
                            current={pageData.current}
                            total={pageData.total}
                            onChange={updatePage}
                        /> 
                    </div>
                    :
                    initStatus &&
                        <EmptyBox 
                            icon="icon_75 default archive_entity"
                            title="Nessun risultato trovato"
                            description="Qui visualizzerai la lista di tutti gli enti"
                        />
                }
                {queueLoading.length > 0 && <LoadingBox blocked={!initStatus} />}
            </div>


            
        </>
    );
}

export default TableConfEntities;