import { Fragment, forwardRef, useContext } from "react";
import CollapseItem from "../utils/collapse-item/CollapseItem";
import "../plan-template/ChapterItem.scss";
import CardChapterContent from "./CardChapterContent";
import EmptyBox from "../emptystate/EmptyBox";
import { getPlanContent } from "../../services/plans.service";
import { usePlanCompileStore } from "../../store/plan-compile.store";
import CardCourseContent from "./CardCourseContent";
import GlobalContext from "../../context/global.context";

const ChapterItem = forwardRef(
  (
    {
      chapter,
      dragHandleProps,
      disabled,
      isDragging,
      position,
      isEditable = false,
      ...props
    },
    ref,
  ) => {
    const { organization } = useContext(GlobalContext);

    const {
      planDetail,
      selectedContent,
      searchResults,
      updateState,
      roleSettings,
      isFiltering,
    } = usePlanCompileStore();

    /**
     * Select Item
     * @param {*} item
     * @returns
     */
    const selectItem = async (item) => {
      if (item.content_type === "organization_chapter_content") {
        const res = await getPlanContent({
          id: item.content_id,
          idCorp: planDetail.organization_id,
        });
        item.data = res.data;
      }

      window.scroll(0, 0);

      updateState({ selectedContent: item });
    };

    const statusDefault = planDetail?.organization_requirements_statuses.find(
      (status) => status.default,
    );

    const contentsLength = chapter.data.organization_contents.filter(
      (el) => el.content_type === "organization_chapter_content",
    ).length;

    const chapterGoals = chapter.data.organization_contents.filter(
      (el) => el.content_type === "organization_goal",
    );

    const isContentSearched = (content) => {
      if (!isFiltering) {
        return true;
      }

      const listContents =
        chapter?.data?.organization_contents?.filter(
          (c) => c.content_type === "organization_chapter_content",
        ) ?? [];

      const listCourses =
        chapter?.data?.organization_contents?.filter(
          (c) => c.content_type === "organization_course",
        ) ?? [];

      const listGoal =
        chapter?.data?.organization_contents?.filter(
          (c) => c.content_type === "organization_goal",
        ) ?? [];

      if (
        searchResults.organization_chapters.includes(chapter.content_id) &&
        listGoal.every(
          (g) => !searchResults.organization_goals.includes(g.content_id),
        ) &&
        listContents.every(
          (c) =>
            !searchResults.organization_chapter_contents.includes(c.content_id),
        ) &&
        listCourses.every(
          (c) => !searchResults.organization_courses.includes(c.content_id),
        )
      ) {
        return true;
      }

      if (content.content_type === "organization_chapter_content") {
        return searchResults.organization_chapter_contents.includes(
          content.content_id,
        );
      } else if (content.content_type === "organization_course") {
        return searchResults.organization_courses.includes(content.content_id);
      } else {
        return searchResults.organization_goals.includes(content.content_id);
      }
    };

    const canAccessContent =
      roleSettings !== "organization" ||
      (!!organization && organization?.reseller_id !== 10);

    return (
      <div
        className={
          "main_card_chapter__container view_mode" +
          (isDragging ? " is_dragging" : "") +
          (selectedContent?.organization_chapter_id === chapter.content_id
            ? " active"
            : "") +
          (disabled ? " disabled" : "")
        }
        ref={ref}
        {...props}
      >
        <CollapseItem disabled={disabled}>
          <CollapseItem.Toggle>
            {({ isOpen, isClosed }) => (
              <div
                className={
                  "main_card_chapter__toggle not_draggable" +
                  (!isOpen ? " closed" : "")
                }
              >
                <div className={"main_heading_toggle"}>
                  <div className="main_icon type">
                    <span
                      className={
                        "icon_10 default chevron" + (isOpen ? "" : " right")
                      }
                    ></span>
                  </div>

                  <div className="main_title">
                    <div className="main_subtitle">
                      {/* Subttile */}
                      <div className="subtitle">Sezione {position + 1}</div>
                    </div>
                    <div className="title">
                      {chapter.data.template_chapter.name}
                    </div>
                  </div>
                </div>
                {isClosed && (
                  <div className="main_summary">
                    {/* Count Contents */}
                    <div className="item_sum">
                      <span className="icon_16 text_box default"></span>
                      <span className="value">{contentsLength}</span>
                    </div>

                    {/* Count Goals */}
                    <div className="item_sum">
                      <span className="icon_16 layers default"></span>
                      <span className="value">{chapterGoals.length}</span>
                    </div>

                    {/* Count Requirements */}
                    <div className="item_sum">
                      <span className="icon_16 layer default"></span>
                      <span className="value">
                        {!!chapterGoals.length
                          ? chapterGoals.reduce(
                              (a, b) =>
                                a + b.data.organization_requirements.length,
                              0,
                            )
                          : 0}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </CollapseItem.Toggle>

          <CollapseItem.Content>
            <div className="main_card_chapter__content">
              {/* Contents */}
              {chapter?.data?.organization_contents?.length > 0 && (
                <div className="wrap_card_chapter__content">
                  {/* Contenst List */}
                  {chapter.data.organization_contents.map(
                    (contentItem, index) =>
                      isContentSearched(contentItem) && (
                        <Fragment key={`${contentItem.content_id}-${index}`}>
                          {contentItem.content_type ===
                          "organization_course" ? (
                            roleSettings !== "reseller" &&
                            canAccessContent && (
                              <CardCourseContent item={contentItem} />
                            )
                          ) : (
                            /* Chapter Content Item */
                            <CardChapterContent
                              isEditable={false}
                              isActived={
                                contentItem.content_id ===
                                  selectedContent?.content_id &&
                                selectedContent.content_type ===
                                  contentItem.content_type
                              }
                              toComplete={
                                contentItem.content_type ===
                                "organization_chapter_content"
                                  ? contentItem.data.status === "uncompleted"
                                  : contentItem.data.organization_requirements.find(
                                      (item) =>
                                        item.requirement_status_id ===
                                        statusDefault.id,
                                    )
                              }
                              isContents={
                                contentItem.content_type ===
                                "organization_chapter_content"
                              }
                              item={
                                contentItem.content_type ===
                                "organization_chapter_content"
                                  ? contentItem.data.template_chapter_content
                                  : contentItem.data.template_goal
                              }
                              onClick={() => selectItem(contentItem)}
                              position={index}
                              counterReqs={
                                contentItem?.data?.organization_requirements
                                  ?.length
                              }
                              counterAttachments={contentItem?.data?.organization_requirements?.reduce(
                                (acc, item) =>
                                  acc + (item?.attachments?.length ?? 0),
                                0,
                              )}
                            />
                          )}
                        </Fragment>
                      ),
                  )}
                </div>
              )}

              {chapter.data.organization_contents.length === 0 && (
                <EmptyBox
                  title="Capitolo vuoto"
                  description="Il capitolo non ha contenuto"
                  small
                />
              )}
            </div>
          </CollapseItem.Content>
        </CollapseItem>
      </div>
    );
  },
);

export default ChapterItem;
