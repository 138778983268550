import { Fragment, forwardRef } from "react";
import CollapseItem from "../utils/collapse-item/CollapseItem";
import "./ChapterItem.scss"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CardChapterContent from "./CardChapterContent";
import AddButton from "./AddButton";
import useCommonHelper from "../../hooks/helper.hook";
import ActionBtnItem from "../utils/ActionBtnItem";
import { getTemplatePlanContent } from "../../services/plans-template.service";
import {usePlanTemplateStore} from "../../store/plan-template.store";
import CardCourseContent from "./CardCourseContent";



const ChapterItem = forwardRef(({ chapter, dragHandleProps, disabled, isDragging, isDraggable = true, position, isEditable = false, ...props}, ref) => {
    
    const { preventDragXAxis, reorderItems } = useCommonHelper()

    const [
        selectedContent,
        chapterList,
        searchModel,
        searchResults,
        updateState,
        deleteChapter,
        duplicateChapter
    ] = usePlanTemplateStore((state) => ([
        state.selectedContent,
        state.chapterList,
        state.searchModel,
        state.searchResults,
        state.updateState,
        state.deleteChapter,
        state.duplicateChapter,
    ]))



    /**
     * On drag end
     * @param {*} result 
     * @returns 
     */
    const onDragEndContents = (result) =>  {
        // dropped outside the list
        if (!result.destination)
            return;

        const reorderedItems = reorderItems(chapter.data.contents, result.source.index, result.destination.index);

        chapter.data.contents = reorderedItems

        chapter.orderContents = true

        updateState({ chapterList: [...chapterList] })
    }



    /**
     * Select Item
     * @param {*} item 
     * @returns 
     */
    const selectItem = async (item, isContents) => {

        if(isContents && !item.data.editorjs){
            const res = await getTemplatePlanContent({id: item.content_id})

            item.data.editorjs = res.data.editorjs
        }

        window.scroll(0,0)

        updateState({ selectedContent: item })
    }



    
    /**
     * Edit Chapter
     */
    const editChapter = () => {
        updateState({
            chapterModel: { model: chapter },
            isCrudChapterOpen: true
        })
    }


    
    /**
     * Edit Chapter
     */
    const deleteChapterHandler = () => {

        updateState({
            deleteConf: {
                title: "Elimina Capitolo",
                icon: "icon_16 default folder",
                subtitle: chapter.name,
                confirmHandler: deleteChapter,
            },
            deleteItem: chapter,
            isDeleteModalOpen: true
        })
    }



    /**
     * Add content
     * @param {} position 
     */
    const addContent = (position) => {
        updateState({
            contentModel: { position, chapter },
            isCrudContentOpen: true
        })
    }

    const isContentSearched = (content) => {
        if(!searchModel){
            return true
        }

        const listContents = chapter?.data?.contents?.filter(c => c.content_type === "template_chapter_content") ?? []

        const listCourse = chapter?.data?.contents?.filter(c => c.content_type === "template_course") ?? []

        const listGoal = chapter?.data?.contents?.filter(c => c.content_type === "template_goal") ?? []

        if(searchResults.template_chapters.includes(chapter.content_id)
            && listGoal.every(g => !searchResults.template_goals.includes(g.content_id))
            && listContents.every(c => !searchResults.template_chapter_contents.includes(c.content_id))
            && listCourse.every(c => !searchResults.template_courses.includes(c.content_id))
        ){
            return true
        }

        if(content.content_type === "template_chapter_content"){
            return searchResults.template_chapter_contents.includes(content.content_id)
        }else if(content.content_type === "template_course"){
            return searchResults.template_courses.includes(content.content_id)
        } else{
            return searchResults.template_goals.includes(content.content_id)
        }
    }


    const filteredContents = chapter.data.contents.filter(el => isContentSearched(el))

    const filteredGoals = chapter.data.contents.filter(content => content.content_type === "template_goal")

    const countContents = chapter.data.contents?.filter(content => content.content_type === "template_chapter_content")?.length ?? 0

    return (
        <div className={"main_card_chapter__container" + (isDragging ? ' is_dragging' : '') + (selectedContent?.data.template_chapter_id === chapter.content_id ? " active" : '') + (disabled ? " disabled": '') + (!isDraggable ? " view_mode" : '')} ref={ref} {...props}>
            <CollapseItem disabled={disabled}>
                <CollapseItem.Toggle>
                    {({isOpen, isClosed}) => 
                        <div className={"main_card_chapter__toggle" + (!isDraggable ? " not_draggable" : '') + (!isOpen ? " closed" : '')}>
                            <div className={"main_heading_toggle"}>

                                <div className="main_icon type">
                                    <span className={"icon_10 default chevron" + (isOpen ? '' : ' right')}></span>
                                </div>

                                {isDraggable &&
                                    <div className="main_icon draggable" {...dragHandleProps}>
                                        <span className="icon_10 default draggable"></span>
                                    </div>
                                }

                                <div className="main_title">
                                    <div className="main_subtitle">

                                        {/* Subttile */}
                                        <div className="subtitle">Sezione {position+1}</div>
                                        
                                        {/* Actions */}
                                        {isEditable &&
                                            <div className="main_actions">

                                                {/* Edit */}
                                                <ActionBtnItem tooltip="Modifica" onClick={editChapter} icon="edit" />

                                                {/* Clone */}
                                                {isDraggable &&
                                                    <ActionBtnItem tooltip="Duplica" onClick={() => duplicateChapter(chapter, position+1)} icon="duplicate" />
                                                }

                                                {/* Delete */}
                                                <ActionBtnItem tooltip="Elimina" onClick={deleteChapterHandler} icon="delete" />

                                            </div>
                                        }
                                    </div>
                                    <div className="title">{chapter.data.name}</div>
                                </div>
                            </div>
                            {isClosed &&
                                <div className="main_summary">

                                    {/* Count Contents */}
                                    <div className="item_sum">
                                        <span className="icon_16 text_box default"></span>
                                        <span className="value">{countContents}</span>
                                    </div>

                                    {/* Count Goals */}
                                    <div className="item_sum">
                                        <span className="icon_16 layers default"></span>
                                        <span className="value">{filteredGoals.length}</span>
                                    </div>

                                    {/* Count Requirements */}
                                    <div className="item_sum">
                                        <span className="icon_16 layer default"></span>
                                        <span className="value">{!!filteredGoals.length ? filteredGoals.reduce((a,b) => a + b.data.template_requirements.length, 0) : 0}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </CollapseItem.Toggle>

                <CollapseItem.Content>
                    <div className="main_card_chapter__content">

                        {/* Contents */}
                        {(filteredContents.length > 0 || isEditable) &&
                            <DragDropContext onDragEnd={onDragEndContents}>
                                <Droppable droppableId='droppable_inset'>
                                    {(provided, snapshot) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef} className="wrap_card_chapter__content">
                                            {filteredContents.length > 0 ?
                                                <>
                                                    {/* Add Content */}
                                                    {isDraggable &&
                                                        <AddButton onClick={() => addContent(0)} inLine />
                                                    }

                                                    {/* Contenst List */}
                                                    {filteredContents.map((contentItem, index) => (
                                                        <Fragment key={`${contentItem.content_id}-${index}`}>
                                                            <Draggable isDragDisabled={!isDraggable} key={contentItem.index} draggableId={String(contentItem.index)} index={index}>

                                                                {/* Chapter Content Item */}
                                                                {(provided, snapshot) => (
                                                                    contentItem.content_type === "template_course" ?
                                                                        <CardCourseContent {...provided.draggableProps}
                                                                            isEditable={isEditable}
                                                                            ref={provided.innerRef}
                                                                            item={contentItem}
                                                                            position={index}
                                                                            dragHandleProps={provided.dragHandleProps}
                                                                            style={preventDragXAxis(provided, snapshot)}
                                                                            isDraggable={isDraggable}
                                                                            isDragging={snapshot.isDragging}
                                                                            isContents={contentItem.content_type === "template_chapter_content"}
                                                                        />
                                                                        :
                                                                        <CardChapterContent {...provided.draggableProps}
                                                                            isEditable={isEditable}
                                                                            ref={provided.innerRef}
                                                                            isActived={contentItem.content_id === selectedContent?.content_id && selectedContent.content_type === contentItem.content_type}
                                                                            item={contentItem}
                                                                            onClick={selectItem}
                                                                            position={index}
                                                                            dragHandleProps={provided.dragHandleProps}
                                                                            style={preventDragXAxis(provided, snapshot)}
                                                                            isDraggable={isDraggable}
                                                                            isDragging={snapshot.isDragging}
                                                                            isContents={contentItem.content_type === "template_chapter_content"}
                                                                        />
                                                                )}

                                                            </Draggable>

                                                            {/* Add Content */}
                                                            {isDraggable &&
                                                                <AddButton onClick={() => addContent(index + 1)} inLine />
                                                            }
                                                        </Fragment>
                                                    ))}
                                                </>
                                                :
                                                isDraggable &&
                                                    <div className="mb-4">
                                                        {/* Add Content */}
                                                        <AddButton small={filteredGoals.length > 0} label="Aggiungi contenuto" onClick={() => addContent(0)} />
                                                    </div>
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        }
                    </div>
                </CollapseItem.Content>
            </CollapseItem>
        </div>
    );
})

export default ChapterItem;