import c from "./CardAnnotation.module.scss"
import ActionBtnItem from '../utils/ActionBtnItem';
import useDateFormatter from '../../hooks/date-formatter.hook';

function CardAnnotation({ active, onClick, annotation, onEdit = () => {}, onDelete = () => {}, onDownload = () => {}, role }) {

    const { formatDate } = useDateFormatter()

    return (
        <div className={`${c.main_card_annotation} ${active ? c.active : ""}`} onClick={onClick}>
            <div className={c.main_intro}>
                <div className={c.wrap_intro}>
                    <div className={c.main_icon}>
                        <span className="icon_16 default note"></span>
                    </div>
                    <div className={c.title}>{formatDate(annotation.date, "DD MMM YYYY")}</div>
                </div>

                <div className={c.main_actions}>

                    {annotation.user_can_update &&
                        <ActionBtnItem onClick={onEdit} icon="edit" tooltip="Modifica"  />
                    }

                    {annotation.user_can_update &&
                        <ActionBtnItem onClick={onDelete} icon="delete" tooltip="Elimina"  />
                    }

                    <ActionBtnItem onClick={onDownload} icon="download" tooltip="Scarica"  />
                </div>
            </div>

            <div className={c.main_desc}>
                <div className={c.desc}>{annotation.name}</div>
            </div>
        </div>
    );
}

export default CardAnnotation;