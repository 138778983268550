import c from "./LoadingBox.module.scss"

function LoadingBox({ blocked = false, classNames = '' }) {
    return (
        <div className={`${c.main_loader} ${!blocked ? c.floating : c.blocked } ${classNames}`}>
            <div className="main_comp_loader_box_icon !bg-dark-70"></div>
        </div>
    );
}

export default LoadingBox;