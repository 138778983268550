import { Link } from "react-router-dom";
import c from "./ItemSummary.module.scss"

function ItemSummary({ icon = "", title, description, counters = [], ctaConf, boxed = false, small = false, templateRight = () => {}, templateBottom }) {
    return (
        <>
            <div className={`${c.main_box} ${boxed ? c.boxed : ''} ${small ? c.small : ""}`}>
                <div className={c.left_content}>
                    <div className={c.main_icon}>
                        <span className={"icon_30 default icon_mask !bg-primary-100 " + icon}></span>
                    </div>
                    <div className={c.main_desc}>
                        <div className={c.name}>{title}</div>
                        {description && <div className={c.desc}>{description}</div>}
                    </div>
                </div>

                <div className={c.right_content}>

                    {counters.length > 0 &&
                        <div className={c.main_counter_container}>

                            <div className={c.main_list_counter}>

                                {counters.map((counter, i) => (
                                    <div key={i} className={c.main_counter}>
                                        <span className={"icon_16 default " + counter.icon}></span>
                                        {counter.value}
                                    </div>
                                ))}
                            </div>

                        </div>
                    }

                    <Link to={ctaConf.href} className="button tertiary fill small">
                        {ctaConf.icon && <span className={"icon_24 default icon_mask !bg-white " + ctaConf.icon}></span>}
                        {ctaConf.label}
                    </Link>

                    {templateRight()}
                </div>

            </div>
            {templateBottom &&
                <div className={c.bottom_content}>
                    {templateBottom()}
                </div>
            }
        </>
    );
}

export default ItemSummary;