import LayoutNav from "../../components/layout/LayoutNav";
import SectionBox from "../../components/section-box/SectionBox";
import TablePlans from "../../components/tables/TablePlans";
import SubHeader from "../../components/elements/SubHeader";
import BoxCurrentPlan from "../../components/plans/BoxCurrentPlan";
import { useLoaderData } from "react-router-dom";

function DetailAgency({ role = "admin" }) {

    const { workingPlans, organization } = useLoaderData()

    const linkDetail = (workingPlan, role) => {
        if(role === "admin") {
            return `/admin/entities/entity/${workingPlan.organization_id}/plan/${workingPlan.id}`
        }
        if(role === "reseller") {
            return `/reseller/${workingPlan.organization_id}/plan/${workingPlan.id}`
        }
        if(role === "organization") {
            return `/organization/plan/${workingPlan.id}`
        }
    } 

    const linkConf = (workingPlan, role) => {
        if(role === "admin"){
            return `/admin/entities/entity/${workingPlan.organization_id}/plan/${workingPlan.id}/compile`
        }
        if(role === "reseller"){
            return `/reseller/${workingPlan.organization_id}/plan/${workingPlan.id}/compile`
        }
        if(role === "organization"){
            return `/organization/plan/${workingPlan.id}/compile`
        }
    } 


    return (
        <LayoutNav withSubheader={role !== "organization"}>
            {role !== "organization" &&
                <SubHeader 
                    logo={organization?.logo_url ? organization?.logo_url : null}
                    title={organization?.name ? organization?.name : null}
                    back
                />
            }

            <div className="main_page_box grid_layout">
                {!!workingPlans?.length &&
                    workingPlans.map((plan, index) => (
                        <SectionBox key={plan.id} title={index === 0 ? "Piani in lavorazione" : ""}>
                            <BoxCurrentPlan
                                title={plan?.template_plan?.name ? plan.template_plan.name : null}
                                workingPlan={plan}
                                linkDetail={linkDetail(plan, role)}
                                linkConfiguration={linkConf(plan, role)}
                                role={role}
                            />

                        </SectionBox>
                    ))
                }

                <SectionBox title="Piani pregressi">
                    <TablePlans organization={organization} role={role} />
                </SectionBox>
            </div>

        </LayoutNav>
    );
}

export default DetailAgency;