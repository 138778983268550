import c from "./SubNav.module.scss"

function SubNav({category, subcategory, title, heading, TemplateRightContent = () => {}}) {
    return (
        <div className={c.main_subnav}>
            <div className={c.wrap_subnav}>

                <div className={c.left_content}>
                    <div className={c.main_intro}>
                        {category && <div className={c.tag_category}>{category}</div>}
                        {subcategory && <div className={c.tag_category}>{subcategory}</div>}
                        {title && <div className={c.title}>{title}</div>}
                        {heading && <div className={c.heading}>{heading}</div>}
                    </div>
                </div>


                <div className={c.right_content}>
                    {TemplateRightContent()}
                </div>
            </div>
        </div>
    );
}

export default SubNav;