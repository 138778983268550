import { useRef } from "react";
import useCollapse from "../../../hooks/collapse-item.hook";
import { Transition } from "react-transition-group";
import useCommonHelper from "../../../hooks/helper.hook";

function Content({children, className = ""}) {

    const { isOpen, setIsClosed } = useCollapse()

    const containerEl = useRef()

    const renderChild = typeof children == "function" ? children({isOpen}) : children

    const {animationClass} = useCommonHelper()

    return (
        <Transition
            nodeRef={containerEl} 
            in={isOpen}
            onEnter={() => setIsClosed(false)}
            onExiting={() => setIsClosed(true)}
            mountOnEnter
            unmountOnExit
            timeout={{enter: 150, exit: 150}}
        >
            {state => (
                <div ref={containerEl} className={`${animationClass(state,"collapse_in","collapse_out")} ${className}`}>
                    {renderChild}
                </div>
            )}
        </Transition>
    );
}

export default Content;