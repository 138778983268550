import { apiClient, apiClientPlatform, SocketEcho } from "../services/config";

/**
 * get localstorage session user
 * @returns
 */
export const getLocalSession = () =>
  JSON.parse(localStorage.getItem("session_piano"));

/**
 * set localstorage session user
 * @returns
 */
export const setLocalSession = (token, id, user) =>
  localStorage.setItem(
    "session_piano",
    JSON.stringify({ token: token, id: id, user: user }),
  );

/**
 * Update auth token
 * @param {*} sessionToken
 */
export const updateAuthToken = (sessionToken) => {
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${sessionToken}`;
  apiClientPlatform.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${sessionToken}`;
  SocketEcho.options.auth.headers.Authorization = `Bearer ${sessionToken}`;
};

/**
 * Reorder items
 * @param {*} list
 * @param {*} startIndex
 * @param {*} endIndex
 * @returns
 */
export const reorderItems = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  const newItems = result.map((item, i) => {
    item.index = i + 1;
    return item;
  });

  return newItems;
};

export const ARRAY_STATUS_ICON = [
  {
    icon: "uncompleted",
    color: "#1EA2CC",
  },
  {
    icon: "completed",
    color: "#1FC810",
  },
  {
    icon: "unrelated",
    color: "#C40BE2",
  },
  {
    icon: "stalled",
    color: "#CFCFD4",
  },
  {
    icon: "planned",
    color: "#234EE9",
  },
  {
    icon: "processing",
    color: "#FFCD1B",
  },
  {
    icon: "message",
    color: "#FF0CBB",
  },
  {
    icon: "eight_point_star",
    color: "#2FDDA9",
  },
  {
    icon: "danger",
    color: "#EE2424",
  },
  {
    icon: "like",
    color: "#6DDE14",
  },
  {
    icon: "four_point_star",
    color: "#FF8024",
  },
  {
    icon: "eye",
    color: "#6583F0",
  },
  {
    icon: "notcompleted",
    color: "#EE2424",
  },
];



/*/!**
 * preventDragXAxis
 * @param {*} provided
 * @param {*} snapshot
 * @returns
 *!/
function preventDragYAxis(provided, snapshot){

  // Restrict dragging to vertical axis
  let transform = provided.draggableProps.style.transform;

  if (snapshot.isDragging && transform) {
    transform = transform.replace(/\(.+\,/, "(0,");
  }

  return {
    ...provided.draggableProps.style,
    transform,
  };
}*/
