import Toggle from "./CollapseToggle";
import Content from "./CollpaseContent";
import { createContext, useContext } from "react";
import CollapseContainerContext from "../../../context/collapse-container.context";
import { useState, useEffect } from "react";

export const CollapseContext = createContext()


function CollapseItem({ children, disabled, initialValue }) {

    const [isOpen, setIsOpen] = useState(initialValue !== undefined ? initialValue :  true)

    const { globalStatus, counterUpdate, setGlobalStatus } = useContext(CollapseContainerContext)

    const [isClosed, setIsClosed] = useState(false)


    useEffect(() => {
        if(typeof globalStatus == 'boolean' && !disabled){
            setIsOpen(globalStatus)
            setIsClosed(!globalStatus)
            setGlobalStatus(null)
        }

    }, [counterUpdate])


    const handleToggle = () => setIsOpen(p => !p)

    return (
        <CollapseContext.Provider value={{ isOpen, handleToggle, isClosed, setIsClosed }}>
            {children}
        </CollapseContext.Provider>
    );
}


CollapseItem.Toggle = Toggle
CollapseItem.Content = Content

export default CollapseItem;