import ModalContainer from "../popups/ModalContainer";
import "./DuplicatePlan.scss"
import { duplicateTemplatePlan } from "../../services/plans-template.service";
import { useState, useEffect } from "react";
import useNotification from "../../hooks/notification.hook";


export default function DuplicatePlanModal({open, setOpen, planModel, onSuccess = () => {} }) {

    const [loading, setLoading] = useState(false)

    const [toggleStatus, setToggleStatus] = useState(false)

    const { openNotification, helperErrorNotification } = useNotification()

    function duplicateHandler(){

        if(!loading){

            /* setLoading(true) */
            const with_organizations = Number(toggleStatus)
            
            duplicateTemplatePlan({id: planModel.id, ...with_organizations && { with_organizations }}, (res) => {
                openNotification({text : "Piano duplicato con successo!", status: "success"})
                onSuccess(res)
                setLoading(false)
            }, (err) => {
                helperErrorNotification(err);
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        if(open) setToggleStatus(false)
    },[open])


	return (
        <ModalContainer isOpen={open} setIsOpen={setOpen}>
            <div className={"main_modal_content duplicate_popup"} >

                <div className="main_close" onClick={() => setOpen(false)}>
                    <span className="icon_10 default close icon_mask !bg-white"></span>
                </div>

                <div className="main_header_modal left_text">
                    <span className="title">Duplica piano</span>
                </div>


                {/* Modal Content */}
                {planModel &&
                    <div className={"main_body_modal"}>

                        {/* Subtitle */}
                        <div className="main_subtitle">
                            <div className="main_icon">
                                <span className="icon_16 default piano"></span>
                            </div>
                            <span className="subtitle">{planModel.name}</span>
                        </div>

                        {/* Description */}
                        {planModel?.description && <div className="main_description">{planModel.description}</div>}

                        {/* Counters */}
                        <div className="main_counter_container">

                            <div className="main_counter">
                                <div className="main_icon">
                                    <span className="icon_16 folder default"></span>
                                </div>
                                {planModel.template_chapters_count}
                            </div>

                            {/* counter contents */}
                            <div className="main_counter">
                                <div className="main_icon">
                                    <span className="icon_16 default text_box"></span>
                                </div>
                                {planModel.template_chapter_contents_count}
                            </div>

                            {/* counter goals */}
                            <div className="main_counter">
                                <div className="main_icon">
                                    <span className="icon_16 layers default"></span>
                                </div>
                                {planModel.template_goals_count}
                            </div>

                            {/* counter actions */}
                            <div className="main_counter">
                                <div className="main_icon">
                                    <span className="icon_16 layer default"></span>
                                </div>
                                {planModel.template_requirements_count}
                            </div>
                        </div>



                        <div className="main_entities">
                            <div className="toggle_landing">
                                <input onChange={(e) => setToggleStatus(e.target.checked)} checked={toggleStatus} type="checkbox" id="copy_all_entity" className="landing"  />
                                <label htmlFor="copy_all_entity">Abilita tutti gli enti </label>
                            </div>
                        </div>
                    </div>
                }

                <div className="main_footer_modal">
                    <div className="right_content">
                        <button onClick={duplicateHandler} className='button fill success'>Conferma</button>
                    </div>
                </div>
            </div>
        </ModalContainer>
	);
}
