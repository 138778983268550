import React, {useEffect, useRef, useState} from 'react';
import {getAnnotation, getAnnotations} from "../../services/agendas.service";
import {formatDate} from "../../helper/date-formatter.helper";
import * as Accordion from "@radix-ui/react-accordion";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";
import {createReactEditorJS} from "react-editor-js";
import {EDITOR_JS_TOOLS} from "../tools/EditorConfig";
import CodeTool from "@editorjs/code"
import Delimiter from "@editorjs/delimiter"
import "./AgendaReader.scss"
import CollapseItem from "../utils/collapse-item/CollapseItem";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const ContainerEditorJS = createReactEditorJS()

const tools = {
    ...EDITOR_JS_TOOLS,
    code: CodeTool,
    delimiter: Delimiter
}

const AgendaReader = ({ agenda, planData, className = "" }) => {

    const [annotations, setAnnotations] = useState([])
    const [currentAnnotation, setCurrentAnnotation] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isReady, setIsReady] = useState(false)
    const abortController = useRef(new AbortController());
    const [alignLeft, setAlignLeft] = useState(false)

    const containerRef = useRef(null);

    const getAllAnnotation = () => {
        getAnnotations({ idCorp: planData.organization_id, id: agenda.id }, (res) => {
            setAnnotations(res.data)
        });
    }

    const getAnnotationDetail = (id) => {

        setIsLoading(true)
        setIsReady(false)
        setCurrentAnnotation(null)

        abortController.current.abort();

        abortController.current = new AbortController();

        getAnnotation({
        params: {
            idCorp: planData.organization_id,
            id
        },
        opts: {
            signal: abortController.current.signal,
        }}, (res) => {
            setCurrentAnnotation(res.data)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getAllAnnotation();
    }, []);


    const onReady = (e) => {
        setIsReady(true)

        if(containerRef.current){
            containerRef.current.querySelectorAll("[data-container-editor] a").forEach(element => {
                element.addEventListener("click", linkForceBlank)
            })
        }
    }

    const linkForceBlank = (e) => {
        e.preventDefault()
        const href = e.target.href
        if(href)
            window.open(href, '_blank', "noopener,noreferrer");
    }

    return (
        <div ref={containerRef} className={"main_agenda_reader_component" + className} data-align-left={alignLeft}>
            <CollapseItem initialValue={false}>
                <CollapseItem.Toggle>
                    {({isOpen, isClosed}) =>
                        <div className="main_header_reader">
                            <div className="flex items-center gap-[6px] text-[14px] font-bold text-dark-60">
                                <div
                                    className="flex bg-white rounded-[2px] shadow-[0_1px_2px] shadow-dark-100/10 p-[2px] flex-none"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setAlignLeft(align => !align)
                                    }}
                                >
                                    <div
                                        className={"icon_16 default arrow_extreme " + (alignLeft ? "right" : "left")}></div>
                                </div>

                                <div className={"icon_16 default book icon_mask !bg-dark-60 flex-none"}></div>
                                <div className="line-clamp-1">
                                    {agenda.name}
                                </div>
                            </div>

                            <div className="icon_16 default collapse_icon icon_mask !bg-interactive-100 flex-none"></div>
                        </div>
                    }
                </CollapseItem.Toggle>

                <CollapseItem.Content className="flex overflow-hidden flex-1">
                    <OverlayScrollbarsComponent defer options={{ scrollbars: { autoHide: "scroll" }}} className="flex-1 bg-white">

                        <Accordion.Root
                            className=""
                            type="single"
                            defaultValue=""
                            onValueChange={(id) => id && getAnnotationDetail(id)}
                            collapsible
                        >
                            {annotations.map((annotation, index) => (
                                <Accordion.Item
                                    key={annotation.id}
                                    value={annotation.id}
                                    className="shadow-[0_1px_0] shadow-dark-10 group"
                                >
                                    <Accordion.Trigger asChild={true}>
                                        <div className="text-dark-100/50 uppercase flex justify-between items-center p-[8px_12px] cursor-pointer"
                                             key={annotation.id}
                                        >
                                            {formatDate(annotation.date, "DD MMM YYYY")}
                                            <span className="icon_16 chevron default icon_mask !bg-dark-70 group-data-[state=open]:rotate-180"></span>
                                        </div>
                                    </Accordion.Trigger>
                                    <Accordion.Content className="data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden" key={currentAnnotation?.id}>
                                        {isLoading ?
                                            <div className="h-[200px] flex items-center justify-center">
                                                <div className="icon_16 default loader opacity-70"></div>
                                            </div>
                                            :
                                            currentAnnotation?.editorjs?.blocks &&
                                                <div className={"main_editor " + (!isReady ? "pointer-events-none" : "")}>
                                                    <ContainerEditorJS
                                                        onReady={onReady}
                                                        tools={tools}
                                                        readOnly={true}
                                                        defaultValue={currentAnnotation?.editorjs}
                                                        minHeight={0}
                                                    />
                                                </div>
                                        }
                                    </Accordion.Content>
                                </Accordion.Item>
                            ))}
                        </Accordion.Root>
                    </OverlayScrollbarsComponent>
                </CollapseItem.Content>
            </CollapseItem>
        </div>
    );
};

export default AgendaReader;