import { useContext } from 'react';
import GlobalContext from '../context/global.context';


function useUserSession() {

    const { user, setUser, organization, setOrganization } = useContext(GlobalContext)


    const logout = () => {
        window.location = `${process.env.REACT_APP_HOST_PLATFORM}`
    }
    
    return { user, logout, organization }
}

export default useUserSession;