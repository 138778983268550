import c from "./TableContainer.module.scss"
import Pagination from "../utils/Pagination";
import FilterDropdown from '../utils/FilterDropdown';
import Table from "../utils/table/Table";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getOrganizations } from "../../services/organizations.service";
import debounce from "lodash.debounce";
import { getAgendas, getAnnotations } from "../../services/agendas.service";
import { getTemplatePlans } from "../../services/plans-template.service";
import CrudAgendaModal from "../popups/CrudAgendaPopup";
import ConfirmModal from '../popups/ConfirmPopup';
import useNotification from "../../hooks/notification.hook";
import { deleteAgenda } from "../../services/agendas.service";
import useLoadingHelper from "../../hooks/loading.hook";
import LoadingBox from "../loading/LoadingBox";
import EmptyBox from "../emptystate/EmptyBox";
import AgendaDownloadModal from "../popups/AgendaDownloadPopup";




function TableAgendas({ role }) {

    const { queueLoading, addQueue, removeQueue, initStatus, setInitStatus } = useLoadingHelper()

    const [ annotations, setAnnotations ] = useState([])

    const [ loadingAnnotations, setLoadingAnnotations ] = useState(false)

    const [ openDownloadAgenda, setOpenDownloadAgenda ] = useState(false)

    const [ agendas, setAgendas ] = useState([])

    const [ plans, setPlans ] = useState([])
    
    const [ organizations, setOrganizations ] = useState([])
    
    const [ selectedAgenda, setSelectedAgenda ] = useState(null)

    const [ filtersLoading, setFiltersLoading ] = useState({
        plans: false,
        organizations: false,
    })
    
    const { openNotification, helperErrorNotification } = useNotification()

    const [ forceUpdate, setForceUpdate ] = useState(0)

    const [ openCrud, setOpenCrud ] = useState(false)
    
    const [ openConfirm, setOpenConfirm ] = useState(false)

    const [ loaderDelete, setLoaderDelete ] = useState(false)

    const [ queryParams, setQueryParams ] = useState({
        page: 1,
        filter: {
            name : "",
            "organizationPlan.template_plan_id": [],
            "organizationPlan.organization_id": [],
        }
    })

    const [ pageData, setPageData ] = useState({
        last: 0,
        current: 0,
        total: 0
    })



    /**
     * Fetch data
     */
    useEffect(() => {

        getListAgendas()

    }, [queryParams, forceUpdate])


    /**
     * Fetch data
     */
    useEffect(() => {
        getFilters()
    }, [])



    /**
     * Get list annotations
     */
    const getListAnnotations = (id, idCorp) => {

        setLoadingAnnotations(true)

        getAnnotations({ id, idCorp }, (res) => {

            setAnnotations(res.data)
            setLoadingAnnotations(false)

        }, () => setLoadingAnnotations(false))
    }







    /**
     * Get List agendas
     */
    const getListAgendas = () => {

        const body = {
            include: [
                "annotationsCount",
                "organizationPlan.organization",
                "organizationPlan.templatePlan",
            ],
            ...queryParams
        }

        addQueue()
        
        // Fetch Api
        getAgendas(body, (res) => {

            setAgendas(res.data)

            removeQueue()

            setInitStatus(true)

            setPageData({
                last: res.meta.last_page,
                current: res.meta.current_page,
                total: res.meta.total
            })
        })
    }





    /**
     * Get List agendas
     */
    const getFilters = () => {

        setFiltersLoading({
            plans: true,
            organizations: true
        })

        if(role === "admin"){
            const getAllPlans = async (page = 1, loadMore = false) => {

                // Fetch Api
                await getTemplatePlans({ per_page: 500, page }, async (res) => {


                    loadMore ? setPlans(plans => [...plans,...res.data]) : setPlans(res.data);

                    if(res.meta.current_page < res.meta.last_page){
                        await getAllPlans(res.meta.current_page + 1, true)
                    }else{
                        setFiltersLoading(f => ({ ...f, plans: false }))
                    }
                })
            }

            getAllPlans();
        }


        if(role !== "organization"){
            const getAllCorps = async (page = 1, loadMore = false) => {

                // Fetch Api
                await getOrganizations({ per_page: 500, page}, async (res) => {

                    loadMore ? setOrganizations(corps => [...corps,...res.data]) : setOrganizations(res.data);

                    if(res.meta.current_page < res.meta.last_page){
                        await getAllCorps(res.meta.current_page + 1, true)
                    }else{
                        setFiltersLoading(f => ({ ...f, organizations: false }))
                    }
                })
            }

            getAllCorps();
        }
    }







    /**
     * Update page
     * @param {*} newPage 
     * @returns 
     */
    const updatePage = (newPage) => setQueryParams(q => ({...q, page: newPage}))



    /**
	 * Debounce Search
	 */
	const debouncedSearch = debounce(onSearch, 300)


    /**
     * Search event function 
     */
    function onSearch(e) {

        const searchModel = e.target.value

        if(searchModel.trim().length > 0 || queryParams.filter.name != null){
            setQueryParams(q => ({...q, page:1, filter: { ...q.filter, name: searchModel.trim().length > 0 ? searchModel : ''}}))
        }
    }



    /**
     * Filter by plan
     * @param {*} items 
     */
    const filterByPlan = (items) => {
        setQueryParams(q => ({...q, page:1, filter: { ...q.filter, "organizationPlan.template_plan_id" : [...items]}}))
    }


    /**
     * Filter by corp
     * @param {*} items 
     */
    const filterByCorp = (items) => {
        setQueryParams(q => ({...q, page:1, filter: { ...q.filter, "organizationPlan.organization_id" : [...items]}}))
    }


    
    /**
     * Reset Filter
     */
    const resetFilter = () => {
        setQueryParams(q => ({...q, page:1, filter: { ...q.filter, "organizationPlan.template_plan_id" : [], "organizationPlan.organization_id" : [], }}))
    }


    /**
     * On Edit
     * @param {*} item 
     */
    const onEdit = (item) => {
        setSelectedAgenda(item)
        setOpenCrud(true)
    }

    /**
     * Delete agenda Request
     * @param {*} item 
     */
    const deleteRequest = (item) => {
        setSelectedAgenda(item)
        setOpenConfirm(true)
    }


    /**
     * New Agenda Request
     */
    const newAgendaRequest = () => {
        setSelectedAgenda(null)
        setOpenCrud(true)
    }


    /**
     * New Agenda Request
     */
    const deleteHandler = () => {
        
        if(!loaderDelete){

            setLoaderDelete(true)

            deleteAgenda({ idCorp: selectedAgenda.organization_plan.organization_id, id: selectedAgenda.id}, (res) => {

                setLoaderDelete(false)

                openNotification({text: "Diario eliminato con successo!", status: "success"})

                setForceUpdate(i => i+1)

                setOpenConfirm(false)

            }, (err) => {

                helperErrorNotification(err)
                setLoaderDelete(false)
            })
        }
    }



    const downloadAgenda = (agenda) => {
        setSelectedAgenda(agenda)
        setAnnotations([])
        setOpenDownloadAgenda(true)
        getListAnnotations(agenda.id, agenda.organization_plan.organization_id)
    }


    const linkDetail = (el) => {
        if(role === "admin"){
            return `/admin/agendas/${el.organization_plan.organization_id}/${el.id}`
        }

        if(role === "reseller"){
            return `/reseller/agendas/${el.organization_plan.organization_id}/${el.id}`
        }

        if(role === "organization"){
            return `/organization/agendas/${el.organization_plan.organization_id}/${el.id}`
        }

        return ""
    }



    return (
        <>
            <div className={c.main_intro}>
                <div className={c.left_content}>
                    <div className={c.main_fitlers}>

                        {role === "admin" &&
                            <FilterDropdown
                                options={plans.map(el => ({ label: el.name, value:el.id }))}
                                activeSelected={queryParams.filter["organizationPlan.template_plan_id"]}
                                onApply={(items) => filterByPlan(items)}
                                searchFilter
                                toggleLabel="Piano"
                                isLoading={filtersLoading.plans}
                            />
                        }

                        {role !== "organization" &&
                            <FilterDropdown
                                options={organizations.map(el => ({ label: el.name, value:el.id }))}
                                activeSelected={queryParams.filter["organizationPlan.organization_id"]}
                                onApply={(items) => filterByCorp(items)}
                                toggleLabel="Ente"
                                searchFilter
                                isLoading={filtersLoading.organizations}
                            />
                        }

                        {(queryParams?.filter["organizationPlan.organization_id"].length > 0 || queryParams?.filter["organizationPlan.template_plan_id"].length > 0) &&
                        
                            <div className={c.main_reset} onClick={resetFilter}>
                                <div className={c.main_icon}>
                                    <span className="icon_16 default cross icon_mask !bg-interactive-100"></span>
                                </div>
                                Resetta
                            </div>
                        }
                    </div>
                </div>
                <div className={c.right_content}>
                    <div className="wrap_input_landing icon">
                        <input type="text" onChange={debouncedSearch} className="landing" placeholder="Ricerca..." />
                        <span className="icon_16 default search"></span>
                    </div>
                    {role === "admin" &&
                        <button onClick={newAgendaRequest} className="button fill primary small">Nuovo</button>
                    }
                </div>
            </div>

            <div className="relative">

                {agendas.length > 0 ?
                    <div className="fade_in">
                        <Table>
                            <Table.Head>
                                <Table.Column>Nome</Table.Column>
                                {role !== "organization" &&
                                    <Table.Column>Ente</Table.Column>
                                }
                                <Table.Column>Piano</Table.Column>
                                <Table.Column>Note</Table.Column>
                                <Table.Column></Table.Column>
                            </Table.Head>
                            <Table.Body>
                                {agendas.map((el, index) => (
                                    <Table.Row key={index} className="action_hover_list">

                                        <Table.Cell width={250}>{el.name}</Table.Cell>

                                        {role !== "organization" &&
                                            <Table.Cell width={150}>
                                                <div className="name_logo">
                                                    {el?.organization_plan?.organization?.logo_url ?
                                                        <img src={el.organization_plan.organization.logo_url} className="logo" />
                                                        :
                                                        <div className="main_icon">
                                                            <span className="icon_16 default entity"></span>
                                                        </div>
                                                    }
                                                    {el.organization_plan.organization.name}
                                                </div>
                                            </Table.Cell>
                                        }

                                        <Table.Cell width={150}>
                                            {el.organization_plan.template_plan.name}
                                        </Table.Cell>

                                        <Table.Cell width={20}>
                                            <div className="spacing_items">
                                                <div className="main_icon_text">
                                                    <span className="icon_16 default note"></span>
                                                    {el.annotations_count}
                                                </div>
                                            </div>
                                        </Table.Cell>
                                        
                                        <Table.Cell width={10}>
                                            <div className="spacing_items">
                                                <div className="action_list">
                                                    {role === "admin" &&
                                                        <>
                                                            <button className="action" onClick={() => onEdit(el)} data-tooltip-id="tooltip_small_top" data-tooltip-offset={10}  data-tooltip-content="Modifica">
                                                                <span className="icon_16 default edit icon_mask !bg-interactive-100"></span>
                                                            </button>

                                                            <button className="action" onClick={() => deleteRequest(el)} data-tooltip-id="tooltip_small_top" data-tooltip-offset={10}  data-tooltip-content="Elimina">
                                                                <span className="icon_16 default delete icon_mask !bg-interactive-100"></span>
                                                            </button>
                                                        </>
                                                    }
                                                    <button className="action" onClick={() => downloadAgenda(el)} data-tooltip-id="tooltip_small_top" data-tooltip-offset={10}  data-tooltip-content="Scarica">
                                                        <span className="icon_16 default download icon_mask !bg-interactive-100"></span>
                                                    </button>
                                                </div>

                                                <Link to={linkDetail(el)} className="button fill secondary x_small">
                                                    <span className="icon_16 default wrench icon_mask !bg-primary-100 -ml-2"></span>
                                                    Modifica
                                                </Link>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>

                        <Pagination
                            last={pageData.last}
                            current={pageData.current}
                            total={pageData.total}
                            onChange={updatePage}
                        /> 
                    </div>
                    :
                    initStatus &&
                        <EmptyBox
                            icon="icon_75 default archive_note"
                            title="Nessun risultato trovato"
                            description="Qui visualizzerai la lista di tutte le agende"
                        />
                }

                {queueLoading.length > 0 && <LoadingBox blocked={!initStatus} />}
            </div>


            <CrudAgendaModal 
                agendaModel={selectedAgenda} 
                templatePlans={plans} 
                open={openCrud} 
                setOpen={setOpenCrud}
                onInsert={() => { setForceUpdate(i => i+1); setOpenCrud(false) }}
                onEdit={() => { setForceUpdate(i => i+1); setOpenCrud(false) }}
            />

            <ConfirmModal 
                open={openConfirm}
                setOpen={setOpenConfirm}
                title="Conferma eliminazione"
                subtitle="Diario"
                icon="icon_16 default book"
                description={selectedAgenda?.name ? selectedAgenda.name : ""}
                alertMessage="Attenzione! Questa operazione è irreversibile! <b>Sei sicuro di procedere?</b>"
                ctaLabel="Elimina"
                onConfirm={deleteHandler}
            />


            <AgendaDownloadModal 
                open={openDownloadAgenda}
                setOpen={setOpenDownloadAgenda}
                annotations={annotations}
                isLoadingAnnotations={loadingAnnotations}
                idAgenda={selectedAgenda?.id}
                idOrganization={selectedAgenda?.organization_plan.organization_id}
            />
        </>
    );
}

export default TableAgendas;