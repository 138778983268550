import { Tooltip } from "react-tooltip";

function LayoutSidebar({children}) {
    return (
        <div className="layout_sidebar">
            {children}
            <div id="modal-container-el"></div>
            <Tooltip id="tooltip_small_top" classNameArrow="tooltipster_arrow" className="tooltipster small" />
        </div>
    );
}

export default LayoutSidebar;