import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route, redirect} from "react-router-dom";
import PlansTemplate from "./pages/PlansTemplate";
import DetailAgency from "./pages/agencies/DetailAgency";
import PlanDetail from "./pages/plan/PlanDetail";
import PlanTemplateContainer from "./pages/plan/PlanTemplateContainer";
import ListAgencies from "./pages/agencies/ListAgencies";
import PlanConfPreview from "./pages/plan/PlanConfPreview";
import { checkPlanTemplate, getPlanTemplate, loaderCopyTemplate, loaderEntityDetail, loaderEntityPlan, loaderCompilePlan, loaderListAgenda, loaderAgendaDetail, redirectUser, checkAdmin, checkReseller, checkOrganization, onganizationDetail, authSession, checkSession,loaderOrganizationPlan, loaderOrganizationCompile } from "./helper/loader.helper";
import PageContainer from "./pages/PageContainer";
import RedirectToken from "./pages/RedirectToken";
import PlanCopyConfContainer from "./pages/plan/PlanCopyConfContainer";
import PlanCompileContainer from "./pages/plan/PlanCompileContainer";
import ListAgendas from "./pages/agenda/ListAgendas";
import AgendaDetail from "./pages/agenda/AgendaDetail";
import Custom404 from "./pages/Custom404";
import HomeRedirect from "./pages/HomeRedirect";


const router = createBrowserRouter(
	createRoutesFromElements(
    <>
      <Route path="/" loader={redirectUser} element={<HomeRedirect/>} errorElement={<Custom404 />} />
      <Route path="/auth-session/:sessionToken/:idUser" loader={authSession} element={<RedirectToken />} />
      <Route path="/" loader={checkSession} errorElement={<Custom404 />} element={<PageContainer />}>

        {/* Admin */}
        <Route path="admin" loader={checkAdmin}>
          <Route path="plans" element={<PlansTemplate />} />
          {/* Plan Template */}
          <Route path='plans/template/detail/:id' loader={getPlanTemplate} element={<PlanConfPreview />} />
          <Route path='plans/template/configuration/:id' loader={checkPlanTemplate} element={<PlanTemplateContainer isAdmin />} />
          <Route path='plans/template/copy/:idCorp/:idPlan' loader={loaderCopyTemplate} element={<PlanCopyConfContainer />} />

          {/* Entities */}
          <Route path='entities' element={<ListAgencies />} />
          <Route path='entities/entity/:id' loader={loaderEntityDetail} element={<DetailAgency />} />
          <Route path='entities/entity/:id/plan/:idPlan' loader={loaderEntityPlan} element={<PlanDetail />} />


          <Route path='entities/entity/:id/plan/:idPlan/compile' loader={loaderCompilePlan} element={<PlanCompileContainer />} />

          {/* Diary */}
          <Route path='agendas' loader={loaderListAgenda} element={<ListAgendas role="admin" />} />
          <Route path='agendas/:idCorp/:id' loader={loaderAgendaDetail} element={<AgendaDetail role="admin" />} />
        </Route>



        {/* Reseller */}
        <Route path="reseller" loader={checkReseller}>
          <Route index element={<ListAgencies isReseller={true} />} />
          <Route path=':id' loader={loaderEntityDetail} element={<DetailAgency role="reseller" />} />
          <Route path=':id/plan/:idPlan' loader={loaderEntityPlan} element={<PlanDetail role="reseller" />} />
          <Route path=':id/plan/:idPlan/compile' loader={loaderCompilePlan} element={<PlanCompileContainer role="reseller" />} />

          <Route path='agendas' loader={loaderListAgenda} element={<ListAgendas role="reseller" />} />
          <Route path='agendas/:idCorp/:id' loader={loaderAgendaDetail} element={<AgendaDetail role="reseller" />} />
        </Route>



        {/* Organization */}
        <Route path="organization" loader={checkOrganization}>
          <Route index loader={onganizationDetail} element={<DetailAgency role="organization" />} />
          <Route path='plan/:idPlan' loader={loaderOrganizationPlan} element={<PlanDetail role="organization" />} />
          <Route path='plan/:idPlan/compile' loader={loaderOrganizationCompile} element={<PlanCompileContainer role="organization" />} />

          <Route path='agendas' loader={loaderListAgenda} element={<ListAgendas role="organization" />} />
          <Route path='agendas/:idCorp/:id' loader={loaderAgendaDetail} element={<AgendaDetail role="organization" />} />
        </Route>

      </Route>

    </>
	)
);



function App() {

  return (<RouterProvider router={router} />);
}

export default App;
