import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import FilterDropdown from "./FilterDropdown";
import EmptyBox from "../emptystate/EmptyBox";
import Table from "./table/Table";
import { useContext } from "react";
import LoadingBox from "../loading/LoadingBox";
import OrganizationsPickerContext from "../../context/organizations-picker.context";

const OrganizationsPickerTable = ({ loadingData }) => {

    const {
        filteredList, 
        setFilteredList, 
        filterType, 
        setFilterType, 
        selectedEntity, 
        setSelectedEntity, 
        searchInput, 
        organizations, 
        types
    } = useContext(OrganizationsPickerContext)

    /**
     * on Check entity
     * @param {*} e 
     * @param {*} entity 
     */
    function onCheck(e,entity){
        
        if(e.target.checked){
            if(!selectedEntity.find(el => el.id == entity.id))
                setSelectedEntity(items => [...items, entity])
        }else{
            removeSelected(entity)
        }
    }


    /**
     * Remove selected entity
     * @param {*} entity
     */
    const removeSelected = (entity) => setSelectedEntity(items => items.filter(item => item.id != entity.id))



    /**
     * Selected all entity
     * @param {*} entity
     */
    function toggleSelectionAll(e){
        if(e.target.checked){
            const newItems = filteredList.filter(i => !selectedEntity.find(s => s.id == i.id))
            setSelectedEntity(items => [...items, ...newItems])
        }else{
            const newItems = selectedEntity.filter(i => !filteredList.find(s => s.id == i.id))
            setSelectedEntity([...newItems])
        }
    }




    /**
     * On search handler
     * @param {*} e 
     */
    const onSearch = (e) => {
        const searchModel = e.target.value.trim().toLowerCase()

        if(searchModel.length > 0){
            setFilteredList(organizations
                .filter(org => org.name.toLowerCase().includes(searchModel) || org?.reseller?.name.toLowerCase().includes(searchModel))
                .filter(org => filterType.includes(org.organization_type.name) || filterType.length == 0)
            )
        }else{
            setFilteredList(organizations.filter(org => filterType.includes(org.organization_type.name) || filterType.length == 0))
        }
    }


    /**
	 * Debounce Search
	 */
    const isSelectAll = () => filteredList.reduce((a,item) => a && !!selectedEntity.find(el => el.id == item.id) , true)



    /**
     * Filter by type
     * @param {*} items 
     */
    const filterByType = (items) => {

        const search = searchInput.current.value.trim().toLowerCase()

        setFilterType(items)

        setFilteredList(organizations
            .filter(org => org.name.toLowerCase().includes(search) || org?.reseller?.name.toLowerCase().includes(search))
            .filter(org => items.includes(org.organization_type.name) || items.length == 0)
        )
    }



    return (
        <div className="main_comp_organizations_picker">
            {/* List Organizations */}
            <div className="main_list_entities">

                {/* Heading  */}
                <div className="main_heading_list">

                    <div className="main_filters">
                            
                        <div>
                            <FilterDropdown
                                options={types.map(el => ({ label: el.name, value:el.name }))}
                                activeSelected={filterType}
                                onApply={(items) => filterByType(items)}
                                toggleLabel="Tipologia"
                                isLoading={loadingData?.types}
                                searchFilter
                            />
                        </div>

                        <div className="wrap_input_landing icon">
                            <input type="text" onChange={onSearch} ref={searchInput} className="landing max-w-[200px]" placeholder="Ricerca..." />
                            <span className="icon_16 default search"></span>
                        </div>
                    </div>
                </div>

                {loadingData?.organizations ?
                    <div>
                        {/* Loading */}
                        <LoadingBox blocked="true"></LoadingBox>
                    </div>
                :
                (filteredList.length > 0 ?
                    <OverlayScrollbarsComponent defer options={{ scrollbars: { autoHide: "scroll" }}} className="main_table_entity">
                        
                        {/* Table organizations */}
                        <Table className="layout_popup">

                            {/* Table Heade */}
                            <Table.Head className="sticky_head">
                                <Table.Column className="!pl-4 !pb-3">
                                    <div className={"check_landing small"}>
                                        <input type="checkbox" checked={isSelectAll()} onChange={(e) => toggleSelectionAll(e)} id={"select_all_entity"} />
                                        <label htmlFor={"select_all_entity"} className="only_toggle"></label>
                                    </div>
                                </Table.Column>
                                <Table.Column className="!pb-3">Nome</Table.Column>
                                <Table.Column className="!pb-3">Rivenditore</Table.Column>
                                <Table.Column></Table.Column>
                            </Table.Head>

                            {/* Table Body */}
                            <Table.Body>
                                {filteredList.map((organization, index) => (
                                    <Table.Row key={index}>

                                        <Table.Cell width={20}>
                                            <div className={"check_landing small"}>
                                                <input type="checkbox" checked={!!selectedEntity.find(item => item.id == organization.id)} onChange={(e) => onCheck(e, organization)} id={"select-entity-" + organization.id} />
                                                <label htmlFor={"select-entity-" + organization.id} className="only_toggle"></label>
                                            </div>
                                        </Table.Cell>

                                        <Table.Cell width={220}>
                                            <div className="name_logo">
                                                {organization.logo_url ? 
                                                    <img src={organization.logo_url} className="logo" />
                                                    :
                                                    <div className="main_icon">
                                                        <span className="icon_16 default entity"></span>
                                                    </div>
                                                }
                                                {organization.name}
                                            </div>
                                        </Table.Cell>

                                        <Table.Cell width={250}>{organization?.reseller?.name ? organization.reseller.name : '–'}</Table.Cell>
                                        
                                        <Table.Cell ></Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </OverlayScrollbarsComponent>
                    :
                    <div className="main_empty_container">
                        
                        {/* Empty organizations */}
                        <EmptyBox
                            icon="icon_75 default archive_entity"
                            title="Nessun ente disponibile"
                            description="Non ci sono enti abilitati."
                        />
                    </div>)
                }
            </div>

            {/* Selected List */}
            <div className="main_list_selected">
                                                
                {selectedEntity.length > 0 ?
                    <>
                        {/* Counter */}
                        <div className="main_counter">
                            <h2 className="title">Enti selezionati: <b>{selectedEntity.length}</b></h2>
                        </div>

                        <div className="list_container">
                            <OverlayScrollbarsComponent defer options={{ scrollbars: { autoHide: "scroll" }}} className="main_list_items">
                                <div className="wrap_list_items">
                                    {selectedEntity.map((entity, i) => (
                                        <div key={i} className="item_entity">
                                            <div className="main_name">
                                                {entity.logo_url ? 
                                                    <img src={entity.logo_url} className="logo" />
                                                    :
                                                    <div className="main_icon">
                                                        <span className="icon_16 default entity"></span>
                                                    </div>
                                                }
                                                <div className="name">{entity.name}</div>
                                            </div>
                                            <div className="main_delete" onClick={() => removeSelected(entity)}>
                                                <span className="icon_16 delete default icon_mask !bg-interactive-100"></span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </OverlayScrollbarsComponent>

                        </div>
                    </>
                :
                    <div className="main_empty_container">

                        <EmptyBox 
                            icon="icon_75 default list_boxs"
                            title="Seleziona uno o più enti"
                            description="Qui vedrai gli enti selezionati"
                        />
                    </div>
                }
            </div>
        </div>
    );
}

export default OrganizationsPickerTable;