import LayoutNav from "../components/layout/LayoutNav";
import SectionBox from "../components/section-box/SectionBox";
import { getTemplatePlans } from "../services/plans-template.service";
import { useEffect, useState } from "react";
import debounce from "lodash.debounce";
import TableTemplatePlans from "../components/tables/TableTemplatePlans";
import LoadingBox from '../components/loading/LoadingBox';
import useLoadingHelper from "../hooks/loading.hook";

function PlansTemplate() {

    const { queueLoading, addQueue, removeQueue, initStatus, setInitStatus } = useLoadingHelper()

    const [plansList, setPlansList] = useState([])

    const [ queryParams, setQueryParams ] = useState({
        page: 1,
        filter: {
            search : ""
        }
    })

    const [ pageTable, setPageTable ] = useState({
        total: 0,
        current: 0,
        last: 0
    })

    /**
     * Update List
     */
    useEffect(() => {
        getList()
    }, [queryParams])



    /**
     * Get List Plans
     */
    function getList(){

        const body = {
            include: ["chaptersCount","goalsCount","requirementsCount","chapterContentsCount","activeOrganizationPlansCount"],
            ...queryParams
        }

        addQueue()

        getTemplatePlans(body, function(res){
            setPlansList(res.data)

            removeQueue()

            setInitStatus(true)
            setPageTable({
                total: res.meta.total,
                current: res.meta.current_page,
                last: res.meta.last_page,
            })
        })
    }


    /**
     * Update page
     * @param {*} newPage 
     * @returns 
     */
    const updatePage = (newPage) => setQueryParams(q => ({...q, page: newPage}))


    /**
	 * Debounce Search
	 */
	const debouncedSearch = debounce(onSearch, 300)


    /**
     * Search event function 
     */
    function onSearch(searchModel) {

        if(searchModel.trim().length > 0 || queryParams.filter.search != null){
            setQueryParams(q => ({...q, filter: { search: searchModel.trim().length > 0 ? searchModel : ''}}))
        }
    }


    
    const forceUpdateList = () => setQueryParams({ page:1, filter: { search: ""}})

    
    return (
        <LayoutNav>

            <div className="main_page_box grid_layout">
                <SectionBox title="Commesse">
                    <TableTemplatePlans 
                        isAdmin
                        updatePage={updatePage}
                        plans={plansList}
                        pageData={pageTable}
                        onSearch={debouncedSearch}
                        onInsert={forceUpdateList}
                        onEdit={forceUpdateList}
                        onDelete={forceUpdateList}
                        onClone={forceUpdateList}
                        isLoading={queueLoading}
                        isInit={initStatus}
                        />
                </SectionBox>
            </div>

        </LayoutNav>
    );
}

export default PlansTemplate;