import { useLoaderData } from "react-router-dom";
import LayoutNav from "../../components/layout/LayoutNav";
import ItemSummary from "../../components/plans/ItemSummary";
import SectionBox from "../../components/section-box/SectionBox";
import TableConfEntities from "../../components/tables/TableConfEntities";
import DropDown from "../../components/utils/dropdown-item/DropDown";
import { useEffect, useRef, useState } from "react";
import EmailMassiveModal from "../../components/popups/plan-template/EmailMassivePopup";
import {
  getOrganizationTypes,
  getOrganizations,
} from "../../services/organizations.service";
import { OrganizationsPickerContextProvider } from "../../context/organizations-picker.context";
import DownloadMultipleModal from "../../components/popups/DownloadMultiplePopup";
import { formatTemplatePlanDownloadObject } from "../../helper/formatter.helper";
import statusPlanPreview from "../../components/plans/StatusPlanPreview";
import StatusPlanPreview from "../../components/plans/StatusPlanPreview";
import StatusPlanCrud from "../../components/popups/StatusPlanCrud";
import {
  exportTemplateCreate,
  exportTemplateShow,
} from "../../services/plans-template.service";
import useNotification from "../../hooks/notification.hook";
import LoaderPage from "../../components/utils/LoaderPage";
import PresetsPlanCrud from "../../components/popups/PresetsPlanCrud";
import FieldsPlanCrud from "../../components/popups/FieldsPlanCrud";

function PlanConfPreview() {
  const planData = useLoaderData();

  const [openEmailMassvie, setOpenEmailMassvie] = useState(false);

  const { openNotification, helperErrorNotification } = useNotification();

  const timerDownload = useRef(null);

  const [loadingDownload, setLoadingDownload] = useState();

  const [openDownloadMassvie, setOpenDownloadMassvie] = useState(false);

  const [listOrganizations, setListOrganizations] = useState([]);

  const [listStatus, setListStatus] = useState(planData.requirement_statuses);

  const [listPresets, setListPresets] = useState(planData.print_templates);

  const [forceUpdate, setForceUpdate] = useState(0);

  const [isStatusCrudOpen, setIsStatusCrudOpen] = useState(false);

  const [isFieldsCrudOpen, setIsFieldsCrudOpen] = useState(false);

  const [isPresetsCrudOpen, setIsPresetsCrudOpen] = useState(false);

  const [orgTypes, setOrgTypes] = useState([]);

  const [loadingData, setLoadingData] = useState({
    organizations: false,
    types: false,
  });

  /**
   * Create Download
   */
  const createDownload = () => {
    if (!loadingDownload) {
      setLoadingDownload(true);

      const body = {
        idTemplatePlan: planData.id,
      };

      exportTemplateCreate(
        body,
        (res) => {
          getDownload(res.data.id);
        },
        (error) => {
          helperErrorNotification(error);
          setLoadingDownload(false);
        },
      );
    }
  };

  /**
   * Get Download
   * @param {*} id
   */
  const getDownload = (id) => {
    exportTemplateShow(
      { idTemplatePlan: planData.id, id },
      (res) => {
        if (res.data.status === "pending") {
          timerDownload.current = setTimeout(() => {
            getDownload(id);
          }, 2000);
        } else {
          const link = document.body.appendChild(document.createElement("a"));
          link.href = res.data.url;
          link.target = "_blank";
          link.download = " ";
          link.click();
          document.body.removeChild(link);
          setLoadingDownload(false);
          openNotification({
            text: "Documento generato con successo!",
            status: "success",
          });
        }
      },
      (error) => {
        helperErrorNotification(error);
        setLoadingDownload(false);
      },
    );
  };

  const templateSubheader = () => (
    <DropDown>
      <DropDown.Toggle>
        <div className="button primary fill small">
          Altre azioni
          <div className="flex p-2 pb-[6px]">
            <span className="icon_16 default icon_mask !bg-white chevron -mb-1"></span>
          </div>
        </div>
      </DropDown.Toggle>

      {/* DropDown Content */}
      <DropDown.Content right={true}>
        {({ closeDropDown }) => (
          <div className="main_comp_dropdown_cotent_generic">
            <div className="main_list_options">
              {/* Email Action */}
              <div
                className="item_option"
                onClick={() => {
                  openModalEmailHanlder(true);
                  closeDropDown();
                }}
              >
                <div className="span label">Invio massivo piani</div>
                <div className="main_icon">
                  <span className="icon_16 default chevron right icon_mask !bg-dark-70"></span>
                </div>
              </div>

              {/* Download Action */}
              <div
                className="item_option"
                onClick={() => {
                  openModalDownloadHanlder(true);
                  closeDropDown();
                }}
              >
                <div className="span label">Download massivo</div>
                <div className="main_icon">
                  <span className="icon_16 default chevron right icon_mask !bg-dark-70"></span>
                </div>
              </div>

              {/* Download Action */}
              <div className="item_option" onClick={createDownload}>
                <div className="span label">Download template</div>
                <div className="main_icon">
                  <span className="icon_16 default chevron right icon_mask !bg-dark-70"></span>
                </div>
              </div>

              {/* Download Action */}
              <div
                className="item_option"
                onClick={() => setIsPresetsCrudOpen(true)}
              >
                <div className="span label">
                  Gestisci configurazioni di stampa
                </div>
                <div className="main_icon">
                  <span className="icon_16 default chevron right icon_mask !bg-dark-70"></span>
                </div>
              </div>


              {/* Custom fields */}
              <div
                  className="item_option"
                  onClick={() => setIsFieldsCrudOpen(true)}
              >
                <div className="span label">
                  Personalizza campi compilazione
                </div>
                <div className="main_icon">
                  <span className="icon_16 default chevron right icon_mask !bg-dark-70"></span>
                </div>
              </div>

            </div>
          </div>
        )}
      </DropDown.Content>
    </DropDown>
  );

  const templateBottom = () => (
    <StatusPlanPreview
      statusList={listStatus}
      onManage={() => setIsStatusCrudOpen(true)}
    />
  );

  /**
   * Get List organizations
   */
  const getListOrganizations = async () => {
    setLoadingData((l) => ({ ...l, organizations: true }));

    const body = {
      template_plan_id: planData.id,
      include: ["organizationType", "reseller", "organizationPlans"],
      filter: {
        template_plan_active: true,
      },
      per_page: 500,
    };

    const getAllCorps = async (page = 1, loadMore = false) => {
      // Fetch Api
      await getOrganizations({ ...body, page }, async (res) => {
        loadMore
          ? setListOrganizations((corps) => [...corps, ...res.data])
          : setListOrganizations(res.data);

        if (res.meta.current_page < res.meta.last_page) {
          await getAllCorps(res.meta.current_page + 1, true);
        } else {
          setLoadingData((l) => ({ ...l, organizations: false }));
        }
      });
    };

    await getAllCorps();
  };

  /**
   * Get Types
   */
  const getTypes = async () => {
    setLoadingData((l) => ({ ...l, types: true }));

    const getAllTypes = async (page = 1, loadMore = false) => {
      // Fetch Api
      await getOrganizationTypes({ page }, async (res) => {
        loadMore
          ? setOrgTypes((types) => [...types, ...res.data])
          : setOrgTypes(res.data);

        if (res.meta.current_page < res.meta.last_page) {
          await getAllTypes(res.meta.current_page + 1, true);
        } else {
          setLoadingData((l) => ({ ...l, types: false }));
        }
      });
    };

    await getAllTypes();
  };

  useEffect(() => {
    getListOrganizations();

    getTypes();

    return () => {
      clearTimeout(timerDownload.current);
    };
  }, []);

  /**
   * Open email massive popup
   */
  const openModalEmailHanlder = () => {
    if (forceUpdate > 0) {
      setForceUpdate(0);
      getListOrganizations();
    }

    setOpenEmailMassvie(true);
  };

  /**
   * Open download massive popup
   */
  const openModalDownloadHanlder = () => {
    if (forceUpdate > 0) {
      setForceUpdate(0);
      getListOrganizations();
    }

    setOpenDownloadMassvie(true);
  };

  return (
    <LayoutNav>
      <div className="main_page_box grid_layout">
        <SectionBox title="Gestione piano">
          <ItemSummary
            icon={`${!planData.diff_download?.length ? "piano" : "branch"}`}
            title={planData.name}
            counters={[
              { icon: "folder", value: planData.template_chapters_count },
              {
                icon: "text_box",
                value: planData.template_chapter_contents_count,
              },
              { icon: "layers", value: planData.template_goals_count },
              { icon: "layer", value: planData.template_requirements_count },
            ]}
            ctaConf={{
              label: "Configura",
              icon: "wrench",
              href: `/admin/plans/template/configuration/${planData.id}`,
            }}
            templateRight={templateSubheader}
            templateBottom={templateBottom}
          />
        </SectionBox>

        <SectionBox title="Enti">
          <TableConfEntities
            plan={planData}
            onToggleChange={() => setForceUpdate((f) => f + 1)}
          />
        </SectionBox>
      </div>

      <OrganizationsPickerContextProvider
        organizations={listOrganizations}
        types={orgTypes}
      >
        <EmailMassiveModal
          open={openEmailMassvie}
          setOpen={setOpenEmailMassvie}
        />
      </OrganizationsPickerContextProvider>

      <OrganizationsPickerContextProvider
        organizations={listOrganizations}
        types={orgTypes}
      >
        <DownloadMultipleModal
          open={openDownloadMassvie}
          setOpen={setOpenDownloadMassvie}
          isAdmin={true}
          template={formatTemplatePlanDownloadObject(planData.contents)}
          loadingData={loadingData}
          statuses={listStatus}
          presets={listPresets}
        />
      </OrganizationsPickerContextProvider>

      <StatusPlanCrud
        open={isStatusCrudOpen}
        setOpen={setIsStatusCrudOpen}
        planTemplate={planData}
        onListUpdate={(list) => setListStatus(list)}
      />

      <FieldsPlanCrud
        open={isFieldsCrudOpen}
        setOpen={setIsFieldsCrudOpen}
        planTemplate={planData}
      />

      <PresetsPlanCrud
        open={isPresetsCrudOpen}
        setOpen={setIsPresetsCrudOpen}
        planTemplate={planData}
        template={formatTemplatePlanDownloadObject(planData.contents)}
        statuses={listStatus}
        onListUpdate={(list) => setListPresets(list)}
      />

      {loadingDownload && <LoaderPage title="Generazione file in corso..." />}
    </LayoutNav>
  );
}

export default PlanConfPreview;
