import c from "./SubHeader.module.scss"
import { Link, useNavigate } from "react-router-dom";

function SubHeader({back, backHref, title, note, tagElement, logo, sublogo, subtitle, description, titleSmall, TemplateRightContent = () => {}}) {

    const navigate = useNavigate()

    return (
        <div className={c.main_subheader}>
            <div className={c.wrap_subheader}>

                <div className={c.left_content}>
                    {back && 
                        (backHref ? 
                            <Link to={backHref} className={c.main_back}>
                                <span className="icon_16 default arrow left icon_mask !bg-interactive-100"></span>
                            </Link>
                            :
                            <div onClick={() => navigate(-1)} className={c.main_back}>
                                <span className="icon_16 default arrow left icon_mask !bg-interactive-100"></span>
                            </div>)
                    }
                    <div className={c.main_intro}>
                        <div className={c.main_title}>
                            
                            <h2 className={`${c.title} ${titleSmall ? c.small : ''} ${logo ? "flex items-center gap-3": ''}`}>
                                {logo &&
                                    <div className={c.main_logo}>
                                        <img src={logo} className={c.logo} />
                                    </div>
                                }
                                {title}
                            </h2>
                            {subtitle && <h3 className={`${c.subtitle} ${sublogo ? "flex items-center gap-3": ''}`}>
                                {sublogo &&
                                    <div className={c.main_logo}>
                                        <img src={sublogo} className={c.logo} />
                                    </div>
                                }
                                {subtitle}
                            </h3>}
                        </div>
                        {description && 
                            <div className={c.main_desc}>
                                <span className={c.desc}>{description}</span>
                            </div>
                        }

                    </div>
                    {tagElement && 
                        <div className={c.main_tag}>
                            {tagElement.image ?
                                <img src={tagElement.image} className={c.logo} />
                                :
                                <div className={c.main_icon}>
                                    <span className={"icon_16 default " + tagElement.icon}></span>
                                </div>
                            }
                            {tagElement.name}
                        </div>
                    }
                    {note && <div className={c.note}>{note}</div> }
                </div>


                <div className={c.right_content}>
                    {TemplateRightContent()}
                </div>
            </div>
        </div>
    );
}

export default SubHeader;