import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import useNotification from "../../hooks/notification.hook";
import ModalContainer from "./ModalContainer";
import NumberInput from "../input/NumberInput";
import {
  createTemplatePlan,
  editTemplatePlan,
} from "../../services/plans-template.service";
import { useTranslation } from "react-i18next";
import ActionBtnItem from "../utils/ActionBtnItem";
import Table from "../utils/table/Table";

export default function CrudPlanModal({
  open,
  setOpen,
  planModel,
  onEdit = function () {},
  onInsert = function () {},
}) {
  const { t } = useTranslation();

  // Set message
  yup.setLocale({
    // use constant translation keys for messages without values
    mixed: {
      required: ({ path }) =>
        t("common:errors.required", { field: t(`common:fields.${path}`) }),
    },
    number: {
      moreThan: ({ path, more }) =>
        t("common:errors.more_than", {
          field: t(`common:fields.${path}`),
          value: more,
        }),
      lessThan: ({ path, less }) =>
        t("common:errors.less_than", {
          field: t(`common:fields.${path}`),
          value: less,
        }),
    },
  });

  // Schema Yup
  const schema = yup.object().shape({
    year: yup
      .number()
      .required()
      .moreThan(1899)
      .lessThan(2101)
      .typeError(({ path }) =>
        t("common:errors.not_number", { field: t(`common:fields.${path}`) }),
      ),
    name: yup.string().required(),
    name_ext: yup.string().required(),
    frontispiece: yup.mixed().required(),
    active: yup.bool(),
    working: yup.bool(),
  });

  const {
    register,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { openNotification, helperErrorNotification } = useNotification();

  const [loading, setLoading] = useState(false);

  const [frontispiece, setFrontispiece] = useState(null);

  const uploadFile = (e) => {
    if (!!e?.target?.files?.length) {
      setValue("frontispiece", e.target.files[0]);
      setFrontispiece(e?.target?.files[0]);
    }
    e.target.value = "";
  };

  /**
   * Form Handler
   * @param {form modal} data
   */
  async function formHandler(data) {
    const body = new FormData();
    body.append("year", data.year);
    body.append("name", data.name);
    body.append("description", data.name_ext);
    body.append("active", +data.active);
    body.append("working", +data.working);
    if (!data.frontispiece?.id) body.append("frontispiece", data.frontispiece);

    /*const body = {
      year: data.year,
      name: data.name,
      description: data.name_ext,
      active: data.active,
      working: data.working,
      ...(!data.frontispiece?.id && { frontispiece: data.frontispiece }),
    };*/

    if (!loading) {
      setLoading(true);

      if (!planModel?.id) {
        // Create plan
        createTemplatePlan(
          body,
          (res) => {
            setLoading(false);
            openNotification({
              text: "Piano creato con successo!",
              status: "success",
            });
            onInsert(res);
          },
          (error) => {
            helperErrorNotification(error);
            setLoading(false);
          },
        );
      } else {
        // Edit plan
        editTemplatePlan(
          { id: planModel.id, formData: body },
          (res) => {
            setLoading(false);
            openNotification({
              text: "Piano modificato con successo!",
              status: "success",
            });
            onEdit(res);
          },
          (error) => {
            helperErrorNotification(error);
            setLoading(false);
          },
        );
      }
    }
  }

  /**
   * Errors Form
   */
  useEffect(() => {
    if (Object.keys(errors).length) {
      const fields = Object.values(errors).map((er) => er.message);
      openNotification({ fields, status: "error" });
    }
  }, [errors]);

  /**
   * Init value
   */
  useEffect(() => {
    if (open) {
      clearErrors();
      setValue("name", planModel?.name ? planModel.name : "");
      setValue("name_ext", planModel?.description ? planModel.description : "");
      setValue("year", planModel?.year ? planModel.year : 0);
      setValue("active", planModel?.active ?? false);
      setValue("working", planModel?.working ?? false);
      setValue("frontispiece", planModel?.frontispiece ?? null);
      setFrontispiece(
        planModel?.frontispiece
          ? {
              id: planModel?.frontispiece.id,
              name: planModel?.frontispiece?.file_name,
            }
          : null,
      );
    }
  }, [open]);

  const guidesLinesFront = [
    {
      label: "Titolo piano",
      value: "${titolo}",
      example: "Piano 2024 - 2025",
      type: "string",
    },
    {
      label: "Logo ente",
      value: "${stemma}",
      example: "icon_16 default entity -my-[4px]",
      type: "icon",
    },
    {
      label: "Nome ente",
      value: "${ente}",
      example: "Abbadia Lariana",
      type: "string",
    },
    {
      label: "Email",
      value: "${email}",
      example: "abbadia.lariana@info.it",
      type: "string",
    },
    {
      label: "Pec",
      value: "${pec}",
      example: "abbadia.lariana@pec.it",
      type: "string",
    },
    {
      label: "Indirizzo",
      value: "${indirizzo}",
      example: "Via Roma, 32",
      type: "string",
    },
    { label: "Cap", value: "${cap}", example: "23821", type: "string" },
    {
      label: "Città",
      value: "${citta}",
      example: "Abbadia Lariana",
      type: "string",
    },
    {
      label: "Provincia",
      value: "${provincia}",
      example: "LECCO",
      type: "string",
    },
  ];

  return (
    <ModalContainer isOpen={open} setIsOpen={setOpen}>
      <div className={"main_modal_content medium_size_modal"}>
        <div className="main_close" onClick={() => setOpen(false)}>
          <span className="icon_10 default close icon_mask !bg-white"></span>
        </div>

        <div className="main_header_modal left_text">
          <span className="title">
            {planModel?.id ? "Modifica piano" : "Nuovo piano"}
          </span>
        </div>

        {/* Modal Form */}
        <div className={"main_body_modal"}>
          <form
            id="crud-template-plan-popup"
            className="global_form_landing"
            onSubmit={handleSubmit(formHandler)}
            noValidate
          >
            <div className="base child">
              {/* Year */}
              <div className="child small">
                <div className="wrap_input_landing">
                  <label htmlFor="crud_plan_year" className="label_input">
                    Anno
                  </label>
                  <NumberInput
                    min={0}
                    id="crud_plan_year"
                    className={"big" + (errors.year ? " err" : "")}
                    {...register("year")}
                  />
                </div>
              </div>

              {/* Name */}
              <div className="child">
                <div className="wrap_input_landing">
                  <label htmlFor="crud_plan_name" className="label_input">
                    Nome
                  </label>
                  <input
                    className={"landing big" + (errors.name ? " err" : "")}
                    id="crud_plan_name"
                    type="text"
                    {...register("name")}
                  />
                </div>
              </div>
            </div>

            {/* Name extended */}
            <div className="base">
              <div className="wrap_input_landing">
                <label htmlFor="crud_plan_name_ext" className="label_input">
                  Nome esteso
                </label>
                <input
                  className={"landing big" + (errors.name_ext ? " err" : "")}
                  id="crud_plan_name_ext"
                  type="text"
                  {...register("name_ext")}
                />
              </div>
            </div>

            {/* active */}
            <div className="base">
              <div className="toggle_landing">
                <input
                  type="checkbox"
                  id="crud-plan-active"
                  className="landing"
                  {...register("active")}
                />
                <label htmlFor="crud-plan-active">Abilita piano</label>
              </div>
            </div>

            {/* working */}
            <div className="base">
              <div className="toggle_landing">
                <input
                  type="checkbox"
                  id="crud-plan-working"
                  className="landing"
                  {...register("working")}
                />
                <label htmlFor="crud-plan-working">
                  Imposta piano in lavorazione
                </label>
              </div>
            </div>

            {/* Frontispiece */}
            <div className="base">
              <label htmlFor="crud_plan_frontispiece" className="label_input">
                Frontespizio
              </label>
              {frontispiece ? (
                <div className="flex bg-interactive-5 p-[11px_12px] justify-between rounded-[8px] text-interactive-100 font-semibold text-[12px] gap-[8px]">
                  <div className="flex gap-[2px] items-center w-0 flex-1">
                    <div className="icon_16 default paper_clip flex-none icon_mask !bg-interactive-100"></div>
                    <div className="truncate">{frontispiece.name}</div>
                  </div>
                  <ActionBtnItem
                    icon="delete"
                    onClick={() => {
                      setValue("frontispiece", null);
                      setFrontispiece(null);
                    }}
                    tooltip="Rimuovi"
                  />
                </div>
              ) : (
                <div className="">
                  <div className="text-13 pb-3 opacity-70">
                    Carica file .docx personalizzato*
                  </div>
                  <input
                    className={"hidden"}
                    id="crud_plan_frontispiece"
                    type="file"
                    accept=".docx"
                    onChange={uploadFile}
                  />
                  <label
                    htmlFor="crud_plan_frontispiece"
                    className={
                      "p-[10px_16px] block border border-dashed text-center text-12 font-bold rounded-[4px] cursor-pointer hover:bg-interactive-5 transition-colors" +
                      (errors.frontispiece
                        ? " border-red-100 text-red-100"
                        : " border-interactive-100 text-interactive-100")
                    }
                  >
                    Carica file
                  </label>
                </div>
              )}

              <div className="shadow-[inset_0_0_0_1px] shadow-dark-10 mt-5 overflow-hidden rounded-[4px]">
                <div className="p-[10px] flex items-center justify-between">
                  <div className="font-bold text-[13px]">
                    Linee guida per la personalizzazione
                  </div>

                  <a
                    target={"_blank"}
                    download
                    href={`${process.env.REACT_APP_API_HOST}/default-plan-frontispiece.docx`}
                    className="flex gap-[4px] items-center font-bold text-[12px] text-interactive-100 cursor-pointer hover:opacity-70"
                  >
                    <span className="icon_16 default download icon_mask !bg-interactive-100"></span>
                    Scarica esempio
                  </a>
                </div>

                <Table className="layout_popup">
                  {/* Table Head */}
                  <Table.Head>
                    <Table.Column className="!pl-[12px]">
                      <span className="text-10">Nome</span>
                    </Table.Column>
                    <Table.Column className="!text-left">
                      <span className="text-10">Campo nel file</span>
                    </Table.Column>
                    <Table.Column className="!text-left">
                      <span className="text-10">Esempio</span>
                    </Table.Column>
                  </Table.Head>

                  {/* Table Body */}
                  <Table.Body>
                    {guidesLinesFront.map((guide, index) => (
                      <Table.Row key={index}>
                        <Table.Cell width={"50%"}>
                          <div className="text-[12px] pl-1">{guide.label}</div>
                        </Table.Cell>

                        <Table.Cell width={"50%"}>
                          <div className="text-[12px] font-bold text-left">
                            {guide.value}
                          </div>
                        </Table.Cell>

                        <Table.Cell width={"50%"}>
                          <div className="text-[12px] text-left pr-1">
                            {guide.type === "icon" ? (
                              <span className={guide.example}></span>
                            ) : (
                              guide.example
                            )}
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </form>
        </div>

        <div className="main_footer_modal">
          <div className="right_content">
            {/* Main Cta */}

            <button
              form={"crud-template-plan-popup"}
              className={"button fill success" + (loading ? " loading" : "")}
            >
              <div className="load_content">Conferma</div>
              <div className="main_loader_icon fade_in"></div>
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}
