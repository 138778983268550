function useCommonHelper() {

	function animationClass(state, enterClass, exitClass){
        if(state === "entering")
            return enterClass

        if(state === "exiting")
            return exitClass
        
        return ''
    }


    /**
     * preventDragXAxis
     * @param {*} provided 
     * @param {*} snapshot 
     * @returns 
     */
    function preventDragXAxis(provided, snapshot){
        
        // Restrict dragging to vertical axis
        let transform = provided.draggableProps.style.transform;

        if (snapshot.isDragging && transform) {
            transform = transform.replace(/\(.+\,/, "(0,");
        }
        
        return {
            ...provided.draggableProps.style,
            transform,
        };
    }

    /**
     * Reorder items
     * @param {*} list 
     * @param {*} startIndex 
     * @param {*} endIndex 
     * @returns 
     */
    const reorderItems = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        const newItems = result.map((item, i) => {
            item.index = i+1;
            return item
        })

        return newItems;
    };





    const resizeTeaxtarea = (textarea) => {
        textarea.style.height = "0px";
            
        const scrollHeight = textarea.scrollHeight + 10;

        textarea.style.height = scrollHeight + "px"
    }
 


	return { animationClass, preventDragXAxis, reorderItems, resizeTeaxtarea };

}

export default useCommonHelper;
