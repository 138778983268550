import { useContext } from "react";
import { DropDownContext } from "../components/utils/dropdown-item/DropDown";

function useDropdown() {

    const { isOpen, handleToggle } = useContext(DropDownContext)


    return { isOpen, handleToggle }
}

export default useDropdown;