import dayjs from "dayjs";
import "dayjs/locale/it";

function useDateFormatter() {

    /**
     * Format date
     * @param {*} date
     * @param {*} format
     * @returns
     */
    function formatDate(date, format) {
        return dayjs(date).locale("it").format(format)
    }

    /**
     * Format date to timestamp
     * @param {*} date
     * @param {*} format
     * @returns
     */
    function formatDateToTimestamp(date) {
        return (new Date(date)).getTime()
    }


    /**
     * Add days
     */
    const addDays = (date, counter) => new Date(date).setDate(new Date(date).getDate() + counter)


    return {formatDate, addDays, formatDateToTimestamp};
}

export default useDateFormatter;
