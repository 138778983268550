import c from "./Sidebar.module.scss"
import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useState } from "react";
import CardAnnotation from './CardAnnotation';
import AddButton from "../plan-template/AddButton";

function Sidebar({ onSelect = () => {}, onSearch = () => {}, onDateFilter = () => {}, annotations = [], selectedAnnotation, onDeleteAnnotation = () => {}, onEditAnnotation = () => {}, onDownloadAnnotation = () => {}, onCreate = () => {}, filters, isLoading, role }) {


    return (
        <div className={c.main_sidebar}>
            <div className={c.wrap_sidebar}>

                {/* Header Sidebar */}
                <div className={c.main_header_sidebar}>

                    <div className={c.main_intro}>

                        <div className={c.main_title}>
                            <div className={c.title}>Appuntamenti</div>
                        </div>

                        <div className={c.main_action}>
                            <button onClick={onCreate} className="link-interactive flex items-center gap-2">
                                <span className="icon_16 plus default icon_mask !bg-interactive-100 -mt-1"></span>
                                Nuovo
                            </button>
                        </div>
                    </div>


                    <div className={c.main_filters}>

                        <div className="wrap_input_landing date">
                            <input onChange={onDateFilter} className={"landing"} id='line_action_date_start' type='date'/>
                            <span className="icon_24 default calendar icon_mask !bg-interactive-100"></span>
                        </div>

                        {/* Search */}
                        <div className={c.main_search}>
                            <div className="wrap_input_landing icon">
                                <input onChange={onSearch} type="text" className="landing" placeholder="Ricerca..." />
                                <span className="icon_16 default search"></span>
                            </div>
                        </div>

                    </div>

                </div>



                <div className={c.main_content_sidebar}>
                    <div className={c.main_heading}></div>

                    <OverlayScrollbarsComponent className={c.main_list_sidebar}>
                        <div className={c.wrap_list_sidebar}>
                            {annotations.length > 0 &&
                                annotations.map((annotation, i) => (
                                    <CardAnnotation 
                                        key={i} 
                                        active={selectedAnnotation?.id === annotation.id} 
                                        onClick={() => onSelect(annotation)}
                                        onEdit={() => annotation.user_can_update && onEditAnnotation(annotation)}
                                        onDelete={() => annotation.user_can_update && onDeleteAnnotation(annotation)}
                                        onDownload={() => onDownloadAnnotation(annotation)}
                                        annotation={annotation}
                                        role={role}
                                    />
                                ))
                            }

                            {(annotations.length === 0 && !filters.filter.name && !filters.filter.date && !isLoading) && 
                                <AddButton label="Aggiungi una nuova annotazione" onClick={onCreate} />
                            }
                        </div>
                    </OverlayScrollbarsComponent>
                </div>
            </div>

        </div>
    );
}

export default Sidebar;
