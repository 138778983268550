import SubHeader from "../../components/elements/SubHeader";
import Sidebar from "../../components/plan-copy/Sidebar";
import SubNav from '../../components/plan-template/SubNav';
import ListContent from "../../components/plan-copy/ListContent";
import { useEffect, useState } from "react";
import CopyContentModal from "../../components/popups/plan-copy/CopyContentPopup";
import { useLoaderData } from "react-router-dom";
import LayoutSidebar from "../../components/layout/LayoutSidebar";
import { getListCopiers, getOrganizationTypes, getOrganizations } from "../../services/organizations.service";
import usePlanCopyConf from '../../hooks/plan-copy-conf.hook';
import CrudContentModal from '../../components/popups/plan-copy/CrudContentPopup';
import ConfirmModal from "../../components/popups/ConfirmPopup";
import LoaderPage from "../../components/utils/LoaderPage";
import ActionBtnItem from "../../components/utils/ActionBtnItem";
import {
    formatOrganizationPlanCopyContentObject,
} from "../../helper/formatter.helper";


function PlanCopyConf() {

    const loaderData = useLoaderData()

    const { 
        crudConfOpen,
        setCrudConfOpen,
        copyPopup,
        setCopyPopup,
        planChapters,
        setPlanChapters,
        planData,
        setPlanData, 

        listConfs,
        setListConfs,

        selectedConf,
        selectedConfId,
        
        deleteModalOpen,
        setDeleteModalOpen,

        deleteConf,

        deleteItem,
        updateListConf,
        updateShowedConf,

        listOrganizations,
        setListOrganizations,

        crudModel,
        loadingCopyPlan,

        deleteConfRequest,
        editConfRequest

    } = usePlanCopyConf()


    const templateRight = () => (<div onClick={() => setCopyPopup(true)} className="button fill primary small">Procedi</div>)

    const [orgTypes, setOrgTypes] = useState([])

    const [loadingData, setLoadingData] = useState({
        organizations: false,
        types: false,
    })



    /**
     * Get list copiers
     */
    const getCopiers = () => {

        const body = {
            id: loaderData.plan.organization.id,
            idPlan: loaderData.plan.id
        }

        getListCopiers(body, (res) => {
            setListConfs(res.data)
        })
    }




    /**
     * Get List organizations
     */
    const getListOrganizations = async () => {

        setLoadingData(l => ({...l, organizations: true}))

        const body = {
            template_plan_id: loaderData.plan.template_plan_id,
            include: ["organizationType", "reseller", "organizationPlans"],
            filter: {
                template_plan_active: true
            },
            per_page: 500
        }

        const getAllCorps = async (page = 1, loadMore = false) => {

            // Fetch Api
            await getOrganizations({...body, page}, async (res) => {

                loadMore ? setListOrganizations(corps => [...corps,...res.data]) : setListOrganizations(res.data);

                if(res.meta.current_page < res.meta.last_page){
                    await getAllCorps(res.meta.current_page + 1, true)
                }else{
                    setLoadingData(l => ({...l, organizations: false}))
                }
            })
        }

        await getAllCorps();
    }



    /**
     * Get Types
     */
    const getTypes = async () => {

        setLoadingData(l => ({...l, types: true}))

        const getAllTypes = async (page = 1, loadMore = false) => {

            // Fetch Api
            await getOrganizationTypes({page}, async (res) => {

                loadMore ? setOrgTypes(types => [...types,...res.data]) : setOrgTypes(res.data);

                if(res.meta.current_page < res.meta.last_page){
                    await getAllTypes(res.meta.current_page + 1, true)
                }else{
                    setLoadingData(l => ({...l, types: false}))
                }
            })
        }

        await getAllTypes();
    }




    
    /**
     * Init data
     */
    useEffect(() => {

        setPlanChapters(loaderData.plan.organization_contents)

        setPlanData(loaderData.plan)

        getListOrganizations()

        getTypes()

    }, [])




    /**
     * Fetch data
     */
    useEffect(() => {

        getCopiers()

        if(selectedConf){
            updateShowedConf(selectedConf.id)
        }

    }, [updateListConf])



    const titleSubHeader = selectedConf ? `Configurazione – ${selectedConf.name}` : `Tutto il contenuto – ${loaderData.plan.template_plan.name}`

    const filteredOrganizations = listOrganizations.filter(corp => corp.id !== loaderData.plan.organization_id && corp.organization_plans.length > 0 && corp.organization_plans[0].template_plan_id === loaderData.plan.template_plan_id)

    /**
     * Template Nav
     * @returns 
     */
    const templateNav = () => (
        <div className="main_comp_actions_group">

            {/* Status */}
            <div className="main_list_counter">

                {/* Contents counter */}
                <div className="main_counter">
                    <div className="main_icon">
                        <span className="icon_16 default text_box"></span>
                    </div>
                    {selectedConf != null ? selectedConf.copier_chapter_contents_count : loaderData.plan.organization_chapter_contents_count}
                </div>

                {/* Requirements counter */}
                <div className="main_counter">
                    <div className="main_icon">
                        <span className="icon_16 default layer"></span>
                    </div>
                    {selectedConf != null ? selectedConf.copier_requirements_count : loaderData.plan.organization_requirements_count}
                </div>

            </div>

            {/* Actions */}
            {selectedConf != null &&
                <div className="main_actions">

                    <ActionBtnItem icon="edit" onClick={() => editConfRequest(selectedConf)} tooltip="Modifica" />

                    <ActionBtnItem icon="delete" onClick={() => deleteConfRequest(selectedConf)} tooltip="Elimina"/>
                </div>
            }
        </div>
    )



     /**
     * Filter list
     * @returns 
     */
    function filteredList(){

        const newList = JSON.parse(JSON.stringify(loaderData.plan.organization_chapters))

        newList.filter(c => {

            c.organization_chapter_contents = c.organization_chapter_contents.filter(content => content.template_chapter_content.editable)

            c.organization_goals = c.organization_goals.filter(goal => goal.organization_requirements.length > 0)

            return {...c}
            
        })

        return newList.filter(c => c.organization_chapter_contents.length > 0 || c.organization_goals.length > 0)
    }


    return (
        <LayoutSidebar>

            <SubHeader 
                title="Copia Contenuto da" 
                titleSmall 
                back 
                TemplateRightContent={templateRight} 
                tagElement={{
                    name: loaderData.plan.organization.name,
                    image: loaderData.plan.organization.logo_url,
                    icon: "entity",
                }}
                note={loaderData.plan.template_plan.name}
            />

            <Sidebar configurations={listConfs} disableCreate={filteredList().length == 0} />


            <div className="main_page_sidebar white_page relative">
                <SubNav heading={titleSubHeader} TemplateRightContent={templateNav} />
                
                <div className={"wrap_page_sidebar relative" + (loadingCopyPlan.showDetail ? " block_scroll" : '')}>

                    {planChapters && <ListContent chapters={planChapters} /> }

                </div>
                    {loadingCopyPlan.showDetail && <LoaderPage title="Caricamento..." section />}
            </div> 


            {/* Copy modal */}
            <CopyContentModal 
                organizations={filteredOrganizations} 
                plan={planData} 
                selectedConfId={selectedConfId} 
                open={copyPopup} 
                types={orgTypes}
                loadingData={loadingData}
                setOpen={setCopyPopup} 
            />


            {/* Crud modal content */}
            {planChapters &&
                <CrudContentModal confModel={crudModel} template={formatOrganizationPlanCopyContentObject(planChapters)} open={crudConfOpen} setOpen={setCrudConfOpen} />
            }


            {/* Confirm content */}
            <ConfirmModal 
                open={deleteModalOpen} 
                setOpen={setDeleteModalOpen}
                title={deleteConf.title } 
                icon={deleteConf.icon}
                subtitle={deleteConf.subtitle}
                description={deleteConf.description}
                alertMessage="Attenzione! Questa operazione è irreversibile! <b>Sei sicuro di procedere?</b>"
                ctaLabel="Elimina"
                onConfirm={() => deleteConf.confirmHandler(deleteItem)}
            />

        </LayoutSidebar>
    );
}

export default PlanCopyConf;