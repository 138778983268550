import { useContext } from "react";
import NotificationContext from "../context/notification.context";
import { useTranslation } from 'react-i18next';

function useNotification() {
    const { setText, setStatus, setFields, setClose, setNotificationActived, notificationActived, timeRef } = useContext(NotificationContext);

	const { t } = useTranslation("common")

    async function openNotification({text = "", status, fields = [], time = 5000}) {
		
		if (notificationActived) {
			await closeNotification();
		}
		
		setTimeout(() => {
			setText(text);
			setClose(time === null ? false : true)
			setStatus(status);
			setFields(fields)
			setNotificationActived(true);
		}, 0)

		if(time !== null){

			timeRef.current = setTimeout(() => {
				closeNotification();
			}, time);
		}
	}

    async function helperErrorNotification(error) {
		if(error?.data?.message)
			openNotification({text : error.data.message, status: "error"})
		else
			openNotification({text : t("errors.general"), status: "error"})
	}

	async function closeNotification() {
		clearTimeout(timeRef.current)
		setNotificationActived(false);
		await resetData()
	}

	async function resetData(){
		setText("");
		setStatus("");
		setFields([]);
	}

	return {openNotification,closeNotification,helperErrorNotification};
}

export default useNotification;
