import { NavLink } from "react-router-dom";
import c from "./Header.module.scss"
import useUserSession from "../../hooks/user-session.hook";
import UserPopover from "../utils/UserPopover";


function Header({ withSubheader = false }) {

    const { user, organization } = useUserSession()

    const navAdmin = [
        { icon: "piano -mt-1", href: "/admin/plans", label: "Gestione piani" },
        { icon: "entity", href: "/admin/entities", label: "Enti" },
        { icon: "book", href: "/admin/agendas", label: "Agenda" },
    ]

    const navResellers = [
        { icon: "piano", href: "/reseller", label: "Home" },
        { icon: "book", href: "/reseller/agendas", label: "Agenda" },
    ]

    const navCorp = [
        { icon: "piano", href: "/organization", label: "Home" },
        { icon: "book", href: "/organization/agendas", label: "Agenda" },
    ]

    return (
        <div className={c.main_header}>
            <div className={`${c.wrap_header} ${withSubheader ? c.with_subheader : ''}`}>
                <div className={c.main_header_content}>

                    <div className={c.left_content}>
                        <img src="/images/logo.svg" alt="" className={c.logo} />
                        {organization &&
                            <div className={`${c.main_name} fade_in`}>
                                <div className={c.name}>{organization.name}</div>
                            </div>
                        }
                    </div>


                    <div className={c.nav_content}>
                        {user.role === "admin" &&
                            <div className={"fade_in " + c.list_nav}>
                                {navAdmin.map((nav, i) => (
                                    <NavLink key={i} to={nav.href} className={({ isActive }) => (`${c.nav_item} ` + (isActive ? `${c.active}` : '')) }>
                                        {({isActive}) => (
                                            <>
                                                <div className={c.main_icon}>
                                                    <span className={"icon_16 default " + nav.icon + (isActive ? ' icon_mask !bg-primary-100' : '')}></span>
                                                </div>
                                                {nav.label}
                                            </>
                                        )}
                                    </NavLink>
                                ))}
                            </div>
                        }
                        {user.role === "reseller" &&
                            <div className={"fade_in " + c.list_nav}>
                                {navResellers.map((nav, i) => (
                                    <NavLink key={i} end to={nav.href} className={({ isActive }) => (`${c.nav_item} ` + (isActive ? `${c.active}` : '')) }>
                                        {({isActive}) => (
                                            <>
                                                <div className={c.main_icon}>
                                                    <span className={"icon_16 default " + nav.icon + (isActive ? ' icon_mask !bg-primary-100' : '')}></span>
                                                </div>
                                                {nav.label}
                                            </>
                                        )}
                                    </NavLink>
                                ))}
                            </div>
                        }
                        {user.role === "organization" &&
                            <div className={"fade_in " + c.list_nav}>
                                {navCorp.map((nav, i) => (
                                    <NavLink key={i} end to={nav.href} className={({ isActive }) => (`${c.nav_item} ` + (isActive ? `${c.active}` : '')) }>
                                        {({isActive}) => (
                                            <>
                                                <div className={c.main_icon}>
                                                    <span className={"icon_16 default " + nav.icon + (isActive ? ' icon_mask !bg-primary-100' : '')}></span>
                                                </div>
                                                {nav.label}
                                            </>
                                        )}
                                    </NavLink>
                                ))}
                            </div>
                        }
                    </div>


                    <div className={c.right_content}>
                        {user &&
                            <UserPopover user={user} />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;