import { useEffect, useState } from "react";
import c from "./Pagination.module.scss"

export default function Pagination(props) {
	const { last, current, onChange, total, labelFound } = props;
	const [pagArray, setPagArray] = useState([]);

	function onChangeHandler(page) {
		if (page != current && page > 0 && page <= last) {
			onChange(page);
		}
	}

	/* effect */
	useEffect(() => {
		const pages = [];
		let i = last - current < 2 ? last - current - 3 : -1;
		for (i; pages.length < 3 && pages.length < last - 2; i++) {
			if (current + i > 1 && current + i < last) {
				pages.push(current + i);
			}
		}
		setPagArray(pages);
	}, [current, last]);


	function formatTotal(){
		return '' + total
	}

	return (
		<div className={c.main_comp_paginator}>
			<div className={c.wrap_comp_paginator}>
				<div className={c.main_counter}>
					<span className={c.counter}>{current}-{last} di {total}</span>
				</div>
				<div>
					<nav className={c.main_paginator}>
						<a className={`${c.item_paginator} ${current <= 1 ? c.dis : ''}`} onClick={() => onChangeHandler(current - 1)}>
							<span className="icon_16 default chevron icon_mask !bg-dark-70 left"></span>
						</a>

						<a className={`${c.item_paginator} ${current == 1 ? c.active : ''}`}
							/* className={'relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20 ' +  (current == 1 ? currentClass : defaultClass)} */
							onClick={() => onChangeHandler(1)}>
							1
						</a>

                        {pagArray[0] > 2 &&
                            <span className={`${c.item_paginator} ${c.fill}`}>
                                ...
                            </span>
                        }

                        {last > 2 &&
                            pagArray.map((val) => (
                                <a key={val} 
									onClick={() => onChangeHandler(val)}
									className={`${c.item_paginator} ${current == val ? c.active : ''}`}
									/* className={'relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20 ' + (current == val ? currentClass : defaultClass)} */>
                                    {val}
                                </a>
                            ))
                        }

                        {(pagArray.length > 0 && pagArray.at(-1) < last - 1) &&
                            <span className={`${c.item_paginator} ${c.fill}`}>
                                ...
                            </span>
                        }

                        {last > 1 &&
                            <a onClick={() => onChangeHandler(last)} className={`${c.item_paginator} ${current == last ? c.active : ''}`}>
                                {last}
                            </a>
                        }
						<a className={`${c.item_paginator} ${current === last ? c.dis : ''}`} onClick={() => onChangeHandler(current + 1)}>
							<span className="icon_16 default chevron icon_mask !bg-dark-70 right"></span>
						</a>
					</nav>
				</div>
			</div>
		</div>
	);
}
