import { apiClient } from "./config";

/**
 * Get list organization plans
 * @param {
 *  filter[except_organization_working_plan]
 *  include[] organizationChaptersCount
 *  include[] organizationChapterContentsCount
 *  include[] organizationGoalsCount
 *  include[] organizationRequirementsCount
 *  include[] templatePlan
 *  per_page
 * } params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const getPlans = async (
  params,
  successCallback = false,
  errorCallback = false,
) => {
  const { id, ...query } = params;

  try {
    const response = await apiClient.get(`/organizations/${id}/plans`, {
      params: query,
    });
    if (successCallback) successCallback(response.data);
    return response.data;
  } catch (error) {
    if (errorCallback) errorCallback(error.response);
    return error;
  }
};

/**
 * Get list plan approvals
 * @param {idCorp, idPlan } params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const getPlanApprovals = async (
  params,
  successCallback = false,
  errorCallback = false,
) => {
  const { idCorp, idPlan, ...query } = params;

  try {
    const response = await apiClient.get(
      `/organizations/${idCorp}/plans/${idPlan}/approvals`,
      { params: query },
    );
    if (successCallback) successCallback(response.data);
    return response.data;
  } catch (error) {
    if (errorCallback) errorCallback(error.response);
    return error;
  }
};

/**
 * Create plan approval
 * @param {idCorp, idPlan, date, note } params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const createPlanApproval = async (
  params,
  successCallback = false,
  errorCallback = false,
) => {
  const { idCorp, idPlan, ...body } = params;

  try {
    const response = await apiClient.post(
      `/organizations/${idCorp}/plans/${idPlan}/approvals`,
      body,
    );
    if (successCallback) successCallback(response.data);
    return response.data;
  } catch (error) {
    if (errorCallback) errorCallback(error.response);
    return error;
  }
};

/**
 * Update plan approval
 * @param {idCorp, idPlan, idApproval, date, note } params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const updatePlanApproval = async (
  params,
  successCallback = false,
  errorCallback = false,
) => {
  const { idCorp, idPlan, idApproval, ...body } = params;

  try {
    const response = await apiClient.put(
      `/organizations/${idCorp}/plans/${idPlan}/approvals/${idApproval}`,
      body,
    );
    if (successCallback) successCallback(response.data);
    return response.data;
  } catch (error) {
    if (errorCallback) errorCallback(error.response);
    return error;
  }
};

/**
 * Get oganization's plan content
 * @param {idCorp, id } params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const getPlanContent = async (
  params,
  successCallback = false,
  errorCallback = false,
) => {
  const { idCorp, id, ...query } = params;

  try {
    const response = await apiClient.get(
      `/organizations/${idCorp}/contents/${id}`,
      { params: query },
    );
    if (successCallback) successCallback(response.data);
    return response.data;
  } catch (error) {
    if (errorCallback) errorCallback(error.response);
    return error;
  }
};

/**
 * Update oganization's plan content
 * @param {idCorp, id, editorjs, status} params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const updatePlanContent = async (
  params,
  successCallback = false,
  errorCallback = false,
) => {
  const { idCorp, id, ...body } = params;

  try {
    const response = await apiClient.patch(
      `/organizations/${idCorp}/contents/${id}`,
      body,
    );
    if (successCallback) successCallback(response.data);
    return response.data;
  } catch (error) {
    if (errorCallback) errorCallback(error.response);
    return error;
  }
};

/**
 * Update oganization's plan requirement
 * @param {idCorp, id, editorjs, status} params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const updatePlanRequirement = async (
  params,
  successCallback = false,
  errorCallback = false,
) => {
  const { idCorp, id, ...body } = params;

  try {
    const response = await apiClient.patch(
      `/organizations/${idCorp}/requirements/${id}`,
      body,
    );
    if (successCallback) successCallback(response.data);
    return response.data;
  } catch (error) {
    if (errorCallback) errorCallback(error.response);
    return error;
  }
};

/**
 * Upload oganization's plan attachments
 * @param {idCorp, id, editorjs, status} params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const uploadPlanAttachment = async (
  params,
  successCallback = false,
  errorCallback = false,
) => {
  const { idCorp, idPlan, formData } = params;

  try {
    const response = await apiClient.post(
      `/organizations/${idCorp}/plans/${idPlan}/attachments`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    if (successCallback) successCallback(response.data);
    return response.data;
  } catch (error) {
    if (errorCallback) errorCallback(error.response);
    return error;
  }
};

/**
 * Delete oganization's plan attachments
 * @param {idCorp, id, editorjs, status} params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const deletePlanAttachment = async (
  params,
  successCallback = false,
  errorCallback = false,
) => {
  const { idCorp, idPlan, id } = params;

  try {
    const response = await apiClient.delete(
      `/organizations/${idCorp}/plans/${idPlan}/attachments/${id}`,
    );
    if (successCallback) successCallback(response.data);
    return response.data;
  } catch (error) {
    if (errorCallback) errorCallback(error.response);
    return error;
  }
};

/**
 * Upload oganization's plan requirement attachments
 * @param {idCorp, id, editorjs, status} params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const uploadAttachmentPlanRequirement = async (
  params,
  successCallback = false,
  errorCallback = false,
) => {
  const { idCorp, idPlan, id, formData } = params;

  try {
    const response = await apiClient.post(
      `/organizations/${idCorp}/plans/${idPlan}/requirements/${id}/attachments`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    if (successCallback) successCallback(response.data);
    return response.data;
  } catch (error) {
    if (errorCallback) errorCallback(error.response);
    return error;
  }
};

/**
 * Delete oganization's plan requirement attachment
 * @param {idCorp, id, editorjs, status} params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const deleteAttachmentPlanRequirement = async (
  params,
  successCallback = false,
  errorCallback = false,
) => {
  const { idCorp, idPlan, idReq, id } = params;

  try {
    const response = await apiClient.delete(
      `/organizations/${idCorp}/plans/${idPlan}/requirements/${idReq}/attachments/${id}`,
    );
    if (successCallback) successCallback(response.data);
    return response.data;
  } catch (error) {
    if (errorCallback) errorCallback(error.response);
    return error;
  }
};

/**
 * Update oganization's plan requirement
 * @param {
 *  idCorp,
 *  idPlan,
 *  options[templateChapters][],
 *  options[templateChapterContents][],
 *  options[templateGoals][],
 *  options[templateRequirements][],
 *  options[organizationRequirementsStatuses][],
 *  format
 * } params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const exportCreate = async (
  params,
  successCallback = false,
  errorCallback = false,
) => {
  const { idCorp, idPlan, ...body } = params;

  try {
    const response = await apiClient.post(
      `/organizations/${idCorp}/plans/${idPlan}/exports`,
      body,
    );
    if (successCallback) successCallback(response.data);
    return response.data;
  } catch (error) {
    if (errorCallback) errorCallback(error.response);
    return error;
  }
};

/**
 * Update oganization's plan requirement
 * @param {
 *  idCorp,
 *  idPlan,
 *  options[templateChapters][],
 *  options[templateChapterContents][],
 *  options[templateGoals][],
 *  options[templateRequirements][],
 *  options[organizationRequirementsStatuses][],
 *  format
 * } params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const exportShow = async (
  params,
  successCallback = false,
  errorCallback = false,
) => {
  const { idCorp, idPlan, id, ...body } = params;

  try {
    const response = await apiClient.get(
      `/organizations/${idCorp}/plans/${idPlan}/exports/${id}`,
      { params: body },
    );
    if (successCallback) successCallback(response.data);
    return response.data;
  } catch (error) {
    if (errorCallback) errorCallback(error.response);
    return error;
  }
};
