import { Outlet, useLoaderData } from "react-router-dom";
import { GlobalContextProvider } from '../context/global.context';
import { NotificationContextProvider } from "../context/notification.context";
import { useNavigate } from "react-router-dom";

function PageContainer() {

    const { notSession } = useLoaderData()

    if(notSession){
        window.location = process.env.REACT_APP_HOST_PLATFORM
        return null
    }
    
    return (
        <GlobalContextProvider>
            <Outlet />
        </GlobalContextProvider>
    );
}

export default PageContainer;