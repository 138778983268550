import { useEffect, useState } from "react";
import "./FieldsPlanCrud.scss";
import ModalContainer from "./ModalContainer";
import Table from "../utils/table/Table";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import {
  getTemplateStatuses,
} from "../../services/plans-template-status.service";
import {useTranslation} from "react-i18next";
import {updateFieldTemplatePlan} from "../../services/plans-template.service";
import useNotification from "../../hooks/notification.hook";

export default function FieldsPlanCrud({
  open,
  setOpen,
  planTemplate,
}) {

  const {t} = useTranslation()

  const [loading, setLoading] = useState(false);

  const { openNotification, helperErrorNotification } = useNotification();

  const [fieldsList, setFieldsList] = useState(Object.entries(planTemplate.requirement_fields_settings).map(([key, value]) => ({ label: key, value })));

  const onToggleField = (checked, field) => {
    setFieldsList(fields => {
      const finded = fields.find(f => f.label === field);
      finded.value = checked
      return [...fields]
    })
  }

  const confirmHandler = () => {

    const body = fieldsList.reduce((acc, item) => {
      const {label, value} = item;
      acc[label] = value;
      return acc;
    }, {});

    if(!loading){
      setLoading(true)
      updateFieldTemplatePlan({ idTemplatePlan: planTemplate.id, ...body}, (res) => {
        setLoading(false)
        setFieldsList(Object.entries(res).map(([key, value]) => ({ label: key, value })))
        openNotification({
          text: "Operazione completata con successo!",
          status: "success",
        });
        setOpen(false)
      }, (err) => {
        helperErrorNotification(err)
        setLoading(false)
      })
    }
  }


  const textCantDelete =
    "Impossibile modificare i campi utilizzati all'interno di uno o più piani";

  return (
    <ModalContainer isOpen={open} setIsOpen={setOpen} disableClose={loading}>
      <div className="main_modal_content fields_plan_crud_popup">
        <div className="main_close" onClick={() => !loading && setOpen(false)}>
          <span className="icon_10 default close icon_mask !bg-white"></span>
        </div>

        <div className="main_header_modal flex_header">
          {/* Left content */}
          <div className="left_content">
            <div className="main_desc">
              <span className="title">Personalizzazione dei campi per la compilazione</span>
            </div>
          </div>

          <div className="main_plan">
            <div className="main_icon">
              <span
                className={
                  "icon_24 default plan icon_mask !bg-primary-100 " +
                  (!planTemplate.diff_download?.length ? "piano" : "branch")
                }
              ></span>
            </div>
            <div className="main_info">
              <div className="name">{planTemplate.name}</div>
              <div className="description">{planTemplate.description}</div>
            </div>
          </div>

          <div className="pt-[10px] text-12 text-dark-60 font-medium">
            Nota: {textCantDelete}
          </div>
        </div>

        <div className={"main_body_modal"}>
          {/* List Reqs */}
            <div className={"main_list_items"}>

              <OverlayScrollbarsComponent
                defer
                options={{ scrollbars: { autoHide: "scroll" } }}
                className="main_table_items"
              >
                {/* Table organizations */}
                <Table className="layout_popup big_row">
                  {/* Table Head */}
                  <Table.Head className="sticky_head">
                    <Table.Column className="!pl-6 !pb-3">Campo</Table.Column>
                    <Table.Column></Table.Column>
                  </Table.Head>

                  {/* Table Body */}
                  <Table.Body>
                    {fieldsList.map((field, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>
                          <div className="flex gap-3 items-center">
                            <div className="flex flex-none">
                              <div
                                className={"icon_20 field " + field.icon}
                              ></div>
                            </div>
                            {t(`common:fields.${field.label}`)}
                          </div>
                        </Table.Cell>

                        <Table.Cell width={40}>
                          <div className={"toggle_landing flex" + (!!planTemplate?.used_requirement_fields[field.label] || loading ? " dis": "")}>
                            <input disabled={!!planTemplate?.used_requirement_fields[field.label] || loading}
                                   checked={field.value}
                                   onChange={(e) => onToggleField(e.target.checked, field.label)}
                                   type="checkbox"
                                   id={`custom-field-${index}`}
                                   className="landing only_toggle"
                            />
                            <label htmlFor={`custom-field-${index}`} className="only_toggle"></label>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </OverlayScrollbarsComponent>
            </div>
        </div>

        <div className={"main_footer_modal"}>
          <div className="right_content">
            <button
                onClick={() => confirmHandler()}
                className={"button fill success" + (loading ? " loading" : "")}
            >
              <div className="load_content">Salva</div>
              <div className="main_loader_icon fade_in"></div>
            </button>
          </div>
        </div>
      </div>

    </ModalContainer>
  );
}
