import c from "./ItemCustomization.module.scss"
import ActionBtnItem from '../utils/ActionBtnItem';

function ItemCustomization({ id, label, onEdit, onDelete, name, ...props }) {
    return (
        <div className={c.main_item}>
            <label htmlFor={id} className={c.main_check}>
                <div className="radio_landing small">
                    <input {...props} name={name} type="radio" id={id} />
                    <label className={c.label} htmlFor={id}>{label}</label>
                </div>
            </label>
            {(onEdit || onDelete) &&
                <div className={c.main_actions}>
                    {onEdit && <ActionBtnItem onClick={onEdit} icon="edit" tooltip="Modifica" />}
                    {onDelete && <ActionBtnItem onClick={onDelete} icon="delete" tooltip="Elimina" />}
                </div>
            }
        </div>
    );
}

export default ItemCustomization;