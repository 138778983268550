import React, {useEffect, useRef, useState} from "react";
import "./AnnualReportPopup.scss";
import ModalContainer from "./ModalContainer";
import useNotification from "../../hooks/notification.hook";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import StatusContent from "../plan-download/StatusContent";
import * as Accordion from "@radix-ui/react-accordion";
import c from "./BoxPlanOrganizationTemplate.module.scss";
import {exportAnnualReportCreate, exportAnnualReportShow} from "../../services/organizations.service";
import {addDays, formatDate} from "../../helper/date-formatter.helper";

export default function AnnualReportPopup({
	open,
	setOpen,
	organizationPlan,
	statuses = [],
}) {
	const { openNotification, helperErrorNotification } = useNotification();

	const [loading, setLoading] = useState(false);

	const [selectedStatus, setSelectedStatus] = useState([])

	const { t } = useTranslation();

	const timerDownload = useRef(null);

	// Set message
	yup.setLocale({
		// use constant translation keys for messages without values
		mixed: {
			required: ({ path }) =>
				t("common:errors.required", { field: t(`common:fields.${path}`) }),
		},
	});


	// Schema Yup
	const schema = yup.object().shape({
		introduction: yup.bool(),
		pnrr: yup.bool(),
		deadlines: yup.bool(),
		soluzionefad: yup.bool(),
		syllabus: yup.bool(),
		conclusions: yup.bool(),
		hasDeadline: yup.bool(),
		dateFrom: yup.string().when("hasDeadline", {
			is: (val) => !!val,
			then: (schema) => schema.test((val, ctx) => {
				if((new Date(val) > new Date(ctx.parent.dateTo)))
					return ctx.createError({ message: 'La data di inizio deve essere minore o uguale a quella di fine' })
				return true
			})
		}),
		dateTo: yup.string().when("hasDeadline", {
			is: (val) => !!val,
			then: (schema) => schema.test((val, ctx) => {
				if((new Date(ctx.parent.dateFrom) > new Date(val)))
					return ctx.createError({ message: 'La data di fine deve essere maggiore o uguale a quella di inizio' })
				return true
			})
		}),
	}, [["dateFrom", "dateTo"]]);

	const {
		register,
		setValue,
		watch,
		clearErrors,
		getValues,
		handleSubmit,
		formState: { errors },
	} = useForm({ resolver: yupResolver(schema) });




	const formHandler = (data) => {
		if (!loading) {
			setLoading(true);

			const body = {
				idOrganization: organizationPlan.organization_id,
				idPlan: organizationPlan.id,
				options: {
					sections: {
						introduction: data.introduction,
						pnrr: data.pnrr,
						deadlines: data.deadlines,
						soluzionefad: data.soluzionefad,
						syllabus: data.syllabus,
						conclusions: data.conclusions,
					},
					organizationRequirementsStatuses: selectedStatus,
					...data.hasDeadline && {dateFrom: data.dateFrom},
					...data.hasDeadline && {dateTo: data.dateTo},
				},
			};

			exportAnnualReportCreate(
				body,
				(res) => {
					getDownload(res.data.id);
				},
				(error) => {
					helperErrorNotification(error);
					setLoading(false);
				},
			);
		}
	};


	/**
	 * Get Download
	 * @param {*} id
	 */
	const getDownload = (id) => {
		exportAnnualReportShow(
			{ idOrganization: organizationPlan.organization_id, idPlan: organizationPlan.id, id },
			(res) => {
				if (res.data.status === "pending") {
					timerDownload.current = setTimeout(() => {
						getDownload(id);
					}, 2000);
				} else {
					const link = document.body.appendChild(document.createElement("a"));
					link.href = res.data.url;
					link.target = "_blank";
					link.download = " ";
					link.click();
					link.href = "";
					document.body.removeChild(link);
					setLoading(false);
					setOpen(false);
					openNotification({
						text: "Documento generato con successo!",
						status: "success",
					});
				}
			},
			(error) => {
				helperErrorNotification(error);
				setLoading(false);
			},
		);
	};


	useEffect(() => {
		if (open) {
			clearErrors();
			setValue("introduction", false);
			setValue("pnrr", false);
			setValue("deadlines", false);
			setValue("soluzionefad", false);
			setValue("syllabus", false);
			setValue("conclusions", false);
			setValue("hasDeadline", false);
			setValue("dateFrom", "");
			setValue("dateTo", "");
			setSelectedStatus([])
		}
	}, [open]);


	const toggleStatus = (status) =>
		selectedStatus.find((s) => s === status)
			? setSelectedStatus((list) => list.filter((s) => s != status))
			: setSelectedStatus((l) => [...l, status]);

	const toggleAllStatus = () =>
		selectedStatus.length === statuses.length
			? setSelectedStatus([])
			: setSelectedStatus(statuses.map((s) => s.id));

	/**
	 * Errors Form
	 */
	useEffect(() => {
		if (Object.keys(errors).length) {
			const fields = Object.values(errors).map((er) => er.message);
			openNotification({ fields, status: "error" });
		}
	}, [errors]);

	return (
		<ModalContainer isOpen={open} setIsOpen={setOpen} disableClose={loading}>
			<div className="main_modal_content annual_report_popup">
				<div className="main_close" onClick={() => !loading && setOpen(false)}>
					<span className="icon_10 default close icon_mask !bg-white"></span>
				</div>


				<div className="main_header_modal flex_header">
					<div className="left_content">
						<div className="main_desc">
              <span className="title">
                {"Genera relazione annuale"}
              </span>
							<div className="desc">{organizationPlan.template_plan.name}</div>
						</div>
					</div>
				</div>

				<div className={"main_body_modal"}>

					<form noValidate id="annual-report-form" onSubmit={handleSubmit(formHandler)}>

						<Accordion.Root
							className=""
							type="single"
							defaultValue="item-1"
							collapsible
						>
							<Accordion.Item
								value="item-1"
								className="shadow-[0_1px_0] shadow-dark-10"
							>
								<Accordion.Trigger asChild={true}>
									<div className="px-7 pb-4 pt-5 flex justify-between w-full items-center cursor-pointer group">
										<div className="text-16 font-medium">
											Seleziona gli stati da includere
										</div>
										<span className="icon_16 chevron default icon_mask !bg-dark-70 group-data-[state=open]:rotate-180"></span>
									</div>
								</Accordion.Trigger>
								<Accordion.Content className="data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden">
									<StatusContent
										selectedStatues={selectedStatus}
										listStatuses={statuses}
										onToggleStatus={toggleStatus}
										onToggleAllStatus={toggleAllStatus}
										className="pb-3"
									/>
								</Accordion.Content>
							</Accordion.Item>

							<Accordion.Item value="item-2">
								<Accordion.Trigger asChild={true}>
									<div className="px-7 pb-4 pt-5 flex justify-between w-full items-center cursor-pointer group">
										<div className="text-16 font-medium">
											Seleziona il contenuto da includere
										</div>
										<span className="icon_16 chevron default icon_mask !bg-dark-70 group-data-[state=open]:rotate-180"></span>
									</div>
								</Accordion.Trigger>
								<Accordion.Content className="data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden">
									<div className="pb-3 pt-3">

										<div className="global_form_landing">
											{/* Has range */}
											<div className="base ">
												<div className={"toggle_landing small"}
												>
													<input
														className="landing"
														{...register("hasDeadline")}
														type="checkbox"
														id="annual-report-has-deadline"
													/>
													<label htmlFor={"annual-report-has-deadline"}>
														<span className={c.name}>Aggiungi periodo di riferimento relazione</span>
													</label>
												</div>
												{watch("hasDeadline") &&
													<div className="base child fade_in pt-3 shadow-[0_1px_0] shadow-dark-10 !pb-5">
														{/* Date start */}
														<div className="child">
															<div className="wrap_input_landing date">
																<label htmlFor="annual-report-deadline-from" className='label_input'>Data inizio</label>
																<div className="relative">
																	<input
																		{...watch("dateTo") && { max : formatDate(addDays(watch("dateTo"), 0), "YYYY-MM-DD")}}
																		{...register("dateFrom")}
																		className={"landing" + (errors.dateFrom ? ' err' : '')}
																		id="annual-report-deadline-from"
																		type='date'
																	/>
																	<span className="icon_24 default calendar icon_mask !bg-interactive-100"></span>
																</div>
															</div>
														</div>

														<div className="child">
															<div className="wrap_input_landing date">
																<label htmlFor="annual-report-deadline-to" className='label_input'>Data Fine</label>
																<div className="relative">
																	<input
																		{...watch("dateFrom") && { min : formatDate(addDays(watch("dateFrom"), 0), "YYYY-MM-DD")}}
																		{...register("dateTo")}
																		className={"landing" + (errors.dateTo ? ' err' : '')}
																		id="annual-report-deadline-to"
																		type='date'
																	/>
																	<span className="icon_24 default calendar icon_mask !bg-interactive-100"></span>
																</div>
															</div>
														</div>
													</div>
												}
											</div>



											{/* Introduction */}
											<div className="base ">
												<div className={"check_landing small"}
												>
													<input
														{...register("introduction")}
														type="checkbox"
														id="annual-report-intro"
													/>
													<label htmlFor={"annual-report-intro"}>
														<span className={c.name}>Introduzione</span>
													</label>
												</div>
											</div>

											{/* Pnrr */}
											<div className="base ">
												<div className={"check_landing small"}
												>
													<input
														{...register("pnrr")}
														type="checkbox"
														id="annual-report-pnrr"
													/>
													<label htmlFor={"annual-report-pnrr"}>
														<span className={c.name}>PNRR</span>
													</label>
												</div>
											</div>

											{/* deadlines */}
											<div className="base ">
												<div className={"check_landing small"}
												>
													<input
														{...register("deadlines")}
														type="checkbox"
														id="annual-report-deadlines"
													/>
													<label htmlFor={"annual-report-deadlines"}>
														<span className={c.name}>Scadenzario</span>
													</label>
												</div>
											</div>

											{/* formative plan */}
											<div className="base ">
												<div className={"check_landing small"}
												>
													<input
														{...register("soluzionefad")}
														type="checkbox"
														id="annual-report-soluzionefad"
													/>
													<label htmlFor={"annual-report-soluzionefad"}>
														<span className={c.name}>Piano formativo</span>
													</label>
												</div>
											</div>


											{/* syllabus */}
											<div className="base ">
												<div className={"check_landing small"}
												>
													<input
														{...register("syllabus")}
														type="checkbox"
														id="annual-report-syllabus"
													/>
													<label htmlFor={"annual-report-syllabus"}>
														<span className={c.name}>Resoconto piattaforma Syllabus</span>
													</label>
												</div>
											</div>

											{/* conclusions */}
											<div className="base ">
												<div className={"check_landing small"}
												>
													<input
														{...register("conclusions")}
														type="checkbox"
														id="annual-report-conclusions"
													/>
													<label htmlFor={"annual-report-conclusions"}>
														<span className={c.name}>Conclusioni</span>
													</label>
												</div>
											</div>
										</div>

									</div>
								</Accordion.Content>
							</Accordion.Item>
						</Accordion.Root>
					</form>
				</div>

				<div
					className={"main_footer_modal"}
				>
					<div className="right_content">
						<button
							form={"annual-report-form"}
							className={
								"button fill success" + (loading ? " loading" : "")
							}
						>
							<div className="load_content">Conferma</div>
							<div className="main_loader_icon fade_in"></div>
						</button>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}
