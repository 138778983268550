import c from "../plan-template/Sidebar.module.scss";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { SidebarContextProvider } from "../../context/sidebar.context";
import ChapterItem from "./ChapterItem";
import "overlayscrollbars/overlayscrollbars.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import CollapseContainerContext from "../../context/collapse-container.context";
import { usePlanCompileStore } from "../../store/plan-compile.store";
import { searchInOrganizationPlan } from "../../services/organizations.service";
import debounce from "lodash.debounce";
import EmptyBox from "../emptystate/EmptyBox";
import FilterDropdown from "../utils/FilterDropdown";
import FilterDateRange from "./FilterDateRange";

const initialFilters = {
  templateTo: {
    date_from: "",
    date_to: "",
  },
  templateFrom: {
    date_from: "",
    date_to: "",
  },
  statusIds: [],
  organizationFrom: {
    date_from: "",
    date_to: "",
  },
  organizationTo: {
    date_from: "",
    date_to: "",
  },
};

function Sidebar({ isAdmin }) {
  const {
    chapterList,
    searchModel,
    searchLoading,
    searchResults,
    planDetail,
    updateState,
    isFiltering,
  } = usePlanCompileStore();

  const { forceOpen, forceClose } = useContext(CollapseContainerContext);

  const abortController = useRef(new AbortController());
  const searchInputRef = useRef(null);
  const isInit = useRef(null);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [filters, setFilters] = useState({ ...initialFilters });

  function searchChapter(e) {
    const search = e.target.value;

    if (search.trim().length > 2) {
      filterContents({
        search: search.trim().toLowerCase(),
        organizationFrom: filters.organizationFrom,
        organizationTo: filters.organizationTo,
        templateFrom: filters.templateFrom,
        templateTo: filters.templateTo,
        statusIds: filters.statusIds,
      });
    } else {
      updateState({ searchModel: "" });
    }
  }

  const filterContents = ({
    search,
    organizationFrom,
    organizationTo,
    templateFrom,
    templateTo,
    statusIds,
  }) => {
    updateState({ searchLoading: true });

    abortController.current.abort();
    abortController.current = new AbortController();

    const body = {
      id: planDetail.organization_id,
      idPlan: planDetail.id,
      q: search,
      template_requirements: {
        start_at: templateFrom,
        end_at: templateTo,
      },
      organization_requirements: {
        start_at: organizationFrom,
        end_at: organizationTo,
        requirement_status_ids: statusIds,
      },
    };

    searchInOrganizationPlan(
      { params: body, options: { signal: abortController.current.signal } },
      (res) => {
        updateState({
          searchLoading: false,
          searchModel: search.trim().toLowerCase(),
          searchResults: res,
        });
      },
      (err) => {
        updateState({ searchLoading: false });
      },
    );
  };

  const debounceSearch = debounce(searchChapter, 500);

  useEffect(() => {
    if (!isInit.current) {
      isInit.current = true;
      return;
    }

    filterContents({
      search: searchModel,
      organizationFrom: filters.organizationFrom,
      organizationTo: filters.organizationTo,
      templateFrom: filters.templateFrom,
      templateTo: filters.templateTo,
      statusIds: filters.statusIds,
    });
  }, [JSON.stringify(filters)]);

  const applyTemplateDateHandler = (templateDate) => {
    setFilters((f) => ({
      ...f,
      templateFrom: {
        date_from: templateDate.fromStart,
        date_to: templateDate.fromEnd,
      },
      templateTo: {
        date_from: templateDate.toStart,
        date_to: templateDate.toEnd,
      },
    }));
  };

  const applyOrganizationDateHandler = (date) => {
    setFilters((f) => ({
      ...f,
      organizationFrom: {
        date_from: date.fromStart,
        date_to: date.fromEnd,
      },
      organizationTo: {
        date_from: date.toStart,
        date_to: date.toEnd,
      },
    }));
  };

  const resetFilter = () => {
    setFilters({ ...initialFilters });
  };

  const counterTemplateDate =
    (filters.templateTo.date_to || filters.templateTo.date_from ? 1 : 0) +
    (filters.templateFrom.date_to || filters.templateFrom.date_from ? 1 : 0);

  const counterOrganizationDate =
    (filters.organizationTo.date_to || filters.organizationTo.date_from
      ? 1
      : 0) +
    (filters.organizationFrom.date_to || filters.organizationFrom.date_from
      ? 1
      : 0);

  const hasFilterApplied =
    !!filters.statusIds.length ||
    filters.templateFrom.date_from ||
    filters.templateFrom.date_to ||
    filters.templateTo.date_from ||
    filters.templateTo.date_to ||
    filters.organizationFrom.date_from ||
    filters.organizationFrom.date_to ||
    filters.organizationTo.date_from ||
    filters.organizationTo.date_to;

  const filteredChapters = chapterList.filter(
    (chapter) =>
      searchResults.organization_chapters.includes(chapter.content_id) ||
      !isFiltering,
  );

  useEffect(() => {
    updateState({ isFiltering: !!searchModel || hasFilterApplied });
  }, [JSON.stringify(filters), searchModel]);

  return (
    <SidebarContextProvider>
      <div className={c.main_sidebar}>
        <div className={c.wrap_sidebar}>
          {/* Header Sidebar */}
          <div className={c.main_header_sidebar}>
            <div className={c.wrap_header_sidebar}>
              <div className={c.main_buttons}>
                {/* Collapse Buttons */}
                <div
                  className={c.main_button}
                  onClick={forceOpen}
                  data-tooltip-id="tooltip_small_top"
                  data-tooltip-offset={10}
                  data-tooltip-position-strategy="fixed"
                  data-tooltip-content="Espandi tutti"
                >
                  <span className="icon_16 default expand icon_mask !bg-interactive-100"></span>
                </div>
                <div
                  className={c.main_button}
                  onClick={forceClose}
                  data-tooltip-id="tooltip_small_top"
                  data-tooltip-offset={10}
                  data-tooltip-position-strategy="fixed"
                  data-tooltip-content="Riduci tutti"
                >
                  <span className="icon_16 default collapsed icon_mask !bg-interactive-100"></span>
                </div>
              </div>

              {/* Search */}
              <div className={c.main_search}>
                <div className="wrap_input_landing icon">
                  <input
                    ref={searchInputRef}
                    onChange={debounceSearch}
                    type="text"
                    className="landing"
                    placeholder="Ricerca..."
                  />
                  <span className="icon_16 default search"></span>
                </div>
              </div>

              <div className={c.main_buttons}>
                <div
                  className={c.main_button}
                  data-active={hasFilterApplied}
                  data-opened={isFiltersOpen}
                  onClick={() => setIsFiltersOpen((is) => !is)}
                  data-tooltip-id="tooltip_small_top"
                  data-tooltip-offset={10}
                  data-tooltip-position-strategy="fixed"
                  data-tooltip-content="Filtri"
                >
                  <span
                    className={
                      "icon_16 default filters icon_mask " +
                      (isFiltersOpen ? "!bg-white" : "!bg-interactive-100")
                    }
                  ></span>
                  {hasFilterApplied && !isFiltersOpen && (
                    <div className="absolute w-3 shadow-[0_0_0_2px] shadow-white h-3 bg-interactive-100 rounded-full -top-[3px] -right-[3px]"></div>
                  )}
                </div>
              </div>
            </div>
            {isFiltersOpen && (
              <div className={c.main_filters_container}>
                <div className="flex justify-between items-center pt-5">
                  <div className="font-semibold">
                    Filtra contenuti del piano
                  </div>
                  <div
                    className={
                      "flex justify-center gap-2 items-center text-interactive-100 text-12 font-bold py-[6px] " +
                      (hasFilterApplied
                        ? "hover:opacity-70 cursor-pointer"
                        : "opacity-30")
                    }
                    onClick={() => hasFilterApplied && resetFilter()}
                  >
                    <span className="icon_16 default cross icon_mask !bg-interactive-100 flex-none"></span>
                    Resetta
                  </div>
                </div>
                <div className={c.main_filters}>
                  <FilterDropdown
                    options={planDetail.organization_requirements_statuses.map(
                      (status) => ({
                        label: status.name,
                        value: status.id,
                        icon: `icon_16 status ${status.icon}`,
                      }),
                    )}
                    activeSelected={filters.statusIds}
                    onApply={(items) =>
                      setFilters((f) => ({ ...f, statusIds: items }))
                    }
                    toggleLabel="Stato linee d'azione"
                  />

                  <FilterDateRange
                    label={"Scadenze piano"}
                    currentValue={{
                      fromStart: filters.templateFrom?.date_from,
                      fromEnd: filters.templateFrom?.date_to,
                      toStart: filters.templateTo?.date_from,
                      toEnd: filters.templateTo?.date_to,
                    }}
                    onApply={applyTemplateDateHandler}
                    counter={counterTemplateDate}
                  />

                  <FilterDateRange
                    label={"Scadenze ente"}
                    currentValue={{
                      fromStart: filters.organizationFrom?.date_from,
                      fromEnd: filters.organizationFrom?.date_to,
                      toStart: filters.organizationTo?.date_from,
                      toEnd: filters.organizationTo?.date_to,
                    }}
                    onApply={applyOrganizationDateHandler}
                    counter={counterOrganizationDate}
                  />
                </div>
              </div>
            )}
          </div>

          {/* Content Sidebar */}
          <div className={c.main_content_sidebar}>
            {filteredChapters.length > 0 ? (
              <div className={c.main_list_sidebar}>
                <OverlayScrollbarsComponent
                  defer
                  options={{ scrollbars: { autoHide: "scroll" } }}
                  className={c.wrap_list_sidebar}
                >
                  {/* Drag Item */}
                  {filteredChapters.map((chapter, index) => (
                    <Fragment key={chapter.content_id}>
                      <ChapterItem
                        isEditable={isAdmin}
                        position={index}
                        chapter={chapter}
                        /*disabled={!isSearched(chapter)}*/
                      />
                    </Fragment>
                  ))}
                </OverlayScrollbarsComponent>
              </div>
            ) : (
              <div className="py-6">
                <EmptyBox
                  title={"Nessun risultato trovato"}
                  icon={"icon_75 default archive_plan"}
                />
              </div>
            )}

            {searchLoading && (
              <div className={c.main_loading}>
                <div className="main_comp_loader_box_icon !bg-dark-70"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </SidebarContextProvider>
  );
}

export default Sidebar;
