import { useEffect, useState, Fragment } from "react";
import "./CrudContentPopup.scss"
import ModalContainer from '../ModalContainer';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useNotification from "../../../hooks/notification.hook";
import usePlanCopyConf from '../../../hooks/plan-copy-conf.hook';
import { createCopier, createCopierContent, createCopierRequirement, deleteCopierContent, deleteCopierRequirement, updateCopier } from "../../../services/organizations.service";
import BoxPlanOrganizationTemplate from "../BoxPlanOrganizationTemplate";



export default function CrudContentModal({open, setOpen, confModel, template}) {

    const { t } = useTranslation()

    const { planData, setUpdateListConf } = usePlanCopyConf()


    // Set message
	yup.setLocale({
		// use constant translation keys for messages without values
		mixed : {
			required: ({path}) => (t("common:errors.required",{field : t(`common:fields.${path}`)})),
		},
        number: {
            moreThan : ({path, more}) => (t("common:errors.more_than",{field : t(`common:fields.${path}`), value: more})),
            lessThan : ({path, less}) => (t("common:errors.less_than",{field : t(`common:fields.${path}`), value: less})),
        }
	});



	// Schema Yup
	const schema = yup.object().shape({
		name: yup.string().required(),
	});

    

    const { register, setValue, trigger, handleSubmit, formState:{ errors } } = useForm({resolver: yupResolver(schema)});

    const { openNotification, helperErrorNotification } = useNotification()

	const [loading, setLoading] = useState(false);

    const [selectedChapters, setSelectedChapters] = useState({
        chapters: [],
        contents: [],
        goals: [],
        reqs: [],
    });



	/**
	 * Form Handler
	 * @param {form modal} data 
	 */
	async function confirmCopy(data) {
		if(selectedChapters.chapters.length > 0 && !loading){

            setLoading(true)

            let copier = null

            if(!confModel?.id){
                const newCopier = await createCopier({id: planData.organization_id, idPlan: planData.id, ...data})
                copier = newCopier.data
            }else{
                if(data.name != confModel.name)
                    updateCopier({idCorp: planData.organization_id, id: confModel.id, ...data})

                copier = confModel
            }

            let contentsToCreate = selectedChapters.contents.map(r => r.id)

            let requirementToCreate = selectedChapters.reqs.map(r => r.id)
            
            let contentsToDelete = []

            let requirementToDelete = []

            if(confModel){

               const copierContens = confModel.copier_chapter_contents.map(content => content.organization_chapter_content_id)

               const copierRequirements = confModel.copier_requirements.map(req => req.organization_requirement_id)

               contentsToCreate = contentsToCreate.filter(el => !copierContens.includes(el))

               requirementToCreate = requirementToCreate.filter(el => !copierRequirements.includes(el))

               let findContensDelete = copierContens.filter(el => !selectedChapters.contents.map(r => r.id).includes(el))

               contentsToDelete = confModel.copier_chapter_contents.filter(el => findContensDelete.includes(el.organization_chapter_content_id)).map(el => el.id)
               
               let findReqsDelete = copierRequirements.filter(el => !selectedChapters.reqs.map(r => r.id).includes(el))

               requirementToDelete = confModel.copier_requirements.filter(el => findReqsDelete.includes(el.organization_requirement_id)).map(el => el.id)
            }

            // Delete reqs
            for (const req of requirementToDelete) {
                await deleteCopierRequirement({idCorp: planData.organization_id, id: req })
            }

            // Delete contents
            for (const content of contentsToDelete) {
                await deleteCopierContent({idCorp: planData.organization_id, id: content })
            }


            // add content
            for (const content of contentsToCreate) {
                await createCopierContent({idCorp: planData.organization_id, idCopier: copier.id, organization_chapter_content_id: content})
            }
            
            // Add reqs
            for (const req of requirementToCreate) {
                await createCopierRequirement({idCorp: planData.organization_id, idCopier: copier.id, organization_requirement_id: req})
            }

            setUpdateListConf(i => i+1)
            
            setLoading(false)

            setOpen(false)


        }else{
            openNotification({ text: "Nessun contenuto da copiare selezionato", status: "error"})            
        }
	}




    useEffect(() => {
        if(open){
            setValue("name", confModel ? confModel.name : '')

            if(!confModel){
                setSelectedChapters({
                    chapters: [],
                    contents: [],
                    goals: [],
                    reqs: [],
                })
            }else{

                const data = {
                    chapters: [],
                    contents: [],
                    goals: [],
                    reqs: [],
                }

                for(const chapt of template){
                    for(const content of chapt.contents){
                        if(content.content_type === "chapter_goal"){
                            for(const req of content.requirements){
                                if(confModel.copier_requirements.map(req => req.organization_requirement_id).includes(req.id)){
                                    data.reqs.push({
                                        id: req.id,
                                        goal_id: content.id,
                                        chapter_id: chapt.id
                                    })

                                    if(!data.goals.find(g => g.id === content.id)){
                                        data.goals.push({
                                            id: content.id,
                                            chapter_id: chapt.id
                                        })
                                    }

                                    data.chapters = [...new Set([...data.chapters, chapt.id])]
                                }
                            }
                        }else{
                            if(confModel.copier_chapter_contents.map(content => content.organization_chapter_content_id).includes(content.id)){
                                data.contents.push({
                                    id: content.id,
                                    chapter_id: chapt.id
                                })

                                data.chapters = [...new Set([...data.chapters, chapt.id])]
                            }
                        }
                    }
                }
                setSelectedChapters(data)
            }
        }
    },[open])


    /**
	 * Errors Form
	 */
	useEffect(() => {
		if (Object.keys(errors).length) {
            const fields = Object.values(errors).map(er => er.message)
			openNotification({ fields, status: "error"})
		}
	}, [errors]);




	return (
        <ModalContainer isOpen={open} setIsOpen={setOpen} disableClose={loading}>
            <div className="main_modal_content conf_plan_popup" >

                <div className="main_close" onClick={() => !loading && setOpen(false)}>
                    <span className="icon_10 default close icon_mask !bg-white"></span>
                </div>

                <div className="main_header_modal">
                    <span className="title">Nuova configurazione</span>

                    <div className="main_action_detail">
                        <form id="content-conf-form" onSubmit={handleSubmit(confirmCopy)} className="global_form_landing">
                            <div className="base">

                                <div className="wrap_input_landing">
                                    <label className="label_input" htmlFor="name_conf">Nome</label>
                                    <input type="text" {...register("name")} className={"landing" + (errors.name ? ' err' : '')} id="name_conf" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className={"main_body_modal"}>
                    <OverlayScrollbarsComponent defer options={{ scrollbars: { autoHide: "scroll" }}} className="main_list_capthers">
                        {/* Chapters */}
                        <BoxPlanOrganizationTemplate
                            template={template}
                            setSelectedItems={setSelectedChapters}
                            showAllContents={false}
                            selectedItems={selectedChapters}
                        />
                    </OverlayScrollbarsComponent>
                </div>

                <div className="main_footer_modal">

                    <div className="right_content">

                        {/* Main Cta */}
                        <button form="content-conf-form" className={'button fill success' + (loading ? " loading" : "")}>
                            <div className="load_content">Conferma</div>
                            <div className="main_loader_icon fade_in"></div>
                        </button>
                    </div>
                </div>
            </div>
        </ModalContainer>
	);
}
