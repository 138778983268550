import { forwardRef } from "react";
import "./CardChapterContent.scss"
import ActionBtnItem from "../utils/ActionBtnItem";
import { getTemplatePlanContent } from "../../services/plans-template.service";
import {usePlanTemplateStore} from "../../store/plan-template.store";


const CardChapterContent = forwardRef(({className = '', item, isDragging, position, onClick, isActived, isContents, dragHandleProps, isEditable, isDraggable = true, onEdit = () => {}, onDelete = () => {}, ...props}, ref) => {

    const [
        updateState,
        duplicateContent,
        deleteContent
    ] = usePlanTemplateStore(state => [
        state.updateState,
        state.duplicateContent,
        state.deleteContent,
    ])


    /**
     * Edit item
     */
    const editItem = () => {
        /*if(isContents){*/
            updateState({ contentModel: {model: item}, isCrudContentOpen: true})
        /*}else{
            updateState({ goalModel: {model: item}, isCrudGoalOpen: true})
        }*/
    }



    /**
     * Delete item
     */
    const deleteItem = () => {
        if(isContents){

            updateState({
                deleteConf: {
                    title: "Elimina documento",
                    icon: "icon_16 default text_box",
                    subtitle: item.data.name,
                    confirmHandler: deleteContent,
                },
                deleteItem: item,
                isDeleteModalOpen: true
            })
        }else{
            updateState({
                deleteConf: {
                    title: "Elimina obiettivo",
                    icon: "icon_16 default layers",
                    subtitle: item.data.name,
                    description: item.data.description,
                    confirmHandler: deleteContent,
                },
                deleteItem: item,
                isDeleteModalOpen: true
            })
        }
    }



    /**
     * Duplicate Item
     */
    const duplicateItem = async () => {
        if(isContents){
            let cloneItem =  item
            if(!item.data.editorjs){
                const res = await getTemplatePlanContent({id: item.content_id})
                cloneItem.data.editorjs = res.data.editorjs
            }

            duplicateContent(cloneItem, position+1)
        }else{
            duplicateContent(item, position+1)
        }
    }


    const filteredReqs = (item) => item?.data?.template_requirements?.filter(chapter => chapter.localStatus !== "delete") ?? []
    

    return (
        <div ref={ref} {...props} onClick={() => onClick(item, isContents)} className={"main_comp_card_chapter_content " + (isDragging ? " is_dragging " : '') + (isActived ? " active " : '') + (!isDraggable ? " not_draggable " : '' ) + (className)}>
            <div className="main_intro">
                <div className="wrap_intro">

                    <div className="main_icon type">
                        <span className={"icon_16 default " + (isContents ? "text_box" : "layers")}></span>
                    </div>

                    {isDraggable &&
                        <div className="main_icon draggable" {...dragHandleProps}>
                            <span className="icon_10 default draggable"></span>
                        </div>
                    }

                    {!isContents &&
                        item.data.status != "default" &&
                            <div className="main_icon">
                                <span className={"icon_16 default icon_mask !bg-secondary-100 " + (item.data.status === "new" ? "megaphone" : "update")}></span>
                            </div>
                    }
                    
                    {isContents &&
                        item.data.editable == false &&
                            <div className="main_icon">
                                <span className={"icon_16 default icon_mask !bg-secondary-100 lock"}></span>
                            </div>
                    }

                    <span className="title">
                        {isContents ? "Documento" : item.data.name}
                    </span>
                </div>

                {/* Actions */}
                {isEditable &&
                    <div className="main_actions">

                        {/* Edit */}
                        <ActionBtnItem tooltip="Modifica" onClick={editItem} icon="edit" />

                        {/* Clone */}
                        {isDraggable &&
                            <ActionBtnItem tooltip="Duplica" onClick={duplicateItem} icon="duplicate" />
                        }

                        {/* Delete */}
                        <ActionBtnItem onClick={deleteItem} tooltip="Elimina" icon="delete" />

                    </div>
                }
            </div>

            <div className="main_desc">

                <div className="desc">{isContents ? item.data.name : item.data.description}</div>

                {!isContents &&
                    <div className="main_sum">
                        <div className="main_icon">
                            <span className="icon_16 layer default"></span>
                        </div>
                        <span><b>{filteredReqs(item).length}</b> linee d'azione</span>
                    </div>
                }
            </div>
        </div>
);
})

export default CardChapterContent;