import { createExportAgenda, showExportAgenda } from "../services/agendas.service"

export const createDownloadAgenda = (body, onSuccess = () => {}, onError = () => {},) => {
    createExportAgenda(body, (res) => {
        getDownload({ id: res.data.id, idCorp: body.idCorp, idAgenda: body.idAgenda }, onSuccess, onError)
    }, (error) => {
        onError(error)
    })
}



const getDownload = ({ id, idCorp, idAgenda }, onSuccess = () => {}, onError = () => {}) => {

    showExportAgenda({ idCorp, idAgenda, id }, (res) => {

        if(res.data.status === "pending"){
            setTimeout(() => {
                getDownload({ id, idCorp, idAgenda }, onSuccess, onError)
            }, 2000)
        }else{
            onSuccess(res)
        }
    }, (error) => {
        onError(error)
    })
}
