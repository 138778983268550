import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import useNotification from "../../../hooks/notification.hook";
import ModalContainer from "../ModalContainer";
import { useTranslation } from 'react-i18next';
import usePlanTemplate from '../../../hooks/plan-template.hook';
import {usePlanTemplateStore} from "../../../store/plan-template.store";
import {reorderItems} from "../../../helper/common.helper";


export default function CrudChapterModal({open, setOpen, chapterModel, onEdit = function(){}, onInsert = function(){}}) {

    const { t } = useTranslation()

	// Set message
	yup.setLocale({
		// use constant translation keys for messages without values
		mixed : {
			required: ({path}) => (t("common:errors.required",{field : t(`common:fields.${path}`)})),
		}
	});

	// Schema Yup
	const schema = yup.object().shape({
		name: yup.string().required(),
	});

	const { register, setValue, clearErrors, handleSubmit, formState:{ errors } } = useForm({resolver: yupResolver(schema)});

	const { openNotification } = useNotification()


    const [
        chapterList,
        idNewCount,
        updateState
    ] = usePlanTemplateStore(state => [
        state.chapterList,
        state.idNewCount,
        state.updateState
    ])


	/**
	 * Form Handler
	 * @param {form modal} data 
	 */
	async function formHandler(data) {
        
        if(chapterModel?.model?.content_id){

            editChapter(data, chapterModel.model)

            onEdit(data)
            
        }else{

            addChapter(data, chapterModel?.position ? chapterModel?.position : 0)
            
            onInsert(data)
        }
	}


    /***
     * Edit Chapter
     */
    const editChapter = ({ name } , chapterModel) => {

        const cpt = chapterList.find(c => c.content_id === chapterModel.content_id)

        cpt.data.name = name

        if(!cpt?.localStatus)
            cpt.localStatus = "edit"

        updateState({ chapterList: [...chapterList]})
    }






    /**
     * Add Chapter
     * @param {*} param0
     * @param {*} destination
     */
    const addChapter = ({ name } , destination) => {

        const templateChapterModel = {
            content_id: `chapt-${idNewCount}`,
            data: {
                id: `chapt-${idNewCount}`,
                name: name,
                contents: []
            },
            content_type: "template_chapter",
            localStatus: "new"
        }

        chapterList.push(templateChapterModel)

        const orderedList = reorderItems(chapterList, chapterList.length-1, destination)

        updateState({ chapterList: [...orderedList], idNewCount: idNewCount+1, isChaptersOrdered: true })
    }



	/**
	 * Errors Form
	 */
	useEffect(() => {
		if (Object.keys(errors).length) {
            const fields = Object.values(errors).map(er => er.message)
			openNotification({ fields, status: "error"})
		}
	}, [errors]);





	/**
	 * Init value
	 */
	useEffect(() => {
		if (open) {
            clearErrors()
			setValue("name", chapterModel?.model?.data?.name ?? '')
		}
	}, [open]);





	return (
        <ModalContainer isOpen={open} setIsOpen={setOpen}>
            <div className={"main_modal_content"} >

                <div className="main_close" onClick={() => setOpen(false)}>
                    <span className="icon_10 default close icon_mask !bg-white"></span>
                </div>

                <div className="main_header_modal left_text">
                    <span className="title">{chapterModel?.model?.content_id ? "Modifica sezione" : "Nuovo sezione"}</span>
                </div>


                {/* Modal Form */}
                <div className={"main_body_modal"}>
                    <form id='crud-chapter-form' className='global_form_landing' onSubmit={handleSubmit(formHandler)} noValidate>

                        <div className="base ">

                            {/* Name */}
                            <div className="wrap_input_landing">
                                <label htmlFor='crud_plan_name' className='label_input'>Nome</label>
                                <input className={"landing big" + (errors.name ? ' err' : '')} id='crud_plan_name' type='text' {...register("name")}/>
                            </div>
                        </div>
                        
                    </form>
                </div>

                <div className="main_footer_modal">

                    <div className="right_content">

                        {/* Main Cta */}
                        <button form="crud-chapter-form" className='button fill success'>Conferma</button>
                    </div>
                </div>
            </div>
        </ModalContainer>
	);
}
