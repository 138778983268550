import { useEffect, useState, useRef } from "react";
import "./StatusPlanCrud.scss";
import ModalContainer from "./ModalContainer";
import useNotification from "../../hooks/notification.hook";
import { Transition } from "react-transition-group";
import useCommonHelper from "../../hooks/helper.hook";
import Table from "../utils/table/Table";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import ActionBtnItem from "../utils/ActionBtnItem";
import TextareaInput from "../input/TextareaInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { ARRAY_STATUS_ICON } from "../../helper/common.helper";
import {
  createTemplateStatus,
  deleteTemplateStatus,
  editTemplateStatus,
  getTemplateStatuses,
} from "../../services/plans-template-status.service";
import ConfirmModal from "./ConfirmPopup";
import NumberInput from "../input/NumberInput";

export default function StatusPlanCrud({
  open,
  setOpen,
  planTemplate,
  onListUpdate = () => {},
}) {
  const { openNotification, helperErrorNotification } = useNotification();

  const { animationClass } = useCommonHelper();

  const [loading, setLoading] = useState(false);

  const [wizardStep, setWizardStep] = useState(1);

  const [wizardVisible, setWizardVisible] = useState(1);

  const [statusList, setStatusList] = useState(
    planTemplate.requirement_statuses ?? [],
  );

  const [selectedStatus, setSelectedStatus] = useState();

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const firstStepRef = useRef(null);

  const secondStepRef = useRef(null);

  const { t } = useTranslation();

  // Set message
  yup.setLocale({
    // use constant translation keys for messages without values
    mixed: {
      required: ({ path }) =>
        t("common:errors.required", { field: t(`common:fields.${path}`) }),
    },
  });

  // Schema Yup
  const schema = yup.object().shape({
    icon: yup.string().required(),
    name: yup.string().required(),
    description: yup.string().required(),
    default: yup.bool(),
    /*done: yup.number().transform((_, val) => +val),*/
    done: yup.bool(),
    hasPercentage: yup.bool(),
    percentage_change: yup
      .number()
      .nullable()
      .when("hasPercentage", {
        is: (value) => !!value,
        then: (schema) => schema.min(0).max(100).required(),
      })
      .transform((_, val) => +val),
  });

  const {
    register,
    setValue,
    clearErrors,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const watchIcon = watch("icon");

  const formHandler = (data) => {
    const { hasPercentage, ...formData } = data;

    const body = {
      ...data,
      percentage_change: hasPercentage ? formData.percentage_change : null,
    };

    if (!loading) {
      setLoading(true);

      if (selectedStatus?.id) {
        editTemplateStatus(
          { id: selectedStatus?.id, ...body },
          () => {
            getListStatus();
            setWizardStep(0);
            setLoading(false);
            openNotification({
              text: "Stato aggiornato con successo!",
              status: "success",
            });
          },
          (err) => {
            setLoading(false);
            helperErrorNotification(err);
          },
        );
      } else {
        createTemplateStatus(
          { idPlan: planTemplate.id, ...body },
          (response) => {
            getListStatus();
            setWizardStep(0);
            setLoading(false);
            openNotification({
              text: "Stato creato con successo!",
              status: "success",
            });
          },
          (err) => {
            setLoading(false);
            helperErrorNotification(err);
          },
        );
      }
    }
  };

  const selectItem = (status) => {
    setValue("description", status?.description ?? "");
    setValue("name", status?.name ?? "");
    setValue("icon", status?.icon ?? "");
    setValue("default", status?.default ?? false);
    setValue("done", status?.done ?? false);
    setValue("percentage_change", status?.percentage_change ?? false);
    setValue(
      "hasPercentage",
      (status?.percentage_change !== null &&
        status?.percentage_change !== undefined) ??
        false,
    );
    setSelectedStatus(status);
    setWizardStep(0);
  };

  const getListStatus = () => {
    getTemplateStatuses({ id: planTemplate.id }, (response) => {
      setStatusList(response.data);
      onListUpdate(response.data);
    });
  };

  const deleteStatusHandler = () => {
    if (!loading) {
      deleteTemplateStatus(
        selectedStatus.id,
        () => {
          setSelectedStatus(null);
          setIsDeleteOpen(false);
          openNotification({
            text: "Stato eliminato con successo!",
            status: "success",
          });
          getListStatus();
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          helperErrorNotification(err);
        },
      );
    }
  };

  useEffect(() => {
    if (open) {
      setWizardStep(1);
      setWizardVisible(1);
      setValue("description", "");
      setValue("name", "");
      setValue("icon", "");
    }
  }, [open]);

  useEffect(() => {
    clearErrors();
  }, [selectedStatus]);

  /**
   * Errors Form
   */
  useEffect(() => {
    if (Object.keys(errors).length) {
      const fields = Object.values(errors).map((er) => er.message);
      openNotification({ fields, status: "error" });
    }
  }, [errors]);

  const textCantDelete =
    "Impossibile eliminare gli stati impostati come predefinito, utilizzati all'interno di un piano o originati da un piano clonato";

  return (
    <ModalContainer isOpen={open} setIsOpen={setOpen} disableClose={loading}>
      <div className="main_modal_content status_plan_crud_popup">
        <div className="main_close" onClick={() => !loading && setOpen(false)}>
          <span className="icon_10 default close icon_mask !bg-white"></span>
        </div>

        {wizardVisible === 1 && (
          <div
            className={
              "transition-opacity " + (wizardStep === 0 ? "opacity-0" : "")
            }
          >
            <div className="main_header_modal flex_header">
              {/* Left content */}
              <div className="left_content">
                <div className="main_desc">
                  <span className="title">Gestione stati dei contenuti</span>
                </div>
              </div>

              <div className="main_plan">
                <div className="main_icon">
                  <span
                    className={
                      "icon_24 default plan icon_mask !bg-primary-100 " +
                      (!planTemplate.diff_download?.length ? "piano" : "branch")
                    }
                  ></span>
                </div>
                <div className="main_info">
                  <div className="name">{planTemplate.name}</div>
                  <div className="description">{planTemplate.description}</div>
                </div>
              </div>

              <div className="pt-[10px] text-12 text-dark-60 font-medium">
                Nota: {textCantDelete}
              </div>
            </div>
          </div>
        )}

        {wizardVisible === 2 && (
          <div
            className={
              "transition-opacity " + (wizardStep === 0 ? "opacity-0" : "")
            }
          >
            <div className="main_header_modal flex_header">
              <div className="left_content">
                <div
                  className="main_back_button"
                  onClick={() => setWizardStep(0)}
                >
                  <span className="icon_16 default arrow left icon_mask !bg-interactive-100"></span>
                </div>

                <div className="main_desc">
                  <span className="title">
                    {selectedStatus?.id ? "Modifica stato" : "Nuovo stato"}
                  </span>
                  <div className="desc">{planTemplate.name}</div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={"main_body_modal"}>
          {/* List Reqs */}

          <Transition
            nodeRef={firstStepRef}
            in={wizardStep === 1}
            mountOnEnter
            onExited={() => {
              setWizardStep(2);
              setWizardVisible(2);
            }}
            unmountOnExit
            timeout={{ enter: 250, exit: 250 }}
          >
            {(state) => (
              <div
                ref={firstStepRef}
                className={
                  "main_list_status " +
                  animationClass(state, "wizard_step_in", "wizard_step_out")
                }
              >
                <div className="main_title">
                  <div className="left_content">
                    Stati a disposizione: {statusList.length}
                  </div>
                  <div
                    className="right_content"
                    onClick={() => selectItem(null)}
                  >
                    <div className="icon_16 default plus icon_mask !bg-interactive-100"></div>
                    Nuovo
                  </div>
                </div>

                <OverlayScrollbarsComponent
                  defer
                  options={{ scrollbars: { autoHide: "scroll" } }}
                  className="main_table_status"
                >
                  {/* Table organizations */}
                  <Table className="layout_popup big_row">
                    {/* Table Heade */}
                    <Table.Head className="sticky_head">
                      <Table.Column className="!pl-4 !pb-3">Stato</Table.Column>
                      <Table.Column className="!pb-3">Nota</Table.Column>
                      <Table.Column className="!pb-3">Predefinito</Table.Column>
                      <Table.Column className="!pb-3">
                        Di Completamento
                      </Table.Column>
                      <Table.Column></Table.Column>
                    </Table.Head>

                    {/* Table Body */}
                    <Table.Body>
                      {statusList.map((status, index) => (
                        <Table.Row key={status.id}>
                          <Table.Cell width={180}>
                            <div className="flex gap-3 items-center">
                              <div className="flex flex-none">
                                <div
                                  className={"icon_20 status " + status.icon}
                                ></div>
                              </div>
                              {status.name}
                            </div>
                          </Table.Cell>

                          <Table.Cell width={50}>
                            <div className="flex">
                              <div
                                className="icon_16 default note icon_mask !bg-interactive-100"
                                data-tooltip-id="tooltip_small_top"
                                data-tooltip-offset={10}
                                data-tooltip-content={status.description}
                              ></div>
                            </div>
                          </Table.Cell>

                          <Table.Cell>
                            {status.default ? "Si" : "No"}
                          </Table.Cell>

                          <Table.Cell>{status.done ? "Si" : "No"}</Table.Cell>

                          <Table.Cell>
                            <div className="flex gap-3">
                              {/* Edit */}
                              <ActionBtnItem
                                tooltip="Modifica"
                                onClick={() => selectItem(status)}
                                icon="edit"
                              />

                              {/* Delete*/}
                              <ActionBtnItem
                                disabled={!status.can_be_deleted}
                                tooltip={status.can_be_deleted ? "Elimina" : ""}
                                onClick={() => {
                                  setIsDeleteOpen(true);
                                  setSelectedStatus(status);
                                }}
                                icon="delete"
                              />
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </OverlayScrollbarsComponent>
              </div>
            )}
          </Transition>

          <Transition
            nodeRef={secondStepRef}
            in={wizardStep === 2}
            mountOnEnter
            onExited={() => {
              setWizardStep(1);
              setWizardVisible(1);
              setSelectedStatus(null);
            }}
            unmountOnExit
            timeout={{ enter: 250, exit: 250 }}
          >
            {(state) => (
              <div
                ref={secondStepRef}
                className={animationClass(
                  state,
                  "wizard_step_in",
                  "wizard_step_out",
                )}
              >
                <form
                  id="crud-status-plan-form"
                  className="global_form_landing"
                  onSubmit={handleSubmit(formHandler)}
                  noValidate
                >
                  <div className="base">
                    <label className="label_input">Icona</label>
                    <div className="main_icon_list">
                      {ARRAY_STATUS_ICON.map((status, i) => (
                        <div
                          className={
                            "main_icon" +
                            (watchIcon === status.icon ? " active" : "")
                          }
                          onClick={() => setValue("icon", status.icon)}
                          key={i}
                        >
                          <span
                            className={"icon_20 status " + status.icon}
                          ></span>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Name */}
                  <div className="base ">
                    <div className="wrap_input_landing">
                      <label htmlFor="crud_goal_code" className="label_input">
                        Nome
                      </label>
                      <input
                        className={"landing big" + (errors.name ? " err" : "")}
                        id="crud_goal_code"
                        type="text"
                        {...register("name")}
                      />
                    </div>
                  </div>

                  {/* Description */}
                  <div className="base ">
                    <label
                      htmlFor="crud_plan_status_description"
                      className="label_input"
                    >
                      Descrizione
                    </label>
                    <input
                      className={
                        "landing big" + (errors.description ? " err" : "")
                      }
                      id="crud_plan_status_description"
                      type="text"
                      {...register("description")}
                    />
                  </div>

                  {/* Percentage Enable */}
                  <div className="base">
                    <div className="toggle_landing">
                      <input
                        type="checkbox"
                        id="crud_plan_status_has_percentage"
                        className="landing"
                        {...register("hasPercentage")}
                      />
                      <label htmlFor="crud_plan_status_has_percentage">
                        <div>Imposta percentuale completamento</div>
                        <div className="text-12 text-dark-60 pt-2">
                          Imposta una percentuale di completamento della linea
                          d'azione
                        </div>
                      </label>
                    </div>
                    {/* Percentage */}
                    {watch("hasPercentage") && (
                      <div className="wrap_input_landing pt-[12px]">
                        <label
                          htmlFor="crud_plan_status_percentage"
                          className="label_input"
                        >
                          Percentuale
                        </label>
                        <NumberInput
                          min={0}
                          max={100}
                          id="crud_plan_status_percentage"
                          className={
                            "big" + (errors.percentage_change ? " err" : "")
                          }
                          {...register("percentage_change")}
                        />
                      </div>
                    )}
                  </div>

                  {/* Default */}
                  <div className="base mt-3">
                    <div
                      className={
                        "toggle_landing" +
                        (selectedStatus?.default ? " dis" : "")
                      }
                    >
                      <input
                        type="checkbox"
                        disabled={selectedStatus?.default}
                        id="crud_plan_status_is_default"
                        className="landing"
                        {...register("default")}
                      />
                      <label htmlFor="crud_plan_status_is_default">
                        <div>Imposta come stato predefinito</div>
                        <div className="text-12 text-dark-60 pt-2">
                          Una volta attivato, verrà attribuito come stato
                          predefinito per tutte le linee d'azione del piano in
                          fase di creazione. Non sarà possibile possibile
                          eliminare lo stato.
                        </div>
                      </label>
                    </div>
                  </div>

                  {/* Completed */}
                  <div className="base ">
                    <div className="toggle_landing">
                      <input
                        type="checkbox"
                        id="crud_plan_status_is_completed"
                        className="landing"
                        {...register("done")}
                      />
                      <label htmlFor="crud_plan_status_is_completed">
                        <div>Imposta come stato di completamento</div>
                        <div className="text-12 text-dark-60 pt-2">
                          La sezione a cui viene attribuito questo stato viene
                          identificata come completata e sarà rimossa dalla
                          lista di cose da fare
                        </div>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </Transition>
        </div>

        <div
          className={
            "main_footer_modal" + (wizardVisible === 1 ? " empty" : "")
          }
        >
          <div className="right_content">
            {/*{wizardVisible === 1 &&
                            <div className={"transition-opacity " + (wizardStep === 0 ? "opacity-0" : '')}>
                                <button onClick={() => setWizardStep(0)} className='button fill success'>Procedi</button>
                            </div>
                        }*/}

            {wizardVisible === 2 && (
              <div
                className={
                  "transition-opacity " + (wizardStep === 0 ? "opacity-0" : "")
                }
              >
                <button
                  form={"crud-status-plan-form"}
                  className={
                    "button fill success" + (loading ? " loading" : "")
                  }
                >
                  <div className="load_content">Salva</div>
                  <div className="main_loader_icon fade_in"></div>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Confirm content */}
      <ConfirmModal
        open={isDeleteOpen}
        setOpen={setIsDeleteOpen}
        title={"Elimina stato"}
        alertMessage="Attenzione! Questa operazione è irreversibile! <b>Sei sicuro di procedere?</b>"
        ctaLabel="Elimina"
        onConfirm={deleteStatusHandler}
      />
    </ModalContainer>
  );
}
