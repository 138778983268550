import { useContext } from "react";
import PlanCopyConfContext from "../context/plan-copy-conf.context";
import { deleteCopier, getCopier } from "../services/organizations.service";


function usePlanCopyConf() {

    const {
         
        crudConfOpen,
        setCrudConfOpen,

        crudModel,
        setCrudModel,
        
        selectedConf,
        setSelectedConf,

        selectedConfId,
        setSelectedConfId,

        copyPopup,
        setCopyPopup,

        planChapters,
        setPlanChapters,

        planData,
        setPlanData,

        listConfs,
        setListConfs,

        deleteModalOpen,
        setDeleteModalOpen,

        deleteConf,
        setDeleteConf,

        deleteItem,
        setDeleteItem,

        updateListConf,
        setUpdateListConf,

        listOrganizations,
        setListOrganizations,

        loadingCopyPlan,
        setLoadingCopyPlan,

    } = useContext(PlanCopyConfContext)



    /**
     * Update selected configuration
     * @param {*} newConf 
     */
    const updateSelectedConf = (newConf) => {
        
        setSelectedConfId(newConf)

        if(newConf != null)
            updateShowedConf(newConf)
        else
            setSelectedConf(null)
    }



    /**
     * Get Copier Detail
     * @param {*} conf 
     */
    const updateShowedConf = (conf) => {

        setLoadingCopyPlan(loaders => ({...loaders, showDetail: true}))

        getCopier({id: conf, idCorp: planData.organization.id}, (res) => {
            setSelectedConf(res.data)
            setLoadingCopyPlan(loaders => ({...loaders, showDetail: false}))
        })
    }



    /**
     * Delete selected configuration
     * @param {*} newConf 
     */
    const deleteConfigurationHandler = (item) => {

        deleteCopier({id: item.id, idCorp: planData.organization_id}, (res) => {
            if (selectedConf?.id === item.id) {
                setSelectedConf(null)
                setSelectedConfId(null)
            }
            setUpdateListConf(i => i+1)
        })
        
        setDeleteModalOpen(false)
    }



     /**
     * Delete item request
     * @param {*} item 
     */
     const deleteConfRequest = (item) => {

        setDeleteItem(item)

        setDeleteConf({
            title: "Elimina configurazione",
            icon: "icon_16 default folder",
            subtitle: item.name,
            confirmHandler: deleteConfigurationHandler,
        })

        setDeleteModalOpen(true)
    }


    /**
     * Edit item request
     * @param {*} item 
     */
    const editConfRequest = async (item) => {
        
        if(!loadingCopyPlan.editConf){
            
            setLoadingCopyPlan(loaders => ({ ...loaders, editConf: true}))

            const copier = await getCopier({id: item.id, idCorp: planData.organization_id})
            
            setLoadingCopyPlan(loaders => ({ ...loaders, editConf: false}))
    
            setCrudModel(copier.data)
    
            setCrudConfOpen(true)
        }
    }





    return {
        crudConfOpen,
        setCrudConfOpen,

        selectedConf,
        setSelectedConf,

        selectedConfId,
        setSelectedConfId,

        updateSelectedConf,

        copyPopup,
        setCopyPopup,

        planChapters,
        setPlanChapters,

        planData,
        setPlanData,

        listConfs,
        setListConfs,

        deleteModalOpen,
        setDeleteModalOpen,

        deleteConf,
        setDeleteConf,

        deleteItem,
        setDeleteItem,

        deleteConfigurationHandler,
        
        updateListConf,
        setUpdateListConf,

        listOrganizations,
        setListOrganizations,

        loadingCopyPlan,
        setLoadingCopyPlan,
        
        crudModel,
        setCrudModel,
        updateShowedConf,


        deleteConfRequest,
        editConfRequest
    }
}

export default usePlanCopyConf;