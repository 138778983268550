import c from "./Notification.module.scss"
import { Transition } from "react-transition-group";
import useNotification from '../hooks/notification.hook';
import { useContext, useRef } from 'react';
import NotificationContext from "../context/notification.context";
import { useState } from "react";
import useCommonHelper from "../hooks/helper.hook";

function Notification() {
    
    const { notificationActived, text, status, fields, close } = useContext(NotificationContext);

    const {closeNotification} = useNotification()

    const [ content , setContent] = useState({
        text : null,
        status: null,
        fields: []
    })


    const notificationEl = useRef(null)

    const { animationClass } = useCommonHelper()

    function initContent(){
        setContent({ text ,status, fields})
    }

    function emptyContent(){
        setContent({
            text : null,
            status: null,
            fields: []
        })
    }

    return (
        <Transition
            nodeRef={notificationEl} 
            in={notificationActived} 
            mountOnEnter
            unmountOnExit
            onEnter={initContent}
            onExited={emptyContent}
            timeout={{enter: 200, exit: 200}}
        >
            {state => (

                <div ref={notificationEl} className={`${c.main_notification} ${animationClass(state,c.notification_in,c.notification_out)} ${c[content.status]}`}>
                    <div className={c.main_title}>
                        {content?.status && (
                            <div className={c.main_icon}>
                                {content.status == "success" ? <span className="icon_16 default check icon_mask !bg-success-100"></span>
                                : content.status == "error" ? <span className="icon_16 default info icon_mask !bg-red-100"></span>
                                : content.status == "alert" ? <span className="icon_16 default warning icon_mask !bg-orange-100"></span>
                                : content.status == "info" ? <span className="icon_16 default information icon_mask !bg-primary-100"></span>
                                : ''}
                            </div>
                        )}
                        <div className={c.main_text}>
                            <div className={c.title}>
                                {(content?.text && !content.fields.length > 0) ? content.text : ''}
                                {content.fields.length > 0  && "Errore di validazione"}
                            </div>
                            {content.fields.length > 0 &&
                                <ul className={c.main_list_field}>
                                    {content.fields.map((field,i) => (
                                        <li key={i} className={c.field_error}>{field}</li>
                                    ))}
                                </ul>
                            }
                        </div>
                    </div>

                    <div className={c.main_close} onClick={closeNotification}>
                        <span className="icon_16 default cross opacity-50"></span>
                    </div>
                </div>
            )}
        </Transition>
    );
}

export default Notification;