export default class JustifyTune {
	constructor({ api, data, config, block}) {
		this.api = api;
		this.data = data;
		this.block = block;
		this.config = config;
		this.className = "ce-justify-text--justify";
	}

	wrap(blockContent) {
        blockContent.classList.toggle(this.className, !!this.data)
        return blockContent
    }

	render() {
		return {
			icon: "<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6 6H14' stroke='black' stroke-width='2' stroke-linecap='round'/><path d='M6 10H14' stroke='black' stroke-width='2' stroke-linecap='round'/><path d='M6 14H11' stroke='black' stroke-width='2' stroke-linecap='round'/></svg>",
			label: "Giustifica testo",
			onActivate: () => {
				this.data = this.data === true ? false : true
                this.block.holder.children[0].classList.toggle(this.className, this.data)

                const index = this.api.blocks.getCurrentBlockIndex()
                this.api.blocks.insertNewBlock()
                this.api.blocks.delete(index+1)

				this.api.saver.save()
			},
            isActive: this.data === true,
			toggle: true,
		};
	}



    save(){
        return this.data
    }

	static get isTune() {
		return true;
	}
}
