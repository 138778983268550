function Head({children, className = '', ...props}) {
    return (
        <thead {...props} className={"main_table_head " + className}>
            <tr>
                {children}
            </tr>
        </thead>
    );
}

export default Head;