import c from "./ListActions.module.scss"
import ItemAction from "./ItemAction";

function ListActions({ children }) {
    return (
        <div className={c.list_container}>
            {children}
        </div>
    );
}


ListActions.Item = ItemAction


export default ListActions;