import c from "./Footer.module.scss"

function Footer() {
    return (
        <div className={c.main_footer}>
            <div className={c.wrap_footer}>

                <div className={c.left_content}>
                    <img src="/images/logo-footer.svg" alt="" className="logo" />
                    <span className={c.label}>&copy; {new Date().getFullYear()} Piano TD - Transizione Digitale</span>
                </div>

                <div className={c.right_content}>
                    <a href="https://www.transizionedigitale.it/informativa-privacy/" className={c.label} target="_blank" rel="noopener noreferrer">Privacy ›</a>
                    <span className={c.label}>Versione 0.1.17</span>
                </div>
            </div>
        </div>
    );
}

export default Footer;