import { useState, useRef, createContext, useEffect } from "react";

const OrganizationsPickerContext = createContext({
	filteredList: "",
	setFilteredList: function () {},
	filterType: "",
	setFilterType: function () {},
	selectedEntity: "",
	setSelectedEntity: function () {},
	organizations : [],
	searchInput: ""
});

export function OrganizationsPickerContextProvider({ children, organizations = [], types = [] }) {

	// Notification attributes
    const [filteredList, setFilteredList] = useState(organizations);

	const [filterType, setFilterType] = useState([]);

	const [selectedEntity, setSelectedEntity] = useState([]);

    const searchInput = useRef()

	useEffect(() => {

		setFilteredList(organizations)

    }, [organizations])


	const context = {
		filteredList,
		setFilteredList,
		filterType,
		setFilterType,
		selectedEntity,
		setSelectedEntity,
		searchInput,
		organizations,
		types
	};

	return (
		<OrganizationsPickerContext.Provider value={context}>
			{children}
		</OrganizationsPickerContext.Provider>
	);
}

export default OrganizationsPickerContext;
