import { useEffect, useState, Fragment } from "react";
import "./MoveActionPopup.scss"
import ModalContainer from '../ModalContainer';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import usePlanTemplate from '../../../hooks/plan-template.hook';
import EmptyBox from '../../emptystate/EmptyBox';
import {reorderItems} from "../../../helper/common.helper";
import {usePlanTemplateStore} from "../../../store/plan-template.store";


export default function MoveActionModal({open, setOpen, action}) {

	const [ selectedChapters, setSelectedChapters ] = useState([]);

    const [
        chapterList,
        idNewCount,
        deleteRequirement,
        updateState
    ] = usePlanTemplateStore(state => [
        state.chapterList,
        state.idNewCount,
        state.deleteRequirement,
        state.updateState
    ])
    

	/**
	 * Confirm Handler
	 * @param {form modal} data 
	 */
	async function confirmMove() {

        if(selectedChapters.length > 0){

            deleteRequirement(action.item, action.goal)

            let counterNew = idNewCount

            selectedChapters.forEach(item => {

                const chapter = chapterList.find(c => c.content_id === item.chapterId)

                const goal = chapter.data.contents.find(g => g.content_id === item.goalId && g.content_type === "template_goal")

                const newItem = {
                    ...action.item,
                    id: `move-r-${counterNew}`,
                    localStatus: "new",
                    template_goal_id: goal.content_id
                }

                goal.data.template_requirements.push(newItem)

                const orderedList = reorderItems(goal.data.template_requirements, goal.data.template_requirements.length-1, goal.data.template_requirements.length)

                goal.data.template_requirements = orderedList

                counterNew++
            })

            updateState({ chapterList: [...chapterList ], idNewCount: counterNew})
    
            setOpen(false)
        }
	}

    useEffect(() => {
        if(open){
            setSelectedChapters([])
        }
    },[open])



    
    /**
     *  on change handler
     * @param {*} e 
     * @param {*} goal 
     */
    function onChangeCheck(e,goal){
        if(e.target.checked){
            setSelectedChapters(items => [...items, {goalId: goal.content_id, chapterId: goal.data.template_chapter_id}])
        }else{
            setSelectedChapters(items => items.filter(item => item.goalId != goal.content_id))
        }
    }


    /**
     * Selected status check
     * @param {*} goal 
     * @returns 
     */
    const selectedCheck = (goal) => selectedChapters.find(item => item.goalId == goal.content_id)

    /**
     * Filter list 
     */
    const filteredList = chapterList.filter(c => c.localStatus !== "delete")
        .filter(c => c.data.contents.filter(g => g.content_id != action?.goal.content_id && g.localStatus != "delete" && g.content_type === "template_goal").length > 0)


	return (
        <ModalContainer isOpen={open} setIsOpen={setOpen}>
            <div className="main_modal_content move_action_popup" >

                <div className="main_close" onClick={() => setOpen(false)}>
                    <span className="icon_10 default close icon_mask !bg-white"></span>
                </div>

                <div className="main_header_modal">
                    <span className="title">Sposta Linea d’azione</span>

                    <div className="main_action_detail">
                        <div className="main_intro">
                            <div className="main_icon">
                                <span className="icon_16 default layer"></span>
                            </div>
                            <div className="intro">Linea d'azione</div>
                        </div>
                        {action && <div className="name">{action.name}</div>}
                    </div>
                </div>

                <div className={"main_body_modal"}>
                    {filteredList.length > 0 ?
                        <OverlayScrollbarsComponent defer options={{ scrollbars: { autoHide: "scroll" }}} className="main_list_capthers">
                            {filteredList.map((chapter, i) => (
                                chapter.data.contents.length > 0 &&
                                    (<Fragment key={i}>

                                        {/* Chapter Item */}
                                        <div className="item_list capther">
                                            <div className="main_icon">
                                                <span className="icon_16 default folder icon_mask !bg-dark-70"></span>
                                            </div>
                                            <div className="name">Sezione {i+1} - {chapter.data.name}</div>
                                        </div>

                                        {/* List Goals */}
                                        <div className="main_list_goals">
                                            {chapter.data.contents.map((goal,k) => (
                                                (goal.content_type === "template_goal" && goal.content_id !== action?.goal?.content_id) &&
                                                    <div key={k} className={"item_list goal" + (selectedCheck(goal) ? " active" : '')}>
                                                        <div className="check_landing small">
                                                            <input type="checkbox" defaultChecked={selectedCheck(goal)} onChange={(e) => onChangeCheck(e, goal)} id={"goal-" + goal.content_id} />
                                                            <label htmlFor={"goal-" + goal.content_id} className="icon">
                                                                <div className="main_icon">
                                                                    <span className="icon_16 default layers"></span>
                                                                </div>
                                                                <span className="name">{goal.data.name}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                            ))}
                                        </div>

                                    </Fragment>)
                            ))}

                        </OverlayScrollbarsComponent>
                        :
                        <EmptyBox 
                            icon="icon_75 default archive"
                            title="Nessun obiettivo disponibile"
                            description="Non ci sono altri obiettivi in cui spostare la linea d'azione"
                        />
                    }
                </div>

                <div className="main_footer_modal">

                    <div className="right_content">

                        {/* Main Cta */}
                        <button onClick={confirmMove} className='button fill success'>Conferma</button>
                    </div>
                </div>
            </div>
        </ModalContainer>
	);
}
