import ModalContainer from "./ModalContainer";
import "./ConfirmPopup.scss"


export default function ConfirmModal({open, setOpen, title, subtitle, icon, description, counterGoals, counterCaphters, counterActions, counterContents, alertMessage, ctaLabel, onConfirm = () => {}, onCancel }) {

	return (
        <ModalContainer isOpen={open} setIsOpen={setOpen}>
            <div className={"main_modal_content confirm_popup"} >

                <div className="main_close" onClick={() => setOpen(false)}>
                    <span className="icon_10 default close icon_mask !bg-white"></span>
                </div>

                <div className="main_header_modal left_text">
                    <span className="title">{title}</span>
                </div>


                {/* Modal Content */}
                <div className={"main_body_modal"}>

                    {/* Subtitle */}
                    {subtitle &&
                        <div className="main_subtitle">
                            {icon &&
                                <div className="main_icon">
                                    <span className={icon}></span>
                                </div>
                            }
                            <span className="subtitle">{subtitle}</span>
                        </div>
                    }

                    {/* Description */}
                    {description && <div className="main_description">{description}</div>}

                    {/* Counters */}
                    {(counterCaphters != undefined || counterContents != undefined || counterGoals != undefined || counterActions != undefined) &&
                    
                        <div className="main_counter_container">
                            {/* Counter chapter */}
                            {counterCaphters != undefined &&
                                <div className="main_counter">
                                    <div className="main_icon">
                                        <span className="icon_16 folder default"></span>
                                    </div>
                                    {counterCaphters}
                                </div>
                            }

                            {/* counter contents */}
                            {counterContents != undefined &&
                                <div className="main_counter">
                                    <div className="main_icon">
                                        <span className="icon_16 default text_box"></span>
                                    </div>
                                    {counterContents}
                                </div>
                            }

                            {/* counter goals */}
                            {counterGoals != undefined &&
                                <div className="main_counter">
                                    <div className="main_icon">
                                        <span className="icon_16 layers default"></span>
                                    </div>
                                    {counterGoals}
                                </div>
                            }

                            {/* counter actions */}
                            {counterActions != undefined &&
                                <div className="main_counter">
                                    <div className="main_icon">
                                        <span className="icon_16 layer default"></span>
                                    </div>
                                    {counterActions}
                                </div>
                            }
                        </div>
                    }


                    {alertMessage && 
                        <div className="main_alert_message">
                            <div className="main_icon">
                                <span className="icon_16 default danger"></span>
                            </div>
                            <div className="message" dangerouslySetInnerHTML={{ __html: alertMessage }}></div>
                        </div>
                    }
                </div>

                <div className="main_footer_modal">

                    <button onClick={() => onCancel ? onCancel() : setOpen(false)} className='button line'>Annulla</button>
                    
                    <button onClick={onConfirm} className='button fill error'>{ctaLabel}</button>
                </div>
            </div>
        </ModalContainer>
	);
}
