import { create } from "zustand";
import { reorderItems } from "../helper/common.helper";
import { getTemplatePlanContent } from "../services/plans-template.service";

const initialState = {
  planDetail: {},
  deleteConf: {},
  chapterList: [],
  usersPresence: [],
  courses: [],
  selectedContent: null,
  moveActionItem: null,
  idNewCount: 1,
  searchModel: "",
  searchLoading: false,
  searchResults: {
    template_chapters: [],
    template_chapter_contents: [],
    template_goals: [],
    template_requirements: [],
  },
  chapterModel: null,
  deleteChapterItem: null,
  contentModel: null,
  deleteItem: null,
  goalModel: null,
  deleteGoalItem: null,
  isMoveActionModalOpen: false,
  isCrudChapterOpen: false,
  isDeleteChapterOpen: false,
  isChaptersOrdered: false,
  isCrudContentOpen: false,
  isDeleteModalOpen: false,
  isCrudGoalOpen: false,
  isDeleteGoalOpen: false,
  deleteList: {
    reqs: [],
    chapters: [],
    courses: [],
    contents: [],
    goals: [],
  },
  loadingSave: false,
};

export const usePlanTemplateStore = create((set, get) => ({
  ...initialState,

  updateState: (newState) => set((state) => ({ ...newState })),

  /**
   * Duplicate Content
   * @param content
   * @param destination
   */
  duplicateContent: (content, destination) => {
    const chapters = get().chapterList;
    const idNewCount = get().idNewCount;
    const cpt = chapters.find(
      (c) => c.content_id === content.data.template_chapter_id,
    );
    const reqs =
      content.content_type === "template_goal"
        ? content.data.template_requirements.map((r, k) => ({
            ...r,
            id: `req-dg-${idNewCount}-${k}`,
            template_goal_id: `new-${idNewCount}`,
            localStatus: "new",
          }))
        : [];

    const newItem = {
      ...content,
      data: {
        ...content.data,
        ...content.content_type !== "template_course" && {name: `${content.data.name} (copia)`},
        id: `new-${idNewCount}`,
        localStatus: "new",
        ...(content.content_type === "template_goal" && {
          template_requirements: reqs,
        }),
      },
      content_id: `new-${idNewCount}`,
      localStatus: "new",
    };

    cpt.orderContents = true;

    cpt.data.contents.push(newItem);
    cpt.data.contents = reorderItems(
      cpt.data.contents,
      cpt.data.contents.length - 1,
      destination,
    );
    set((state) => ({
      chapterList: [...chapters],
      idNewCount: idNewCount + 1,
    }));
  },

  /**
   * Delet Content
   * @param item
   */
  deleteContent: (item) => {
    const chapters = get().chapterList;
    const cpt = chapters.find(
      (c) => c.content_id === item.data.template_chapter_id,
    );
    const contentIndex = cpt.data.contents.findIndex(
      (c) =>
        c.content_id === item.content_id &&
        c.content_type === item.content_type,
    );
    const deleteList = get().deleteList;

    if (item.localStatus !== "new") {
      if (item.content_type === "template_chapter_content") {
        deleteList.contents = [
          ...deleteList.contents,
          { id: item.content_id, chapter_id: item.data.template_chapter_id },
        ];
      } else if(item.content_type === "template_course"){
        deleteList.courses = [
          ...deleteList.courses,
          { id: item.content_id, chapter_id: item.data.template_chapter_id },
        ];
      } else {
        deleteList.goals = [
          ...deleteList.goals,
          { id: item.content_id, chapter_id: item.data.template_chapter_id },
        ];
        deleteList.reqs = deleteList.reqs.filter(
          (r) => r.goal_id !== item.content_id,
        );
      }
    }

    cpt.data.contents.splice(contentIndex, 1);

    set((state) => ({
      chapterList: [...chapters],
      isDeleteModalOpen: false,
      ...(item.content_id === get().selectedContent?.content_id && {
        selectedContent: null,
      }),
      deleteList: { ...deleteList },
    }));
  },

  /**
   * Update Editor content
   * @param editorText
   */
  updateEditorContents: (editorText) => {
    const chapters = get().chapterList;
    const selectedContent = get().selectedContent;
    const cpt = chapters.find(
      (c) => c.content_id === selectedContent.data.template_chapter_id,
    );
    const content = cpt.data.contents.find(
      (c) =>
        c.content_id === selectedContent.content_id &&
        c.content_type === selectedContent.content_type,
    );

    content.data.editorjs = editorText;

    if (!content.localStatus) {
      content.localStatus = "edit";
    }

    set((state) => ({ chapterList: [...chapters] }));
  },

  /**
   * Delete Chapter
   * @param item
   */
  deleteChapter: (item) => {
    const chapters = get().chapterList;
    const indexChapt = chapters.findIndex(
      (c) => c.content_id === item.content_id,
    );
    const deleteList = get().deleteList;

    if (item.localStatus !== "new") {
      deleteList.chapters = [...deleteList.chapters, { id: item.content_id }];
      deleteList.contents = deleteList.contents.filter(
        (c) => c.chapter_id !== item.content_id,
      );
      deleteList.courses = deleteList.courses.filter(
        (c) => c.chapter_id !== item.content_id,
      );
      deleteList.goals = deleteList.goals.filter(
        (g) => g.chapter_id !== item.content_id,
      );
      deleteList.reqs = deleteList.reqs.filter(
        (r) => r.chapter_id !== item.content_id,
      );
    }

    chapters.splice(indexChapt, 1);

    set((state) => ({
      chapterList: [...chapters],
      isDeleteModalOpen: false,
      ...(item.content_id ===
        get().selectedContent?.data.template_chapter_id && {
        selectedContent: null,
      }),
      deleteList: { ...deleteList },
    }));
  },

  /**
   * Duplicate Chapter
   * @param chapter
   * @param destination
   * @returns {Promise<void>}
   */
  duplicateChapter: async (chapter, destination) => {
    set((state) => ({ loadingSave: true }));

    const chapters = get().chapterList;
    const idNewCount = get().idNewCount;

    let contents = [];

    for (const [i, content] of chapter.data.contents.entries()) {
      const newContent = {
        ...content,
        data: {
          ...content.data,
          id: `new-${idNewCount}-${i}`,
          localStatus: "new",
          template_chapter_id: `new-ch-${idNewCount}`,
          ...(content.content_type === "template_goal" && {
            template_requirements: content.data.template_requirements.map(
              (r, k) => ({
                ...r,
                id: `req-dg-${idNewCount}-${k}`,
                template_goal_id: `new-${idNewCount}-${i}`,
                localStatus: "new",
              }),
            ),
          }),
        },
        content_id: `new-${idNewCount}-${i}`,
        localStatus: "new",
      };

      if (
        content.content_type === "template_chapter_content" &&
        !content.data.editorjs
      ) {
        const res = await getTemplatePlanContent({ id: content.content_id });

        newContent.data.editorjs = res.data.editorjs;
      }

      contents.push(newContent);
    }

    const newItem = {
      ...chapter,
      data: {
        ...chapter.data,
        name: `${chapter.data.name} (copia)`,
        id: `new-ch-${idNewCount}`,
        localStatus: "new",
        contents,
      },
      index: destination,
      content_id: `new-ch-${idNewCount}`,
      localStatus: "new",
    };

    chapters.push(newItem);

    const orderedList = reorderItems(
      chapters,
      chapters.length - 1,
      destination,
    );

    set((state) => ({
      chapterList: [...orderedList],
      idNewCount: idNewCount + 1,
      isChaptersOrdered: true,
      loadingSave: false,
    }));
  },

  /**
   * Delete Requirement
   * @param item
   * @param goalItem
   */
  deleteRequirement: (item, goalItem) => {
    const deleteList = get().deleteList;
    const index = goalItem.data.template_requirements.findIndex(
      (r) => r.id === item.id,
    );

    if (item?.localStatus !== "new") {
      deleteList.reqs = [
        ...deleteList.reqs,
        {
          id: item.id,
          goal_id: goalItem.content_id,
          chapter_id: goalItem.data.template_chapter_id,
        },
      ];
    }

    goalItem.data.template_requirements.splice(index, 1);

    set((state) => ({
      chapterList: [...get().chapterList],
      isDeleteModalOpen: false,
      deleteList: { ...deleteList },
    }));
  },

  /**
   * Dyplicate Requirement
   * @param req
   * @param goal
   * @param destination
   */
  duplicateRequirement: (req, goal, destination) => {
    const idNewCount = get().idNewCount;

    const newItem = {
      ...JSON.parse(JSON.stringify(req)),
      name: `${req.name} (copia)`,
      id: `req-dr-${idNewCount}`,
      localStatus: "new",
    };

    goal.data.template_requirements.push(newItem);

    const listToOrder = goal.data.template_requirements;

    goal.data.template_requirements = reorderItems(
      listToOrder,
      listToOrder.length - 1,
      destination,
    );

    goal.orderReqs = true;

    set((state) => ({
      chapterList: [...get().chapterList],
      idNewCount: idNewCount + 1,
    }));
  },

  /**
   * Reset Data
   */
  reset: (omitData) => set(JSON.parse(JSON.stringify({...initialState, ...omitData}))),
}));
