import c from "./ImpersonificationLayer.module.scss"
import useUserSession from '../../hooks/user-session.hook';

function ImpersonificationLayer({ organization, onEndSession = () => {} }) {

    const { user } = useUserSession()

    return (
        <div className={c.main_layer}>
            <div className={c.main_session}>
                {user &&
                    <div className={c.main_user}>
                        {user.first_name[0]}{user.last_name[0]}
                    </div>
                }
                <div className={c.label}>
                    Accesso come ente <b>{organization.name}</b>
                </div>

                <div className={c.main_cta}>
                    <button onClick={onEndSession} className="button x_small fill white">
                        <span className="icon_16 default out_entity icon_mask !bg-dark-60"></span>
                        Termina sessione
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ImpersonificationLayer;