/**
 * Format Object for download using plan organization chapters group by contents
 * @param chapters
 * @returns {*}
 */
export function formatOrganizationPlanDownloadObject(chapters) {
  return chapters.map((chapt) => ({
    id: chapt.data.template_chapter_id,
    name: chapt.data.template_chapter.name,
    contents: chapt.data.organization_contents.filter(content => content.content_type !== "organization_course").map((content) => ({
      content_type:
        content.content_type === "organization_chapter_content"
          ? "chapter_content"
          : "chapter_goal",
      id:
        content.content_type === "organization_chapter_content"
          ? content.data.template_chapter_content_id
          : content.data.template_goal_id,
      name:
        content.content_type === "organization_chapter_content"
          ? content.data.template_chapter_content.name
          : content.data.template_goal.name,
      ...(content.content_type === "organization_goal" && {
        requirements: content.data.organization_requirements.map((req) => ({
          id: req.template_requirement_id,
          name: req.template_requirement.name,
        })),
      }),
      ...(content.content_type === "organization_chapter_content" && {
        editable: content.data.template_chapter_content.editable,
      }),
    })),
  }));
}

/**
 * Format Object for download using plan template chapters group by contents
 * @param chapters
 * @returns {*}
 */
export function formatTemplatePlanDownloadObject(chapters) {
  return chapters.map((chapt) => ({
    id: chapt.content_id,
    name: chapt.data.name,
    contents: chapt.data.contents.filter(content => content.content_type !== "template_course").map((content) => ({
      content_type:
        content.content_type === "template_chapter_content"
          ? "chapter_content"
          : "chapter_goal",
      id: content.content_id,
      name: content.data.name,
      ...(content.content_type === "template_goal" && {
        requirements: content.data.template_requirements.map((req) => ({
          id: req.id,
          name: req.name,
        })),
      }),
      ...(content.content_type === "organization_chapter_content" && {
        editable: content.data.editable,
      }),
    })),
  }));
}

/**
 * Format Object for copy content using plan organization chapters group by contents
 * @param chapters
 * @returns {*}
 */
export function formatOrganizationPlanCopyContentObject(chapters) {
  return chapters.map((chapt) => ({
    id: chapt.content_id,
    name: chapt.data.template_chapter.name,
    contents: chapt.data.organization_contents.filter(content => content.content_type !== "organization_course").map((content) => ({
      content_type:
        content.content_type === "organization_chapter_content"
          ? "chapter_content"
          : "chapter_goal",
      id: content.content_id,
      name:
        content.content_type === "organization_chapter_content"
          ? content.data.template_chapter_content.name
          : content.data.template_goal.name,
      ...(content.content_type === "organization_goal" && {
        requirements: content.data.organization_requirements.map((req) => ({
          id: req.id,
          name: req.template_requirement.name,
        })),
      }),
      ...(content.content_type === "organization_chapter_content" && {
        editable: content.data.template_chapter_content.editable,
      }),
    })),
  }));
}

/**
 * get preset content selected
 * @param template
 * @param modelReqs
 * @param modelContents
 * @returns {{reqs: *[], chapters: *[], contents: *[], goals: *[]}}
 * @constructor
 */
export function getPresetSelection(template, modelReqs, modelContents) {
  const data = {
    chapters: [],
    contents: [],
    goals: [],
    reqs: [],
  };

  for (const chapt of template) {
    for (const content of chapt.contents) {
      if (content.content_type === "chapter_goal") {
        for (const req of content.requirements) {
          if (modelReqs?.includes(req.id)) {
            data.reqs.push({
              id: req.id,
              goal_id: content.id,
              chapter_id: chapt.id,
            });

            if (!data.goals.find((g) => g.id === content.id)) {
              data.goals.push({
                id: content.id,
                chapter_id: chapt.id,
              });
            }

            data.chapters = [...new Set([...data.chapters, chapt.id])];
          }
        }
      } else {
        if (modelContents?.includes(content.id)) {
          data.contents.push({
            id: content.id,
            chapter_id: chapt.id,
          });

          data.chapters = [...new Set([...data.chapters, chapt.id])];
        }
      }
    }
  }

  return data;
}
