import { apiClient } from "./config"





/**
 * Get list agendas
 * @param {
 *  filter[organizationPlan.organization_id]
 *  filter[organizationPlan.template_plan_id]
 *  include[] organizationPlan
 *  include[] organizationPlan.organization
 *  include[] organizationPlan.templatePlan
 *  include[] annotationsCount
 * } params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getAgendas = async (params, successCallback = false, errorCallback = false) => {

    try{
        const response = await apiClient.get(`/agendas`, {params: params})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}






/**
 * Get agenda detail
 * @param {} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getAgenda = async (params, successCallback = false, errorCallback = false) => {

    const { idCorp, id, ...body } = params

    try{
        const response = await apiClient.get(`/organizations/${idCorp}/agendas/${id}`, {params: body})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}





/**
 * Create new agenda
 * @param {name} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const createAgenda = async (params, successCallback = false, errorCallback = false) => {

    const { idCorp, idPlan, ...body } = params 

    try{
        const response = await apiClient.post(`/organizations/${idCorp}/plans/${idPlan}/agendas`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}





/**
 * Update agenda
 * @param {name} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const updateAgenda = async (params, successCallback = false, errorCallback = false) => {

    const { idCorp, id, ...body } = params 

    try{
        const response = await apiClient.patch(`/organizations/${idCorp}/agendas/${id}`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}





/**
 * Delete agenda
 * @param {} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const deleteAgenda = async (params, successCallback = false, errorCallback = false) => {

    const { idCorp, id } = params 

    try{
        const response = await apiClient.delete(`/organizations/${idCorp}/agendas/${id}`)
        if(successCallback)
            successCallback(response)
        return response;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}




/**
 * get last annotation agenda
 * @param {} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getLastAnnotation = async (params, successCallback = false, errorCallback = false) => {

    try{
        const response = await apiClient.get(`/organizations/last-annotation`, {params : params})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}








/**
 * Create new annotation
 * @param { name, date, editorjs } params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const createAnnotation = async (params, successCallback = false, errorCallback = false) => {

    const { idCorp, idAgenda, ...body } = params 

    try{
        const response = await apiClient.post(`/organizations/${idCorp}/agendas/${idAgenda}/annotations`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}









/**
 * Update annotation
 * @param { name, date, editorjs } params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const updateAnnotation = async (params, successCallback = false, errorCallback = false) => {

    const { idCorp, id, ...body } = params 

    try{
        const response = await apiClient.patch(`/organizations/${idCorp}/annotations/${id}`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}





/**
 * Delete annotation
 * @param { name, date, editorjs } params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const deleteAnnotation = async (params, successCallback = false, errorCallback = false) => {

    const { idCorp, id } = params 

    try{
        const response = await apiClient.delete(`/organizations/${idCorp}/annotations/${id}`)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}







/**
 * Get list annotations
 * @param {} params
* @param {function} successCallback 
* @param {function} errorCallback 
* @returns 
*/
export const getAnnotations = async (params, successCallback = false, errorCallback = false) => {

    const { id, idCorp, ...body } = params

   try{
       const response = await apiClient.get(`/organizations/${idCorp}/agendas/${id}/annotations`, {params: body})
       if(successCallback)
           successCallback(response.data)
       return response.data;
   }catch(error){
       if (errorCallback) 
           errorCallback(error.response)
       return error
   }
}






/**
 * Get detail annotation
 * @param {} params
* @param {function} successCallback 
* @param {function} errorCallback 
* @returns 
*/
export const getAnnotation = async ({params, opts}, successCallback = false, errorCallback = false) => {

    const { id, idCorp, ...body } = params

   try{
       const response = await apiClient.get(`/organizations/${idCorp}/annotations/${id}`, {params: body}, {...opts})
       if(successCallback)
           successCallback(response.data)
       return response.data;
   }catch(error){
       if (errorCallback) 
           errorCallback(error.response)
       return error
   }
}






/**
 * Create export agenda
 * @param {idAgenda, idCorp, options[annotations][]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const createExportAgenda = async (params, successCallback = false, errorCallback = false) => {

    const { idAgenda, idCorp, ...body } = params

   try{
       const response = await apiClient.post(`/organizations/${idCorp}/agendas/${idAgenda}/exports`, body)
       if(successCallback)
           successCallback(response.data)
       return response.data;
   }catch(error){
       if (errorCallback) 
           errorCallback(error.response)
       return error
   }
}





/**
 * Show export agenda
 * @param {idAgenda, idCorp, id} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const showExportAgenda = async (params, successCallback = false, errorCallback = false) => {

    const { idAgenda, idCorp, id, ...body } = params

   try{
       const response = await apiClient.get(`/organizations/${idCorp}/agendas/${idAgenda}/exports/${id}`, {params: body})
       if(successCallback)
           successCallback(response.data)
       return response.data;
   }catch(error){
       if (errorCallback) 
           errorCallback(error.response)
       return error
   }
}


