import { useState, useRef, createContext } from "react";
import useOnClickOutside from '../../../hooks/click-outside.hook';
import Toggle from "./DrowDownToggle";
import Content from "./DropDownContent";

export const DropDownContext = createContext()


function DropDown({ children, disabled, onToggle = () => {} }) {

    const popoverContainer = useRef(null)

    const [isOpen, setIsOpen] = useState(false);

    useOnClickOutside(popoverContainer, () => {
        setIsOpen(false)
        onToggle(false)
    })

    const handleToggle = () => {
        onToggle(!isOpen)
        setIsOpen(p => !p)
    }
    

    return (
        <DropDownContext.Provider value={{ isOpen, handleToggle }}>
            <div ref={popoverContainer} className='main_comp_dropdow_container'>
                {children}
            </div>
        </DropDownContext.Provider>
    );
}

DropDown.Toggle = Toggle
DropDown.Content = Content

export default DropDown;