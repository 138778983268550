import c from "./ActionLineForm.module.scss"
import TextareaInput from '../input/TextareaInput';
import ActionBtnItem from '../utils/ActionBtnItem';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import StatusBtnItem from "../utils/StatusBtnItem";
import useDateFormatter from "../../hooks/date-formatter.hook";
import {usePlanTemplateStore} from "../../store/plan-template.store";


function ActionLineForm({position, item, goalItem, canDuplicate = true}) {

    const {
        chapterList,
        deleteRequirement,
        duplicateRequirement,
        updateState,
        courses
    } = usePlanTemplateStore()

    const { formatDate, addDays } = useDateFormatter()

    // Schema Yup
	const schema = yup.object().shape({
		name: yup.string().required(),
		date_start: yup.string().test((val, ctx) => {

            if(checkDatesValidation())
                return ctx.createError({ message: 'date start missing' })

            return true
        }),
		date_end: yup.string().test((val, ctx) => {

            if(checkDatesValidation())
                return ctx.createError({ message: 'date end missing' })

            return true
        }),
	});

	const { register, getValues, trigger, formState:{ errors } } = useForm({resolver: yupResolver(schema)});


    /**
     * Check dates validation
     * @param {*} isEnd 
     * @returns 
     */
    const checkDatesValidation = () => {

        const values = getValues()

        return (new Date(values.date_start) >= new Date(values.date_end))
    }


    /**
     * Update Item
     * @param {*} key 
     * @param {*} value 
     */
    function updateItem (key, value){

        item[key] = value;

        if(!item.localStatus)
            item.localStatus = "edit";

        updateState({ chapterList: [...chapterList] })

        trigger(["name", "date_start", "date_end"])
    }
     /**
     * Update Item
     * @param {*} key
     * @param {*} value
     */
    function updateYoutubeLink (index, value){

        item.youtube_urls[index] = value;

        if(!item.localStatus)
            item.localStatus = "edit";

        updateState({ chapterList: [...chapterList] })

        trigger(["name", "date_start", "date_end"])
    }


    /**
     * Delete Item
     */
    const deleteItem = () => {
        updateState({
            deleteConf: {
                title: "Elimina linea d'azione",
                icon: "icon_16 default layer",
                subtitle: item.name,
                confirmHandler: () => deleteRequirement(item, goalItem),
            },
            deleteItem: {item: item, goal: goalItem},
            isDeleteModalOpen: true
        })
        /*setDeleteConf({
            title: "Elimina linea d'azione",
            icon: "icon_16 default layer",
            subtitle: item.name,
            confirmHandler: () => deleteRequirement(item, goalItem),
        })

        setDeleteItem({item: item, goal: goalItem})
        setDeleteModalOpen(true)*/
    }



    /**
     * Errom Handler
     * @param {*} removeError 
     */
    function errorHandler(removeError){

        removeError ? delete item.errorValidationStatus : item.errorValidationStatus = true;

        updateState({ chapterList: [...chapterList ]})
    }


    useEffect(() => {

        if (Object.keys(errors).length) {
            errorHandler()
		}else{

            if(item.errorValidationStatus)
                errorHandler(true)
        }

    },[errors.name, errors.date_start, errors.date_end])


    useEffect(() => {
        trigger(["name", "date_start", "date_end"])
    }, [])



    return (
        <div className={c.main_action_line}>
            <div className={c.main_header}>
                <div className={c.left_content}>
                    <div className={c.title}>Linea d'azione {position+1}</div>
                </div>

                <div className={c.right_content}>
                    <div className="main_comp_actions_group">

                        {/* Status */}
                        <div className="main_list_status_container">
                            
                            {/* New */}
                            <StatusBtnItem active={item.status === "new"} icon="megaphone" onClick={() => updateItem("status", item.status === "new" ? "default" : "new" )} tooltip="Nuovo" />

                            {/* Modified */}
                            <StatusBtnItem active={item.status === "modified"} icon="update" onClick={() => updateItem("status", item.status === "modified" ? "default" : "modified")} tooltip="Modificato" />

                        </div>

                        {/* Actions */}
                        <div className="main_actions">

                            {/* Move to */}
                            <ActionBtnItem icon="to_folder" onClick={() => {
                                updateState({ isMoveActionModalOpen: true, moveActionItem: { item: item, goal: goalItem }})
                            }} tooltip="Sposta" />

                            {/* Duplicate */}
                            {canDuplicate &&
                                <ActionBtnItem icon="duplicate" onClick={() => duplicateRequirement(item, goalItem, position+1)} tooltip="Duplica" />
                            }

                            {/* Elimina */}
                            <ActionBtnItem icon="delete" onClick={deleteItem} tooltip="Elimina" />
                        </div>
                    </div>
                </div>
            </div>

            <div className={c.main_form}>
                <form className="global_form_landing" onSubmit={(e) => e.preventDefault()}>


                    <div className="base child">

                        {/* Code */}
                        <div className="child">
                            <div className="wrap_input_landing">
                                <div className="group_label">
                                    <label htmlFor={`line_action_code-${item.id}`} className='label_input'>Codice</label>

                                    {/* Modify status */}
                                    <StatusBtnItem active={item.name_status === "modified"} icon="update" onClick={() => updateItem("name_status", item.name_status === "modified" ? "default" : 'modified')} tooltip="Modificato" />

                                </div>
                                <input {...register("name", { onChange: (e) => updateItem("name", e.target.value)})} defaultValue={item.name} className={"landing" + (errors.name ? ' err' : '')}  id={`line_action_code-${item.id}`} type='text'/>
                            </div>
                        </div>



                        {/* Date start */}
                        <div className="child">
                            <div className="wrap_input_landing date">
                                <div className="group_label">
                                    <label htmlFor={`line_action_date_start-${item.id}`} className='label_input'>Data inizio</label>

                                    {/* Modify status */}
                                    <StatusBtnItem active={item.date_from_status === "modified"} icon="update" onClick={() => updateItem("date_from_status", item.date_from_status === "modified" ? "default" : 'modified')} tooltip="Modificato" />

                                </div>
                                <div className="relative">
                                    <input {...item.date_to && { max : formatDate(addDays(item.date_to, -1), "YYYY-MM-DD")}} {...register("date_start", { onChange: (e) => updateItem("date_from", e.target.value)})} className={"landing" + (errors.date_start ? ' err' : '')} defaultValue={formatDate(item.date_from, "YYYY-MM-DD")} id={`line_action_date_start-${item.id}`} type='date'/>
                                    <span className="icon_24 default calendar icon_mask !bg-interactive-100"></span>
                                </div>
                            </div>
                        </div>



                        {/* Date end */}
                        <div className="child">
                            <div className="wrap_input_landing date">
                                <div className="group_label">
                                    <label htmlFor={`line_action_date_end-${item.id}`} className='label_input'>Data fine</label>

                                    {/* Modify status */}
                                    <StatusBtnItem active={item.date_to_status === "modified"} icon="update" onClick={() => updateItem("date_to_status", item.date_to_status === "modified" ? "default" : 'modified')} tooltip="Modificato" />

                                </div>
                                <div className="relative">
                                    <input min={formatDate(addDays(item.date_from, 1), "YYYY-MM-DD")} {...register("date_end", { onChange: (e) => updateItem("date_to", e.target.value)})} className={"landing" + (errors.date_end ? ' err' : '')} defaultValue={formatDate(item.date_to, "YYYY-MM-DD")} id={`line_action_date_end-${item.id}`} type='date'/>
                                    <span className="icon_24 default calendar icon_mask !bg-interactive-100"></span>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Description */}
                    <div className="base">
                        <div className="group_label">
                            <label htmlFor={`line_action_description-${item.id}`}
                                   className='label_input'>Descrizione</label>

                            {/* Modify status */}
                            <StatusBtnItem active={item.description_status === "modified"} icon="update"
                                           onClick={() => updateItem("description_status", item.description_status === "modified" ? "default" : 'modified')}
                                           tooltip="Modificato"/>

                        </div>
                        <TextareaInput onChange={(e) => updateItem("description", e.target.value)}
                                       model={item.description} id={`line_action_description-${item.id}`}/>
                    </div>



                    {/* Advice */}
                    <div className="base">
                        <div className="group_label">
                            <label htmlFor={`line_action_advice-${item.id}`} className='label_input'>Commento dell'esperto</label>

                            {/* Modify status */}
                            <StatusBtnItem active={item.advice_status === "modified"} icon="update" onClick={() => updateItem("advice_status", item.advice_status === "modified" ? "default" : 'modified')} tooltip="Modificato" />

                        </div>
                        <TextareaInput onChange={(e) => updateItem("advice", e.target.value)} model={item.advice} id={`line_action_advice-${item.id}`} />
                    </div>


                    {/* youtube urls */}
                    <div className="base">
                        <div className="wrap_input_landing icon_right">
                            <div className="flex justify-between gap-3">

                                <label className='label_input'>
                                    Link di Youtube
                                </label>
                            </div>

                            <div className="grid gap-4">
                                {!!item?.youtube_urls?.length && item.youtube_urls.map((link, i) => (
                                  <div key={`${i}`} className="relative">
                                      <input
                                        value={link}
                                        className={"landing"}
                                        type="text"
                                        onChange={(e) => updateYoutubeLink(i, e.target.value)}
                                      />
                                      <div className="p-3 icon_right !flex !right-[2px] hover:!opacity-100"
                                           onClick={() => updateItem("youtube_urls", item?.youtube_urls?.filter((_, k) => k !== i))}
                                      >
                                          <div
                                            className="icon_16 default delete cursor-pointer !pointer-events-auto"></div>
                                      </div>
                                  </div>
                                ))}

                                <div className="text-13 font-semibold text-interactive-100 cursor-pointer bg-interactive-10 rounded-[4px] p-[6px_12px] text-center hover:bg-interactive-20 transition-colors"
                                     onClick={() => (!item?.youtube_urls?.length || (!!item?.youtube_urls?.length && !!item?.youtube_urls?.at(-1)?.trim()?.length)) &&
                                       updateItem("youtube_urls", [...(item.youtube_urls ?? []), ""])
                                     }
                                >
                                    + Aggiungi link
                                </div>
                            </div>
                        </div>
                    </div>



                    {/* Course */}
                    <div className="base">
                        <div className="main_select_landing">
                            <label htmlFor={`line_action_course-${item.id}`} className='label_input'>Corso</label>
                            <div className="relative">
                                <select onChange={(e) => updateItem("course_id", e.target.value ?? null)} id={`line_action_course-${item.id}`} value={item.course_id ?? ""} className={"landing"}>
                                    <option value="">
                                        - Nessun corso -
                                    </option>
                                    {courses.map((course, i) => (
                                      <option key={course.id} value={course.id}>
                                          {course.name}
                                      </option>
                                    ))}

                                </select>
                                <div className="wrap_arrow">
                                    <span className="icon_16 default chevron icon_mask !bg-interactive-100"></span>
                                </div>
                            </div>
                        </div>
                    </div>




                </form>
            </div>
        </div>
    );
}

export default ActionLineForm;