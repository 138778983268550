import LayoutNav from "../../components/layout/LayoutNav";
import SectionBox from "../../components/section-box/SectionBox";
import TableEntities from "../../components/tables/TableEntities";

function ListAgencies({ isReseller }) {
    return (
        <LayoutNav>

            <div className="main_page_box grid_layout">
                <SectionBox title="Enti">
                    <TableEntities isReseller={isReseller} />
                </SectionBox>
            </div>

        </LayoutNav>
    );
}

export default ListAgencies;