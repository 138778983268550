import React, { useState } from "react";
import "./PlanAttachmentsPopup.scss";
import ModalContainer from "./ModalContainer";
import useNotification from "../../hooks/notification.hook";
import EmptyBox from "../emptystate/EmptyBox";
import {
  deletePlanAttachment,
  uploadPlanAttachment,
} from "../../services/plans.service";
import ActionBtnItem from "../utils/ActionBtnItem";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import Table from "../utils/table/Table";
import ConfirmModal from "./ConfirmPopup";

export default function PlanAttachmentsPopup({
  open,
  setOpen,
  organizationPlan,
  attachments = [],
  onUpdateList = () => {},
}) {
  const { openNotification, helperErrorNotification } = useNotification();

  const [loading, setLoading] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [attachmentsList, setAttachmentsList] = useState(attachments);

  const uploadAttachment = (e) => {
    if (!loading && !!e?.target?.files?.length) {
      setLoading(true);

      const formData = new FormData();
      formData.append("file", e?.target?.files[0]);

      const body = {
        idCorp: organizationPlan.organization_id,
        idPlan: organizationPlan.id,
        formData,
      };

      e.target.value = "";
      uploadPlanAttachment(
        body,
        (res) => {
          setLoading(false);
          onUpdateList([...attachmentsList, res]);
          setAttachmentsList((atts) => [...atts, res]);
        },
        (err) => {
          setLoading(false);
          helperErrorNotification(err);
        },
      );
    }
  };

  const removeAttachmentHandler = (file) => {
    setIsConfirmOpen(true);
    setDeleteItem(file);
  };

  const deleteAttachmentHandler = () => {
    setIsConfirmOpen(true);
    deletePlanAttachment(
      {
        id: deleteItem.id,
        idCorp: organizationPlan.organization_id,
        idPlan: organizationPlan.id,
      },
      () => {
        onUpdateList(attachmentsList.filter((a) => a.id !== deleteItem.id));
        setAttachmentsList((atts) =>
          atts.filter((a) => a.id !== deleteItem.id),
        );
        setDeleteItem(null);
        setIsConfirmOpen(false);
      },
    );
  };

  return (
    <>
      <ModalContainer isOpen={open} setIsOpen={setOpen} disableClose={loading}>
        <div className="main_modal_content plan_attachments_popup">
          <div
            className="main_close"
            onClick={() => !loading && setOpen(false)}
          >
            <span className="icon_10 default close icon_mask !bg-white"></span>
          </div>

          <div className="main_header_modal flex_header">
            <div className="left_content">
              <div className="main_desc">
                <span className="title">Gestione allegati del piano</span>
                <div className="desc">
                  {organizationPlan.template_plan.name}
                </div>
              </div>
            </div>
          </div>

          <div className={"main_body_modal"}>
            <div className="main_list_items">
              <div className="main_title">
                <div className="left_content">
                  Allegati: {attachmentsList.length}
                </div>
                <label
                  className="right_content"
                  htmlFor="plan-attachments-popup-input"
                >
                  <div className="icon_16 default plus icon_mask !bg-interactive-100"></div>
                  Nuovo
                  <input
                    accept=".jpg,.png,.pdf,.docx,.doc,.xlsx,.xls,.pptx,.ppt,.txt,.zip,.rar,.7z,.mp4,.mp3"
                    id={"plan-attachments-popup-input"}
                    className="hidden"
                    type={"file"}
                    onChange={uploadAttachment}
                  />
                </label>
              </div>
              {attachmentsList.length > 0 ? (
                <OverlayScrollbarsComponent
                  defer
                  options={{ scrollbars: { autoHide: "scroll" } }}
                  className="main_table_content"
                >
                  {/* Table organizations */}
                  <Table className="layout_popup big_row">
                    {/* Table Heade */}
                    <Table.Head className="sticky_head">
                      <Table.Column className="!pl-4 !pb-3">Nome</Table.Column>
                      <Table.Column></Table.Column>
                    </Table.Head>

                    {/* Table Body */}
                    <Table.Body>
                      {attachmentsList.map((file, index) => (
                        <Table.Row key={file.uuid}>
                          <Table.Cell>
                            <span className="line-clamp-1">
                              {file.file_name}
                            </span>
                          </Table.Cell>

                          <Table.Cell width={50}>
                            <div className="flex gap-3">
                              {/* Download */}
                              <a
                                href={file.original_url}
                                download={file.name}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <ActionBtnItem
                                  icon="download"
                                  tooltip="Scarica"
                                />
                              </a>

                              {/* Delete*/}
                              <ActionBtnItem
                                tooltip={"Elimina"}
                                onClick={() => removeAttachmentHandler(file)}
                                icon="delete"
                              />
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </OverlayScrollbarsComponent>
              ) : (
                <div className="min-h-[400px]">
                  <EmptyBox
                    icon="icon_75 default archive_plan"
                    title="Nessun allegato trovato"
                    description="Qui visualizzerai la lista degli alleati del piano"
                  />
                </div>
              )}
            </div>
          </div>
          <div className={"main_footer_modal"}></div>
        </div>
      </ModalContainer>

      <ConfirmModal
        open={isConfirmOpen}
        setOpen={setIsConfirmOpen}
        title={"Elimina allegato"}
        alertMessage="Attenzione! Questa operazione è irreversibile! <b>Sei sicuro di procedere?</b>"
        ctaLabel="Elimina"
        onConfirm={deleteAttachmentHandler}
      />
    </>
  );
}
