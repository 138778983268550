import { Link } from "react-router-dom";
import Footer from "../components/elements/Footer";
import c from "./errors.module.scss"

function Custom404({ props }) {
    return (
        <div className={c.main_error_page}>

            <div className={c.main_error}>
                <div className={c.main_image}>
                    <img src="/images/404.svg" alt="" className={c.image} />
                </div>
                <div className={c.main_desc}>
                    <h2 className={c.title}>Pagina non trovata.</h2>
                    <p className={c.desc}>Ops, qualcosa è andato storto.</p>
                </div>

                <div className={c.main_cta}>
                    <Link to="/" className="button fill primary small">Torna alla home ›</Link>
                </div>
            </div>


            <Footer />
        </div>
    );
}

export default Custom404;