import "./PlanOriginalTextPopup.scss"
import ModalContainer from "./ModalContainer";
import EditorContent from "../plan-template/EditorContent";
import useDateFormatter from "../../hooks/date-formatter.hook";


export default function PlanOriginalTextModal({open, setOpen, setReset, selectedContent = {}}) {
    const { formatDate } = useDateFormatter()

    return (
        <ModalContainer isOpen={open} setIsOpen={setOpen}>
            <div className={`main_modal_content plan_original_text_popup`}>

                <div className="main_close" onClick={() => !setOpen(false)}>
                    <span className="icon_10 default close icon_mask !bg-white"></span>
                </div>


                <div className="main_header_modal flex_header">

                    {/* Left content */}
                    <div className="left_content">
                        <div className="main_desc">
                            <span className="title">Template documento</span>
                            <span className="desc">Ultimo aggiornamento: {formatDate(selectedContent?.template_chapter_content?.updated_at, "DD MMM YYYY, HH:mm")}</span>
                        </div>
                    </div>

                    {/* Right content */}
                    <div className="right_content">
                        <div className="main_desc">

                        </div>
                    </div>
                </div>


                <div className={"main_body_modal"}>
                    <EditorContent
                        initVal={selectedContent?.template_chapter_content?.editorjs}
                        timeDebounce={400}
                        headerLevels={[2]}
                        readOnly={true}
                        minHeight={0}
                        layout={'editor_read_only'}
                    />
                </div>

                <div className="main_footer_modal">

                    <div className="right_content">
                        <button className="button fill error" onClick={() => setReset(true)}>Ripristina documento</button>
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
}
