import axios from "axios";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { getLocalSession } from "../helper/common.helper";

export const apiClientPlatform = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST_PLATFORM}/`,
});

export const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/`,
});

export const SocketEcho = new Echo({
  broadcaster: "pusher",
  key: process.env.REACT_APP_SOCKET_KEY,
  cluster: "eu",
  forceTLS: true,
  authEndpoint: `${process.env.REACT_APP_API_HOST}/broadcasting/auth`,
  auth: {
    headers: {
      Accept: "application/json",
      Authorization: getLocalSession()?.token
        ? `Bearer ${getLocalSession()?.token}`
        : "",
    },
  },
});

/**
 * Interceptors Requests
 */
apiClient.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("userData")) {
      const { token } = JSON.parse(localStorage.getItem("userData"));
      config.headers.Authorization = token ? `Bearer ${token}` : "";
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

/**
 * Interceptors Response
 */
apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error?.response?.status === 401) {
      errorSession();
    }
    return Promise.reject(error);
  },
);

function errorSession() {
  if (localStorage.getItem("userData")) {
    localStorage.removeItem("userData");
    localStorage.removeItem("globalFilterEvents");
    localStorage.removeItem("tenant");
    window.location = "/";
  }
}
