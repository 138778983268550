import {
    createTemplateChapter,
    createTemplateContent,
    createTemplateGoal,
    createTemplateRequirement,
    deleteTemplateChapter,
    deleteTemplateContent,
    deleteTemplateGoal,
    deleteTemplateRequirement,
    sortTemplateRequirements,
    updateTemplateChapter,
    updateTemplateContent,
    updateTemplateGoal,
    updateTemplateRequirement,
    sortTemplateContents,
    getTemplatePlan, deleteTemplateCourse, createTemplateCourse, updateTemplateCourse
} from "../services/plans-template.service";
import {usePlanTemplateStore} from "../store/plan-template.store";

function usePlanTemplate() {


    const [
        chapterList,
        deleteList,
        planDetail,
        isChaptersOrdered,
        updateState,
        resetData,
        coursesData,
    ] = usePlanTemplateStore(state => [
        state.chapterList,
        state.deleteList,
        state.planDetail,
        state.isChaptersOrdered,
        state.updateState,
        state.reset,
        state.courses
    ])

    /**
     * Save modify
     */
    async function saveModify(){

        updateState({ loadingSave: true })

        const edited = JSON.parse(JSON.stringify(chapterList))
        
        // Delete Chapters
        for (const chapter of deleteList.chapters) {
            await deleteTemplateChapter(chapter.id)
        }

        // Delete Courses
        for (const course of deleteList.courses) {
            await deleteTemplateCourse(course.id)
        }

        // Delete Contents
        for (const content of deleteList.contents) {
            await deleteTemplateContent(content.id)
        }

        // Delete Goals
        for (const goal of deleteList.goals) {
            await deleteTemplateGoal(goal.id)
        }

        // Delete Requirements
        for (const req of deleteList.reqs) {
            await deleteTemplateRequirement(req.id)
        }


        if(isChaptersOrdered && edited.length > 1){

            const contents = edited
                .filter(cap => cap.localStatus !== "new")
                .map(cap => ({ id: cap.content_id, content_type: cap.content_type, index: cap.index }))

            await sortTemplateContents({ id_template: planDetail.id, contents })

            updateState({ isChaptersOrdered: false })
        }


        for (const chapter of edited) {

            // Add Chapter
            if(chapter.localStatus === "new"){

                await createTemplateChapter({
                    ...chapter.data,
                    id_plan: planDetail.id,
                    index: chapter.index
                }, (res) => {

                    chapter.content_id = res.data.id
                    chapter.data.id = res.data.id

                    chapter.data.contents.forEach(content => {
                        content.data.template_chapter_id = res.data.id
                    });
                })
            }

            // Edit Chapter
            if(chapter.localStatus === "edit"){
                await updateTemplateChapter({ ...chapter.data })
            }

            // Sort content chapter
            if(chapter.data.contents.length > 1 && chapter.orderContents){

                const contents = chapter.data.contents.filter(content => content.localStatus !== "new")
                    .map(content => ({ id: content.content_id, content_type: content.content_type, index: content.index }))

                await sortTemplateContents({ id_template: planDetail.id, contents })
            }

            // Contents
            for (const content of chapter.data.contents) {

                if(content.content_type === "template_chapter_content"){

                    // New Content
                    if(content.localStatus === "new"){
                        await createTemplateContent({
                            id_chapter: chapter.content_id,
                            ...content.data,
                            index: content.index
                        }, (res) => {

                            content.content_id = res.data.id
                            content.data.id = res.data.id
                        })
                    }

                    // Edit content
                    if(content.localStatus === "edit"){
                        await updateTemplateContent(content.data)
                    }

                }else if(content.content_type === "template_course"){

                    // New Content
                    if(content.localStatus === "new"){
                        await createTemplateCourse({
                            id_chapter: chapter.content_id,
                            course_id: content.data?.course?.id,
                            index: content.index
                        }, (res) => {
                            content.content_id = res.data.id
                            content.data.id = res.data.id
                        })
                    }

                    // Edit content
                    if(content.localStatus === "edit"){
                        await updateTemplateCourse({ id: content.content_id, course_id: content.data?.course?.id})
                    }

                } else{
                    if(content.localStatus === "new"){
                        await createTemplateGoal({ id_chapter: chapter.content_id, ...content.data, index: content.index }, (res) => {

                            const reqs = content.data.template_requirements.map(r => ({ ...r, template_goal_id: res.data.id }))

                            content.data.template_requirements = reqs
                            content.content_id = res.data.id
                            content.data.id = res.data.id
                        })
                    }

                    // Edit Goal
                    if(content.localStatus === "edit"){
                        await updateTemplateGoal(content.data)
                    }


                    // Requirements
                    for (const req of content.data.template_requirements) {

                        req.youtube_urls = !!req?.youtube_urls?.length ? req?.youtube_urls.filter(item => !!item.trim().length) : []

                        // New Req
                        if(req.localStatus === "new"){
                            await createTemplateRequirement({ id_goal: content.content_id, ...req }, (res) => {
                                req.id = res.data.id
                            })
                        }

                        // Edit Req
                        if(req.localStatus === "edit"){
                            await updateTemplateRequirement(req)
                        }
                    }

                    // Sort Goal
                    const sortedReqs = content.data.template_requirements.map(c => c.id)

                    if(content.orderReqs && sortedReqs.length > 1){
                        await sortTemplateRequirements({id: content.content_id, requirementIds: sortedReqs})
                    }
                }
            }
        }

        await getTemplatePlan({id: planDetail.id, include : []}, (res) => {
            resetData({ courses: coursesData })
            updateState({
                planDetail: res.data,
                chapterList: res.data.contents,
                deleteList: {
                    reqs: [],
                    chapters: [],
                    contents: [],
                    courses: [],
                    goals: [],
                }
            })
        })

        updateState({ loadingSave: false })
    }







    return {
        saveModify,
    }
}

export default usePlanTemplate;