import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import useNotification from "../../../hooks/notification.hook";
import { useTranslation } from 'react-i18next';
import ModalContainer from '../ModalContainer';
import "./EndSessionPopup.scss"
import TextareaInput from "../../input/TextareaInput";
import {createPlanApproval, updatePlanApproval} from "../../../services/plans.service";


export default function CrudApprovalPopup({open, setOpen, plan, approval, onCancel = function(){}, onSuccess = () => {} }) {

    const { t } = useTranslation()

	// Set message
	yup.setLocale({
		// use constant translation keys for messages without values
		mixed : {
			required: ({path}) => (t("common:errors.required",{field : t(`common:fields.${path}`)})),
		},
        number: {
            moreThan : ({path, more}) => (t("common:errors.more_than",{field : t(`common:fields.${path}`), value: more})),
            lessThan : ({path, less}) => (t("common:errors.less_than",{field : t(`common:fields.${path}`), value: less})),
        }
	});



	// Schema Yup
	const schema = yup.object().shape({
		date_approval: yup.string().required(),
		note_approval: yup.string().required(),
	});


	const { register, setValue, clearErrors, handleSubmit, formState:{ errors } } = useForm({resolver: yupResolver(schema)});

	const { openNotification, helperErrorNotification } = useNotification()

	const [loading, setLoading] = useState(false);

	/**
	 * Form Handler
	 * @param {form modal} data
	 */
	async function formHandler(data) {

        if(!loading){


            setLoading(true)

            const body = {
                idCorp: plan.organization_id,
                idPlan: plan.id,
                ...(!!approval && {idApproval: approval.id}),
                note: data.note_approval,
                date: data.date_approval,
            }


            if(!approval){

                // Create annotation API
                createPlanApproval(body,(res) => {

                    setLoading(false)

                    setOpen(false)

                    onSuccess(res.data)

                    openNotification({text: "Piano adottato con successo!", status: "success"})

                }, (err) => {

                    setLoading(false)

                    helperErrorNotification(err)
                })
            }else{

                // Update annotation API
                updatePlanApproval(body,(res) => {

                    setLoading(false)

                    setOpen(false)

                    onSuccess(res.data)

                    openNotification({text: "Adozione modificata con successo!", status: "success"})

                }, (err) => {

                    setLoading(false)

                    helperErrorNotification(err)
                })
            }
        }
	}



	/**
	 * Errors Form
	 */
	useEffect(() => {
		if (Object.keys(errors).length) {
            const fields = Object.values(errors).map(er => er.message)
			openNotification({ fields, status: "error"})
		}
	}, [errors]);





	/**
	 * Init value
	 */
	useEffect(() => {
		if (open) {
            clearErrors()
			setValue("date_approval", approval?.date ?? '' )
			setValue("note_approval", approval?.note ?? '' )
		}
	}, [open]);





	return (
        <ModalContainer isOpen={open} setIsOpen={setOpen}>
            <div className={"main_modal_content"} >

                <div className="main_close" onClick={() => setOpen(false)}>
                    <span className="icon_10 default close icon_mask !bg-white"></span>
                </div>

                <div className="main_header_modal left_text">
                    <span className="title">Adotta il piano</span>
                </div>


                {/* Modal Form */}
                <div className={"main_body_modal"}>

                    {plan &&
                        <div className="main_sub_intro pb-5">
                            <div className="wrap_sub_intro">
                                <div className="main_icon">
                                    <span className="icon_16 default piano"></span>
                                </div>
                                <div className="subintro">{plan.template_plan.name}</div>
                            </div>

                            <div className="main_description">{plan.template_plan.description}</div>

                            <div className="main_counter_container">

                                <div className="main_counter">
                                    <span className="icon_16 default folder"></span>
                                    {plan.organization_chapters_count}
                                </div>

                                <div className="main_counter">
                                    <span className="icon_16 default text_box"></span>
                                    {plan.organization_chapter_contents_count}
                                </div>

                                <div className="main_counter">
                                    <span className="icon_16 default layers"></span>
                                    {plan.organization_goals_count}
                                </div>

                                <div className="main_counter">
                                    <span className="icon_16 default layer"></span>
                                    {plan.organization_requirements_count}
                                </div>
                            </div>
                        </div>
                    }


                    <form id='approval-form' className='global_form_landing' onSubmit={handleSubmit(formHandler)} noValidate>


                        {/* Date */}
                        <div className="base">
                            <div className="wrap_input_landing date">
                                <label htmlFor='approval-date' className='label_input'>Data di adozione</label>
                                <div className="relative">
                                    <input {...register("date_approval")} className={"landing big" + (errors.date_approval ? ' err' : '')} id='approval-date' type='date'/>
                                    <span className="icon_24 default calendar icon_mask !bg-interactive-100"></span>
                                </div>
                            </div>
                        </div>


                        {/* Name annotation */}
                        <div className="base">
                            <label htmlFor='approval-note' className='label_input'>Estremi di adozione</label>
                            <TextareaInput className={"big" + (errors.note_approval ? ' err' : '')} {...register("note_approval")} id="approval-note" />
                        </div>


                    </form>
                </div>

                <div className="main_footer_modal">


                    <div className="right_content">

                        {/* Main Cta */}
                        <button form="approval-form" className='button fill success'>Salva</button>
                    </div>

                </div>
            </div>
        </ModalContainer>
	);
}
