import c from "./FilterDropdown.module.scss";
import { useState, useRef, useEffect } from "react";
import { Transition } from "react-transition-group";
import useCommonHelper from "../../hooks/helper.hook";
import useOnClickOutside from '../../hooks/click-outside.hook';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import CollapseItem from "./collapse-item/CollapseItem";
import {CollapseContainerContextProvider} from "../../context/collapse-container.context";

function FilterDropdownCollpase({ toggleLabel, icon , options, onApply, activeSelected = [], type = "checkbox", defaultStatus, searchFilter, isLoading }) {

    const [selectedList, setSelectedList] = useState(activeSelected ? activeSelected : type == "radio" ? null : [])

    const [filteredList, setFilteredList] = useState(options ? options :  [])

    const popoverContainer = useRef(null)

    const popoverContent = useRef(null)

    const searchInput = useRef(null)

    const [isOpen, setIsOpen] = useState(false);
    
    const { animationClass } = useCommonHelper()

    useOnClickOutside(popoverContainer, () => setIsOpen(false))



    /**
     * Listener Checkbox
     * @param {event} e 
     * @param {option selected} option 
     */
    function onChangeCheck(e, option){
        if(e.target.checked){
            setSelectedList(items => [...items, option.value])
        }else{
            setSelectedList(items => items.filter(item => item != option.value))
        }
    }




    /**
     * Update selected list when open popover
     */
    useEffect(() => {
        if(isOpen){
            setSelectedList(activeSelected)
            setFilteredList(options)
        }
    }, [isOpen])


    /**
     * Filter List
     */
    function filterList(){
        const search = searchInput.current.value.trim()

        if(search.length > 0){
            setFilteredList(options.filter(el => el.label.toLowerCase().includes(search.toLowerCase())))
        }else{
            setFilteredList(options)
        }
    }
    

    return (
        <div ref={popoverContainer} className={`${c.main_dropdown_filter} ${isLoading ? c.loading : ''}`}>
            <div className={`${c.main_toggle_filter} ${isOpen ? c.open : ''}`} onClick={() => !isLoading && setIsOpen(!isOpen)}>
                <span className={c.label}>{toggleLabel}</span>
                
                {(icon && activeSelected && activeSelected.length < 1) && 
                    <div className="flex">
                        <span className={`${icon} icon_mask !bg-dark-50`}></span>
                    </div>
                }
                {activeSelected.length > 0 &&
                    <div className={c.main_counter}>
                        <span className={c.counter}>{activeSelected.length}</span>
                    </div>
                }
                {isLoading && 
                    <div className="flex">
                        <span className="icon_16 default loader icon_mask !bg-dark-70"></span>
                    </div>
                }
            </div>

            <Transition
                nodeRef={popoverContent} 
                in={isOpen} 
                mountOnEnter
                unmountOnExit
                timeout={{enter: 200, exit: 200}}
                >
                {state => (
                    <div ref={popoverContent} className={`${c.main_popover_filter} ${animationClass(state,"popover_in","popover_out")}`}>
                        <CollapseContainerContextProvider>
                            {searchFilter &&
                                <div className={c.popover_header}>
                                    <div className="wrap_input_landing icon">
                                        <input ref={searchInput} onChange={filterList} className="landing" placeholder="Ricerca..." type="text" />
                                        <span className="icon_16 default search"></span>
                                    </div>
                                </div>
                            }


                            <OverlayScrollbarsComponent defer options={{ scrollbars: { autoHide: "scroll" }}} className={c.popover_content}>
                                <div className={c.main_list_collpase}>
                                    {filteredList.length > 0 ?
                                        filteredList.map((item,k) => (
                                            <div  key={k} className={c.item_collapse}>
                                                <CollapseItem>
                                                    <CollapseItem.Toggle>
                                                        {({isOpen, isClosed}) =>
                                                            <div className={c.main_toggle}>
                                                                <div className={c.left_content}>
                                                                    <span className={"icon_10 default chevron flex-none -mt-1 " + (isClosed ? "right" : "")}></span>
                                                                    {item.label}
                                                                </div>
                                                                {!!item.items.filter(el => selectedList.includes(el.value))?.length &&
                                                                    <div className={c.counter}>
                                                                        {item.items.filter(el => selectedList.includes(el.value)).length}
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </CollapseItem.Toggle>
                                                    <CollapseItem.Content>
                                                        <div className={c.main_list_items}>
                                                            {item.items.map((option, index) =>
                                                                <div key={`${k}-${index}`} className={c.main_item}>
                                                                    <div className="check_landing small">
                                                                        <input type="checkbox" onChange={(e) => onChangeCheck(e, option)} checked={selectedList.includes(option.value) || selectedList.includes(`${option.value}`)} id={option.value} />
                                                                        <label htmlFor={option.value} className="icon">{option.icon && <span className={`${option.icon} flex-none`}></span>}{option.label}</label>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </CollapseItem.Content>
                                                </CollapseItem>
                                            </div>
                                        ))
                                        :
                                        <div className={c.empty_list}>
                                            <div className={c.title}>Nessun filtro trovato</div>
                                            <div className={c.desc}>Prova con un'altra ricerca</div>
                                        </div>
                                    }
                                </div>
                            </OverlayScrollbarsComponent>

                            <div className={c.popover_footer}>
                                <div className="button fill small primary w-full" onClick={() => {onApply(selectedList); setIsOpen(false)}}>
                                    Filtra
                                </div>
                            </div>
                        </CollapseContainerContextProvider>
                    </div>
                )}
            </Transition>
        </div>
    );
}

export default FilterDropdownCollpase;