import { Fragment } from "react";
import useDateFormatter from "../../../hooks/date-formatter.hook";
import c from "./ListActivities.module.scss"

function ListActivities({ list }) {

    const { formatDate } = useDateFormatter()

    return (
        <div className={c.main_box}>
            {list.length > 0 &&
                list.map((item,i) => (
                    <Fragment key={i}>
                        {(i == 0 || formatDate(item.created_at, "DD-MM-YYYY") !== formatDate(list[i - 1].created_at, "DD-MM-YYYY")) &&
                            <div className={c.main_date}>{formatDate(item.created_at, "DD MMM YYYY")}</div>
                        }
                        <div className={`${c.main_item} ${i === list.length - 1 || formatDate(item.created_at, "DD-MM-YYYY") !== formatDate(list[i + 1].created_at, "DD-MM-YYYY") ? c.last : ''}`}>
                            <div className={c.main_title}>
                                <span className={c.user}>{item.user.first_name} {item.user.last_name}</span> <span dangerouslySetInnerHTML={{ __html: item.description }}></span>
                            </div>
                        </div>
                    </Fragment>
                ))
            }
        </div>
    );
}

export default ListActivities;