import { useNavigate, useParams, Outlet, useLoaderData } from "react-router-dom";
import { useEffect } from 'react';


function HomeRedirect({children}) {

    const { user, noSession } = useLoaderData()

    const navigate = useNavigate()

    const linkRedirect = {
        admin: "/admin/plans",
        reseller: "/reseller",
        organization: "/organization"
    }

    if (noSession) {
        window.location = `${process.env.REACT_APP_HOST_PLATFORM}`
    }

    useEffect(() => {

        if(user){
            navigate(linkRedirect[user.role], { replace: true })
        }

    }, [])

    return null

}

export default HomeRedirect;