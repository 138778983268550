import { BarChart, Bar, YAxis, XAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import c from "./BarChartDetail.module.scss"

function BarChartDetail({data, xKey = "date", completePercentage}) {

    const longestLabelLength = data.map(c => String(c.value)).reduce((acc, cur) => (cur.length > acc ? cur.length : acc), 0)

    return (
        <div className={c.main_box}>
            <div className={c.main_intro}>
                <h3 className={c.title}>Completamento linee d'azione</h3>

                <div className={c.main_progress_container}>
                    <div className={c.main_progress}>
                        <div className={c.progress} style={{ width: `${completePercentage}%`}}></div>
                    </div>
                    <span className={c.counter}>{completePercentage}%</span>
                </div>
            </div>

            <div className={c.main_chart}>

                <div className={c.right_content}>
                    <div className={c.main_legend}>
                        <div className={c.wrap_legend}>
                            {data.map((entry, index) => (
                                <div key={index} className={c.item_legend}>
                                    <div className={c.main_value}>
                                        <span className="icon_16 default layer_fill icon_mask" style={{ background: entry.color}}></span>
                                        <span className={c.value}>{entry.value}</span>
                                    </div>

                                    <span className={c.label}>{entry.label}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>


                <div className={c.left_content}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={data} margin={{ top: 1, right: 0, left: 7, bottom: 0, }}>
                            <CartesianGrid strokeDasharray="0 3 3" vertical={false} /* horizontalCoordinatesGenerator={(props) => [1, 37.5, 75, 112.5, 149 ]} */ strokeWidth={1} stroke="#B6B7BF" />
                            
                            <XAxis interval={0} dataKey={xKey} tickMargin={10} tickLine={false} fontSize={13} style={{ textTransform: "capitalize"}} tick={{ fill: "#818198", fontSize: 10, fontWeight: 600 }} strokeWidth={0} mirror stroke="#B6B7BF" />
                            <YAxis width={longestLabelLength * 7} interval="preserveStartEnd" dataKey="value" allowDecimals={false} tickMargin={0} tickLine={false} fontSize={13} style={{ textTransform: "capitalize"}} tickCount={5} tick={{ fill: "#868694", fontSize: 10, fontWeight: 400 }} strokeWidth={0} stroke="#B6B7BF" />
                            <YAxis yAxisId='right' orientation='right' dataKey="value" mirror tickLine={false} fontSize={0} strokeWidth={2} stroke="#D9D9D9" />

                            <Bar minPointSize={1} label="name" radius={[4,4,0,0]} type="linear" dataKey="value" strokeWidth={2} animationDuration={400} /* fill={setting.color} */ dot={false}>
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
}

export default BarChartDetail;