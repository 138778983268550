import ItemSummary from "../../plans/ItemSummary";
import c from "./BoxNotes.module.scss"

function BoxNotes({ notes = [], organizationId }) {
    return (
        <div className={c.main_box}>
            <div className={c.list_notes}>
                {notes.map((note,i) => (

                    <ItemSummary
                        key={i}
                        boxed
                        small
                        icon="note"
                        title={note.name}
                        description={note.name}
                        ctaConf={{
                            label: "Modifica",
                            icon: "edit_box",
                            href: `/admin/agendas/${organizationId}/${note.agenda_id}?annotation=${note.id}`
                        }}
                    /> 
                ))}
            </div>
        </div>
    );
}

export default BoxNotes;