import { apiClient } from "./config"



/**
 * Get template plan content
 * @param params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getSfadCourses = async (params, successCallback = false, errorCallback = false) => {

    try{
        const response = await apiClient.get(`/soluzionefad/courses`, {params: params})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}