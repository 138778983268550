import { PlanCopyConfContextProvider } from "../../context/plan-copy-conf.context";
import PlanCopyConf from "./PlanCopyConf";

function PlanCopyConfContainer(props) {
    return (
        <PlanCopyConfContextProvider>
            <PlanCopyConf {...props} />
        </PlanCopyConfContextProvider>
    );
}

export default PlanCopyConfContainer;