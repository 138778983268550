import { getLocalSession } from "../helper/common.helper"
import { apiClient } from "./config"





/**
 * Get list activities
 * @param { idCorp, idPlan } params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getActivitiesList = async (params, successCallback = false, errorCallback = false) => {

    const { idCorp, idPlan, ...body } = params

    try{
        const response = await apiClient.get(`/organizations/${idCorp}/plans/${idPlan}/activities`, {params: body})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}







/**
 * Create activity
 * @param { idCorp, idPlan, description } params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const createActivity = async (params, successCallback = false, errorCallback = false) => {

    const { idCorp, idPlan, ...body } = params

    try{

        const session = getLocalSession()
        
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/organizations/${idCorp}/plans/${idPlan}/activities`, {
            method : "POST",

            body: JSON.stringify(body),

            cache: "no-cache",

            keepalive: true,

            headers : {
                "Authorization" : `Bearer ${session.token}`,
                "Content-Type" : "application/json"
            }
        })

        const data = await response.json();
        /* const response = await apiClient.post(`/organizations/${idCorp}/plans/${idPlan}/activities`, body) */
        if(successCallback)
            successCallback(data)
        return data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}

