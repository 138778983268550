import c from "./ActionLineCompile.module.scss";
import TextareaInput from "../input/TextareaInput";
import NumberInput from "../input/NumberInput";
import PopoverActionStatus from "./PopoverActionStatus";
import {
  updatePlanRequirement,
  uploadAttachmentPlanRequirement,
  deleteAttachmentPlanRequirement,
} from "../../services/plans.service";
import debounce from "lodash.debounce";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import useDateFormatter from "../../hooks/date-formatter.hook";
import { useCallback, useContext, useEffect, useState } from "react";
import { usePlanCompileStore } from "../../store/plan-compile.store";
import { ARRAY_STATUS_ICON } from "../../helper/common.helper";
import ReactPlayer from "react-player";
import GlobalContext from "../../context/global.context";
import FileInput from "../input/FileInput";
import ActionBtnItem from "../utils/ActionBtnItem";

function ActionLineCompile({ item, goal }) {
  const { organization } = useContext(GlobalContext);

  const [isLoadingAttachments, setIsLoadingAttachments] = useState(false);

  const {
    planDetail,
    chapterList,
    roleSettings,
    isSavingContent,
    updateState,
    storeRequirementChanges,
    courses,
    deleteConf,
  } = usePlanCompileStore();

  const canAccessContent =
    roleSettings !== "organization" ||
    (!!organization && organization?.reseller_id !== 10);

  const requiredFields = planDetail.template_plan.requirement_fields_settings;

  const { formatDate, addDays } = useDateFormatter();

  // Schema Yup
  const schema = yup.object().shape({
    progress_percentage: yup.string(),
    progress_slider: yup.string(),
    date_from: yup.string().test((val, ctx) => {
      if (checkDatesValidation())
        return ctx.createError({ message: "date start missing" });

      return true;
    }),
    date_to: yup.string().test((val, ctx) => {
      if (checkDatesValidation(true))
        return ctx.createError({ message: "date end missing" });

      return true;
    }),
  });

  const findCourse = item?.template_requirement?.course_id
    ? courses.find((c) => c.id === item?.template_requirement?.course_id)
    : null;

  const {
    register,
    setValue,
    getValues,
    trigger,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const watchPercentage = watch("progress_percentage");

  /**
   * Dates Validation
   * @param {*} isEnd
   * @returns
   */
  const checkDatesValidation = () => {
    const values = getValues();

    return new Date(values.date_from) >= new Date(values.date_to);
  };

  const handleSlider = (key, val) => {
    setValue("progress_percentage", val);
    setValue("progress_slider", val);
    debounceProgressSlider(key, val);
  };

  const handlePercentage = (key, val) => {
    let checkedVal = val;

    if (val === "") {
      setValue("progress_percentage", 0);
      checkedVal = 0;
    }

    setValue("progress_slider", checkedVal);
    debounceProgressNumber(key, checkedVal);
  };

  const debounceDescription = debounce(updateItem, 800);

  const debounceBudgetStart = debounce(updateItem, 800);

  const debounceBudgetEnd = debounce(updateItem, 800);

  const debounceProgressSlider = useCallback(debounce(updateItem, 500), []);

  const debounceProgressNumber = useCallback(debounce(updateItem, 500), []);

  const debounceBudgetAllocation = debounce(updateItem, 800);

  const debounceStakeholders = debounce(updateItem, 800);

  const debounceNote = debounce(updateItem, 800);

  /**
   * Update Item
   * @param {*} key
   * @param {*} value
   */
  async function updateItem(key, value, oldItem) {
    trigger(["date_from", "date_to"]);

    const body = {
      id: item.id,
      idCorp: planDetail.organization_id,
      [key]: value,
      ...(key !== "requirement_status_id" && {
        requirement_status_id: item.requirement_status_id,
      }),
      ...(key === "date_from" && { date_to: item.date_to }),
      ...(key === "date_to" && { date_from: item.date_from }),
    };

    updateLocalItem(key, value);

    isSavingContent.push("queue");
    updateState({ isSavingContent });

    await updatePlanRequirement(body, () => {
      storeRequirementChanges(item, goal.data, { key, value }, oldItem);
    });

    isSavingContent.pop();
    updateState({ isSavingContent });
  }

  /**
   * Upload File
   * @param {*} key
   * @param {*} value
   */
  async function uploadFile(e) {
    if (!isLoadingAttachments && !!e?.target?.files?.length) {
      setIsLoadingAttachments(true);
      const formData = new FormData();
      formData.append("file", e?.target?.files[0]);
      e.target.value = "";

      const body = {
        id: item.id,
        idCorp: planDetail.organization_id,
        idPlan: planDetail.id,
        formData,
      };

      isSavingContent.push("queue");
      updateState({ isSavingContent });

      await uploadAttachmentPlanRequirement(body, (res) => {
        updateLocalItem("attachments", [...item?.attachments, res]);
        /*setAttachments((atts) => [...atts, res]);*/
      });

      setIsLoadingAttachments(false);

      isSavingContent.pop();
      updateState({ isSavingContent });
    }
  }

  useEffect(() => {
    setValue("progress_percentage", item.progress_percentage);
    setValue("progress_slider", item.progress_percentage);
  }, []);

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return `<a target="_blank" href="${url}">${url}</a>`;
    });
  }

  /**
   * Update Item
   * @param {*} key
   * @param {*} value
   */
  function updateLocalItem(key, value) {
    const req = goal.data.organization_requirements.find(
      (g) => g.id === item.id,
    );

    req[key] = value;

    updateState({ chapterList: [...chapterList] });
  }

  /**
   * Update Item
   * @param {*} key
   * @param {*} value
   */
  function updateStatus(value) {
    updateItem("requirement_status_id", value);
    if (requiredFields.progress_percentage) {
      const findStatus = planDetail.organization_requirements_statuses.find(
        (s) => s.id === value,
      );
      if (findStatus?.percentage_change !== null) {
        handleSlider("progress_percentage", findStatus?.percentage_change);
      }
    }
  }

  const arrayStatus = planDetail.organization_requirements_statuses.map(
    (status) => ({
      title: status.name,
      desc: status.description,
      icon: status.icon,
      value: status.id,
      color: ARRAY_STATUS_ICON.find((s) => s.icon === status.icon).color,
    }),
  );

  const removeAttachmentHandler = (file) => {
    updateState({
      deleteConf: {
        title: "Elimina allegato",
        confirmHandler: () => deleteAttachmentHandler(file),
        item: file,
        isOpen: true,
      },
    });
  };

  const deleteAttachmentHandler = (file) => {
    deleteAttachmentPlanRequirement(
      {
        idCorp: planDetail.organization_id,
        idPlan: planDetail.id,
        idReq: item.id,
        id: file.id,
      },
      () => {
        updateLocalItem(
          "attachments",
          item.attachments.filter((a) => a.uuid !== file.uuid),
        );
        updateState({ deleteConf: { ...deleteConf, isOpen: false } });
      },
    );
  };

  return (
    <div className={c.main_action_compile}>
      <div className={c.main_header}>
        <div className={c.main_title}>
          {item.template_requirement.status !== "default" && (
            <div className={c.main_icon}>
              <span
                className={
                  "icon_16 default icon_mask !bg-interactive-100 -mt-1" +
                  (item.template_requirement.status === "new"
                    ? " megaphone"
                    : " update")
                }
              ></span>
            </div>
          )}
          <div className={c.title}>{item.template_requirement.name}</div>
        </div>

        <div className={c.right_content}>
          <PopoverActionStatus
            onChange={(value) => updateStatus(value)}
            initStatus={item.requirement_status_id}
            arrayStatus={arrayStatus}
          />
        </div>
      </div>

      {(item?.template_requirement?.date_from ||
        item?.template_requirement?.date_to ||
        item?.template_requirement?.description ||
        item?.template_requirement?.advice ||
        ((findCourse || !!item?.template_requirement?.youtube_urls?.length) &&
          roleSettings !== "reseller" &&
          canAccessContent)) && (
        <div className={c.main_detail}>
          {(item.template_requirement.date_from ||
            item.template_requirement.date_to) && (
            <div className={c.group_items}>
              {/* Date Start */}
              {item.template_requirement.date_from && (
                <div className={c.main_item}>
                  <div className={c.main_title}>
                    {item.template_requirement.date_from_status !==
                      "default" && (
                      <div className={c.main_icon}>
                        <span className="icon_16 default update icon_mask !bg-interactive-100 -mt-1"></span>
                      </div>
                    )}
                    <div className={c.title}>Data di inizio</div>
                  </div>
                  <div className={c.value}>
                    Dal{" "}
                    <b className="capitalize">
                      {formatDate(
                        item.template_requirement.date_from,
                        "DD MMM YYYY",
                      )}
                    </b>
                  </div>
                </div>
              )}

              {/* Date End */}
              {item.template_requirement.date_to && (
                <div className={c.main_item}>
                  <div className={c.main_title}>
                    {item.template_requirement.date_to_status !== "default" && (
                      <div className={c.main_icon}>
                        <span className="icon_16 default update icon_mask !bg-interactive-100 -mt-1"></span>
                      </div>
                    )}
                    <div className={c.title}>Data di fine</div>
                  </div>
                  <div className={c.value}>
                    Al{" "}
                    <b className="capitalize">
                      {formatDate(
                        item.template_requirement.date_to,
                        "DD MMM YYYY",
                      )}
                    </b>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Description */}
          {item.template_requirement.description && (
            <div className={c.main_item}>
              <div className={c.main_title}>
                {item.template_requirement.description_status !== "default" && (
                  <div className={c.main_icon}>
                    <span className="icon_16 default update icon_mask !bg-interactive-100 -mt-1"></span>
                  </div>
                )}
                <div className={c.title}>Descrizione</div>
              </div>
              <div className={c.value}>
                {item.template_requirement.description}
              </div>
            </div>
          )}

          {/* Comment */}
          {item.template_requirement.advice && (
            <div className={c.main_item}>
              <div className={c.main_title}>
                {item.template_requirement.advice_status !== "default" && (
                  <div className={c.main_icon}>
                    <span className="icon_16 default update icon_mask !bg-interactive-100 -mt-1"></span>
                  </div>
                )}
                <div className={c.title}>Commento dell'esperto</div>
              </div>
              <div
                className={c.value}
                dangerouslySetInnerHTML={{
                  __html: urlify(item.template_requirement.advice),
                }}
              ></div>
            </div>
          )}

          {/* Course */}
          {item.template_requirement.course_id &&
            !!findCourse &&
            roleSettings !== "reseller" &&
            canAccessContent && (
              <div className={c.main_item}>
                <div className={c.main_title}>
                  <div className={c.title}>Corso</div>
                </div>
                <div className={c.value}>
                  <a
                    href={findCourse?.td_url}
                    target="_blank"
                    className="flex gap-4 bg-dark-10 p-4 rounded-[4px] items-center hover:bg-dark-20/50 transition-colors"
                  >
                    <div className="flex shadow-[0_0_0_1px] rounded-[4px] shadow-dark-100/10 w-[90px] h-[60px] relative bg-dark-10 items-center justify-center flex-none">
                      {findCourse?.preview_full_path ? (
                        <img
                          src={findCourse.preview_full_path}
                          className="absolute w-full h-full rounded-[4px] object-cover"
                        />
                      ) : (
                        <span className="icon_16 default piano icon_mask !bg-dark-60"></span>
                      )}
                    </div>
                    <div className="flex-1">{findCourse?.name}</div>

                    <span className="icon_16 default chevron right icon_mask !bg-dark-70 flex-none"></span>
                  </a>
                </div>
              </div>
            )}

          {!!item?.template_requirement?.youtube_urls?.length &&
            roleSettings !== "reseller" &&
            canAccessContent && (
              <div className={c.main_item}>
                <div className={c.main_title}>
                  <div className={c.title}>Video correlati</div>
                </div>
                <div className={c.value}>
                  <div className="grid gap-4 pt-2">
                    {item?.template_requirement?.youtube_urls.map((url, i) => (
                      <ReactPlayer
                        key={i}
                        url={url}
                        width="100%"
                        height={"auto"}
                        className={"aspect-video rounded-[8px] overflow-hidden"}
                        config={{
                          youtube: {
                            playerVars: {
                              controls: 1,
                            },
                          },
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
        </div>
      )}

      <div className={c.main_form}>
        <form className="global_form_landing">
          {/* Description */}
          {requiredFields.description && (
            <div className="base">
              <label
                htmlFor={`action_compile_description-${item.id}`}
                className="label_input"
              >
                Attività operative - descrizione di dettaglio
              </label>
              <TextareaInput
                className="big_text"
                onChange={(e) =>
                  debounceDescription("description", e.target.value)
                }
                id={`action_compile_description-${item.id}`}
                model={item.description}
              />
            </div>
          )}

          {/* Note */}
          {roleSettings === "admin" && (
            <div className="base">
              <label
                htmlFor={`action_compile_note-${item.id}`}
                className="label_input"
              >
                <div className="flex flex-none pr-1">
                  <span className="icon_16 note default icon_mask !bg-dark-50"></span>
                </div>
                Azioni importanti da compiere:
              </label>
              <TextareaInput
                onChange={(e) => debounceNote("admin_note", e.target.value)}
                model={item.admin_note}
                id={`action_compile_note-${item.id}`}
              />
            </div>
          )}

          {(requiredFields.date_from ||
            requiredFields.date_to ||
            requiredFields.actual_budget ||
            requiredFields.estimated_budget) && (
            <div className="base child xxl:!grid xxl:grid-cols-2">
              {/* Date start */}
              {requiredFields.date_from && (
                <div className="child">
                  <div className="wrap_input_landing date">
                    <label
                      htmlFor={`action_compile_data_start-${item.id}`}
                      className="label_input"
                    >
                      Data inizio
                    </label>
                    <div className="relative">
                      <input
                        {...register("date_from", {
                          onChange: (e) =>
                            updateItem("date_from", e.target.value),
                        })}
                        {...(item.date_to && {
                          max: formatDate(
                            addDays(item.date_to, -1),
                            "YYYY-MM-DD",
                          ),
                        })}
                        defaultValue={formatDate(item.date_from, "YYYY-MM-DD")}
                        className={"landing" + (errors.date_from ? " err" : "")}
                        id={`action_compile_data_start-${item.id}`}
                        type="date"
                      />
                      <span className="icon_24 default calendar icon_mask !bg-interactive-100"></span>
                    </div>
                  </div>
                </div>
              )}

              {/* Date end */}
              {requiredFields.date_to && (
                <div className="child">
                  <div className="wrap_input_landing date">
                    <label
                      htmlFor={`action_compile_data_end-${item.id}`}
                      className="label_input"
                    >
                      Data fine
                    </label>
                    <div className="relative">
                      <input
                        {...register("date_to", {
                          onChange: (e) =>
                            updateItem("date_to", e.target.value),
                        })}
                        min={formatDate(
                          addDays(item.date_from, 1),
                          "YYYY-MM-DD",
                        )}
                        defaultValue={formatDate(item.date_to, "YYYY-MM-DD")}
                        className={"landing" + (errors.date_to ? " err" : "")}
                        id={`action_compile_data_end-${item.id}`}
                        type="date"
                      />
                      <span className="icon_24 default calendar icon_mask !bg-interactive-100"></span>
                    </div>
                  </div>
                </div>
              )}

              {/* Budget start */}
              {requiredFields.estimated_budget && (
                <div className="child">
                  <div className="wrap_input_landing">
                    <label
                      htmlFor={`action_compile_budget-${item.id}`}
                      className="label_input"
                    >
                      Budget previsto
                    </label>
                    <NumberInput
                      step=".1"
                      icon="eur"
                      onChange={(e) =>
                        debounceBudgetStart(
                          "estimated_budget",
                          e.target.value !== "" ? e.target.value : 0,
                        )
                      }
                      min={0}
                      defaultValue={item.estimated_budget}
                      id={`action_compile_budget-${item.id}`}
                      placeholder="0,00"
                    />
                  </div>
                </div>
              )}

              {/* Budget end */}
              {requiredFields.actual_budget && (
                <div className="child">
                  <div className="wrap_input_landing">
                    <label
                      htmlFor={`action_compile_budget_used-${item.id}`}
                      className="label_input"
                    >
                      Budget speso
                    </label>
                    <NumberInput
                      step=".1"
                      icon="eur"
                      onChange={(e) =>
                        debounceBudgetEnd(
                          "actual_budget",
                          e.target.value !== "" ? e.target.value : 0,
                        )
                      }
                      min={0}
                      defaultValue={item.actual_budget}
                      id={`action_compile_budget_used-${item.id}`}
                      placeholder="0,00"
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Progress */}
          {requiredFields.progress_percentage && (
            <div className="base">
              <label
                htmlFor={`action_compile_progress-${item.id}`}
                className="label_input"
              >
                Completamento
              </label>
              <div className="flex items-center gap-6">
                <div className=" w-full">
                  <div className="main_range_input">
                    <input
                      {...register("progress_slider")}
                      defaultValue={watchPercentage}
                      onChange={(e) =>
                        handleSlider("progress_percentage", e.target.value)
                      }
                      type="range"
                      min="0"
                      max="100"
                    />
                    <div
                      className="range_value"
                      style={{ width: `${watchPercentage}%` }}
                    ></div>
                  </div>
                </div>
                <div className="wrap_input_landing">
                  <NumberInput
                    className="percentage"
                    {...register("progress_percentage", {
                      onChange: (e) =>
                        handlePercentage("progress_percentage", e.target.value),
                    })}
                    onUpdate={(val) => {
                      setValue("progress_percentage", val);
                      handlePercentage("progress_percentage", val);
                    }}
                    icon="percentage"
                    min={0}
                    max={100}
                    id={`action_compile_progress-${item.id}`}
                    placeholder="0"
                  />
                </div>
              </div>
            </div>
          )}

          {/* Chapter of expense */}
          {requiredFields.budget_allocation && (
            <div className="base">
              <label
                htmlFor={`action_compile_expense-${item.id}`}
                className="label_input"
              >
                Capitolo di spesa
              </label>
              <TextareaInput
                className="small"
                rows="1"
                onChange={(e) =>
                  debounceBudgetAllocation("budget_allocation", e.target.value)
                }
                model={item.budget_allocation}
                id={`action_compile_expense-${item.id}`}
              />
            </div>
          )}

          {/* Chapter of expense */}
          {requiredFields.stakeholders && (
            <div className="base">
              <label
                htmlFor={`action_compile_stakeholders-${item.id}`}
                className="label_input"
              >
                Strutture responsabili e attori coinvolte
              </label>
              <TextareaInput
                className="small"
                rows="1"
                onChange={(e) =>
                  debounceStakeholders("stakeholders", e.target.value)
                }
                model={item.stakeholders}
                id={`action_compile_stakeholders-${item.id}`}
              />
            </div>
          )}

          <div className="base">
            <label
              htmlFor={`action_compile_stakeholders-${item.id}`}
              className="label_input"
            >
              Allegati
            </label>
            <FileInput
              accept={
                ".jpg, .png, .pdf, .docx, .doc, .xlsx, .xls, .pptx, .ppt, .txt, .zip, .rar, .7z, .mp4, .mp3"
              }
              isLoading={isLoadingAttachments}
              onChange={uploadFile}
              id={`action_compile_attachments-${item.id}`}
            />
            {!!item?.attachments?.length && (
              <div className="grid gap-[8px] pt-4">
                {item.attachments.map((file) => (
                  <div
                    key={file.uuid}
                    className="flex bg-interactive-5 p-[11px_12px] justify-between rounded-[8px] text-interactive-100 font-semibold text-[12px] gap-[8px]"
                  >
                    <div className="flex gap-[2px] items-center w-0 flex-1">
                      <div className="icon_16 default paper_clip flex-none icon_mask !bg-interactive-100"></div>
                      <div className="truncate">{file.file_name}</div>
                    </div>
                    <div className="flex gap-3 items-center">
                      <a
                        href={file.original_url}
                        download={file.name}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <ActionBtnItem icon="download" tooltip="Scarica" />
                      </a>
                      <ActionBtnItem
                        icon="delete"
                        onClick={() => removeAttachmentHandler(file)}
                        tooltip="Elimina"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default ActionLineCompile;
