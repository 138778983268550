import { useRef, useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import useNotification from "../../hooks/notification.hook";
import ModalContainer from "./ModalContainer";
import { useTranslation } from 'react-i18next';
import { createAnnotation, updateAnnotation } from "../../services/agendas.service";


export default function CrudAnnotationModal({open, setOpen, annotationModel, agenda, onEdit = function(){}, onInsert = function(){}}) {

    const { t } = useTranslation()


	// Set message
	yup.setLocale({
		// use constant translation keys for messages without values
		mixed : {
			required: ({path}) => (t("common:errors.required",{field : t(`common:fields.${path}`)})),
		},
        number: {
            moreThan : ({path, more}) => (t("common:errors.more_than",{field : t(`common:fields.${path}`), value: more})),
            lessThan : ({path, less}) => (t("common:errors.less_than",{field : t(`common:fields.${path}`), value: less})),
        }
	});



	// Schema Yup
	const schema = yup.object().shape({
		date: yup.string().required(),
		title: yup.string().required(),
	});




	const { register, setValue, clearErrors, getValues, handleSubmit, formState:{ errors } } = useForm({resolver: yupResolver(schema)});

	const { openNotification, helperErrorNotification } = useNotification()

	const [loading, setLoading] = useState(false);


	/**
	 * Form Handler
	 * @param {form modal} data 
	 */
	async function formHandler(data) {
        
        if(!loading){

            setLoading(true)
            
            if(!annotationModel?.id){

                const body = {
                    idAgenda: agenda.id,
                    idCorp: agenda.organization_plan.organization_id,
                    name: data.title,
                    date: data.date,
                    editorjs: {
                        blocks: []
                    }
                }

                // Create plan
                createAnnotation(body, (res) => {
                    setLoading(false)
                    openNotification({text: "Annotazione creata con successo!", status: "success"})
                    onInsert(res)
                }, (error) => {
                    helperErrorNotification(error)
                    setLoading(false)
                })
            }else{

                const body = {
                    id: annotationModel.id,
                    idCorp: agenda.organization_plan.organization_id,
                    name: data.title,
                    date: data.date
                }

                // Edit plan
                updateAnnotation(body, (res) => {
                    setLoading(false)
                    openNotification({text: "Annotazione modificata con successo!", status: "success"})
                    onEdit(res);
                }, (error) => {
                    helperErrorNotification(error)
                    setLoading(false)
                })
            }
        }
	}



	/**
	 * Errors Form
	 */
	useEffect(() => {
		if (Object.keys(errors).length) {
            const fields = Object.values(errors).map(er => er.message)
			openNotification({ fields, status: "error"})
		}
	}, [errors]);





	/**
	 * Init value
	 */
	useEffect(() => {
		if (open) {
            clearErrors()
			setValue("title", annotationModel?.name ? annotationModel.name : "")
			setValue("date", annotationModel?.date ? annotationModel.date : "")
		}
	}, [open]);


	return (
        <ModalContainer isOpen={open} setIsOpen={setOpen}>
            <div className={"main_modal_content"} >

                <div className="main_close" onClick={() => setOpen(false)}>
                    <span className="icon_10 default close icon_mask !bg-white"></span>
                </div>

                <div className="main_header_modal left_text">
                    <span className="title">{annotationModel?.id ? "Modifica annotazione" : "Nuova annotazione"}</span>
                </div>


                {/* Modal Form */}
                <div className={"main_body_modal"}>

                    <form id='crud-edit-psw-form' className='global_form_landing' onSubmit={handleSubmit(formHandler)} noValidate>

                        {/* Name */}
                        <div className="base">
                            <div className="wrap_input_landing">
                                <label htmlFor='crud-annotation-title' className='label_input'>Titolo</label>
                                <input className={"landing big" + (errors.title ? ' err' : '')} id='crud-annotation-title' type='text' {...register("title")}/>
                            </div>
                        </div>


                        {/* Date */}
                        <div className="base">
                            <div className="wrap_input_landing date">
                                <label htmlFor='crud-annotation-date' className='label_input'>Data inizio</label>
                                <div className="relative">
                                    <input {...register("date")} className={"landing big" + (errors.date ? ' err' : '')} id='crud-annotation-date' type='date'/>
                                    <span className="icon_24 default calendar icon_mask !bg-interactive-100"></span>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

                <div className="main_footer_modal">

                    <div className="right_content">

                        {/* Main Cta */}
                        <button form="crud-edit-psw-form" className='button fill success'>Conferma</button>
                    </div>
                </div>
            </div>
        </ModalContainer>
	);
}
