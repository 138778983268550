import SubHeader from "../../components/elements/SubHeader";
import Sidebar from "../../components/plan-compile/Sidebar";
import { CollapseContainerContextProvider } from "../../context/collapse-container.context";
import SubNav from "../../components/plan-template/SubNav";
import EditorContent from "../../components/plan-template/EditorContent";
import React, { useEffect, useState } from "react";
import LayoutSidebar from "../../components/layout/LayoutSidebar";
import { useLoaderData, useNavigate } from "react-router-dom";
import usePlanCompile from "../../hooks/plan-compile.hook";
import GoalsContent from "../../components/plan-compile/GoalsContent";
import ImpersonificationLayer from "../../components/plan-compile/ImpersonificationLayer";
import IconContainer from "../../components/utils/IconContainer";
import EndSessionPopup from "../../components/popups/plan-compile/EndSessionPopup";
import ConfirmModal from "../../components/popups/ConfirmPopup";
import CrudApprovalPopup from "../../components/popups/plan-compile/CrudApprovalPopup";
import PlanOriginalTextPopup from "../../components/popups/PlanOriginalTextPopup";
import Prompt from "../../components/utils/Prompt";
import ActionBtnItem from "../../components/utils/ActionBtnItem";
import useCalendly from "../../hooks/calenldy.hook";
import PopoverActionStatus from "../../components/plan-template/PopoverActionStatus";
import { getAnnotations } from "../../services/agendas.service";
import PlanDownloadModal from "../../components/popups/PlanDownloadPopup";
import { getTemplatePlanContent } from "../../services/plans-template.service";
import { updatePlanContent } from "../../services/plans.service";
import TextareaInput from "../../components/input/TextareaInput";
import debounce from "lodash.debounce";
import useDateFormatter from "../../hooks/date-formatter.hook";
import { usePlanCompileStore } from "../../store/plan-compile.store";
import { formatOrganizationPlanDownloadObject } from "../../helper/formatter.helper";
import { ARRAY_STATUS_ICON } from "../../helper/common.helper";
import { SocketEcho } from "../../services/config";
import AgendaReader from "../../components/plan-compile/AgendaReader";
import { getSfadCourses } from "../../services/course.service";
import PlanAttachmentsPopup from "../../components/popups/PlanAttachmentsPopup";

function PlanCompile({ role = "admin" }) {
  const planData = useLoaderData();

  const { redirectPlan, saveActivities } = usePlanCompile();

  /**
   * Socket Users Presence
   */
  useEffect(() => {
    if (role === "admin") {
      SocketEcho.join(`organization-plans.${planData.id}`)
        .here(initUserPresence)
        .joining(addUserPresence)
        .leaving(removeUserPresence)
        .error((error) => {
          console.error(error);
        });
    }

    return () => {
      if (role === "admin") {
        SocketEcho.leave(`organization-plans.${planData.id}`);
      }
    };
  }, []);

  const {
    selectedContent,
    chapterList,
    isSavingContent,
    changesList,
    isEndSessionOpen,
    isConfirmOpen,
    isApprovalOpen,
    isPlanApproved,
    updateState,
    editContent,
    updateContentStatus,
    deleteConf,
  } = usePlanCompileStore();

  const { openCalendly } = useCalendly();

  const { formatDate, formatDateToTimestamp } = useDateFormatter();

  const navigate = useNavigate();

  const [loadingAnnotations, setLoadingAnnotations] = useState(false);

  const [showAgenda, setShowAgenda] = useState(false);

  const [usersPresence, setUsersPresence] = useState([]);

  const [annotationsList, setAnnotationsList] = useState([]);

  const [openPrinterModal, setOpenPrinterModal] = useState(false);

  const [openAttachmentsModal, setOpenAttachmentsModal] = useState(false);

  const [counterAttachments, setCounterAttachments] = useState(
    planData?.attachments?.length ?? 0,
  );

  const [openResetContent, setOpenResetContent] = useState(false);

  const [resetCounter, setResetCounter] = useState(0);

  const [planOriginalTextPopupOpen, setPlanOriginalTextPopupOpen] =
    useState(false);

  const downloadTemplate = formatOrganizationPlanDownloadObject(
    planData.organization_contents,
  );

  useEffect(() => {
    updateState({
      chapterList: planData.organization_contents,
      planDetail: planData,
      roleSettings: role,
      changesList: [],
    });

    getListAnnotations();
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", preventNavigation);

    return () => {
      window.removeEventListener("beforeunload", preventNavigation);
    };
  }, [changesList]);

  useEffect(() => {
    window.addEventListener("unload", trackActivities);

    return () => {
      window.removeEventListener("unload", trackActivities);
    };
  }, [changesList]);

  useEffect(() => {
    getSfadCourses(undefined, (res) => {
      updateState({ courses: res?.data ?? [] });
    });

    return () => {
      saveActivities(planData);
    };
  }, []);

  /**
   * Track activities
   * @param {*} e
   * @returns
   */
  const trackActivities = (e) => {
    saveActivities(planData);
    e.preventDefault();
    return null;
  };

  /**
   * Prevent Reload
   * @param {*} e
   * @returns
   */
  const preventNavigation = (e) =>
    changesList.length > 0 || isPlanApproved ? (e.returnValue = true) : null;

  const arrayStatusContent = [
    {
      title: "Da gestire",
      icon: "uncompleted",
      value: "uncompleted",
      color:
        ARRAY_STATUS_ICON?.find((s) => s.icon === "uncompleted")?.color ?? "",
    },
    {
      title: "Completato",
      icon: "completed",
      value: "completed",
      color:
        ARRAY_STATUS_ICON?.find((s) => s.icon === "completed")?.color ?? "",
    },
  ];

  const addUserPresence = (user) => {
    setUsersPresence((users) => [...users, user]);
  };

  const removeUserPresence = (user) => {
    setUsersPresence((users) =>
      users.filter((item) => item.user.id !== user.user.id),
    );
  };

  const initUserPresence = (items) => {
    setUsersPresence(items);
  };

  /**
   * Template Nav
   * @returns
   */
  const templateNav = () => (
    <div className="main_comp_actions_group">
      <div className="main_list_status_container">
        {isSavingContent.length > 0 && (
          <IconContainer
            icon="loader opacity-70"
            tooltip="Salvataggio in corso"
          />
        )}

        {selectedContent.content_type === "organization_goal" &&
          selectedContent?.data?.template_goal?.status !== "default" && (
            <>
              <IconContainer
                icon={
                  "icon_mask !bg-interactive-100 " +
                  (selectedContent?.data?.template_goal?.status === "new"
                    ? "megaphone"
                    : "update")
                }
                tooltip={
                  selectedContent?.data?.template_goal?.status === "new"
                    ? "Nuovo"
                    : "Modificato"
                }
              />

              {selectedContent?.data?.organization_requirements.find(
                (el) => el.status === "uncompleted",
              ) && <IconContainer icon="danger_corp" tooltip="Da gestire" />}
            </>
          )}

        {selectedContent?.content_type === "organization_chapter_content" &&
          selectedContent?.data?.status !== null && (
            <>
              {role === "admin" && (
                <>
                  <ActionBtnItem
                    icon="preview"
                    big
                    onClick={() => setPlanOriginalTextPopupOpen(true)}
                    tooltip="Anteprima documento di ripristino"
                  />

                  {formatDateToTimestamp(
                    selectedContent?.data?.template_chapter_content?.updated_at,
                  ) >
                    formatDateToTimestamp(
                      selectedContent?.data?.updated_at,
                    ) && (
                    <ActionBtnItem
                      icon="danger"
                      layout={"my-[4px] !bg-white"}
                      iconLayout={""}
                      small
                      tooltip={`Template modificato il ${formatDate(
                        selectedContent?.data?.template_chapter_content
                          ?.updated_at,
                        "DD MMM YYYY, HH:mm",
                      )}`}
                    />
                  )}
                </>
              )}

              <PopoverActionStatus
                layout="small"
                onChange={(value) => updateContentStatus(value)}
                initStatus={selectedContent?.data?.status}
                arrayStatus={arrayStatusContent}
              />

              {role === "admin" && (
                <button
                  className=" link-red  text-12 ml-3"
                  onClick={() => setOpenResetContent(true)}
                >
                  Ripristina documento
                </button>
              )}
            </>
          )}
      </div>
    </div>
  );

  const templateHeader = () => (
    <div className="flex gap-4 items-center">
      {usersPresence.length > 1 && (
        <div
          data-tooltip-id="tooltip_small_top"
          data-tooltip-offset={10}
          data-tooltip-position-strategy="fixed"
          data-tooltip-content={usersPresence
            .map((item) => `${item.user.first_name} ${item.user.last_name}`)
            .join("\n")}
          className="flex items-center gap-3  fade_in bg-danger-100 rounded-full font-medium pl-3 pr-[1px] text-white py-[1px]"
        >
          <div className="flex items-center gap-2">
            <div className="icon_16 default user !bg-white icon_mask"></div>
            {usersPresence.length}
          </div>

          <div className="bg-white p-[6px] rounded-full flex">
            <div className="icon_16 default danger"></div>
          </div>
        </div>
      )}

      <div className="relative">
        <ActionBtnItem
          icon="attachments"
          onClick={() => setOpenAttachmentsModal(true)}
          big
        />
        {counterAttachments > 0 && (
          <div className="absolute -top-2 -right-2 bg-interactive-100 text-white rounded-full text-9 leading-[1em] font-bold w-4 h-4 flex items-center justify-center">
            {counterAttachments}
          </div>
        )}
      </div>

      <ActionBtnItem icon="support" onClick={openCalendly} big />

      <ActionBtnItem
        icon="printer"
        onClick={() => setOpenPrinterModal(true)}
        big
      />

      {!!planData.agenda && (
        <div
          className={
            "shadow-[inset_0_0_0_1px] shadow-interactive-50 flex items-center gap-3 rounded-[4px] p-[8px_10px] ml-[4px]"
          }
        >
          <div
            onClick={() => setShowAgenda(false)}
            className={
              "icon_16 default cursor-pointer book_disabled icon_mask !bg-interactive-100" +
              (showAgenda ? " opacity-30" : "")
            }
          ></div>

          {/* Editable */}
          <div className="toggle_landing flex">
            <input
              checked={showAgenda}
              onChange={(e) => setShowAgenda(e.target.checked)}
              type="checkbox"
              id="copy_all_entity"
              className="landing"
            />
            <label className="only_toggle" htmlFor="copy_all_entity"></label>
          </div>

          <div
            onClick={() => setShowAgenda(true)}
            className={
              "icon_16 default cursor-pointer book icon_mask !bg-interactive-100" +
              (!showAgenda ? " opacity-30" : "")
            }
          ></div>
        </div>
      )}

      <button
        className="button ligth_green small fill"
        onClick={() => updateState({ isApprovalOpen: true })}
      >
        <span className="icon_24 default approved icon_mask !bg-success-100 -my-1"></span>
        Adotta il piano
      </button>

      {role !== "admin" && (
        <button
          className="button primary small fill"
          onClick={() =>
            changesList.length > 0 || isPlanApproved
              ? updateState({ isEndSessionOpen: true })
              : navigateBack()
          }
        >
          Termina sessione
        </button>
      )}
    </div>
  );

  /**
   * Cancel session
   */
  const cancelSession = () => {
    updateState({ isEndSessionOpen: false, isConfirmOpen: true });
  };

  /**
   * Get list annotations
   */
  const getListAnnotations = () => {
    if (planData?.agenda?.id) {
      setLoadingAnnotations(true);

      const body = {
        id: planData.agenda.id,
        idCorp: planData.organization_id,
      };

      getAnnotations(
        body,
        (res) => {
          setAnnotationsList(res.data);
          setLoadingAnnotations(false);
        },
        () => setLoadingAnnotations(false),
      );
    }
  };

  /**
   * Reset Content Document
   */
  const resetContentDocument = () => {
    getTemplatePlanContent(
      { id: selectedContent.data.template_chapter_content_id },
      (res) => {
        updatePlanContent(
          {
            id: selectedContent.content_id,
            idCorp: planData.organization_id,
            editorjs: res.data.editorjs,
            status: "uncompleted",
          },
          (res) => {
            const findChapt = chapterList.find(
              (c) =>
                c.content_id === selectedContent.data.organization_chapter_id,
            );

            const findContent = findChapt.data.organization_contents.find(
              (c) =>
                c.content_id === selectedContent.content_id &&
                c.content_type === selectedContent.content_type,
            );

            findContent.data.status = "uncompleted";

            updateState({
              selectedContent: {
                ...selectedContent,
                data: {
                  ...res.data,
                },
              },
              chapterList: [...chapterList],
            });

            setResetCounter((r) => r + 1);
            setOpenResetContent(false);
            setPlanOriginalTextPopupOpen(false);
          },
        );
      },
    );
  };

  const navigateBack = () => {
    setTimeout(() => {
      if (role === "admin") {
        navigate(`/admin/entities/entity/${planData.organization_id}`);
      }
      if (role === "reseller") {
        navigate(`/reseller/${planData.organization_id}`);
      }
      if (role === "organization") {
        navigate(`/organization`);
      }
    }, 0);
  };

  return (
    <LayoutSidebar>
      <div onClick={(e) => e.stopPropagation()}>
        <SubHeader
          title={planData.template_plan.name}
          titleSmall
          description={planData.template_plan.description}
          TemplateRightContent={templateHeader}
        />

        <CollapseContainerContextProvider>
          <Sidebar />
        </CollapseContainerContextProvider>

        <div className="main_page_sidebar">
          {selectedContent && (
            <>
              <SubNav
                category={
                  selectedContent.content_type ===
                    "organization_chapter_content" && `Documento`
                }
                title={
                  selectedContent.content_type === "organization_goal"
                    ? `${selectedContent.data.template_goal.name} – ${selectedContent.data.template_goal.description}`
                    : selectedContent.data.template_chapter_content.name
                }
                TemplateRightContent={templateNav}
              />

              <div className="wrap_page_sidebar">
                {selectedContent.content_type ===
                "organization_chapter_content" ? (
                  <div
                    key={selectedContent.content_id + resetCounter}
                    className={`bg-white rounded-[4px]`}
                  >
                    {selectedContent.data.status !== null &&
                    role === "admin" ? (
                      <div
                        className={`p-[32px_28px_0_86px] max-w-[1200px] mx-auto`}
                      >
                        <div
                          className={`font-semibold text-[12px] text-dark-50 mb-[8px]`}
                        >
                          Note amministrative
                        </div>
                        <TextareaInput
                          model={selectedContent.data.admin_note}
                          onChange={debounce(
                            (data) =>
                              editContent("adminNote", data.target.value),
                            400,
                          )}
                          className={""}
                        />
                      </div>
                    ) : (
                      selectedContent.data.admin_note && (
                        <div
                          className={`p-[32px_28px_0_86px] max-w-[1200px] mx-auto`}
                        >
                          <div className="p-5 bg-dark-5 rounded-[4px]">
                            <div
                              className={`font-semibold text-[12px] text-dark-50`}
                            >
                              Note
                            </div>
                            <div className={`text-[14px] text-dark-80`}>
                              {selectedContent.data.admin_note}
                            </div>
                          </div>
                        </div>
                      )
                    )}

                    <EditorContent
                      initVal={selectedContent.data.editorjs}
                      onChange={(data) => editContent("editorjs", data)}
                      timeDebounce={400}
                      headerLevels={[2]}
                      readOnly={selectedContent.data.status == null}
                    />
                  </div>
                ) : (
                  <GoalsContent />
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {/* Close session */}
      <ConfirmModal
        open={isConfirmOpen}
        setOpen={(isOpen) => updateState({ isConfirmOpen: isOpen })}
        title="Termina sessione senza salvare"
        alertMessage="Attenzione! Hai scelto di non salvare i dati della sessione. <b>Sei sicuro di procedere?</b>"
        ctaLabel="Termina"
        onConfirm={redirectPlan}
      />

      {/* Reset content */}
      <ConfirmModal
        open={openResetContent}
        setOpen={setOpenResetContent}
        title="Ripristina documento"
        alertMessage="Attenzione! Ripristinando il documento perderai tutte le modifiche fatte. <b>Sei sicuro di procedere?</b>"
        ctaLabel="Conferma"
        onConfirm={resetContentDocument}
      />

      {/* Approval popup */}
      <CrudApprovalPopup
        open={isApprovalOpen}
        plan={planData}
        onSuccess={(data) => updateState({ isPlanApproved: data })}
        setOpen={(isOpen) => updateState({ isApprovalOpen: isOpen })}
      />

      {/* Plan original popup */}
      <PlanOriginalTextPopup
        open={planOriginalTextPopupOpen}
        selectedContent={selectedContent?.data}
        setOpen={setPlanOriginalTextPopupOpen}
        setReset={setOpenResetContent}
      />

      <Prompt
        when={changesList.length > 0 || isPlanApproved}
        message="Vuoi abbandonare la sessione?"
      />

      <EndSessionPopup
        open={isEndSessionOpen}
        setOpen={(isOpen) => updateState({ isEndSessionOpen: isOpen })}
        onCancel={cancelSession}
        agenda={planData.agenda}
        annotations={annotationsList.filter(
          (annotation) => !!annotation?.user_can_update,
        )}
        loadingAnnotations={loadingAnnotations}
        role={role}
      />

      {role === "admin" && (
        <ImpersonificationLayer
          organization={planData.organization}
          onEndSession={() =>
            changesList.length > 0 || isPlanApproved
              ? updateState({ isEndSessionOpen: true })
              : navigateBack()
          }
        />
      )}

      {showAgenda && !!planData.agenda && (
        <AgendaReader
          planData={planData}
          agenda={planData.agenda}
          className={role !== "admin" ? " !bottom-[40px]" : ""}
        />
      )}

      {/* Confirm content */}
      <ConfirmModal
        open={deleteConf.isOpen}
        setOpen={(isOpen) =>
          updateState({ deleteConf: { ...deleteConf, isOpen } })
        }
        title={deleteConf.title}
        icon={deleteConf.icon}
        subtitle={deleteConf.subtitle}
        description={deleteConf.description}
        alertMessage="Attenzione! Questa operazione è irreversibile! <b>Sei sicuro di procedere?</b>"
        ctaLabel="Elimina"
        onConfirm={() => deleteConf.confirmHandler(deleteConf.item)}
      />

      <PlanDownloadModal
        open={openPrinterModal}
        setOpen={setOpenPrinterModal}
        template={downloadTemplate}
        idOrganization={planData.organization_id}
        idPlan={planData.id}
        statuses={planData.organization_requirements_statuses}
        isAdmin={role === "admin"}
        presets={planData.template_plan.print_templates}
      />

      <PlanAttachmentsPopup
        open={openAttachmentsModal}
        setOpen={setOpenAttachmentsModal}
        attachments={planData?.attachments ?? []}
        organizationPlan={planData}
        onUpdateList={(list) => setCounterAttachments(list?.length ?? 0)}
      />
    </LayoutSidebar>
  );
}

export default PlanCompile;
